interface IFXReserve {
  id: number;
  name: string;
}
export const FXReserve: IFXReserve[] = [
  {
    id: 0,
    name: "تهاتر",
  },
  {
    id: 1,
    name: "غیرتهاتر",
  },
];
