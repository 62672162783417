import { Row } from "antd";
import { styled } from "styled-components";

export const EditContactContainer = styled(Row)`
  display: flex;
  padding: 8px 32px 24px 32px !important;
  gap: 24px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};
`;

export const EditBasicInformationContainer = styled(Row)`
  display: flex;
  padding: 24px 32px;
  gap: 24px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};
`;

export const EditContactsTableContainer = styled(Row)`
  display: flex;
  gap: 24px;
  .tableHolder {
    display: flex;
    padding: 12px;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;
