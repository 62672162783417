import { App, Col, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ContactsTableContainer } from "../../style";
import { useWarehouses } from "src/modules/Warehouses/context";
import { IWarehouseResponderDetailsByIdResult } from "src/services/BaseInfo/models/result.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { Guard } from "src/components/Guard";
import {
  DeleteWarehousePhonePath,
  UpdateWarehousePhonePath,
} from "src/services/BaseInfo/guardPath";

export const RespondentContactTable: FC = () => {
  const {
    dispatch: {
      setResponderContactEditMode,
      setPaginationPhoneNumber,
      setSelectedResponderPhone,
    },
    form: { RCInformationForm },
    value: {
      responderPhoneData,
      responderPhoneDetailLoading,
      paginationPhoneNumber,
    },
    func: { getResponderPhoneDetail },
  } = useWarehouses()!;
  const { words } = useLanguage();
  const { message } = App.useApp();

  const onDeleteResponderPhone = async (
    warehousePhoneId: number,
    responderId: number
  ) => {
    try {
      const { DeleteWarehousePhone } = new BaseInfoService();
      const res = await DeleteWarehousePhone(warehousePhoneId);
      if (res && res.status === 200) {
        message.success(
          words.basicInformation.suppliers.deleteSupplierPhoneMessage
        );
        getResponderPhoneDetail(responderId);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columns: ColumnsType<IWarehouseResponderDetailsByIdResult> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.PhoneNumber,
      dataIndex: "phone",
      key: "phone",
      width: 233,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateWarehousePhonePath}>
            <span
              onClick={() => {
                setResponderContactEditMode(true);
                setSelectedResponderPhone(record);
                RCInformationForm?.setFieldValue("phoneNumber", record.phone);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <Guard action={DeleteWarehousePhonePath}>
            <DPopconfirm
              deleteClassName
              onConfirm={async () => {
                await onDeleteResponderPhone(record.id, record.responderId);
              }}
              description={words.global.deletePhoneNumberPopConfirm}
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <ContactsTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={responderPhoneData}
          loading={responderPhoneDetailLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: false,
            total: paginationPhoneNumber.total,
            current: paginationPhoneNumber.current,
            pageSize: paginationPhoneNumber.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationPhoneNumber((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </ContactsTableContainer>
  );
};
