import { FC } from "react";
import { VesselsContainer } from "./style";
import { VesselsHeader } from "./components/vesselsHeader";
import { AddNewVessel } from "./components/addNewVessel";
import { VesselsTable } from "./components/vesselsTable";

export const Vessels: FC = () => {
  return (
    <div>
      <VesselsHeader />
      <div className="flex justify-center">
        <VesselsContainer>
          <AddNewVessel />
          <VesselsTable />
        </VesselsContainer>
      </div>
    </div>
  );
};
