import { Divider, Tabs } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { TabsProps } from "antd/lib";
import { EditBasicInformation } from "./components/BasicInformation/BasicInformation";
import { useEditTransportCompany } from "./context/context";
import { IEditTransportCompaniesTabs } from "./models";
import { HeaderEditTransportCompany } from "./header";
import { EditTransportCompanyContainer } from "./style/style";
import { EditContact } from "./components/ContactInfo";

export const EditTransportCompanies: FC = () => {
  const { words } = useLanguage();
  const {
    value: { editTransportCompaniesActiveKey },
    dispatch: { setEditTransportCompaniesActiveKey },
  } = useEditTransportCompany()!;

  const onChange = (key: string) => {
    setEditTransportCompaniesActiveKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: IEditTransportCompaniesTabs.basicInformation,
      label: words.global.basicInformation,
      children: <EditBasicInformation />,
    },
    {
      key: IEditTransportCompaniesTabs.Contacts,
      label: words.global.Contacts,
      children: <EditContact />,
    },
  ];

  return (
    <div>
      <HeaderEditTransportCompany />
      <Divider />
      <div className="flex justify-center items-center">
        <EditTransportCompanyContainer>
          <Tabs
            activeKey={editTransportCompaniesActiveKey}
            items={items}
            onChange={onChange}
            centered
            animated
          />
        </EditTransportCompanyContainer>
      </div>
    </div>
  );
};
