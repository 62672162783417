import { FC } from "react";
import { InsuranceCompanies } from "src/modules/InsuranceCompanies";
import { InsuranceCompaniesProvider } from "src/modules/InsuranceCompanies/context";

export const InsuranceCompaniesPages: FC = () => {
  return (
    <InsuranceCompaniesProvider>
      <InsuranceCompanies />
    </InsuranceCompaniesProvider>
  );
};
