import { FC } from "react";
import { Header } from "./components/Header";
import { AddNewProduct } from "./components/AddNewProduct";
import { ProductContainer } from "./style/style";
import { ProductTable } from "./components/ProductsTable";

export const Products: FC = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-center">
        <ProductContainer>
          <AddNewProduct />
          <ProductTable />
        </ProductContainer>
      </div>
    </div>
  );
};
