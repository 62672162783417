import { create } from "zustand";

interface IStore {
  orderCode: string;
  setOrderCode: (orderCode: string) => void;
}

const useOrderStore = create<IStore>((set) => ({
  orderCode: "",
  setOrderCode: (orderCode) => set({ orderCode }),
}));

export default useOrderStore;
