import { IGetPorts } from "../models";

export const portsMockData: IGetPorts[] = [
  {
    id: 1,
    portName: "بندر امیر آباد",
  },
  {
    id: 2,
    portName: "بندر امام",
  },
  {
    id: 3,
    portName: "بندر انزلی",
  },
  {
    id: 4,
    portName: "بندر نوشهر",
  },
];
