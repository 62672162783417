import { Col, Typography } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { AveragePriceHeaderContainer } from "../style";
const { Title } = Typography;

export const AveragePriceHeader: FC = () => {
  const { words } = useLanguage();

  return (
    <AveragePriceHeaderContainer>
      <Col span={24} className="middleHeader">
        <span className="material-symbols-outlined">table_rows</span>
        <Title className="mb-[0px]" level={4}>
          {words.basicInformation.averagePrice.middleHeader}
        </Title>
      </Col>
    </AveragePriceHeaderContainer>
  );
};
