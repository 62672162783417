import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useSuppliers } from "src/modules/suppliers/context/context";
import useLanguage from "src/store/language";
import { BasicInformationContainer } from "./style";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddSupplier } from "src/services/BaseInfo/models/args.models";
import { IAddNewSupplierTabs } from "src/services/Identity/models/mockModels/supp.models";

export const BasicInformation: FC = () => {
  const {
    dispatch: {
      setPressKeyboard,
      setAddNewSupplierActiveKey,
      setBasicInfoEditMode,
    },
    form: { basicInformationForm },
    value: { pressKeyboard, addSupplierLoading, supplierId, basicInfoEditMode },
    func: { onFinishAddSupplier },
  } = useSuppliers()!;
  const { words } = useLanguage();
  const { message } = App.useApp();
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], basicInformationForm);
  useEffect(() => {
    basicInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [basicInformationForm, values]);
  const updateBasicInformation = async (values: IAddSupplier) => {
    try {
      const { UpdateSupplier } = new BaseInfoService();
      const result = await UpdateSupplier(values, supplierId);
      if (result && result.status === 200) {
        message.success(words.basicInformation.suppliers.editSupplierMessage);
        setAddNewSupplierActiveKey(IAddNewSupplierTabs.Contacts);
        setBasicInfoEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  return (
    <Form
      form={basicInformationForm}
      onFinish={
        basicInfoEditMode ? updateBasicInformation : onFinishAddSupplier
      }
    >
      <BasicInformationContainer>
        <Col span={24}>
          <Typography>
            {words.basicInformation.suppliers.suppliersInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name={"name"}
                label={words.basicInformation.suppliers.companyName}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                  onPressEnter={basicInformationForm?.submit}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name={"sellerId"}
                label={words.basicInformation.suppliers.sellerID}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                  onPressEnter={basicInformationForm?.submit}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name={"address"}
                label={words.basicInformation.suppliers.address}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                  onPressEnter={basicInformationForm?.submit}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div className="flex gap-[16px] justify-end borderTop">
            <DPopconfirm
              description={words.global.descriptionPopConfirm}
              onConfirm={() => {
                basicInformationForm?.resetFields();
                setPressKeyboard(false);
              }}
            >
              <Button
                type="default"
                className="!min-w[85px]"
                disabled={!pressKeyboard}
              >
                {words.global.reset}
              </Button>
            </DPopconfirm>
            <Button
              className="!min-w[85px]"
              type="primary"
              disabled={!submittable}
              onClick={basicInformationForm?.submit}
              loading={addSupplierLoading}
            >
              {words.global.confirmationAndNext}
            </Button>
          </div>
        </Col>
      </BasicInformationContainer>
    </Form>
  );
};
