import { Button, Checkbox, Flex } from "antd";
import { FC, useState } from "react";
import useLanguage from "src/store/language";
import styled from "styled-components";
const Style = styled.div`
  width: 100%;
  .content {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-bottom: 1px solid var(--strock, #f3f4f5);
    height: 204px;
  }
  button {
    text-align: center;
    font-family: DM Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 21px;
    padding: 4px 12px;
    border-radius: 6px;
    width: 51px;
  }
`;
interface IProps {
  onChange: (key: string, value?: string) => void;
  onCancel: () => void;
  goToFirstPage: () => void;
}
export const FilterUserState: FC<IProps> = ({
  onChange,
  onCancel,
  goToFirstPage,
}) => {
  const { words } = useLanguage();
  const [checks, setChecks] = useState<{
    Enable: boolean;
    Disable: boolean;
  }>({ Enable: false, Disable: false });
  const reset = () => {
    onChange("Enable");
    setChecks({ Enable: false, Disable: false });
    goToFirstPage();
  };
  const onSubmit = () => {
    const { Enable, Disable } = checks;
    onCancel();
    if ((Enable && Disable) || (!Enable && !Disable)) {
      onChange("Enable");
      goToFirstPage();
      return;
    }
    if (Enable) {
      onChange("Enable", "true");
      goToFirstPage();
      return;
    }
    if (Disable) {
      onChange("Enable", "false");
      goToFirstPage();
      return;
    }
  };
  const onCheck = (type: string, value: boolean) => {
    setChecks((prev) => ({ ...prev, [type]: value }));
  };
  return (
    <Style>
      <div className="content">
        <div>
          <Checkbox
            onChange={(event) => {
              onCheck("Enable", event.target.checked);
            }}
            checked={checks.Enable}
          >
            {words.systemSetting.roleToUser.active}
          </Checkbox>
        </div>
        <div>
          <Checkbox
            onChange={(event) => {
              onCheck("Disable", event.target.checked);
            }}
            checked={checks.Disable}
          >
            {words.systemSetting.roleToUser.inActive}
          </Checkbox>
        </div>
      </div>
      <Flex justify="space-between" align="center" className="w-full mt-[16px]">
        <Button onClick={reset} className="flex justify-center items-center">
          {words.global.reset}
        </Button>
        <Button
          type="primary"
          onClick={onSubmit}
          className="flex justify-center items-center"
        >
          {words.global.ok}
        </Button>
      </Flex>
    </Style>
  );
};
