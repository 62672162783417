import { usePrivateRoutes } from "./private";
import { publicRoutes } from "./public";

const useRoutesDefinition = () => {
  const privateRoutes = usePrivateRoutes();
  const routesDefinition = {
    public: publicRoutes,
    private: privateRoutes,
  };

  return { routesDefinition };
};

export default useRoutesDefinition;
