import { FC, useState } from "react";
import { useAssignRoleToUser } from "./context";
import { AssignRoleToUserContainer } from "./styles";
import { Col, Flex, Popover, Table, Typography } from "antd";
import { getRowKey } from "src/helper/getRowKey";
import { AssignRoleToUserModal } from "./components/AssignRoleToUserModal";
import "./styles/popconfirm.css";
import { FilterUserState } from "./components/FilterUserState";
import { useSearchParams } from "react-router-dom";
import { ResetPassword } from "../ResetPassword";
import { DInput } from "src/components/UiKit/DInput";
import useLanguage from "src/store/language";
export const AssignRoleToUser: FC = () => {
  const {
    values: {
      tableColumns,
      dataSource,
      showAssign,
      treeData,
      currentCheckedKeys,
      loadingRolesOfAction,
      assignLoading,
      allUserQuery,
      showPassword,
      currentUser,
      pagination,
    },
    func: {
      onCancelAssign,
      onAssign,
      onCancelPassword,
      onChangeState,
      goToFirstPage,
    },
    dispatch: { setPagination },
  } = useAssignRoleToUser();
  const { words } = useLanguage();
  const { Title } = Typography;
  const [openStatePopover, setOpenStatePopover] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>("");
  return (
    <AssignRoleToUserContainer justify={"center"} gutter={[0, 24]}>
      <Col span={24} className="header">
        <Flex align="center" justify="space-between">
          <Title className="text-[24px] font-[700] leading-[32px] text-[#5d6881]">
            {words.systemSetting.roleToUser.roleToUser}
          </Title>
          <Flex justify="flex-start" align="center" gap={32}>
            <DInput
              placeholder={words.systemSetting.roleToUser.searchPlaceHolder}
              prefix={
                <span className="material-icons text-[#1B2559]">search</span>
              }
              className="w-[361px] h-[46px] bg-[#E5F3F3]"
              onPressEnter={(event) => {
                const value = event.currentTarget.value;
                onChangeState("UserName", value.trim());
                goToFirstPage();
              }}
              onChange={(event) => {
                setSearchValue(event.target.value);
                if (!event.target.value.trim()) {
                  onChangeState("UserName");
                  goToFirstPage();
                }
              }}
              defaultValue={searchParams.get("UserName") || ""}
              suffix={
                <span
                  className="material-icons cursor-pointer select-none text-[#A3AED0] text-[20px]"
                  onClick={() => {
                    onChangeState("UserName", searchValue.trim());
                  }}
                >
                  subdirectory_arrow_left
                </span>
              }
            />
            <Popover
              title={words.systemSetting.roleToUser.state}
              rootClassName="assign-role-to-user-filter-confirm"
              placement="bottom"
              content={
                <FilterUserState
                  onChange={onChangeState}
                  onCancel={() => setOpenStatePopover((prev) => !prev)}
                  goToFirstPage={goToFirstPage}
                />
              }
              zIndex={10000}
              open={openStatePopover}
            >
              <Flex
                justify="flex-start"
                align="center"
                gap={12}
                className="text-[24px] text-[#008C84] font-[700] select-none cursor-pointer"
                onClick={() => setOpenStatePopover((prev) => !prev)}
              >
                <span className="material-icons">filter_alt </span>
                <span className="text-[20px] text-[#008C84]">
                  {words.systemSetting.roleToUser.state}
                </span>
              </Flex>
            </Popover>
          </Flex>
        </Flex>
      </Col>
      <Col span={20} order={2} className="content">
        <Table
          columns={tableColumns}
          dataSource={dataSource}
          loading={allUserQuery?.isFetching}
          rowKey={getRowKey}
          scroll={{ x: 800 }}
          rowClassName={(record, index) =>
            index % 2 !== 0 ? "bg-[#F2F7FF]" : ""
          }
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: pagination.total,
            current: pagination.current,
            onChange: (page: number, pageSize: number) => {
              setPagination((prev) => ({ ...prev, current: page }));
              onChangeState("Limit", pageSize.toString());
              onChangeState("Offset", page.toString());
            },
            onShowSizeChange: (current: number, size: number) => {
              onChangeState("Limit", size.toString());
              onChangeState("Offset", current.toString());
              setPagination((prev) => ({ ...prev, current }));
            },
          }}
        />
      </Col>
      <AssignRoleToUserModal
        open={showAssign}
        onCancel={onCancelAssign}
        title={words.systemSetting.roleToUser.roleToUser}
        treeData={treeData}
        onAssign={onAssign}
        currentCheckedKeys={currentCheckedKeys}
        loading={loadingRolesOfAction}
        confirmLoading={assignLoading}
      />
      <ResetPassword
        isModalOpen={showPassword}
        onCancel={onCancelPassword}
        currentUser={currentUser}
      />
    </AssignRoleToUserContainer>
  );
};
