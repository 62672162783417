import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useInsuranceCompanies } from "../../context";
import { IAddNewInsuranceCompanyTabs } from "./models";
import { AddNewInsuranceCompanyContainer } from "./style";
import { BasicInformation } from "./components/BasicInformation/BasicInformation";
import { Contact } from "./components/ContactInfo";

export const AddNewInsuranceCompanies: FC = () => {
  const { words } = useLanguage();
  const {
    value: { addNewInsuranceCompaniesActiveKey },
  } = useInsuranceCompanies()!;

  const items: TabsProps["items"] = [
    {
      key: IAddNewInsuranceCompanyTabs.basicInformation,
      label: words.global.basicInformation,
      children: <BasicInformation />,
    },
    {
      key: IAddNewInsuranceCompanyTabs.Contacts,
      label: words.global.Contacts,
      children: <Contact />,
    },
  ];

  return (
    <AddNewInsuranceCompanyContainer>
      <Tabs
        activeKey={addNewInsuranceCompaniesActiveKey}
        items={items}
        centered
        animated
      />
    </AddNewInsuranceCompanyContainer>
  );
};
