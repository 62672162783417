import { Form, FormInstance, TreeSelect, TreeSelectProps, message } from "antd";
import { DefaultOptionType } from "antd/es/select";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import { INewRoleArg, IRole } from "src/services/UserManagement/models";
import useLanguage from "src/store/language";
const { SHOW_PARENT } = TreeSelect;

interface IContext {
  roles: IRole[];
  treeProps: TreeSelectProps;
  showConfirm: boolean;
  confirmLoading: boolean;
  createRoleForm: FormInstance | undefined;
  onFinish: (value: INewRoleArg) => void;
  onConfirm: () => Promise<void>;
  onCancelConfirm: () => void;
}

const defaultContextValue: IContext = {
  roles: [],
  treeProps: {},
  showConfirm: false,
  confirmLoading: false,
  createRoleForm: undefined,
  onFinish: () => {},
  onConfirm: async () => {},
  onCancelConfirm: () => {},
};

export const CreateRoleCTX = createContext<IContext>(defaultContextValue);

export const CreateRoleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { words } = useLanguage();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [newRoleData, setNewRoleData] = useState<INewRoleArg>();
  const [value, setValue] = useState<number>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [getRolesLoading, setRolesLoading] = useState<boolean>(false);
  const [createRoleForm] = Form.useForm();
  const onChange = (newValue: number) => {
    setValue(newValue);
  };
  const createTree = (role: IRole) => {
    const newRole: DefaultOptionType = {
      label: role.roleName,
      value: role.id,
      key: role.id,
    };
    if (role.childeren.length > 0) {
      newRole.children = role.childeren.map((item) => createTree(item));
    }
    return newRole;
  };
  const data = roles.map((item) => createTree(item))[0]?.children;
  const treeProps: TreeSelectProps = useMemo(
    () => ({
      treeData: data,
      value,
      onChange,
      treeCheckable: false,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: "select",
      style: {
        width: "100%",
      },
      loading: getRolesLoading,
    }),
    [data, getRolesLoading, value]
  );
  const onFinish = (values: INewRoleArg) => {
    setNewRoleData(values);
    if (values) setShowConfirm(true);
  };
  const onCancelConfirm = () => {
    setShowConfirm(false);
  };
  const onConfirm = async () => {
    if (!newRoleData) {
      message.error(words.systemSetting.createRole.noDataMessage);
      return;
    }
    try {
      setConfirmLoading(true);
      const { NewRole } = new UserManagementService();
      const result = await NewRole(newRoleData);
      if (result && result.status === 200) {
        onCancelConfirm();
        fetchRoles().finally(() => {
          message.success(words.systemSetting.createRole.newRoleMessage);
          createRoleForm.resetFields();
        });
      }
    } catch (err) {
      setConfirmLoading(false);
      console.log(err);
    }
  };
  const fetchRoles = async () => {
    setRolesLoading(true);
    getRoles()
      .then((roles) => {
        if (roles && roles.length) {
          setRoles(roles);
        }
      })
      .finally(() => {
        setRolesLoading(false);
      });
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  const contextValue: IContext = {
    roles,
    treeProps,
    showConfirm,
    confirmLoading,
    createRoleForm,
    onFinish,
    onConfirm,
    onCancelConfirm,
  };
  return (
    <CreateRoleCTX.Provider value={contextValue}>
      {children}
    </CreateRoleCTX.Provider>
  );
};
export const useCreateRoleContext = () => useContext(CreateRoleCTX);
const getRoles = async () => {
  try {
    const { Roles } = new UserManagementService();
    const res = await Roles();
    if (res && res.status === 200 && res.data) {
      return res.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
