import { styled } from "styled-components";

export const LogoutStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  .material-symbols-outlined {
    color: ${({ theme }) => theme.colors.gray[500]};
  }
`;
