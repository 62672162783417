import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { IAveragePriceTableData } from "../models";
import { useAveragePrice } from "../context/context";
import moment from "moment-jalaali";

export const AddNewAveragePrice: FC = () => {
  const { words } = useLanguage();
  const [submittable, setSubmittable] = useState<boolean>(false);
  const {
    dispatch: { setDataTable, setCurrentEditValue, setEditMode },
    value: { formController, editMode, dataTable, currentEditValue },
  } = useAveragePrice();
  const formValues = Form.useWatch([], formController);
  const onFinish = (values: IAveragePriceTableData) => {
    if (editMode) {
      values.date = moment(values.date).format("jYYYY/jMM/jDD");
      const newData = { ...currentEditValue, ...values };
      const data = dataTable.map((i) => {
        if (i.id === newData.id) {
          return newData;
        } else {
          return i;
        }
      });

      setDataTable(data);
      formController?.resetFields();
      setCurrentEditValue(undefined);
      setEditMode(false);
    } else {
      values.id = Math.floor(Math.random() * 1000) + 1;
      values.date = moment(values.date).format("jYYYY/jMM/jDD");

      setDataTable((perv) => [...perv, { ...values }]);
      formController?.resetFields();
    }
  };
  useEffect(() => {
    formController
      ?.validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [formController, formValues]);

  const onCancel = () => {
    if (editMode) {
      setEditMode(false);
    }
    formController?.resetFields();
  };
  return (
    <Form form={formController} onFinish={onFinish}>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={24} className="py-[16px]">
            <Typography className="text-[16px]">
              {words.basicInformation.averagePrice.averagePriceInformation}
            </Typography>
          </Col>
          <Col span={8} className="mt-[24px]">
            <FormItem
              label={words.basicInformation.averagePrice.product}
              rules={[{ required: true }]}
              name="product"
            >
              <Select
                placeholder={words.global.select}
                allowClear
                showSearch
                optionFilterProp="label"
                options={[
                  { label: "test", value: "test" },
                  { label: "test 2", value: "test 2" },
                  { label: "test 3", value: "test 3" },
                ]}
              />
            </FormItem>
          </Col>
          <Col span={8} className="mt-[24px]">
            <FormItem
              label={words.basicInformation.averagePrice.averagePriceInput}
              name={"averagePrice"}
            >
              <Input placeholder={words.global.insert} />
            </FormItem>
          </Col>
          <Col span={8} className="mt-[24px]">
            <FormItem
              label={words.basicInformation.averagePrice.date}
              name={"date"}
            >
              <DatePicker placeholder={words.global.select} />
            </FormItem>
          </Col>
          <Col span={24}>
            <div className="flex gap-[16px] justify-end pb-[24px] borderBottom">
              <Button type="default" onClick={onCancel}>
                {editMode ? words.global.cancel : words.global.reset}
              </Button>
              <Button type="primary" disabled={!submittable} htmlType="submit">
                {editMode ? words.global.saveEdit : words.global.add}
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
