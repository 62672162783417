import { App, Col, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ContactsTableContainer } from "../../style";
import { useTransportCompanies } from "src/modules/TransportCompanies/context";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { ITransportResponderDetailsByIdResult } from "src/services/BaseInfo/models/result.models";

export const RespondentContactTable: FC = () => {
  const {
    dispatch: {
      setPaginationPhoneNumber,
      setResponderContactEditMode,
      setResponderPhoneId,
      setIsAddPhoneNumber,
    },
    form: { RCInformationForm },
    value: {
      TransportResponderId,
      responderPhoneDetailLoading,
      responderPhoneData,
      paginationPhoneNumber,
    },
    func: { getResponderPhoneDetail },
  } = useTransportCompanies()!;
  const { words } = useLanguage();
  const { message } = App.useApp();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  useEffect(() => {
    if (TransportResponderId) getResponderPhoneDetail(TransportResponderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResponderPhoneDetail]);

  const onDeleteResponderPhone = async (
    TransportPhoneId: number,
    responderId: number
  ) => {
    try {
      setDeleteLoading(true);
      const { DeleteTransportPhone } = new BaseInfoService();
      const res = await DeleteTransportPhone(TransportPhoneId);
      if (res && res.status === 200) {
        message.success(
          words.basicInformation.transportCompanies.deleteTransportPhoneMessage
        );
        setIsAddPhoneNumber(false);
        getResponderPhoneDetail(responderId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setOpenDeleteConfirm(undefined);
    }
  };
  const columns: ColumnsType<ITransportResponderDetailsByIdResult> = [
    {
      title: " #",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.PhoneNumber,
      dataIndex: "phone",
      key: "phone",
      width: 233,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.tools,
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setResponderContactEditMode(true);
              setResponderPhoneId(record.id);
              RCInformationForm?.setFieldsValue({
                responder: record.responderId,
                phoneNumber: record.phone,
              });
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <DPopconfirm
            deleteClassName
            onConfirm={() => {
              onDeleteResponderPhone(record.id, record.responderId);
            }}
            description={words.global.deletePhoneNumberPopConfirm}
            okButtonProps={{
              loading: deleteLoading,
            }}
            open={openDeleteConfirm === record.id}
            onCancel={() => {
              setOpenDeleteConfirm(undefined);
            }}
          >
            <span
              className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]"
              onClick={() => {
                setOpenDeleteConfirm(record.id);
              }}
            >
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];
  return (
    <ContactsTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={responderPhoneData}
          loading={responderPhoneDetailLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: false,
            total: paginationPhoneNumber.total,
            current: paginationPhoneNumber.current,
            pageSize: paginationPhoneNumber.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationPhoneNumber((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </ContactsTableContainer>
  );
};
