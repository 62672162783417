import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  IAddNewSupplierTabs,
  ISuppliersTabs,
} from "src/services/Identity/models/mockModels/supp.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { ResponderTableData, TableData } from "../models";
import { ITablePagination } from "src/models/interfaces/pagination";
import { IAddSupplier } from "src/services/BaseInfo/models/args.models";
import useLanguage from "src/store/language";
import {
  IAllSupplierResponderDetailsResult,
  ISupplierResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";
import { IObject } from "src/models/interfaces";

interface IContext {
  value: {
    suppliersActiveKey: string;
    addNewSupplierActiveKey: string;
    selectedResponsive: ResponderTableData | undefined;
    isModalOpen: boolean;
    pressKeyboard: boolean;
    tableRecord: string;
    dataSource: TableData[];
    loading: boolean;
    paginationSupplier: ITablePagination;
    paginationResponder: ITablePagination;
    paginationPhoneNumber: ITablePagination;
    paginationSupplierDetail: ITablePagination;
    supplierId: number;
    allRespondersData: ResponderTableData[];
    responderEditMode: boolean;
    responderContactEditMode: boolean;
    supplierResponderId: number | undefined;
    isAddPhoneNumber: boolean;
    addSupplierLoading: boolean;
    allSupplierResponders: boolean;
    supplierDetailLoading: boolean;
    responderPhoneDetailLoading: boolean;
    responderPhoneData: ISupplierResponderDetailsByIdResult[];
    supplierDetailData: IAllSupplierResponderDetailsResult[];
    basicInfoEditMode: boolean;
    phoneId: number | undefined;
  };
  dispatch: {
    setSuppliersActiveKey: Dispatch<SetStateAction<string>>;
    setAddNewSupplierActiveKey: Dispatch<SetStateAction<string>>;
    setResponderEditMode: Dispatch<SetStateAction<boolean>>;
    setResponderContactEditMode: Dispatch<SetStateAction<boolean>>;
    setSelectedResponsive: Dispatch<
      SetStateAction<ResponderTableData | undefined>
    >;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;
    setTableRecord: Dispatch<SetStateAction<string>>;
    setPaginationSupplier: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationResponder: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationPhoneNumber: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationSupplierDetail: Dispatch<SetStateAction<ITablePagination>>;
    setSupplierResponderId: Dispatch<SetStateAction<number | undefined>>;
    setResponderPhoneData: Dispatch<
      SetStateAction<ISupplierResponderDetailsByIdResult[]>
    >;
    setBasicInfoEditMode: Dispatch<SetStateAction<boolean>>;
    setPhoneId: Dispatch<SetStateAction<number | undefined>>;
    setIsAddPhoneNumber: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    getTableData: () => void;
    onFinishAddSupplier: (values: IAddSupplier) => void;
    GetAllSupplierResponders: () => void;
    getResponderPhoneDetail: (id: number) => void;
    GetAllSupplierResponderDetails: (supplierId: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
    basicInformationForm: FormInstance<any> | undefined;
    contactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const SuppliersContext = createContext<IContext | undefined>(undefined);

export const SuppliersProvider: FC<PropsWithChildren> = ({ children }) => {
  const { words } = useLanguage();
  const [suppliersActiveKey, setSuppliersActiveKey] = useState<string>(
    ISuppliersTabs.tableOfSuppliers
  );
  const [addNewSupplierActiveKey, setAddNewSupplierActiveKey] =
    useState<string>(IAddNewSupplierTabs.basicInformation);
  const [responderEditMode, setResponderEditMode] = useState<boolean>(false);
  const [responderContactEditMode, setResponderContactEditMode] =
    useState<boolean>(false);
  const [selectedResponsive, setSelectedResponsive] =
    useState<ResponderTableData>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tableRecord, setTableRecord] = useState<string>("");
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [allSupplierResponders, setAllSupplierResponders] =
    useState<boolean>(false);
  const [addSupplierLoading, setAddSupplierLoading] = useState<boolean>(false);
  const [responderPhoneDetailLoading, setResponderPhoneDetailLoading] =
    useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [paginationSupplier, setPaginationSupplier] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [paginationResponder, setPaginationResponder] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationPhoneNumber, setPaginationPhoneNumber] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationSupplierDetail, setPaginationSupplierDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [supplierId, setSupplierId] = useState<number>(-1);
  const [allRespondersData, setAllRespondersData] = useState<
    ResponderTableData[]
  >([]);
  const [supplierResponderId, setSupplierResponderId] = useState<number>();
  const [responderPhoneData, setResponderPhoneData] = useState<
    ISupplierResponderDetailsByIdResult[]
  >([]);
  const [isAddPhoneNumber, setIsAddPhoneNumber] = useState<boolean>(false);
  const [supplierDetailLoading, setSupplierDetailLoading] =
    useState<boolean>(false);
  const [supplierDetailData, setSupplierDetailData] = useState<
    IAllSupplierResponderDetailsResult[]
  >([]);

  const [phoneId, setPhoneId] = useState<number>();

  const [basicInfoEditMode, setBasicInfoEditMode] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [basicInformationForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();

  ////////////////////////////functions////////////////////////////////////////////////
  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      const { GetAllSupplier } = new BaseInfoService();

      const params: IObject = {
        Offset: paginationSupplier.current,
        Limit: paginationSupplier.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllSupplier(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPaginationSupplier((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationSupplier.current, paginationSupplier.pageSize]);

  const onFinishAddSupplier = async (values: IAddSupplier) => {
    setAddSupplierLoading(true);
    try {
      const { AddSupplier } = new BaseInfoService();
      const result = await AddSupplier(values);
      if (result && result.status === 200) {
        message.success(words.basicInformation.suppliers.addSupplierMessage);
        setAddNewSupplierActiveKey(IAddNewSupplierTabs.Contacts);
        setSupplierId(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddSupplierLoading(false);
    }
  };

  const GetAllSupplierResponders = useCallback(async () => {
    setAllSupplierResponders(true);
    try {
      const { GetAllSupplierResponders: GetAllSupplierR } =
        new BaseInfoService();
      const params: IObject = {
        Offset: paginationResponder.current,
        Limit: paginationResponder.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllSupplierR(searchParams.toString(), supplierId);
      if (result && result.status === 200 && result.data) {
        setAllRespondersData(result.data.records);
        setPaginationResponder((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllSupplierResponders(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationResponder.current, paginationResponder.pageSize, supplierId]);

  const getResponderPhoneDetail = useCallback(
    async (id: number) => {
      setResponderPhoneDetailLoading(true);
      try {
        const { GetSupplierResponderDetailsById } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationPhoneNumber.current,
          Limit: paginationPhoneNumber.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetSupplierResponderDetailsById(
          searchParams.toString(),
          id
        );
        if (result && result.status === 200) {
          setResponderPhoneData(result.data.records);
          setPaginationPhoneNumber((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }

        if (result && result?.data.count > 0) {
          setIsAddPhoneNumber(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setResponderPhoneDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationPhoneNumber.current, paginationPhoneNumber.pageSize]
  );

  const GetAllSupplierResponderDetails = useCallback(
    async (supplierId: number) => {
      if (!(supplierId > 0)) return;
      setSupplierDetailLoading(true);
      try {
        const { GetAllSupplierResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationSupplierDetail.current,
          Limit: paginationSupplierDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllSupplierResponderDetails(
          supplierId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setSupplierDetailData(result.data.records);
          setPaginationSupplierDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setSupplierDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationSupplierDetail.current, paginationSupplierDetail.pageSize]
  );
  const ContextValue: IContext = {
    value: {
      suppliersActiveKey,
      addNewSupplierActiveKey,
      responderEditMode,
      selectedResponsive,
      isModalOpen,
      tableRecord,
      pressKeyboard,
      dataSource,
      loading,
      paginationPhoneNumber,
      paginationResponder,
      paginationSupplier,
      supplierId,
      allRespondersData,
      supplierResponderId,
      isAddPhoneNumber,
      addSupplierLoading,
      allSupplierResponders,
      responderPhoneDetailLoading,
      responderPhoneData,
      responderContactEditMode,
      supplierDetailData,
      paginationSupplierDetail,
      supplierDetailLoading,
      basicInfoEditMode,
      phoneId,
    },
    dispatch: {
      setSuppliersActiveKey,
      setAddNewSupplierActiveKey,
      setResponderEditMode,
      setSelectedResponsive,
      setIsModalOpen,
      setTableRecord,
      setPressKeyboard,
      setPaginationPhoneNumber,
      setPaginationResponder,
      setPaginationSupplier,
      setSupplierResponderId,
      setResponderPhoneData,
      setResponderContactEditMode,
      setPaginationSupplierDetail,
      setBasicInfoEditMode,
      setPhoneId,
      setIsAddPhoneNumber,
    },
    func: {
      getTableData,
      onFinishAddSupplier,
      GetAllSupplierResponders,
      getResponderPhoneDetail,
      GetAllSupplierResponderDetails,
    },
    form: {
      form,
      basicInformationForm,
      contactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  return (
    <SuppliersContext.Provider value={ContextValue}>
      {children}
    </SuppliersContext.Provider>
  );
};

export const useSuppliers = () => useContext(SuppliersContext);
