import { Divider, Popconfirm, PopconfirmProps } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";

interface IDPopconfirm extends Omit<PopconfirmProps, "title"> {
  endPointName: string;
}
export const DeletePopconfirm: FC<IDPopconfirm> = (props) => {
  const { words } = useLanguage();
  const { children, endPointName } = props;
  return (
    <Popconfirm
      {...props}
      icon=""
      placement="topRight"
      title={
        <div className="pt-[8px] pb-[16px]">
          <div className="text-[#EC2D30] text-[18px] font-bold">
            {words.global.warning}
          </div>
          <div className="text-[16px] text-[#8E8E8E] font-normal	pb-[4px]">
            {endPointName}
          </div>
          <Divider />
        </div>
      }
      description={words.global.deleteDesc}
      rootClassName="descHolder"
      okText={
        <div className="flex gap-[12px] bg-[#F64C4C] text-[#fff] w-full h-full justify-center items-center">
          <span className="material-icons text-[20px]">delete_forever</span>
          <div className="text-[14px]">{words.global.delete}</div>
        </div>
      }
      cancelText={
        <div className="flex gap-[12px]">
          <span className="material-icons text-[20px]">highlight_off</span>
          <div className="text-[14px]">{words.global.cancel}</div>
        </div>
      }
      okType="danger"
    >
      <>{children}</>
    </Popconfirm>
  );
};
