import { Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { IEditContactsTable, IEditWarehousesTabs } from "../models";

interface IContext {
  value: {
    editWarehousesActiveKey: string;
    editContact: boolean;
    editContactInfo: IEditContactsTable | undefined;
    isRegister: boolean;
  };
  dispatch: {
    setEditWarehousesActiveKey: Dispatch<SetStateAction<string>>;
    setEditContactInfo: Dispatch<
      SetStateAction<IEditContactsTable | undefined>
    >;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setIsRegister: Dispatch<SetStateAction<boolean>>;
  };
  func: {};
  form: {
    form: FormInstance<any> | undefined;
    editBasicInformationForm: FormInstance<any> | undefined;
    editContactForm: FormInstance<any> | undefined;
  };
}

export const EditWarehousesContext = createContext<IContext | undefined>(
  undefined
);

export const EditWarehousesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [editWarehousesActiveKey, setEditWarehousesActiveKey] =
    useState<string>(IEditWarehousesTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [editContactInfo, setEditContactInfo] = useState<IEditContactsTable>();
  const [isRegister, setIsRegister] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [editBasicInformationForm] = Form.useForm();
  const [editContactForm] = Form.useForm();
  const ContextValue: IContext = {
    value: {
      editWarehousesActiveKey,
      editContact,
      editContactInfo,
      isRegister,
    },
    dispatch: {
      setEditWarehousesActiveKey,
      setEditContactInfo,
      setEditContact,
      setIsRegister,
    },
    func: {},
    form: { form, editBasicInformationForm, editContactForm },
  };
  return (
    <EditWarehousesContext.Provider value={ContextValue}>
      {children}
    </EditWarehousesContext.Provider>
  );
};

export const useEditWarehouses = () => useContext(EditWarehousesContext);
