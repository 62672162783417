import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { EditContactsTable } from "./ContactTable";
import { useEditClearanceWorkers } from "../../context/context";
import { EditContactContainer } from "./style";
import { useLocation } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAllCustomBrokerResult } from "src/services/BaseInfo/models/result.models";

export const EditContact: FC = () => {
  const {
    value: { editContact, customBrokerPhoneId },
    dispatch: { setEditContact },
    form: { editContactForm },
    func: { GetAllCustomBrokerPhone },
  } = useEditClearanceWorkers()!;

  const { words } = useLanguage();
  const location = useLocation();
  const data: IAllCustomBrokerResult = location.state;

  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], editContactForm);
  useEffect(() => {
    editContactForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onClickCancel = () => {
    editContactForm?.resetFields();
    setEditContact(false);
  };

  const onFinish = async (value: { phoneNumber: string }) => {
    if (!data.id) return console.log("CustomBrokerId not found");
    setLoading(true);
    try {
      const { AddCustomBrokerPhone } = new BaseInfoService();
      const result = await AddCustomBrokerPhone(value.phoneNumber, data.id);
      if (result && result.status === 200) {
        GetAllCustomBrokerPhone(data.id);
        setPressKeyboard(false);
        editContactForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const update = async (value: { phoneNumber: string }) => {
    if (!customBrokerPhoneId || !data.id)
      return console.log("customBrokerPhoneId or CustomBrokerId not found");
    setLoading(true);
    try {
      const { UpdateCustomBrokerPhone } = new BaseInfoService();
      const result = await UpdateCustomBrokerPhone(
        customBrokerPhoneId,
        value.phoneNumber
      );
      if (result && result.status === 200) {
        GetAllCustomBrokerPhone(data.id);
        setPressKeyboard(false);
        editContactForm?.resetFields();
        setEditContact(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form form={editContactForm} onFinish={editContact ? update : onFinish}>
      <EditContactContainer>
        <Col span={24}>
          <Typography className="p-[16px]">
            {words.basicInformation.clearanceWorkers.editContactInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name={"phoneNumber"}
                label={words.basicInformation.clearanceWorkers.phoneNumber}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.phoneNumberPLaceHolder}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <div className="flex gap-[16px] justify-end items-center h-full">
                {editContact ? (
                  ""
                ) : (
                  <div className="flex gap-[16px]">
                    <Button
                      type="primary"
                      ghost
                      disabled={!submittable && !editContact}
                      loading={loading}
                      onClick={editContactForm?.submit}
                    >
                      {words.global.addNewPhoneNumber}
                    </Button>
                  </div>
                )}
                {editContact ? (
                  <Button className="!min-w[85px]" onClick={onClickCancel}>
                    {words.global.cancel}
                  </Button>
                ) : (
                  ""
                )}
                {editContact ? (
                  <Button
                    className="!min-w[85px]"
                    type="primary"
                    disabled={!pressKeyboard}
                    onClick={editContactForm?.submit}
                  >
                    {words.global.saveEdit}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <EditContactsTable />
        </Col>
      </EditContactContainer>
    </Form>
  );
};
