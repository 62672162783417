import { Divider, Form, Tabs } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { TabsProps } from "antd/lib";
import { EditBasicInformation } from "./components/BasicInformation/BasicInformation";
import { EditContact } from "./components/ContactInfo";
import { useEditInspectionCompanies } from "./context/context";
import { HeaderEditInspectionCompanies } from "./header";
import { EditInspectionCompaniesContainer } from "./style/style";
import { IEditInspectionCompaniesTabs } from "./models/models";

export const EditInspectionCompanies: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    value: { editInspectionCompaniesActiveKey },
    dispatch: { setEditInspectionCompaniesActiveKey },
  } = useEditInspectionCompanies()!;

  const onChange = (key: string) => {
    setEditInspectionCompaniesActiveKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: IEditInspectionCompaniesTabs.basicInformation,
      label: words.global.basicInformation,
      children: <EditBasicInformation />,
    },
    {
      key: IEditInspectionCompaniesTabs.Contacts,
      label: words.global.Contacts,
      children: <EditContact />,
    },
  ];
  const onFinish = (values: any) => {};

  return (
    <Form form={form} onFinish={onFinish}>
      <HeaderEditInspectionCompanies />
      <Divider />
      <div className="flex justify-center items-center">
        <EditInspectionCompaniesContainer>
          <Tabs
            activeKey={editInspectionCompaniesActiveKey}
            items={items}
            onChange={onChange}
            centered
            animated
          />
        </EditInspectionCompaniesContainer>
      </div>
    </Form>
  );
};
