import { ReactNode } from "react";

export enum IClearanceWorkersTabs {
  tableOfClearanceWorkers = "1",
  addNewClearanceWorkers = "2",
}
export interface IPhoneNumber {
  name: string;
  phoneNumber: string;
}
export interface IGetClearanceWorkers {
  id: number;
  workClearanceName: string;
  workPlace: string;
  phoneNumber: IPhoneNumber[];
}
export interface TableData extends IGetClearanceWorkers {
  tools?: ReactNode;
}
export const dataSource: TableData[] = [
  {
    id: 0,
    workClearanceName: "Jafari",
    workPlace: "Amirabad port",
    phoneNumber: [{ name: "Hamid behruzi", phoneNumber: "+7 (917) 091-85-06" }],
  },
  {
    id: 1,
    workClearanceName: "Parsian insurance",
    workPlace: "Tehran, Waliasr St., above Mirdamad ",
    phoneNumber: [{ name: "Ms. Shafii", phoneNumber: "9012963829" }],
  },
  {
    id: 2,
    workClearanceName: "Asan tarabar",
    workPlace: "Mazandaran - Amirabad Behshahr",
    phoneNumber: [
      { name: "Hasan Talai", phoneNumber: "01134614116" },
      {
        name: "Seyed mehdi moosavy eslam abadi",
        phoneNumber: "+7 (917) 091-60-06",
      },
    ],
  },
];
