import { FC, useState } from "react";
import { UserStateStyle } from "./userState.style";
import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import { Popconfirm, message } from "antd";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { IDataModel } from "src/services/models";
import { IUsersReport } from "src/services/UserManagement/models";
import useLanguage from "src/store/language";
type refetchType = <TPageData>(
  options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<
  QueryObserverResult<IDataModel<IUsersReport[]> | undefined, unknown>
>;

interface IProps {
  state: boolean;
  userId: number;
  refetch: refetchType;
}
export const UserState: FC<IProps> = ({ state, userId, refetch }) => {
  const { words } = useLanguage();
  const { Enable, Disable } = new UserManagementService();
  const [loading, setLoading] = useState<{ enable: boolean; disable: boolean }>(
    {
      disable: false,
      enable: false,
    }
  );
  const OnEnableUser = async () => {
    setLoading((prev) => ({ ...prev, enable: true }));
    try {
      const result = await Enable(userId);
      if (result && result.status) {
        message.success(words.systemSetting.roleToUser.OnEnableUserMessage);
        refetch();
      }
    } catch (err) {
      console.log("failed enable  user", err);
      message.error(words.systemSetting.roleToUser.OnEnableUserFailedMessage);
    } finally {
      setLoading((prev) => ({ ...prev, enable: false }));
    }
  };
  const OnDisableUser = async () => {
    setLoading((prev) => ({ ...prev, disable: true }));

    try {
      const result = await Disable(userId);
      if (result && result.status) {
        message.success(words.systemSetting.roleToUser.OnDisableUserMessage);
        refetch();
      }
    } catch (err) {
      console.log("failed Disable user", err);
      message.error(words.systemSetting.roleToUser.OnEnableUserFailedMessage);
    } finally {
      setLoading((prev) => ({ ...prev, disable: false }));
    }
  };
  return (
    <UserStateStyle>
      <Popconfirm
        title={words.systemSetting.roleToUser.userStatus}
        description={
          <div className="mb-4">
            {words.systemSetting.roleToUser.userStatusDisableMessage}
          </div>
        }
        onConfirm={OnDisableUser}
        okButtonProps={{
          loading: loading.disable,
        }}
      >
        <div className={` defaultItem ${!state ? "inactive" : ""}`}>
          {words.systemSetting.roleToUser.inActive}
        </div>
      </Popconfirm>
      <Popconfirm
        title={words.systemSetting.roleToUser.userStatus}
        description={
          <div className="mb-4">
            {words.systemSetting.roleToUser.userStatusEnableMessage}
          </div>
        }
        onConfirm={OnEnableUser}
        okButtonProps={{
          loading: loading.enable,
        }}
      >
        <div className={` defaultItem ${state ? "active" : ""}`}>
          {words.systemSetting.roleToUser.active}
        </div>
      </Popconfirm>
    </UserStateStyle>
  );
};
