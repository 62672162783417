import { Col, Typography, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import { ProductTableContainer } from "../style/style";
import { TableData } from "../models/models";
import { useProducts } from "../context/context";
import { Guard } from "src/components/Guard";
import {
  DeleteProductPath,
  UpdateProductPath,
} from "src/services/BaseInfo/guardPath";
import { useSearchParams } from "react-router-dom";
import { IAllProductResult } from "src/services/BaseInfo/models/result.models";

export const ProductTable: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    dispatch: { setEditProduct, setPagination, setProductId },
    value: { dataSource, pagination, productLoading },
    func: { getProductsTableData, deleteProduct },
  } = useProducts()!;

  const onChangeParams = useChangeParams();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.current.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);
  useEffect(() => {
    getProductsTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns: ColumnsType<TableData> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.products.productsName,
      dataIndex: "name",
      key: "name",
      width: 420,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.basicInformation.products.tariffCode,
      dataIndex: "hsCode",
      key: "hsCode",
      width: 152,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record: IAllProductResult) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateProductPath}>
            <span
              onClick={() => {
                setEditProduct(true);
                form?.setFieldsValue(record);
                setProductId(record.id);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <Guard action={DeleteProductPath}>
            <DPopconfirm
              deleteClassName
              onConfirm={() => deleteProduct(record.id)}
              description={words.basicInformation.products.deletePopConfirm}
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <ProductTableContainer>
      <Col span={24}>
        <Typography className="py-[16px]">
          {words.basicInformation.products.tableOfProducts}
        </Typography>
      </Col>
      <Col span={24}>
        <div className="tableHolder">
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={productLoading}
            scroll={{ x: "auto" }}
            rowKey={getRowKey}
            className="w-[100%]"
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: pagination.total,
              current: pagination.current,
              onChange: (page: number, pageSize: number) => {
                setPagination((prev) => ({ ...prev, current: page, pageSize }));
                onChangeParams("Limit", pageSize.toString());
                onChangeParams("Offset", page.toString());
              },
              onShowSizeChange: (current: number, size: number) => {
                onChangeParams("Limit", size.toString());
                onChangeParams("Offset", current.toString());
                setPagination((prev) => ({ ...prev, current }));
              },
            }}
          />
        </div>
      </Col>
    </ProductTableContainer>
  );
};
