import { ReactNode } from "react";

export enum IAddNewInspectionCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface IContactsTable {
  id: number;
  name: string;
  phoneNumber: string;
}
export interface TableData extends IContactsTable {
  tools?: ReactNode;
}

export const dataSource: TableData[] = [
  {
    id: 0,
    name: "حمید بهروزی",
    phoneNumber: "+7(917) 091-85-06",
  },
  {
    id: 1,
    name: "سید مهدی موسوی اسلام آبادی",
    phoneNumber: "+7 (917) 091-60-09",
  },
  {
    id: 2,
    name: "حامد نظری",
    phoneNumber: "09123456789",
  },
];
