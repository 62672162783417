import { IPath } from "../Identity/models";
export const AllApiPath: IPath[] = [
  {
    path: "api/BaseInfo/AddCountry",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllCountry",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CountryId}/UpdateCountry",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{CountryId}/DeleteCountry",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddSupplier",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{SupplierId}/UpdateSupplier",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{SupplierId}/DeleteSupplier",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{SupplierResponderId}/AddSupplierPhone",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{SupplierPhoneId}/UpdateSupplierPhone",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{SupplierPhoneId}/DeleteSupplierPhone",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddPort",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllPort",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{PortId}/UpdatePort",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{PortId}/DeletePort",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddVessel",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllVessel",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{VesselId}/UpdateVessel",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{VesselId}/DeleteVessel",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddCurrency",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllCurrency",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CurrencyId}/UpdateCurrency",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{CurrencyId}/DeleteCurrency",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddUnit",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllUnit",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{UnitId}/UpdateUnit",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{UnitId}/DeleteUnit",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddProduct",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllProduct",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{ProductId}/UpdateProduct",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{ProductId}/DeleteProduct",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddBank",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllBank",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{BankId}/UpdateBank",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{BankId}/DeleteBank",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{BankId}/AddBankResponder",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{BankId}/AllBankResponders",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{BankResponderId}/UpdateBankResponder",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{BankResponderId}/DeleteBankResponder",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{ResponderId}/AddBankPhone",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{ResponderId}/BankPhonesByResponderId",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{BankPhoneId}/UpdateBankPhone",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{BankPhoneId}/DeleteBankPhone",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddInsurance",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllInsurace",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{InsuranceId}/UpdateInsurance",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{InsuranceId}/DeleteInsurance",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{InsuranceId}/AddInsuranceResponder",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{InsuranceId}/AllInsuranceResponders",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{InsuranceResponderId}/UpdateInsuranceResponder",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{InsuranceResponderId}/DeleteInsuranceResponder",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{InsuranceResponderId}/AddInsurancePhone",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{InsuranceResponderId}/InsuranceResponderDetailsById",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{InsurancePhoneId}/UpdateInsurancePhone",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{InsurancePhoneId}/DeleteInsurancePhone",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddTransport",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllTransport",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{TransportId}/UpdateTransport",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{TransportId}/DeleteTransport",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{TransportId}/AddTransportResponder",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{TransportId}/AllTransportResponders",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{TransportResponderId}/UpdateTransportResponder",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{TransportResponderId}/DeleteTransportResponder",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{TransportResponderId}/AddTransportPhone",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{TransportResponderId}/TransportResponderDetailsById",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{TransportPhoneId}/UpdateTransportPhone",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{TransportPhoneId}/DeleteTransportPhone",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddCustomBroker",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllCustomBroker",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CustomBrokerId}/UpdateCustomBroker",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{CustomBrokerId}/DeleteCustomBroker",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{CustomBrokerId}/AddCustomBrokerPhone",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{CustomBrokerId}/AllCustomBrokerPhone",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CustomBrokerPhoneId}/UpdateCustomBrokerPhone",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{CustomBrokerPhoneId}/DeleteCustomBrokerPhone",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AllSupplier",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{SupplierId}/AddSupplierResponder",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{SupplierId}/AllSupplierResponders",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{SupplierResponderId}/UpdateSupplierResponder",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{SupplierResponderId}/DeleteSupplierResponder",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{SupplierResponderId}/SupplierResponderDetailsById",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/AddWarehouse",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllWarehouse",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{WarehouseId}/UpdateWarehouse",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{WarehouseId}/DeleteWarehouse",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{WarehouseId}/AddWarehouseResponder",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{WarehouseId}/AllWarehouseResponders",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{WarehouseResponderId}/UpdateWarehouseResponder",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{WarehouseResponderId}/DeleteWarehouseResponder",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{WarehouseResponderId}/AddWarehousePhone",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{WarehouseResponderId}/WarehouseResponderDetailsById",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{WarehousePhoneId}/UpdateWarehousePhone",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{WarehousePhoneId}/DeleteWarehousePhone",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddInspection",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllInspection",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{InspectionId}/UpdateInspection",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{InspectionId}/DeleteInspection",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{InspectionId}/AddInspectionResponder",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{InspectionId}/AllInspectionResponders",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{InspectionResponderId}/UpdateInspectionResponder",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{InspectionResponderId}/DeleteInspectionResponder",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{InspectionResponderId}/AddInspectionPhone",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{InspectionResponderId}/InspectionResponderDetailsById",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{InspectionPhoneId}/UpdateInspectionPhone",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{InspectionPhoneId}/DeleteInspectionPhone",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{BankId}/AllBankResponderDetails",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CustomBrokerId}/AllCustomBrokerResponderDetails",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{InsuranceId}/AllInsuranceResponderDetails",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{TransportId}/AllTransportResponderDetails",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{SupplierId}/AllSupplierResponderDetails",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{WarehouseId}/AllWarehouseResponderDetails",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{InspectionId}/AllInspectionResponderDetails",
    httpMethod: "GET",
  },
];
export const AddCountryPath: IPath = {
  path: "api/BaseInfo/AddCountry",
  httpMethod: "POST",
};
export const AllCountryPath: IPath = {
  path: "api/BaseInfo/AllCountry",
  httpMethod: "GET",
};
export const UpdateCountryPath: IPath = {
  path: "api/BaseInfo/{CountryId}/UpdateCountry",
  httpMethod: "PUT",
};
export const DeleteCountryPath: IPath = {
  path: "api/BaseInfo/{CountryId}/DeleteCountry",
  httpMethod: "DELETE",
};
export const AddPortPath: IPath = {
  path: "api/BaseInfo/AddPort",
  httpMethod: "POST",
};
export const AllPortPath: IPath = {
  path: "api/BaseInfo/AllPort",
  httpMethod: "GET",
};
export const UpdatePortPath: IPath = {
  path: "api/BaseInfo/{PortId}/UpdatePort",
  httpMethod: "PUT",
};
export const DeletePortPath: IPath = {
  path: "api/BaseInfo/{PortId}/DeletePort",
  httpMethod: "DELETE",
};
export const AddVesselPath: IPath = {
  path: "api/BaseInfo/AddVessel",
  httpMethod: "POST",
};
export const AllVesselPath: IPath = {
  path: "api/BaseInfo/AllVessel",
  httpMethod: "GET",
};
export const UpdateVesselPath: IPath = {
  path: "api/BaseInfo/{VesselId}/UpdateVessel",
  httpMethod: "PUT",
};
export const DeleteVesselPath: IPath = {
  path: "api/BaseInfo/{VesselId}/DeleteVessel",
  httpMethod: "DELETE",
};
export const AddCurrencyPath: IPath = {
  path: "api/BaseInfo/AddCurrency",
  httpMethod: "POST",
};
export const AllCurrencyPath: IPath = {
  path: "api/BaseInfo/AllCurrency",
  httpMethod: "GET",
};
export const UpdateCurrencyPath: IPath = {
  path: "api/BaseInfo/{CurrencyId}/UpdateCurrency",
  httpMethod: "PUT",
};
export const DeleteCurrencyPath: IPath = {
  path: "api/BaseInfo/{CurrencyId}/DeleteCurrency",
  httpMethod: "DELETE",
};
export const AddUnitPath: IPath = {
  path: "api/BaseInfo/AddUnit",
  httpMethod: "POST",
};
export const AllUnitPath: IPath = {
  path: "api/BaseInfo/AllUnit",
  httpMethod: "GET",
};
export const UpdateUnitPath: IPath = {
  path: "api/BaseInfo/{UnitId}/UpdateUnit",
  httpMethod: "PUT",
};
export const DeleteUnitPath: IPath = {
  path: "api/BaseInfo/{UnitId}/DeleteUnit",
  httpMethod: "DELETE",
};
export const AddProductPath: IPath = {
  path: "api/BaseInfo/AddProduct",
  httpMethod: "POST",
};
export const AllProductPath: IPath = {
  path: "api/BaseInfo/AllProduct",
  httpMethod: "GET",
};
export const UpdateProductPath: IPath = {
  path: "api/BaseInfo/{ProductId}/UpdateProduct",
  httpMethod: "PUT",
};
export const DeleteProductPath: IPath = {
  path: "api/BaseInfo/{ProductId}/DeleteProduct",
  httpMethod: "DELETE",
};
export const AddBankPath: IPath = {
  path: "api/BaseInfo/AddBank",
  httpMethod: "POST",
};
export const AllBankPath: IPath = {
  path: "api/BaseInfo/AllBank",
  httpMethod: "GET",
};
export const UpdateBankPath: IPath = {
  path: "api/BaseInfo/{BankId}/UpdateBank",
  httpMethod: "PUT",
};
export const DeleteBankPath: IPath = {
  path: "api/BaseInfo/{BankId}/DeleteBank",
  httpMethod: "DELETE",
};
export const AddBankResponderPath: IPath = {
  path: "api/BaseInfo/{BankId}/AddBankResponder",
  httpMethod: "POST",
};
export const AllBankRespondersPath: IPath = {
  path: "api/BaseInfo/{BankId}/AllBankResponders",
  httpMethod: "GET",
};
export const UpdateBankResponderPath: IPath = {
  path: "api/BaseInfo/{BankResponderId}/UpdateBankResponder",
  httpMethod: "PUT",
};
export const DeleteBankResponderPath: IPath = {
  path: "api/BaseInfo/{BankResponderId}/DeleteBankResponder",
  httpMethod: "DELETE",
};
export const AddBankPhonePath: IPath = {
  path: "api/BaseInfo/{ResponderId}/AddBankPhone",
  httpMethod: "POST",
};
export const BankPhonesByResponderIdPath: IPath = {
  path: "api/BaseInfo/{ResponderId}/BankPhonesByResponderId",
  httpMethod: "GET",
};
export const UpdateBankPhonePath: IPath = {
  path: "api/BaseInfo/{BankPhoneId}/UpdateBankPhone",
  httpMethod: "PUT",
};
export const DeleteBankPhonePath: IPath = {
  path: "api/BaseInfo/{BankPhoneId}/DeleteBankPhone",
  httpMethod: "DELETE",
};
export const AddInsurancePath: IPath = {
  path: "api/BaseInfo/AddInsurance",
  httpMethod: "POST",
};
export const AllInsuracePath: IPath = {
  path: "api/BaseInfo/AllInsurace",
  httpMethod: "GET",
};
export const UpdateInsurancePath: IPath = {
  path: "api/BaseInfo/{InsuranceId}/UpdateInsurance",
  httpMethod: "PUT",
};
export const DeleteInsurancePath: IPath = {
  path: "api/BaseInfo/{InsuranceId}/DeleteInsurance",
  httpMethod: "DELETE",
};
export const AddInsuranceResponderPath: IPath = {
  path: "api/BaseInfo/{InsuranceId}/AddInsuranceResponder",
  httpMethod: "POST",
};
export const AllInsuranceRespondersPath: IPath = {
  path: "api/BaseInfo/{InsuranceId}/AllInsuranceResponders",
  httpMethod: "GET",
};
export const UpdateInsuranceResponderPath: IPath = {
  path: "api/BaseInfo/{InsuranceResponderId}/UpdateInsuranceResponder",
  httpMethod: "PUT",
};
export const DeleteInsuranceResponderPath: IPath = {
  path: "api/BaseInfo/{InsuranceResponderId}/DeleteInsuranceResponder",
  httpMethod: "DELETE",
};
export const AddInsurancePhonePath: IPath = {
  path: "api/BaseInfo/{InsuranceResponderId}/AddInsurancePhone",
  httpMethod: "POST",
};
export const InsuranceResponderDetailsByIdPath: IPath = {
  path: "api/BaseInfo/{InsuranceResponderId}/InsuranceResponderDetailsById",
  httpMethod: "GET",
};
export const UpdateInsurancePhonePath: IPath = {
  path: "api/BaseInfo/{InsurancePhoneId}/UpdateInsurancePhone",
  httpMethod: "PUT",
};
export const DeleteInsurancePhonePath: IPath = {
  path: "api/BaseInfo/{InsurancePhoneId}/DeleteInsurancePhone",
  httpMethod: "DELETE",
};
export const AddTransportPath: IPath = {
  path: "api/BaseInfo/AddTransport",
  httpMethod: "POST",
};
export const AllTransportPath: IPath = {
  path: "api/BaseInfo/AllTransport",
  httpMethod: "GET",
};
export const UpdateTransportPath: IPath = {
  path: "api/BaseInfo/{TransportId}/UpdateTransport",
  httpMethod: "PUT",
};
export const DeleteTransportPath: IPath = {
  path: "api/BaseInfo/{TransportId}/DeleteTransport",
  httpMethod: "DELETE",
};
export const AddTransportResponderPath: IPath = {
  path: "api/BaseInfo/{TransportId}/AddTransportResponder",
  httpMethod: "POST",
};
export const AllTransportRespondersPath: IPath = {
  path: "api/BaseInfo/{TransportId}/AllTransportResponders",
  httpMethod: "GET",
};
export const UpdateTransportResponderPath: IPath = {
  path: "api/BaseInfo/{TransportResponderId}/UpdateTransportResponder",
  httpMethod: "PUT",
};
export const DeleteTransportResponderPath: IPath = {
  path: "api/BaseInfo/{TransportResponderId}/DeleteTransportResponder",
  httpMethod: "DELETE",
};
export const AddTransportPhonePath: IPath = {
  path: "api/BaseInfo/{TransportResponderId}/AddTransportPhone",
  httpMethod: "POST",
};
export const TransportResponderDetailsByIdPath: IPath = {
  path: "api/BaseInfo/{TransportResponderId}/TransportResponderDetailsById",
  httpMethod: "GET",
};
export const UpdateTransportPhonePath: IPath = {
  path: "api/BaseInfo/{TransportPhoneId}/UpdateTransportPhone",
  httpMethod: "PUT",
};
export const DeleteTransportPhonePath: IPath = {
  path: "api/BaseInfo/{TransportPhoneId}/DeleteTransportPhone",
  httpMethod: "DELETE",
};
export const AddSupplierResponderPath: IPath = {
  path: "api/BaseInfo/{SupplierId}/AddSupplierResponder",
  httpMethod: "POST",
};
export const AllSupplierRespondersPath: IPath = {
  path: "api/BaseInfo/{SupplierId}/AllSupplierResponders",
  httpMethod: "GET",
};
export const UpdateSupplierResponderPath: IPath = {
  path: "api/BaseInfo/{SupplierResponderId}/UpdateSupplierResponder",
  httpMethod: "PUT",
};
export const DeleteSupplierResponderPath: IPath = {
  path: "api/BaseInfo/{SupplierResponderId}/DeleteSupplierResponder",
  httpMethod: "DELETE",
};
export const AddSupplierPhonePath: IPath = {
  path: "api/BaseInfo/{SupplierResponderId}/AddSupplierPhone",
  httpMethod: "POST",
};

export const SupplierResponderDetailsByIdPath: IPath = {
  path: "api/BaseInfo/{SupplierResponderId}/SupplierResponderDetailsById",
  httpMethod: "GET",
};
export const AllSupplierResponderDetailsPath: IPath = {
  path: "api/BaseInfo/{SupplierId}/AllSupplierResponderDetails",
  httpMethod: "GET",
};
export const AddSupplierPath: IPath = {
  path: "api/BaseInfo/AddSupplier",
  httpMethod: "POST",
};
export const AllSupplierPath: IPath = {
  path: "api/BaseInfo/AllSupplier",
  httpMethod: "GET",
};
export const UpdateSupplierPath: IPath = {
  path: "api/BaseInfo/{SupplierId}/UpdateSupplier",
  httpMethod: "PUT",
};
export const DeleteSupplierPath: IPath = {
  path: "api/BaseInfo/{SupplierId}/DeleteSupplier",
  httpMethod: "DELETE",
};
export const UpdateSupplierPhonePath: IPath = {
  path: "api/BaseInfo/{SupplierPhoneId}/UpdateSupplierPhone",
  httpMethod: "PUT",
};
export const DeleteSupplierPhonePath: IPath = {
  path: "api/BaseInfo/{SupplierPhoneId}/DeleteSupplierPhone",
  httpMethod: "DELETE",
};
export const AllInspectionPath: IPath = {
  path: "api/BaseInfo/AllInspection",
  httpMethod: "GET",
};
export const AddInspectionPath: IPath = {
  path: "api/BaseInfo/AddInspection",
  httpMethod: "POST",
};
export const UpdateInspectionPath: IPath = {
  path: "api/BaseInfo/{InspectionId}/UpdateInspection",
  httpMethod: "PUT",
};
export const DeleteInspectionPath: IPath = {
  path: "api/BaseInfo/{InspectionId}/DeleteInspection",
  httpMethod: "DELETE",
};
export const AddInspectionResponderPath: IPath = {
  path: "api/BaseInfo/{InspectionId}/AddInspectionResponder",
  httpMethod: "POST",
};
export const AllInspectionRespondersPath: IPath = {
  path: "api/BaseInfo/{InspectionId}/AllInspectionResponders",
  httpMethod: "GET",
};
export const UpdateInspectionResponderPath: IPath = {
  path: "api/BaseInfo/{InspectionResponderId}/UpdateInspectionResponder",
  httpMethod: "PUT",
};
export const DeleteInspectionResponderPath: IPath = {
  path: "api/BaseInfo/{InspectionResponderId}/DeleteInspectionResponder",
  httpMethod: "DELETE",
};
export const AddInspectionPhonePath: IPath = {
  path: "api/BaseInfo/{InspectionResponderId}/AddInspectionPhone",
  httpMethod: "POST",
};
export const InspectionResponderDetailsByIdPath: IPath = {
  path: "api/BaseInfo/{InspectionResponderId}/InspectionResponderDetailsById",
  httpMethod: "GET",
};
export const AllInspectionResponderDetailsPath: IPath = {
  path: "api/BaseInfo/{InspectionId}/AllInspectionResponderDetails",
  httpMethod: "GET",
};
export const UpdateInspectionPhonePath: IPath = {
  path: "api/BaseInfo/{InspectionPhoneId}/UpdateInspectionPhone",
  httpMethod: "PUT",
};
export const DeleteInspectionPhonePath: IPath = {
  path: "api/BaseInfo/{InspectionPhoneId}/DeleteInspectionPhone",
  httpMethod: "DELETE",
};
export const AllInsuranceResponderDetailsPath: IPath = {
  path: "api/BaseInfo/{InsuranceId}/AllInsuranceResponderDetails",
  httpMethod: "GET",
};

export const AddWarehousePath: IPath = {
  path: "api/BaseInfo/AddWarehouse",
  httpMethod: "POST",
};
export const AllWarehousePath: IPath = {
  path: "api/BaseInfo/AllWarehouse",
  httpMethod: "GET",
};
export const UpdateWarehousePath: IPath = {
  path: "api/BaseInfo/{WarehouseId}/UpdateWarehouse",
  httpMethod: "PUT",
};
export const DeleteWarehousePath: IPath = {
  path: "api/BaseInfo/{WarehouseId}/DeleteWarehouse",
  httpMethod: "DELETE",
};
export const AddWarehouseResponderPath: IPath = {
  path: "api/BaseInfo/{WarehouseId}/AddWarehouseResponder",
  httpMethod: "POST",
};
export const AllWarehouseRespondersPath: IPath = {
  path: "api/BaseInfo/{WarehouseId}/AllWarehouseResponders",
  httpMethod: "GET",
};
export const UpdateWarehouseResponderPath: IPath = {
  path: "api/BaseInfo/{WarehouseResponderId}/UpdateWarehouseResponder",
  httpMethod: "PUT",
};
export const DeleteWarehouseResponderPath: IPath = {
  path: "api/BaseInfo/{WarehouseResponderId}/DeleteWarehouseResponder",
  httpMethod: "DELETE",
};
export const AddWarehousePhonePath: IPath = {
  path: "api/BaseInfo/{WarehouseResponderId}/AddWarehousePhone",
  httpMethod: "POST",
};
export const WarehouseResponderDetailsByIdPath: IPath = {
  path: "api/BaseInfo/{WarehouseResponderId}/WarehouseResponderDetailsById",
  httpMethod: "GET",
};
export const UpdateWarehousePhonePath: IPath = {
  path: "api/BaseInfo/{WarehousePhoneId}/UpdateWarehousePhone",
  httpMethod: "PUT",
};
export const DeleteWarehousePhonePath: IPath = {
  path: "api/BaseInfo/{WarehousePhoneId}/DeleteWarehousePhone",
  httpMethod: "DELETE",
};
export const AllWarehouseResponderDetailsPath: IPath = {
  path: "api/BaseInfo/{WarehouseId}/AllWarehouseResponderDetails",
  httpMethod: "GET",
};
export const AllBankResponderDetailsPath: IPath = {
  path: "api/BaseInfo/{BankId}/AllBankResponderDetails",
  httpMethod: "GET",
};
export const AllTransportResponderDetailsPath: IPath = {
  path: "api/BaseInfo/{TransportId}/AllTransportResponderDetails",
  httpMethod: "GET",
};
export const AllCustomBrokerPath: IPath = {
  path: "api/BaseInfo/AllCustomBroker",
  httpMethod: "GET",
};
export const AddCustomBrokerPath: IPath = {
  path: "api/BaseInfo/AddCustomBroker",
  httpMethod: "POST",
};
export const UpdateCustomBrokerPath: IPath = {
  path: "api/BaseInfo/{CustomBrokerId}/UpdateCustomBroker",
  httpMethod: "PUT",
};
export const DeleteCustomBrokerPath: IPath = {
  path: "api/BaseInfo/{CustomBrokerId}/DeleteCustomBroker",
  httpMethod: "DELETE",
};
export const AddCustomBrokerPhonePath: IPath = {
  path: "api/BaseInfo/{CustomBrokerId}/AddCustomBrokerPhone",
  httpMethod: "POST",
};
export const AllCustomBrokerPhonePath: IPath = {
  path: "api/BaseInfo/{CustomBrokerId}/AllCustomBrokerPhone",
  httpMethod: "GET",
};
export const AllCustomBrokerResponderDetailsPath: IPath = {
  path: "api/BaseInfo/{CustomBrokerId}/AllCustomBrokerResponderDetails",
  httpMethod: "GET",
};
export const UpdateCustomBrokerPhonePath: IPath = {
  path: "api/BaseInfo/{CustomBrokerPhoneId}/UpdateCustomBrokerPhone",
  httpMethod: "PUT",
};
export const DeleteCustomBrokerPhonePath: IPath = {
  path: "api/BaseInfo/{CustomBrokerPhoneId}/DeleteCustomBrokerPhone",
  httpMethod: "DELETE",
};
