import { ReactNode } from "react";

export enum IAddNewWarehousesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface IContactsTable {
  id: number;
  name: string;
  phoneNumber: string;
}
export interface TableData extends IContactsTable {
  tools?: ReactNode;
}
