import { FC } from "react";
import { Header } from "./components/Header";
import { AddNewCurrencies } from "./components/AddNewProduct";
import { CurrenciesContainer } from "./style/style";
import { CurrenciesTable } from "./components/ProductsTable";

export const Currencies: FC = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-center">
        <CurrenciesContainer>
          <AddNewCurrencies />
          <CurrenciesTable />
        </CurrenciesContainer>
      </div>
    </div>
  );
};
