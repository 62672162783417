import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { App, Form } from "antd";
import { FormInstance } from "antd/lib";
import { IAddNewInsuranceCompanyTabs } from "../components/AddNewInsuranceCompany/models";
import {
  IAddNewInsuranceCompaniesTabs,
  IContactsTable,
  IInsuranceCompaniesTabs,
} from "src/services/Identity/models/mockModels/insur.models";
import useLanguage from "src/store/language";
import { ResponderTableData, TableData } from "../models/models";
import { ITablePagination } from "src/models/interfaces/pagination";
import {
  IAllInsuranceResponderDetailsResult,
  IAllInsuranceResult,
  IInsuranceResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";
import { IAddInsurance } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";

interface IContext {
  value: {
    insuranceCompaniesActiveKey: string;
    addNewInsuranceCompaniesActiveKey: string;
    editContact: boolean;
    selectedResponsive: IContactsTable | undefined;
    isModalOpen: boolean;
    tableRecord: IAllInsuranceResult | undefined;
    pressKeyboard: boolean;
    dataSource: TableData[];
    loading: boolean;
    paginationInsurance: ITablePagination;
    paginationResponder: ITablePagination;
    paginationPhoneNumber: ITablePagination;
    paginationInsuranceDetail: ITablePagination;
    insuranceId: number;
    allRespondersData: ResponderTableData[];
    responderEditMode: boolean;
    responderContactEditMode: boolean;
    insuranceResponderId: number | undefined;
    isAddPhoneNumber: boolean;
    addInsuranceLoading: boolean;
    allInsuranceResponders: boolean;
    insuranceDetailLoading: boolean;
    responderPhoneDetailLoading: boolean;
    responderPhoneData: IInsuranceResponderDetailsByIdResult[];
    insuranceDetailData: IAllInsuranceResponderDetailsResult[];
    basicInfoEditMode: boolean;
    responderPhoneId: number | undefined;
  };
  dispatch: {
    setInsuranceCompaniesActiveKey: Dispatch<SetStateAction<string>>;
    setAddNewInsuranceCompaniesActiveKey: Dispatch<SetStateAction<string>>;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setSelectedResponsive: Dispatch<SetStateAction<IContactsTable | undefined>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;
    setTableRecord: Dispatch<SetStateAction<IAllInsuranceResult | undefined>>;
    setPaginationInsurance: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationResponder: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationPhoneNumber: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationInsuranceDetail: Dispatch<SetStateAction<ITablePagination>>;
    setInsuranceResponderId: Dispatch<SetStateAction<number | undefined>>;
    setResponderPhoneData: Dispatch<
      SetStateAction<IInsuranceResponderDetailsByIdResult[]>
    >;
    setBasicInfoEditMode: Dispatch<SetStateAction<boolean>>;
    setResponderContactEditMode: Dispatch<SetStateAction<boolean>>;
    setResponderEditMode: Dispatch<SetStateAction<boolean>>;
    setIsAddPhoneNumber: Dispatch<SetStateAction<boolean>>;
    setResponderPhoneId: Dispatch<SetStateAction<number | undefined>>;
  };
  func: {
    getTableData: () => void;
    onFinishAddInsurance: (values: IAddInsurance) => void;
    GetAllInsuranceResponders: () => void;
    getResponderPhoneDetail: (id: number) => void;
    GetAllInsuranceResponderDetails: (InsuranceId: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
    basicInformationForm: FormInstance<any> | undefined;
    contactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const InsuranceCompaniesContext = createContext<IContext | undefined>(
  undefined
);

export const InsuranceCompaniesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const [insuranceCompaniesActiveKey, setInsuranceCompaniesActiveKey] =
    useState<string>(IInsuranceCompaniesTabs.tableOfInsuranceCompanies);
  const [
    addNewInsuranceCompaniesActiveKey,
    setAddNewInsuranceCompaniesActiveKey,
  ] = useState<string>(IAddNewInsuranceCompanyTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [selectedResponsive, setSelectedResponsive] =
    useState<IContactsTable>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tableRecord, setTableRecord] = useState<IAllInsuranceResult>();
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [responderEditMode, setResponderEditMode] = useState<boolean>(false);
  const [responderContactEditMode, setResponderContactEditMode] =
    useState<boolean>(false);
  const [allInsuranceResponders, setAllInsuranceResponders] =
    useState<boolean>(false);
  const [addInsuranceLoading, setAddInsuranceLoading] =
    useState<boolean>(false);
  const [responderPhoneDetailLoading, setResponderPhoneDetailLoading] =
    useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [paginationInsurance, setPaginationInsurance] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [paginationResponder, setPaginationResponder] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationPhoneNumber, setPaginationPhoneNumber] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationInsuranceDetail, setPaginationInsuranceDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [insuranceId, setInsuranceId] = useState<number>(-1);
  const [allRespondersData, setAllRespondersData] = useState<
    ResponderTableData[]
  >([]);
  const [insuranceResponderId, setInsuranceResponderId] = useState<number>();
  const [responderPhoneData, setResponderPhoneData] = useState<
    IInsuranceResponderDetailsByIdResult[]
  >([]);
  const [isAddPhoneNumber, setIsAddPhoneNumber] = useState<boolean>(false);
  const [insuranceDetailLoading, setInsuranceDetailLoading] =
    useState<boolean>(false);
  const [insuranceDetailData, setInsuranceDetailData] = useState<
    IAllInsuranceResponderDetailsResult[]
  >([]);
  const [basicInfoEditMode, setBasicInfoEditMode] = useState<boolean>(false);
  const [responderPhoneId, setResponderPhoneId] = useState<number>();

  const [form] = Form.useForm();
  const [basicInformationForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();

  ////////////////////////////functions////////////////////////////////////////////////
  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      const { GetAllInsurance } = new BaseInfoService();

      const params: IObject = {
        Offset: paginationInsurance.current,
        Limit: paginationInsurance.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllInsurance(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPaginationInsurance((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInsurance.current, paginationInsurance.pageSize]);

  const onFinishAddInsurance = async (values: IAddInsurance) => {
    setAddInsuranceLoading(true);
    try {
      const { AddInsurance } = new BaseInfoService();
      const result = await AddInsurance(values);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.insuranceCompanies.addInsuranceMessage
        );
        setAddNewInsuranceCompaniesActiveKey(
          IAddNewInsuranceCompaniesTabs.Contacts
        );
        setInsuranceId(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddInsuranceLoading(false);
    }
  };

  const GetAllInsuranceResponders = useCallback(async () => {
    setAllInsuranceResponders(true);
    try {
      const { GetAllInsuranceResponders: GetAllInsuranceR } =
        new BaseInfoService();
      const params: IObject = {
        Offset: paginationResponder.current,
        Limit: paginationResponder.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllInsuranceR(
        searchParams.toString(),
        insuranceId
      );
      if (result && result.status === 200 && result.data) {
        setAllRespondersData(result.data.records);
        setPaginationResponder((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllInsuranceResponders(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationResponder.current, paginationResponder.pageSize, insuranceId]);

  const getResponderPhoneDetail = useCallback(
    async (id: number) => {
      setResponderPhoneDetailLoading(true);
      try {
        const { GetInsuranceResponderDetailsById } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationPhoneNumber.current,
          Limit: paginationPhoneNumber.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetInsuranceResponderDetailsById(
          searchParams.toString(),
          id
        );
        if (result && result.status === 200) {
          setResponderPhoneData(result.data.records);
          setPaginationPhoneNumber((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }

        if (result && result?.data.count > 0) {
          setIsAddPhoneNumber(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setResponderPhoneDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationPhoneNumber.current, paginationPhoneNumber.pageSize]
  );

  const GetAllInsuranceResponderDetails = useCallback(
    async (insuranceId: number) => {
      if (!(insuranceId > 0)) return;
      setInsuranceDetailLoading(true);
      try {
        const { GetAllInsuranceResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationInsuranceDetail.current,
          Limit: paginationInsuranceDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllInsuranceResponderDetails(
          insuranceId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setInsuranceDetailData(result.data.records);
          setPaginationInsuranceDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setInsuranceDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationInsuranceDetail.current, paginationInsuranceDetail.pageSize]
  );
  const ContextValue: IContext = {
    value: {
      insuranceCompaniesActiveKey,
      addNewInsuranceCompaniesActiveKey,
      editContact,
      selectedResponsive,
      isModalOpen,
      tableRecord,
      pressKeyboard,
      dataSource,
      loading,
      paginationPhoneNumber,
      paginationResponder,
      paginationInsurance,
      insuranceId,
      allRespondersData,
      insuranceResponderId,
      isAddPhoneNumber,
      addInsuranceLoading,
      allInsuranceResponders,
      responderPhoneDetailLoading,
      responderPhoneData,
      responderContactEditMode,
      insuranceDetailData,
      paginationInsuranceDetail,
      insuranceDetailLoading,
      basicInfoEditMode,
      responderEditMode,
      responderPhoneId,
    },
    dispatch: {
      setInsuranceCompaniesActiveKey,
      setAddNewInsuranceCompaniesActiveKey,
      setEditContact,
      setSelectedResponsive,
      setIsModalOpen,
      setTableRecord,
      setPressKeyboard,
      setPaginationPhoneNumber,
      setPaginationResponder,
      setResponderPhoneData,
      setBasicInfoEditMode,
      setPaginationInsurance,
      setPaginationInsuranceDetail,
      setInsuranceResponderId,
      setResponderContactEditMode,
      setResponderEditMode,
      setResponderPhoneId,
      setIsAddPhoneNumber,
    },
    func: {
      getTableData,
      onFinishAddInsurance,
      GetAllInsuranceResponders,
      getResponderPhoneDetail,
      GetAllInsuranceResponderDetails,
    },
    form: {
      form,
      basicInformationForm,
      contactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  return (
    <InsuranceCompaniesContext.Provider value={ContextValue}>
      {children}
    </InsuranceCompaniesContext.Provider>
  );
};

export const useInsuranceCompanies = () =>
  useContext(InsuranceCompaniesContext);
