import { styled } from "styled-components";

export const AddNewWarehousesContainer = styled.div`
  display: flex;
  width: 95%;
  padding: 24px 32px !important;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .ant-tabs-tab {
    font-size: 14px !important;
  }
  .ant-divider {
    margin: unset !important;
  }
  .ant-tabs {
    width: 100% !important;
  }
`;
