import { Modal } from "antd";
import { styled } from "styled-components";

export const EditSupplierContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};
  width: 95%;

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .ant-tabs-tab {
    font-size: 14px !important;
  }
  .ant-tabs {
    width: 100% !important;
  }
`;

export const ConfirmModalContainer = styled(Modal);
