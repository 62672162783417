export const darkColors = {
  gray: {
    700: "#1F1F1F",
    600: "#4B4B4B",
    500: "#8E8E8E",
    400: "#CACACA",
    300: "#E1E1E1",
    200: "#EEEEEE",
    100: "#F5F5F5",
    50: "#FAFAFA",
  },
  primary: {
    700: "#007E77",
    600: "#008C84",
    500: "#33A39D",
    400: "#80C6C2",
    300: "#BFE2E0",
    200: "#D9EEED",
    100: "#E5F3F3",
    50: "#F2F9F9",
  },
  secondary: {
    700: "#73C8C3",
    600: "#80DED9",
    500: "#99E5E1",
    400: "#BFEFEC",
    300: "#DFF7F6",
    200: "#ECFAF9",
    100: "#F2FCFB",
    50: "#F9FDFD",
  },
  danger: {
    700: "#EC2D30",
    600: "#F64C4C",
    500: "#EB6F70",
    400: "#F49898",
    300: "#FFCCD2",
    200: "#FFEBEE",
    100: "#FEF2F2",
    50: "#FFFBFB",
  },
  warning: {
    700: "#FE9B0E",
    600: "#FFAD0D",
    500: "#FFC62B",
    400: "#FFDD82",
    300: "#FFEAB3",
    200: "#FFF7E1",
    100: "#FFF9EE",
    50: "#FFFDFA",
  },
  success: {
    700: "#0C9D61",
    600: "#47B881",
    500: "#6BC497",
    400: "#97D4B4",
    300: "#C0E5D1",
    200: "#E5F5EC",
    100: "#F2FAF6",
    50: "#FBFEFC",
  },
  info: {
    700: "#3A70E2",
    600: "#3B82F6",
    500: "#4BA1FF",
    400: "#93C8FF",
    300: "#BDDDFF",
    200: "#E4F2FF",
    100: "#F1F8FF",
    50: "#F8FCFF",
  },
  title: "#FFFFFF",
  primaryText: "#D9D9D9",
  secondaryText: "#737373",
  thirdText: "#3B82F64D",
  selected: "#0D0D0D",
  disable1: "#242424",
  disable2: "#4B4B4B",
  disable3: "#1F1F1F",
  disable4: "#000000",
  border1: "#1F1F1F",
  border2: "#F3F4F5",
  border3: "#DADEEC",
  border4: "#E5E7EB",
  border5: "#CACACA",
  border6: "#BFE2E0",
  background1: "#F2F7FF",
  background2: "#FFFFFF",
  table: "#DBE9FF",
  white: "#FFFFFF",
  black: "#000000",
  fixWhite: "#ffffff",
  fixBlack: "#000000",
  overlay: "rgba(83, 127, 192, 0.60)",
};
