import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { IAllCustomBrokerResult } from "src/services/BaseInfo/models/result.models";
import useLanguage from "src/store/language";

export const HeaderViewClearanceWorkers: FC = () => {
  const { words } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const values: IAllCustomBrokerResult = location.state;
  return (
    <div className="flex justify-between items-center pt-[19px] px-[24px] ">
      <div className="flex flex-col justify-end">
        <div className="text-[#044440] text-[18px]">
          {words.basicInformation.clearanceWorkers.viewClearanceWorkers}
        </div>
        <div className="text-[#4B4B4B] text-[14px]">{values?.workPlace}</div>
      </div>
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={() => {
          navigate(ROUTES.clearanceWorkers);
        }}
      >
        <div className=" text-[#008C84] text-[24px] ">
          {words.global.backToTable}
        </div>
        <span className="material-icons text-[#008C84] text-[32px]">
          keyboard_backspace
        </span>
      </div>
    </div>
  );
};
