import { Divider } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import useLanguage from "src/store/language";

export const Header: FC = () => {
  const { words } = useLanguage();
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex justify-between items-center px-[24px] ">
        <div className="text-[#044440] text-[16px]">
          {words.orders.orderManagement}
        </div>
        <div
          className="flex flex-row gap-[12px] items-center cursor-pointer"
          onClick={() => {
            navigate(ROUTES.orders);
          }}
        >
          <div className=" text-[#008C84] text-[16px] ">
            {words.global.backToTable}
          </div>
          <span className="material-icons text-[#008C84] text-[24px]">
            keyboard_backspace
          </span>
        </div>
      </div>
      <Divider />
    </div>
  );
};
