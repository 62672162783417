import { FC, useCallback, useEffect, useState } from "react";
import { ViewOrderContainer } from "../style";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Col, Divider, Flex, Row, Skeleton } from "antd";
import { OrderService } from "src/services/Order/order.service";
import { useParams } from "react-router-dom";
import { IGetProform } from "src/services/Order/models/result.models";
import dayjs from "dayjs";
export const ViewOrder: FC = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const [proformaData, setProformaData] = useState<IGetProform>();
  const [loading, setLoading] = useState<boolean>(false);
  const CFR = () => {
    const fob = proformaData?.fobUnitPmt;
    const freightUnitPmt = proformaData?.freightUnitPmt;
    if (fob && freightUnitPmt) return fob + freightUnitPmt;
  };
  const standard = () => {
    if (proformaData?.standard === true) {
      return words.orders.standardMessage;
    } else {
      return words.orders.hasNotStandardMessage;
    }
  };
  const getProform = useCallback(async () => {
    if (!orderId) return undefined;
    setLoading(true);
    try {
      const { GetProform } = new OrderService();
      const result = await GetProform(parseInt(orderId));
      if (result && result.status === 200 && result.data) {
        setProformaData(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getProform();
  }, [getProform]);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">{words.orders.proformaInfo}</div>
      <Row
        className="w-full bg-[#f2f9f9] py-[24px] px-[16px]"
        gutter={[16, 24]}
      >
        <Col span={6} className="!font-medium">
          {words.orders.proformaNo}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.proformaDate}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.currency}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.bank}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.product}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.weight}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.FOB}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.freightPerTon}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.CFR}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Divider dashed />
        <Col span={6} className="!font-medium">
          {words.orders.standard}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        {proformaData?.standard ? (
          <>
            <Col span={6} className="!font-medium">
              {words.orders.standardNo}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              <Skeleton.Input className="!h-[22px]" />
            </Col>
          </>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma headerName={words.orders.viewProformaInfo} />
      <Flex justify="center">
        <ViewOrderContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">{words.orders.proformaInfo}</div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={6} className="!font-medium">
                  {words.orders.proformaNo}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {proformaData?.piNo}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.proformaDate}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {dayjs(proformaData?.piDate).format("YYYY-MM-DD")}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.currency}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {proformaData?.currencyName}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.bank}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {proformaData?.operationBankName}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.product}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {proformaData?.productName}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.weight}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {proformaData?.weight}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.FOB}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {proformaData?.fobUnitPmt}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.freightPerTon}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {proformaData?.freightUnitPmt}
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.CFR}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {CFR()}
                </Col>
                <Divider dashed />
                <Col span={6} className="!font-medium">
                  {words.orders.standard}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  {standard()}
                </Col>
                {proformaData?.standard ? (
                  <>
                    <Col span={6} className="!font-medium">
                      {words.orders.standardNo}
                    </Col>
                    <Col span={6} className="!font-normal text-[#4B4B4B]">
                      {proformaData?.standardNo}
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </Row>
            </>
          )}
        </ViewOrderContainer>
      </Flex>
    </div>
  );
};
