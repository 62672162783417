import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { SidebarContainer } from "../styles/sidebar";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import SideBarLogo from "./SideBarLogo";
import { useSidebarItems } from "../constant/sidebarItems";
import useLanguage from "src/store/language";

interface IProps {
  width: number;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

const Sidebar: FC<IProps> = ({ width, collapsed, setCollapsed }) => {
  let location = useLocation();
  const currentPath = location.pathname;
  const [activeKey, setActiveKey] = useState<string>(currentPath);
  const items = useSidebarItems(activeKey);
  const { isRtl } = useLanguage();
  useEffect(() => {
    items.forEach((item) => {
      if (item && item.key) {
        if (currentPath.includes(item.key as string)) {
          setActiveKey(item.key as string);
        } else return setActiveKey(currentPath);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <SidebarContainer
      width={width}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        right: isRtl ? 0 : "unset",
        left: isRtl ? "unset" : 0,
        top: 0,
        bottom: 0,
        paddingBlock: 24,
        paddingInline: collapsed ? 0 : 24,
      }}
      $isRtl={isRtl}
    >
      <div className="flex flex-col gap-[24px]">
        {/* <div className="h-[105px]"> */}
        <SideBarLogo width={collapsed ? 80 : 200} />
        {/* </div> */}
        <Menu
          defaultSelectedKeys={["dashboard"]}
          mode="inline"
          items={items}
          // className="menu"
          selectedKeys={[activeKey]}
        />
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
