import {
  BankPageGuard,
  CountryPageGuard,
  CurrencyPageGuard,
  CustomBrokerPageGuard,
  InspectionPageGuard,
  InsurancePageGuard,
  PortPageGuard,
  ProductPageGuard,
  SupplierPageGuard,
  TransportPageGuard,
  UnitPageGuard,
  VesselPageGuard,
  WareHousePageGuard,
} from "src/components/Guard/permissions";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { ROUTES } from "src/models/enums/routes";
import { AllApiPath } from "src/services/BaseInfo/guardPath";
import {
  AddUserRolePath,
  AssignRoleToActionPath,
  RolesPath,
  newUserPath,
} from "src/services/UserManagement/guardPath";

import { create } from "zustand";

type PageTitleKeys = keyof typeof ROUTES;
export type PageTitleKeysType = (typeof ROUTES)[PageTitleKeys];
export type IRoutesAccessed = Record<PageTitleKeys, boolean>;
interface IStore {
  routes: IRoutesAccessed;
  setRoutes: (routes: Partial<Record<PageTitleKeys, boolean>>) => void;
}

const useRoutesAccessStore = () => {
  const { checkRoutePermission } = useCheckPermission();

  const store = create<IStore>((set) => ({
    routes: {
      averagePrice: false,
      basicInformation: checkRoutePermission(AllApiPath),
      clearanceWorkers: checkRoutePermission(CustomBrokerPageGuard),
      countries: checkRoutePermission(CountryPageGuard),
      currencies: checkRoutePermission(CurrencyPageGuard),
      design: true,
      editClearanceWorkers: true,
      editInspectionCompanies: true,
      editInsuranceCompanies: true,
      editOperatingBank: true,
      editSupplier: true,
      editTransportCompany: true,
      editWarehouses: true,
      home: true,
      inspectionCompanies: checkRoutePermission(InspectionPageGuard),
      insuranceCompanies: checkRoutePermission(InsurancePageGuard),
      login: true,
      operatingBanks: checkRoutePermission(BankPageGuard),
      ports: checkRoutePermission(PortPageGuard),
      products: checkRoutePermission(ProductPageGuard),
      suppliers: checkRoutePermission(SupplierPageGuard),
      transportCompanies: checkRoutePermission(TransportPageGuard),
      unitsOfMeasurement: checkRoutePermission(UnitPageGuard),
      vessels: checkRoutePermission(VesselPageGuard),
      viewClearanceWorkers: true,
      viewInspectionCompanies: true,
      viewInsuranceCompanies: true,
      viewOperatingBank: true,
      viewSuppliers: true,
      viewTransportCompanies: true,
      viewWarehouses: true,
      warehouses: checkRoutePermission(WareHousePageGuard),
      CreateRole: checkRoutePermission([RolesPath]),
      AssignActionToRole: checkRoutePermission([
        RolesPath,
        AssignRoleToActionPath,
      ]),
      CreateUser: checkRoutePermission([newUserPath]),
      AssignRoleToUser: checkRoutePermission([AddUserRolePath]),
      settings: true,
      orders: true,
      orderManagement: true,
      ViewOrder: true,
      origin: true,
      viewOrigin: true,
      vip: true,
      viewVip: true,
      addOrder: true,
      viewAddOrder: true,
    },
    setRoutes: (newRoutes) =>
      set((state) => ({ routes: { ...state.routes, ...newRoutes } })),
  }));

  return store.getState();
};

export default useRoutesAccessStore;
