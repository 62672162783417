import { Col, Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";

import { RAndCNumberTableContainer } from "./style";
import { useWarehouses } from "../../context";
import { IAllWarehouseResponderDetailsResult } from "src/services/BaseInfo/models/result.models";

export const ResponderAndContactNumberTable: FC = () => {
  const {
    dispatch: { setPaginationWarehouseDetail },
    value: {
      tableRecord,
      warehouseDetailLoading,
      warehouseDetailData,
      paginationWarehouseDetail,
    },
    func: { GetAllWarehouseResponderDetails },
  } = useWarehouses()!;
  const { words } = useLanguage();
  useEffect(() => {
    if (tableRecord && tableRecord.id) {
      GetAllWarehouseResponderDetails(tableRecord.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetAllWarehouseResponderDetails, tableRecord?.id]);

  const columns: ColumnsType<IAllWarehouseResponderDetailsResult> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 47,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.responsive,
      dataIndex: "responderName",
      key: "name",
      width: 111,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.PhoneNumber,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 431,
      align: "center",
      className: "!font-bold",
      render: (text: string[]) => (
        <Flex gap={16}>
          {text.map((item, index) => (
            <div key={index} className="whitespace-nowrap !font-[vazir]">
              {item}
            </div>
          ))}
        </Flex>
      ),
    },
  ];
  return (
    <RAndCNumberTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={warehouseDetailData}
          loading={warehouseDetailLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: paginationWarehouseDetail.total,
            current: paginationWarehouseDetail.current,
            pageSize: paginationWarehouseDetail.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationWarehouseDetail((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </RAndCNumberTableContainer>
  );
};
