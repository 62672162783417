import { Button, Col, Divider, Form, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { ContactContainer } from "./style";
import { RespondentName } from "./components/RespondentName/RespondentName";
import { RespondentContactInformation } from "./components/RespondentContactInformation/RespondentContactInformation";
import { useInspectionCompanies } from "src/modules/InspectionCompanies/context";
import { IAddNewInspectionCompaniesTabs } from "../../models";
import { IInspectionCompaniesTabs } from "src/modules/InspectionCompanies/models/models";

export const Contact: FC = () => {
  const {
    dispatch: {
      setAddNewInspectionCompaniesActiveKey,
      setInspectionCompaniesActiveKey,
      setBasicInfoEditMode,
      setPressKeyboard,
    },
    form: { contactForm },
    func: { getTableData },
  } = useInspectionCompanies()!;
  const { words } = useLanguage();
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], contactForm);
  useEffect(() => {
    contactForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onClickBack = () => {
    setBasicInfoEditMode(true);
    setAddNewInspectionCompaniesActiveKey(
      IAddNewInspectionCompaniesTabs.basicInformation
    );
  };

  return (
    <div>
      <ContactContainer>
        <Row className="flex gap-6">
          <Col span={24}>
            <Typography className="py-[16px]">
              {words.basicInformation.inspectionCompanies.contactInformation}
            </Typography>
          </Col>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={12} className="h-[100%]">
                <RespondentName />
              </Col>
              <Col span={12} className="h-[100%]">
                <RespondentContactInformation />
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col span={24}>
            <div className="flex gap-[16px] py-[12px] justify-end">
              <Button
                type="default"
                disabled={!submittable}
                onClick={onClickBack}
              >
                {words.global.previousPage}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setInspectionCompaniesActiveKey(
                    IInspectionCompaniesTabs.tableOfInspectionCompanies
                  );
                  setPressKeyboard(false);
                  getTableData();
                }}
              >
                {words.global.completeRegistration}
              </Button>
            </div>
          </Col>
        </Row>
      </ContactContainer>
    </div>
  );
};
