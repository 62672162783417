import useLanguage from "src/store/language";
import { IManagementCard } from "../models/interface";
import { ROUTES } from "src/models/enums/routes";
import { useParams } from "react-router-dom";

export const useManagement = () => {
  const { words } = useLanguage();
  const { orderId } = useParams();
  const cards: IManagementCard = {
    orderInformationManagement: [
      {
        id: 1,
        name: `${words.orders.proforma}`,
        flag: "proforma",
        endPointView: ROUTES.ViewOrder.concat(`/${orderId}`),
        endPointAdd: ROUTES.orders.concat(`/${orderId}`),
      },
      {
        id: 2,
        name: `${words.orders.sale}`,
        flag: "sale",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 3,
        name: `${words.orders.customsFormalities}`,
        flag: "customsFormalities",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 4,
        name: `${words.orders.deviceOpinion}`,
        flag: "deviceOpinion",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 5,
        name: `${words.orders.shipArrival}`,
        flag: "shipArrival",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 6,
        name: `${words.orders.insurance}`,
        flag: "insurance",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 7,
        name: `${words.orders.orderRecord}`,
        flag: "orderRecord",
        endPointView: ROUTES.viewAddOrder.concat(`/${orderId}`),
        endPointAdd: ROUTES.addOrder.concat(`/${orderId}`),
      },

      {
        id: 8,
        name: `${words.orders.origin.origin}`,
        flag: "origin",
        endPointView: ROUTES.viewOrigin.concat(`/${orderId}`),
        endPointAdd: ROUTES.origin.concat(`/${orderId}`),
      },
      {
        id: 9,
        name: `${words.orders.vip.vip}`,
        flag: "vip",
        endPointView: ROUTES.viewVip.concat(`/${orderId}`),
        endPointAdd: ROUTES.vip.concat(`/${orderId}`),
      },
      {
        id: 10,
        name: `${words.orders.inspection}`,
        flag: "inspection",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 11,
        name: `${words.orders.inventory}`,
        flag: "inventory",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 12,
        name: `${words.orders.statement}`,
        flag: "statement",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 13,
        name: `${words.orders.carry}`,
        flag: "carry",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 14,
        name: `${words.orders.financialOrigin}`,
        flag: "financialOrigin",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 15,
        name: `${words.orders.financialDestination}`,
        flag: "financialDestination",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 16,
        name: `${words.orders.bankInfo}`,
        flag: "bankInfo",
        endPointView: "",
        endPointAdd: "",
      },
      {
        id: 17,
        name: `${words.orders.feesRate}`,
        flag: "feesRate",
        endPointView: "",
        endPointAdd: "",
      },
    ],
  };

  return cards;
};
