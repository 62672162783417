import { FC } from "react";
import { InspectionCompanies } from "src/modules/InspectionCompanies";
import { InspectionCompaniesProvider } from "src/modules/InspectionCompanies/context";

export const InspectionCompaniesPages: FC = () => {
  return (
    <InspectionCompaniesProvider>
      <InspectionCompanies />
    </InspectionCompaniesProvider>
  );
};
