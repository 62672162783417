import { Button, Divider, Form, Input, Modal, Typography, message } from "antd";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { ChangePasswordContainer } from "./changePassword.style";
import { IdentityService } from "src/services/Identity/Identity.service";
import { IChangePassword } from "src/services/Identity/models";
import { useLogin, useMediaQuery } from "src/hook";
import { FormItem } from "src/components/UiKit/FormItem";
import { breakPointsSm } from "src/constants/screen";
import useLanguage from "src/store/language";
interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}
interface IChangePasswordForm extends IChangePassword {
  repeatPassword?: string;
}
export const ChangePassword: FC<IProps> = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const [loading, setLoading] = useState<boolean>(false);
  const { loginHandler } = useLogin();
  const isMobileDevice = !useMediaQuery(breakPointsSm);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onFinish = async (values: IChangePasswordForm) => {
    try {
      setLoading(true);
      const { ChangePassword } = new IdentityService();
      if (values.newPassword === values.repeatPassword) {
        delete values.repeatPassword;
        const result = await ChangePassword(values);
        if (result && result.data && result.status === 200) {
          loginHandler(
            result.data.accessToken,
            result.data.roles,
            result.data.paths
          );
        }
        form.resetFields();
        message.success(words.mainLayout.changePasswordMessage);
        handleCancel();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Modal
        width={isMobileDevice ? "95%" : "34.7%"}
        title={words.mainLayout.changePassword}
        open={isModalOpen}
        onCancel={handleCancel}
        styles={{ body: { height: "606px" } }}
        footer={null}
        afterClose={() => {
          form.resetFields();
        }}
        className="relative"
      >
        <ChangePasswordContainer>
          <Form className="w-[100%]" onFinish={onFinish} form={form}>
            <div>
              <Divider className="mt-0 mb-0" />
              <Typography className="nameHolder mt-[54px] mb-[48px]">
                {words.mainLayout.passwordInformation}
              </Typography>
              <FormItem
                label={words.mainLayout.oldPassword}
                name="oldPassword"
                rules={[{ required: true }]}
                required={false}
              >
                <Input.Password
                  placeholder={words.global.insert}
                  className="inputPassword"
                />
              </FormItem>
              <FormItem
                label={words.mainLayout.newPassword}
                name="newPassword"
                rules={[{ required: true }]}
                required={false}
              >
                <Input.Password
                  placeholder={words.global.insert}
                  className="inputPassword"
                />
              </FormItem>
              <FormItem
                label={words.mainLayout.repeatPassword}
                name="repeatPassword"
                style={{}}
                dependencies={["newPassword"]}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(words.mainLayout.newPasswordNotMatch)
                      );
                    },
                  }),
                ]}
                required={false}
              >
                <Input.Password
                  placeholder={words.global.insert}
                  className="inputPassword"
                />
              </FormItem>
            </div>
            <div className="flex justify-center gap-[24px] footer  mt-[24px]">
              <Button
                className="w-[30%]"
                htmlType="button"
                onClick={() => {
                  handleCancel();
                  form.resetFields();
                }}
              >
                {words.global.cancel}
              </Button>
              <Button
                className="w-[30%]"
                type="primary"
                htmlType="button"
                loading={loading}
                onClick={form.submit}
                disabled={!submittable}
              >
                {words.global.save}
              </Button>
            </div>
          </Form>
        </ChangePasswordContainer>
      </Modal>
    </div>
  );
};
