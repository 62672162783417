import { Col, Form, Row, Typography } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useEditInspectionCompanies } from "../../context/context";
import { RespondentName } from "./components/RespondentName/RespondentName";
import { RespondentContactInformation } from "./components/RespondentContactInformation/RespondentContactInformation";
import { EditContactContainer } from "../style";

export const EditContact: FC = () => {
  const {
    form: { editContactForm },
  } = useEditInspectionCompanies()!;
  const { words } = useLanguage();

  const onFinish = (values: any) => {};

  return (
    <Form form={editContactForm} onFinish={onFinish}>
      <EditContactContainer>
        <Row className="flex gap-6">
          <Col span={24}>
            <Typography className="py-[16px]">
              {
                words.basicInformation.inspectionCompanies
                  .editContactInformation
              }
            </Typography>
          </Col>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={12} className="h-[100%]">
                <RespondentName />
              </Col>
              <Col span={12} className="h-[100%]">
                <RespondentContactInformation />
              </Col>
            </Row>
          </Col>
        </Row>
      </EditContactContainer>
    </Form>
  );
};
