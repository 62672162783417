import { FC } from "react";
import { SuppliersContainer } from "./style";
import { Tabs, TabsProps } from "antd";
import useLanguage from "src/store/language";
import { useSuppliers } from "./context/context";
import { SuppliersTable } from "./components/SuppliersTable";
import { AddNewSupplier } from "./components/AddNewSupplier";
import {
  IAddNewSupplierTabs,
  ISuppliersTabs,
} from "src/services/Identity/models/mockModels/supp.models";
import { Guard } from "src/components/Guard";
import { AddSupplierPath } from "src/services/BaseInfo/guardPath";

export const Suppliers: FC = () => {
  const {
    value: { suppliersActiveKey },
    dispatch: {
      setSuppliersActiveKey,
      setAddNewSupplierActiveKey,
      setResponderEditMode,
    },
    form: { contactForm, basicInformationForm },
  } = useSuppliers()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: ISuppliersTabs.tableOfSuppliers,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">table_chart</span>
          {words.basicInformation.suppliers.tableOfSuppliers}
        </span>
      ),
      children: <SuppliersTable />,
    },
    {
      key: ISuppliersTabs.addNewSupplier,
      label: (
        <Guard action={AddSupplierPath}>
          <span className="flex gap-[8px] justify-center items-center">
            <span className="material-icons text-[20px]">add_box</span>
            {words.basicInformation.suppliers.addNewSupplier}
          </span>
        </Guard>
      ),
      children: (
        <Guard action={AddSupplierPath}>
          <AddNewSupplier />
        </Guard>
      ),
    },
  ];

  const onChange = (key: string) => {
    setSuppliersActiveKey(key);
    setAddNewSupplierActiveKey(IAddNewSupplierTabs.basicInformation);
    contactForm?.resetFields();
    basicInformationForm?.resetFields();
    setResponderEditMode(false);
  };
  return (
    <SuppliersContainer>
      <Tabs items={items} onChange={onChange} activeKey={suppliersActiveKey} />
    </SuppliersContainer>
  );
};
