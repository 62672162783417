import { message } from "antd";
import { ColumnsType } from "antd/es/table";
import { DataNode } from "antd/es/tree";
import {
  Dispatch,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseQueryResult,
  useQuery,
} from "react-query";
import {
  SetURLSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { reactQueryEndPoints } from "src/constants/rectQueryEndPoints";

import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import {
  IAddRoleToUserArg,
  IRole,
  IUsersReport,
} from "src/services/UserManagement/models";
import { IDataModel } from "src/services/models";
import { UserState } from "./components/UserState";
import { ITablePagination } from "src/models/interfaces/pagination";
import useLanguage from "src/store/language";
import { IObject } from "src/models/interfaces";

//---------------------------Models-------------------------------------
interface TableData extends IUsersReport {
  "#": number;
  tools: ReactNode;
}
interface IContext {
  values: {
    tableColumns: ColumnsType<any>;
    allUserQuery:
      | UseQueryResult<IDataModel<IUsersReport[]> | undefined>
      | undefined;
    dataSource: TableData[];
    showAssign: boolean;
    treeData: DataNode[] | undefined;
    loadingRolesOfAction: boolean;
    currentCheckedKeys: number[];
    assignLoading: boolean;
    showPassword: boolean;
    currentUser: IUsersReport | undefined;
    pagination: ITablePagination;
    getRolesLoading: boolean;
  };
  func: {
    onCancelAssign: () => void;
    onCancelPassword: () => void;
    onAssign: (selectedKeys: number[]) => Promise<void>;
    onChangeState: (key: string, value?: string) => void;
    goToFirstPage: () => void;
  };
  dispatch: {
    setSearchParams: SetURLSearchParams;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
}
//-------------------------Create Context---------------------------------------
const defaultContextValue: IContext = {
  values: {
    tableColumns: [],
    allUserQuery: undefined,
    dataSource: [],
    showAssign: false,
    treeData: [],
    loadingRolesOfAction: false,
    currentCheckedKeys: [],
    assignLoading: false,
    showPassword: false,
    currentUser: undefined,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 10,
    },
    getRolesLoading: false,
  },
  func: {
    onCancelAssign: () => {},
    onAssign: async () => {},
    onCancelPassword: () => {},
    onChangeState: () => {},
    goToFirstPage: () => {},
  },
  dispatch: {
    setSearchParams: () => {},
    setPagination: () => {},
  },
};

export const AssignRoleToUserCTX = createContext<IContext>(defaultContextValue);

//-------------------------Provider---------------------------------------
export const AssignRoleToUserProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { words } = useLanguage();
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [showAssign, setShowAssign] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUsersReport>();
  const [getRolesLoading, setRolesLoading] = useState<boolean>(false);
  const [currentCheckedKeys, setCurrentCheckedKeys] = useState<number[]>([]);
  const [assignLoading, setAssignLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loadingRolesOfAction, setLoadingRolesOfAction] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const onClickTools = async (user: IUsersReport) => {
    try {
      setLoadingRolesOfAction(true);
      setShowAssign(true);
      setCurrentUser(user);
      setCurrentCheckedKeys([]);
      const selectedRoles = await getRolesOfUser(user.id);
      if (selectedRoles) {
        setCurrentCheckedKeys(selectedRoles.map((role) => role.id));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingRolesOfAction(false);
    }
  };
  const onCancelAssign = () => {
    setCurrentUser(undefined);
    setShowAssign(false);
  };
  const onClickPassword = (user: IUsersReport) => {
    setCurrentUser(user);
    setShowPassword(true);
  };
  const onCancelPassword = () => {
    setCurrentUser(undefined);
    setShowPassword(false);
  };

  const createTree = (role: IRole) => {
    const newRole: DataNode = {
      title: role.roleName,
      key: role.id,
    };
    if (role.childeren.length > 0) {
      newRole.children = role.childeren.map((item) => createTree(item));
    }
    return newRole;
  };
  const treeData: DataNode[] | undefined = roles.map((item) =>
    createTree(item)
  )[0]?.children;
  const fetchRoles = async () => {
    setRolesLoading(true);
    getRoles()
      .then((roles) => {
        if (roles && roles.length) {
          setRoles(roles);
        }
      })
      .finally(() => {
        setRolesLoading(false);
      });
  };
  const onAssign = async (selectedKeys: number[]) => {
    if (!currentUser) return;
    try {
      setAssignLoading(true);
      const { AddUserRole } = new UserManagementService();
      const reqBody: IAddRoleToUserArg = {
        rolesId: selectedKeys,
      };
      const result = await AddUserRole(currentUser.id, reqBody);
      if (result && result.status === 200) {
        message.success(
          words.systemSetting.actionToRole["assignRole "] +
            " " +
            `"` +
            currentUser?.userName +
            `"` +
            words.systemSetting.actionToRole.wasSuccessfully
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAssignLoading(false);
    }
  };
  const getUserReports = useCallback(async () => {
    try {
      const { userReport } = new UserManagementService();
      const res = await userReport(searchParams.toString());
      if (res && res.data) {
        return res.data;
      }
    } catch (err) {
      console.log(err);
    }
  }, [searchParams]);
  const onChangeState = (key: string, value?: string) => {
    setSearchParams((prev) => {
      if (!value) {
        prev.delete(key);
      } else {
        prev.delete(key);
        prev.append(key, value);
      }

      return prev;
    });
  };
  const goToFirstPage = () => onChangeState("Offset", "1");
  //---------------------------------react query-------------------------------
  const allUserQuery = useQuery({
    queryFn: getUserReports,
    queryKey: reactQueryEndPoints.AllUserReport,
    enabled: false,
  });
  const { data, refetch } = allUserQuery;
  const tableColumns = useTableColumns(refetch);
  //-----------------------------useEffects-----------------------------------
  useEffect(() => {
    fetchRoles();
  }, []);
  useEffect(() => {
    if (data) {
      setDataSource(
        createTableData(data.records, onClickTools, onClickPassword)
      );
      setPagination((prev) => ({ ...prev, total: data.count }));
    }
  }, [data]);

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.current.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);
  useEffect(() => {
    if (searchParams.size) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, searchParams.size]);

  const contextValue: IContext = {
    values: {
      tableColumns,
      allUserQuery,
      dataSource,
      showAssign,
      treeData,
      loadingRolesOfAction,
      currentCheckedKeys,
      assignLoading,
      showPassword,
      currentUser,
      pagination,
      getRolesLoading,
    },
    func: {
      onCancelAssign,
      onAssign,
      onCancelPassword,
      onChangeState,
      goToFirstPage,
    },
    dispatch: {
      setSearchParams,
      setPagination,
    },
  };
  return (
    <AssignRoleToUserCTX.Provider value={contextValue}>
      {children}
    </AssignRoleToUserCTX.Provider>
  );
};

export const useAssignRoleToUser = () => useContext(AssignRoleToUserCTX);

//---------------------------Functions-------------------------------------

const createTableData = (
  data: IUsersReport[] | undefined,
  onClickTools: (user: IUsersReport) => void,
  passwordOnClick: (user: IUsersReport) => void
) => {
  if (!data) return [];
  const newData = data.map((user, i) => ({
    "#": i + 1,
    ...user,
    tools: (
      <Tools
        onClick={() => onClickTools(user)}
        passwordOnClick={() => passwordOnClick(user)}
      />
    ),
  }));
  return newData;
};

const getRoles = async () => {
  try {
    const { Roles } = new UserManagementService();
    const res = await Roles();
    if (res && res.status === 200 && res.data) {
      return res.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
const getRolesOfUser = async (id: number) => {
  try {
    const { RolesOfUser } = new UserManagementService();
    const res = await RolesOfUser(id);
    if (res && res.data) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
};

//---------------------------FC-------------------------------------
const Tools: FC<{ onClick: () => void; passwordOnClick: () => void }> = ({
  onClick,
  passwordOnClick,
}) => {
  const { words } = useLanguage();
  return (
    <div className="flex justify-center items-center gap-[16px] font-[400] text-[#2C73FC] cursor-pointer">
      <span className="material-icons text-[16px]" onClick={passwordOnClick}>
        password
      </span>
      <span onClick={onClick}>{words.systemSetting.roleToUser.assignRole}</span>
    </div>
  );
};

interface IRenderTableValue extends HTMLAttributes<HTMLDivElement> {
  text: string | number;
}
const RenderTableValue: FC<IRenderTableValue> = (props) => {
  return (
    <div
      className="text-center text-[14px] text-[#5D6881] font-[400]"
      {...props}
    >
      {props.text}
    </div>
  );
};
//-------------------------Columns---------------------------------------
type refetchType = <TPageData>(
  options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<
  QueryObserverResult<IDataModel<IUsersReport[]> | undefined, unknown>
>;
const useTableColumns = (refetch: refetchType) => {
  const { words } = useLanguage();
  const columns: ColumnsType<IUsersReport> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      className: "!font-bold !text-center ",
      width: 61,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: words.systemSetting.createUser.userName,
      dataIndex: "userName",
      key: "userName",
      className: "!font-bold !text-center !text-center ",
      width: 134,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: words.systemSetting.createUser.firstName,
      dataIndex: "firstName",
      key: "firstName",
      className: "!font-bold !text-center ",
      width: 154,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: words.systemSetting.createUser.lastName,
      dataIndex: "lastName",
      key: "lastName",
      className: "!font-bold !text-center ",
      width: 146,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: words.systemSetting.roleToUser.state,
      dataIndex: "enable",
      key: "enable",
      className: "!font-bold !text-center ",
      width: 150,
      render: (value, record) => (
        <UserState state={value} userId={record.id} refetch={refetch} />
      ),
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      className: "!font-bold !text-center select-none whitespace-nowrap ",
      width: 177,
    },
  ];

  return columns;
};
