import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IOrdersTabs } from "./models";
import {
  IAllBankResult,
  IAllCurrencyResult,
  IAllProductResult,
  IAllUnitResult,
} from "src/services/BaseInfo/models/result.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { useParams, useSearchParams } from "react-router-dom";
import { IGetAllProforma } from "src/services/Order/models/result.models";
import { OrderService } from "src/services/Order/order.service";
import { ITablePagination } from "src/models/interfaces/pagination";
import { IObject } from "src/models/interfaces";
import useLanguage from "src/store/language";
import { App } from "antd";

interface IContext {
  value: {
    editMode: boolean;
    currencyLoading: boolean;
    productLoading: boolean;
    unitLoading: boolean;
    bankLoading: boolean;
    allProformaLoading: boolean;
    activeKey: string;
    currency: IAllCurrencyResult[];
    product: IAllProductResult[];
    unit: IAllUnitResult[];
    bank: IAllBankResult[];
    allProforma: IGetAllProforma[];
    pagination: ITablePagination;
    deleteLoading: boolean;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setCurrencyLoading: Dispatch<SetStateAction<boolean>>;
    setProductLoading: Dispatch<SetStateAction<boolean>>;
    setUnitLoading: Dispatch<SetStateAction<boolean>>;
    setBankLoading: Dispatch<SetStateAction<boolean>>;
    setActiveKey: Dispatch<SetStateAction<string>>;
    setCurrency: Dispatch<SetStateAction<IAllCurrencyResult[]>>;
    setProduct: Dispatch<SetStateAction<IAllProductResult[]>>;
    setUnit: Dispatch<SetStateAction<IAllUnitResult[]>>;
    setBank: Dispatch<SetStateAction<IAllBankResult[]>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    getCurrency: () => void;
    getProducts: () => void;
    getUnit: () => void;
    getBank: () => void;
    getAllProforma: () => void;
    deleteProforma: (id: number) => void;
  };
}
export const OrderContext = createContext<IContext | undefined>(undefined);

export const OrderContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { words } = useLanguage();
  const { message } = App.useApp();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(IOrdersTabs.table);
  const [currency, setCurrency] = useState<IAllCurrencyResult[]>([]);
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<IAllProductResult[]>([]);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);
  const [unit, setUnit] = useState<IAllUnitResult[]>([]);
  const [bankLoading, setBankLoading] = useState<boolean>(false);
  const [bank, setBank] = useState<IAllBankResult[]>([]);
  const [allProformaLoading, setAllProformaLoading] = useState<boolean>(false);
  const [allProforma, setAllProforma] = useState<IGetAllProforma[]>([]);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 5,
  });
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  ////////////////////////////////Functions////////////////////////////////
  const getCurrency = useCallback(async () => {
    setCurrencyLoading(true);
    try {
      const { GetAllCurrency } = new BaseInfoService();
      const result = await GetAllCurrency();
      if (result && result.status === 200) {
        setCurrency(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrencyLoading(false);
    }
  }, []);

  const getProducts = useCallback(async () => {
    setProductLoading(true);
    try {
      const { GetAllProduct } = new BaseInfoService();
      const result = await GetAllProduct();
      if (result && result.status === 200) {
        setProduct(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  }, []);

  const getUnit = useCallback(async () => {
    setUnitLoading(true);
    try {
      const { GetAllUnit } = new BaseInfoService();
      const result = await GetAllUnit();
      if (result && result.status === 200) {
        setUnit(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUnitLoading(false);
    }
  }, []);

  const getBank = useCallback(async () => {
    setBankLoading(true);
    try {
      const { GetAllBank } = new BaseInfoService();
      const result = await GetAllBank();
      if (result && result.status === 200) {
        setBank(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setBankLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllProforma = useCallback(async () => {
    setAllProformaLoading(true);
    try {
      const params: IObject = {
        Offset: pagination.current,
        Limit: pagination.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const { GetAllProforma } = new OrderService();
      const result = await GetAllProforma(searchParams.toString());
      if (result && result.status === 200 && result.data) {
        setAllProforma(result.data.records);
        setPagination((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch {
    } finally {
      setAllProformaLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize]);

  const deleteProforma = async (id: number) => {
    setDeleteLoading(true);
    try {
      const { DeleteProform } = new OrderService();
      const result = await DeleteProform(id);
      if (result && result.status === 200) {
        message.success(words.orders.deleteProformaMessage);
        getAllProforma();
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };
  const [search, setSearch] = useSearchParams();
  const editId = search.get("editId");
  const { orderId } = useParams();
  useEffect(() => {
    if (orderId) {
      setEditMode(true);
    }
  }, [orderId]);

  useEffect(() => {
    getBank();
    getCurrency();
    getProducts();
    getUnit();
    getAllProforma();
  }, [getAllProforma, getBank, getCurrency, getProducts, getUnit]);
  const ContextValue: IContext = {
    value: {
      editMode,
      activeKey,
      currency,
      bank,
      bankLoading,
      currencyLoading,
      product,
      productLoading,
      unit,
      unitLoading,
      allProforma,
      allProformaLoading,
      pagination,
      deleteLoading,
    },
    dispatch: {
      setEditMode,
      setActiveKey,
      setCurrency,
      setBank,
      setBankLoading,
      setCurrencyLoading,
      setProduct,
      setProductLoading,
      setUnit,
      setUnitLoading,
      setPagination,
    },
    func: {
      getBank,
      getCurrency,
      getProducts,
      getUnit,
      deleteProforma,
      getAllProforma,
    },
  };
  return (
    <OrderContext.Provider value={ContextValue}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
