import { styled } from "styled-components";

export const EditProfileContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .ant-row {
    padding: 0 !important;
  }
  .username {
    color: ${({ theme }) => theme.colors.secondaryText};
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .userRole {
    color: ${({ theme }) => theme.colors.primary[600]};
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .nameHolder {
    text-align: center;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
  }
  .divider1 {
    width: 1px;
    height: 32px;
    color: #a3aed0 !important;
    z-index: 0;
  }
  .changePicture {
    border-radius: 6px;
    background: var(--2, #2c73fc);
    box-shadow: 1px 2px 2px 0px rgba(147, 147, 147, 0.5);
    width: 24px;
    height: 24px;
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 16px;
    padding-inline: 24px;
    bottom: 16px;
    left: 24px;
    width: calc(100% - 48px);
  }
  .avatarBorder {
    border: 3px solid #2c73fc;
  }
`;
