import * as GuardPath from "../../../services/BaseInfo/guardPath";
import { IPath } from "src/services/Identity/models";

export const ProductPageGuard: IPath[] = [
  GuardPath.AddProductPath,
  GuardPath.AllProductPath,
  GuardPath.UpdateProductPath,
  GuardPath.DeleteProductPath,
];
export const UnitPageGuard: IPath[] = [
  GuardPath.AddUnitPath,
  GuardPath.AllUnitPath,
  GuardPath.UpdateUnitPath,
  GuardPath.DeleteUnitPath,
];
export const CurrencyPageGuard: IPath[] = [
  GuardPath.AddCurrencyPath,
  GuardPath.AllCurrencyPath,
  GuardPath.UpdateCurrencyPath,
  GuardPath.DeleteCurrencyPath,
];
export const VesselPageGuard: IPath[] = [
  GuardPath.AddVesselPath,
  GuardPath.AllVesselPath,
  GuardPath.UpdateVesselPath,
  GuardPath.DeleteVesselPath,
];
export const CountryPageGuard: IPath[] = [
  GuardPath.AddCountryPath,
  GuardPath.AllCountryPath,
  GuardPath.UpdateCountryPath,
  GuardPath.DeleteCountryPath,
];
export const PortPageGuard: IPath[] = [
  GuardPath.AddPortPath,
  GuardPath.AllPortPath,
  GuardPath.UpdatePortPath,
  GuardPath.DeletePortPath,
];
export const SupplierPageGuard: IPath[] = [
  GuardPath.AddSupplierPath,
  GuardPath.AddSupplierResponderPath,
  GuardPath.AddSupplierPhonePath,

  GuardPath.AllSupplierPath,
  GuardPath.AllSupplierResponderDetailsPath,
  GuardPath.AllSupplierRespondersPath,
  GuardPath.SupplierResponderDetailsByIdPath,

  GuardPath.DeleteSupplierPath,
  GuardPath.DeleteSupplierPhonePath,
  GuardPath.DeleteSupplierResponderPath,

  GuardPath.UpdateSupplierPath,
  GuardPath.UpdateSupplierPhonePath,
  GuardPath.UpdateSupplierResponderPath,
];
export const InspectionPageGuard: IPath[] = [
  GuardPath.AddInspectionPath,
  GuardPath.AddInspectionResponderPath,
  GuardPath.AddInspectionPhonePath,

  GuardPath.AllInspectionPath,
  GuardPath.AllInspectionResponderDetailsPath,
  GuardPath.AllInspectionRespondersPath,
  GuardPath.InspectionResponderDetailsByIdPath,

  GuardPath.DeleteInspectionPath,
  GuardPath.DeleteInspectionPhonePath,
  GuardPath.DeleteInspectionResponderPath,

  GuardPath.UpdateInspectionPath,
  GuardPath.UpdateInspectionPhonePath,
  GuardPath.UpdateInspectionResponderPath,
];
export const InsurancePageGuard: IPath[] = [
  GuardPath.AddInsurancePath,
  GuardPath.AddInsuranceResponderPath,
  GuardPath.AddInsurancePhonePath,

  GuardPath.AllInsuracePath,
  GuardPath.AllInsuranceResponderDetailsPath,
  GuardPath.AllInsuranceRespondersPath,
  GuardPath.InsuranceResponderDetailsByIdPath,

  GuardPath.DeleteInsurancePath,
  GuardPath.DeleteInsurancePhonePath,
  GuardPath.DeleteInsuranceResponderPath,

  GuardPath.UpdateInsurancePath,
  GuardPath.UpdateInsurancePhonePath,
  GuardPath.UpdateInsuranceResponderPath,
];
export const BankPageGuard: IPath[] = [
  GuardPath.AddBankPath,
  GuardPath.AddBankResponderPath,
  GuardPath.AddBankPhonePath,

  GuardPath.AllBankPath,
  GuardPath.AllBankResponderDetailsPath,
  GuardPath.AllBankRespondersPath,
  GuardPath.BankPhonesByResponderIdPath,

  GuardPath.DeleteBankPath,
  GuardPath.DeleteBankPhonePath,
  GuardPath.DeleteBankResponderPath,

  GuardPath.UpdateBankPath,
  GuardPath.UpdateBankPhonePath,
  GuardPath.UpdateBankResponderPath,
];
export const TransportPageGuard: IPath[] = [
  GuardPath.AddTransportPath,
  GuardPath.AddTransportResponderPath,
  GuardPath.AddTransportPhonePath,

  GuardPath.AllTransportPath,
  GuardPath.AllTransportResponderDetailsPath,
  GuardPath.AllTransportRespondersPath,
  GuardPath.TransportResponderDetailsByIdPath,

  GuardPath.DeleteTransportPath,
  GuardPath.DeleteTransportPhonePath,
  GuardPath.DeleteTransportResponderPath,

  GuardPath.UpdateTransportPath,
  GuardPath.UpdateTransportPhonePath,
  GuardPath.UpdateTransportResponderPath,
];
export const WareHousePageGuard: IPath[] = [
  GuardPath.AddWarehousePath,
  GuardPath.AddWarehouseResponderPath,
  GuardPath.AddWarehousePhonePath,

  GuardPath.AllWarehousePath,
  GuardPath.AllWarehouseResponderDetailsPath,
  GuardPath.AllWarehouseRespondersPath,
  GuardPath.WarehouseResponderDetailsByIdPath,

  GuardPath.DeleteWarehousePath,
  GuardPath.DeleteWarehousePhonePath,
  GuardPath.DeleteWarehouseResponderPath,

  GuardPath.UpdateWarehousePath,
  GuardPath.UpdateWarehousePhonePath,
  GuardPath.UpdateWarehouseResponderPath,
];
export const CustomBrokerPageGuard: IPath[] = [
  GuardPath.AddCustomBrokerPath,
  GuardPath.AddCustomBrokerPhonePath,

  GuardPath.AllCustomBrokerPath,
  GuardPath.AllCustomBrokerPhonePath,
  GuardPath.AllCustomBrokerResponderDetailsPath,

  GuardPath.DeleteCustomBrokerPath,
  GuardPath.DeleteCustomBrokerPhonePath,

  GuardPath.UpdateCustomBrokerPath,
  GuardPath.UpdateCustomBrokerPhonePath,
];
