import { ReactNode } from "react";
import {
  IAllInsuranceRespondersResult,
  IInsuranceResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";

export enum IInsuranceCompaniesTabs {
  tableOfInsuranceCompanies = "1",
  addNewInsuranceCompanies = "2",
}
export enum IAddNewInsuranceCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export enum IEditInsuranceCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface ResponderTableData extends IAllInsuranceRespondersResult {
  "#"?: number;
  tools?: ReactNode;
}

export interface ResponderPhoneTableData
  extends IInsuranceResponderDetailsByIdResult {
  "#"?: number;
  tools?: ReactNode;
}
