import { ReactNode } from "react";
export enum IOperatingBanksTabs {
  tableOfOperatingBanks = "1",
  addNewOperatingBanks = "2",
}
export enum IAddNewOperatingBanksTabs {
  basicInformation = "1",
  Contacts = "2",
}
export enum IEditOperatingBanksTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface IEditContactsTable {
  id: number;
  name: string;
  phoneNumber: number[];
}
export interface IContactsTable {
  id: number;
  name: string;
  phoneNumber: number[];
}
export interface TableDataNamePhoneNumber extends IContactsTable {
  tools?: ReactNode;
}
export interface IPhoneNumber {
  name: string;
  phoneNumber: string[];
}
export interface IContactPhoneNumber {
  phoneNumber: number;
}
export interface IGetOperatingBanks {
  id: number;
  bankName: string;
  branchName: string;
  branchCode: string;
  address: string;
  phoneNumber: IPhoneNumber[];
}
export interface TableData extends IGetOperatingBanks {
  tools?: ReactNode;
}

export const dataSource: TableData[] = [
  {
    id: 1,
    bankName: "Keshavarzi",
    branchName: "amol",
    branchCode: "103",
    address: "414040, Astrakhan, Admiralteyskaya str. 51,Liter B, office 54",
    phoneNumber: [
      {
        name: "سید مهدی موسوی اسلام آبادی",
        phoneNumber: [
          "09115697466",
          "09115697466",
          "09115697466",
          "09115697466",
        ],
      },
      { name: "حامد نظری ", phoneNumber: ["09115697466"] },
      { name: "حمید بهروزی ", phoneNumber: ["09115697466"] },
      { name: "حمید بهروزی ", phoneNumber: ["09115697466"] },
    ],
  },
  {
    id: 2,
    address: "address",
    bankName: "melli",
    branchName: "babol",
    branchCode: "1432",
    phoneNumber: [{ name: "Hamid behruzi", phoneNumber: ["09115697466"] }],
  },
  {
    id: 3,
    bankName: "resalat",
    branchName: "sari",
    branchCode: "1023",
    address: "address",
    phoneNumber: [
      { name: "Hamid behruzi", phoneNumber: ["09115697466"] },
      {
        name: "Seyed mehdi moosavy eslam abadi",
        phoneNumber: ["09115697466"],
      },
      {
        name: "Seyed mehdi moosavy",
        phoneNumber: ["09115697466"],
      },
      {
        name: "Seyed mehdi moosavy",
        phoneNumber: ["09115697466"],
      },
    ],
  },
];
export const dataSourceNamePhoneNumber: TableDataNamePhoneNumber[] = [
  {
    id: 0,
    name: "حمید بهروزی",
    phoneNumber: [9170916009, 9123456980, 9123456786],
  },
  {
    id: 1,
    name: "سید مهدی موسوی اسلام آبادی",
    phoneNumber: [9170916009, 9123456789],
  },
  {
    id: 2,
    name: "حامد نظری",
    phoneNumber: [9123456789],
  },
];
