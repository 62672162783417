import { FC } from "react";
import { Products } from "src/modules/Products";
import { ProductsProvider } from "src/modules/Products/context/context";

export const ProductsPage: FC = () => {
  return (
    <ProductsProvider>
      <Products />
    </ProductsProvider>
  );
};
