import { Row } from "antd";
import { styled } from "styled-components";

export const ViewClearanceWorkersContainer = styled.div`
  width: 92%;
  border-radius: 16px;
  display: flex;
  padding: 32px 0px;
  gap: 32px;
  background: var(--color-Base-Color-White, #fff);
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .borderViewItems {
    padding: 24px 16px;
    border-radius: 8px;
    background: var(--color-Light-Mode-BG-Background-1, #f2f9f9);
    width: 100%;
  }
`;
export const RAndCNumberTableContainer = styled(Row)`
  display: flex;
  gap: 24px;
  width: 100%;
`;
