import { Col, Typography, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import { TableData } from "../models/models";
import { useCurrencies } from "../context/context";
import { CurrenciesTableContainer } from "../style/style";
import { useSearchParams } from "react-router-dom";
import { Guard } from "src/components/Guard";
import {
  DeleteCurrencyPath,
  UpdateCurrencyPath,
} from "src/services/BaseInfo/guardPath";

export const CurrenciesTable: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    dispatch: { setEditMode, setPagination, setCurrencyId },
    value: { dataSource, pagination, currencyLoading },
    func: { getCurrencyTableData, deleteCurrency },
  } = useCurrencies()!;

  const onChangeParams = useChangeParams();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.current.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);
  useEffect(() => {
    getCurrencyTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns: ColumnsType<TableData> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.currencies.currenciesName,
      dataIndex: "name",
      key: "name",
      width: 420,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.basicInformation.currencies.abbreviationName,
      dataIndex: "shortName",
      key: "shortName",
      width: 152,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateCurrencyPath}>
            <span
              onClick={() => {
                setEditMode(true);
                form?.setFieldsValue(record);
                setCurrencyId(record.id);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <Guard action={DeleteCurrencyPath}>
            <DPopconfirm
              deleteClassName
              onConfirm={() => {
                deleteCurrency(record.id);
              }}
              description={words.basicInformation.currencies.deletePopConfirm}
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <CurrenciesTableContainer>
      <Col span={24}>
        <Typography>
          {words.basicInformation.currencies.tableOfCurrencies}
        </Typography>
      </Col>
      <Col span={24}>
        <div className="tableHolder">
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={currencyLoading}
            scroll={{ x: "auto" }}
            rowKey={getRowKey}
            className="w-[100%]"
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: pagination.total,
              current: pagination.current,
              onChange: (page: number, pageSize: number) => {
                setPagination((prev) => ({ ...prev, current: page, pageSize }));
                onChangeParams("Limit", pageSize.toString());
                onChangeParams("Offset", page.toString());
              },
              onShowSizeChange: (current: number, size: number) => {
                onChangeParams("Limit", size.toString());
                onChangeParams("Offset", current.toString());
                setPagination((prev) => ({ ...prev, current }));
              },
            }}
          />
        </div>
      </Col>
    </CurrenciesTableContainer>
  );
};
