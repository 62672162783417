import { ReactNode } from "react";

import {
  IAllBankRespondersResult,
  IBankResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";

export interface ResponderTableData extends IAllBankRespondersResult {
  "#"?: number;
  tools?: ReactNode;
}

export interface ResponderPhoneTableData
  extends IBankResponderDetailsByIdResult {
  "#"?: number;
  tools?: ReactNode;
}

export enum IEditOperatingBanksTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface IEditContactsTable {
  id: number;
  name: string;
  phoneNumber: string;
}
export interface TableData extends IEditContactsTable {
  tools?: ReactNode;
}

export const dataSource: TableData[] = [
  {
    id: 0,
    name: "Hamid behruzi",
    phoneNumber: "+7 (917) 091-85-06",
  },
  {
    id: 1,
    name: "Hamid behruzi",
    phoneNumber: "+7 (917) 091-85-06",
  },
  {
    id: 2,
    name: "Hamid behruzi",
    phoneNumber: "+7 (917) 091-85-06",
  },
];
