import { ReactNode } from "react";
import {
  IAllTransportRespondersResult,
  ITransportResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";

export interface ResponderTableData extends IAllTransportRespondersResult {
  "#"?: number;
  tools?: ReactNode;
}

export interface ResponderPhoneTableData
  extends ITransportResponderDetailsByIdResult {
  "#"?: number;
  tools?: ReactNode;
}

export enum IEditTransportCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
