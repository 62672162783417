import { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageTitleKeysType, usePageTitle } from "../hook/usePageTitle";
import { HeaderContainer } from "../styles/header";
import { Avatar, Col, Dropdown, Flex, MenuProps, Popover, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import useGlobalStore from "src/store/global";
import { USER_DATA } from "src/constants";
import { useLogout } from "src/hook";
import useLanguage from "src/store/language";
import { useTheme } from "src/theme";
import { ChangePassword } from "src/modules/changePassword";
import { EditProfile } from "src/modules/editProfile";

const Header: FC = () => {
  let location = useLocation();
  const pageTitle = usePageTitle(location.pathname as PageTitleKeysType);
  const [userRole, setUserRole] = useState<string[]>([]);
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const { userInfo } = useGlobalStore();
  const { words } = useLanguage();

  const {
    theme: { colors },
  } = useTheme();
  useEffect(() => {
    const userRoleInLocal = localStorage.getItem(USER_DATA);
    if (userRoleInLocal) {
      setUserRole(JSON.parse(userRoleInLocal));
    }
  }, []);
  const { logoutHandler } = useLogout();
  const editProfileModalHandler = () => {
    setShowEditProfile(true);
  };
  const changePasswordModalHandler = () => {
    setShowChangePassword(true);
  };
  const items: MenuProps["items"] = useMemo(() => {
    const color = colors.gray[400];
    return [
      {
        key: "1",
        label: (
          <div
            className="flex gap-[5px] py-[10px]"
            onClick={editProfileModalHandler}
          >
            <span
              className={`material-icons w-[24px] h-[24px]`}
              style={{ color }}
            >
              person
            </span>
            <div> {words.mainLayout.editProfile}</div>
          </div>
        ),
      },
      {
        type: "divider",
      },
      {
        key: "2",
        label: (
          <div
            className="flex gap-[5px] py-[10px]"
            onClick={changePasswordModalHandler}
          >
            <span
              className={`material-icons w-[24px] h-[24px] `}
              style={{ color }}
            >
              lock
            </span>
            {words.mainLayout.changePassword}
          </div>
        ),
      },
      {
        type: "divider",
      },
      {
        key: "3",
        label: (
          <div onClick={logoutHandler} className="flex gap-[5px] py-[10px]">
            <span
              className={`material-icons w-[24px] h-[24px] `}
              style={{ color }}
            >
              logout
            </span>
            {words.mainLayout.logout}
          </div>
        ),
      },
      {
        type: "divider",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors]);
  return (
    <HeaderContainer>
      <Row className="content">
        <Col span={12}>
          <Flex align="center" className="h-full">
            <h2 className="title">{pageTitle}</h2>
          </Flex>
        </Col>
        <Col span={12} className="notice">
          <Dropdown menu={{ items }} trigger={["hover"]} placement="bottomLeft">
            <div className="avatar cursor-pointer">
              <Avatar size={59} icon={<UserOutlined />} />
              <div className="flex justify-between items-center gap-[32px] ">
                <div>
                  <div className="username">
                    {userInfo?.firstName} {userInfo?.lastName}
                  </div>

                  {userRole && userRole.join(" , ").length > 30 ? (
                    <Popover title={userRole.join(" , ")} placement="right">
                      <div className="userRole">
                        {userRole.join(" , ").substring(0, 30).concat("...")}
                      </div>
                    </Popover>
                  ) : (
                    <div className="userRole">{userRole?.join(" , ")}</div>
                  )}
                </div>
                <span className={`material-icons grayColor`}>
                  keyboard_arrow_down
                </span>
              </div>
            </div>
          </Dropdown>
        </Col>
      </Row>
      <EditProfile
        isModalOpen={showEditProfile}
        setIsModalOpen={setShowEditProfile}
      />
      <ChangePassword
        isModalOpen={showChangePassword}
        setIsModalOpen={setShowChangePassword}
      />
    </HeaderContainer>
  );
};

export default Header;
