import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { TableData } from "../models/models";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import useLanguage from "src/store/language";

interface IContext {
  value: {
    editMode: boolean;
    dataSource: TableData[];
    pagination: ITablePagination;
    unitLoading: boolean;
    unitId: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setUnitId: Dispatch<SetStateAction<number>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: { getUnitTableData: () => void; deleteUnit: (id: number) => void };
  form: { form: FormInstance<any> | undefined };
}

export const UnitsOfMeasurementContext = createContext<IContext | undefined>(
  undefined
);

export const UnitsOfMeasurementProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [unitId, setUnitId] = useState<number>(-1);

  ///////////Functions////////////////////////////////////////////////////////////////
  const getUnitTableData = async () => {
    setUnitLoading(true);
    try {
      const { GetAllUnit } = new BaseInfoService();
      const result = await GetAllUnit(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUnitLoading(false);
    }
  };

  const deleteUnit = async (id: number) => {
    setUnitLoading(true);
    try {
      const { DeleteUnit } = new BaseInfoService();
      const result = await DeleteUnit(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.unitsOfMeasurement.deleteUnitMessage
        );
        getUnitTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUnitLoading(false);
    }
  };
  const ContextValue: IContext = {
    value: { editMode, dataSource, pagination, unitLoading, unitId },
    dispatch: { setEditMode, setPagination, setUnitId },
    func: { getUnitTableData, deleteUnit },
    form: { form },
  };
  return (
    <UnitsOfMeasurementContext.Provider value={ContextValue}>
      {children}
    </UnitsOfMeasurementContext.Provider>
  );
};

export const useUnitsOfMeasurement = () =>
  useContext(UnitsOfMeasurementContext);
