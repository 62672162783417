import { FC, HTMLAttributes, useEffect, useState } from "react";
import { AssignActionToRoleContainer } from "./styles";
import { Col, Flex, Input, Table, Typography } from "antd";
import { TableData, useAssignActionToRole } from "./context";
import { AssignActionModal } from "./components/AssignActionsModal";
import { getRowKey } from "src/helper/getRowKey";
import useLanguage from "src/store/language";
import { ColumnsType } from "antd/es/table";

export const AssignActionToRole: FC = () => {
  const {
    values: {
      dataSource,
      showAssign,
      allActionsQuery,
      treeData,
      currentCheckedKeys,
      loadingRolesOfAction,
      assignLoading,
    },
    func: { onCancelAssign, onAssign },
  } = useAssignActionToRole();
  const { words } = useLanguage();
  const { Title } = Typography;
  const [data, setData] = useState<TableData[]>([]);
  interface IRenderTableValue extends HTMLAttributes<HTMLDivElement> {
    text: string | number;
  }
  const RenderTableValue: FC<IRenderTableValue> = (props) => {
    return (
      <div
        className="text-center text-[14px] text-[#5D6881] font-[400]"
        {...props}
      >
        {props.text}
      </div>
    );
  };
  const tableColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      className: "!font-bold text-center",
      width: 61,
      render: (text, record, index) => <RenderTableValue text={text} />,
    },
    {
      title: words.systemSetting.actionToRole.displayName,
      dataIndex: "displayName",
      key: "displayName",
      className: "!font-bold !text-center",
      width: 170,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: words.systemSetting.actionToRole.name,
      dataIndex: "name",
      key: "name",
      className: "!font-bold !text-center",
      width: 126,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: words.systemSetting.actionToRole.direction,
      dataIndex: "urlPath",
      key: "urlPath",
      className: "!font-bold !text-center",
      width: 583,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      className: "!font-bold select-none whitespace-nowrap !text-center",
      width: 156,
    },
  ];
  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);
  const onSearch = (value: string) => {
    if (!value) {
      setData(dataSource);
      return;
    }
    const newData = dataSource
      .filter(
        (item) =>
          item.displayName
            .toLowerCase()
            .trim()
            .includes(value.toLowerCase().trim()) ||
          item.name.toLowerCase().trim().includes(value.toLowerCase().trim()) ||
          item.urlPath.toLowerCase().trim().includes(value.toLowerCase().trim())
      )
      .map((item, i) => {
        item["#"] = i + 1;
        return item;
      });
    setData(newData);
  };
  return (
    <AssignActionToRoleContainer justify={"center"} gutter={[0, 24]}>
      <Col span={24} className="header">
        <Flex align="center" justify="space-between">
          <div className="text-[24px] font-[700] leading-[32px] text-[#5d6881]">
            <Title>{words.systemSetting.actionToRole.actionToRole}</Title>
          </div>
          <Flex justify="flex-start" align="center" gap={32}>
            <Input
              placeholder={words.systemSetting.actionToRole.searchPlaceHolder}
              prefix={
                <span className="material-icons text-[#1B2559]">search</span>
              }
              className="w-[341px] h-[46px] rounded-[10px] bg-[#E5F3F3]"
              onChange={(event) => {
                const value = event.currentTarget.value;
                onSearch(value);
              }}
            />
          </Flex>
        </Flex>
      </Col>
      <Col span={22} order={1} className="content">
        <Table
          columns={tableColumns}
          dataSource={data}
          loading={allActionsQuery?.isFetching}
          rowKey={getRowKey}
          scroll={{ x: 860 }}
          pagination={{
            position: ["bottomCenter"],
          }}
          rowClassName={(record, index) =>
            index % 2 !== 0 ? "bg-[#F2F7FF]" : ""
          }
        />
      </Col>
      <AssignActionModal
        open={showAssign}
        onCancel={onCancelAssign}
        title={words.systemSetting.actionToRole.modalTitle}
        treeData={treeData}
        onAssign={onAssign}
        currentCheckedKeys={currentCheckedKeys}
        loading={loadingRolesOfAction}
        confirmLoading={assignLoading}
      />
    </AssignActionToRoleContainer>
  );
};
