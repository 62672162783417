import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import useLanguage from "src/store/language";
import { ClearanceWorkersContainer } from "./style/style";
import { IClearanceWorkersTabs } from "./models/models";
import { useClearanceWorkers } from "./context/context";
import { AddNewClearanceWorker } from "./components/AddNewClearanceWorker";
import { ClearanceWorkersTable } from "./components/ClearanceWorkersTable";
import { IAddNewClearanceWorkerTabs } from "./components/AddNewClearanceWorker/models";

export const ClearanceWorkers: FC = () => {
  const {
    value: { clearanceWorkersActiveKey },
    dispatch: {
      setClearanceWorkersActiveKey,
      setAddNewClearanceWorkersActiveKey,
      setEditContact,
    },
    form: { contactForm, basicInformationForm },
  } = useClearanceWorkers()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: IClearanceWorkersTabs.tableOfClearanceWorkers,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">table_chart</span>
          {words.basicInformation.clearanceWorkers.clearanceWorkersTable}
        </span>
      ),
      children: <ClearanceWorkersTable />,
    },
    {
      key: IClearanceWorkersTabs.addNewClearanceWorkers,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">add_box</span>
          {words.basicInformation.clearanceWorkers.addNewClearanceWorker}
        </span>
      ),
      children: <AddNewClearanceWorker />,
    },
  ];

  const onChange = (key: string) => {
    setClearanceWorkersActiveKey(key);
    setAddNewClearanceWorkersActiveKey(
      IAddNewClearanceWorkerTabs.basicInformation
    );
    contactForm?.resetFields();
    basicInformationForm?.resetFields();
    setEditContact(false);
  };
  return (
    <ClearanceWorkersContainer>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={clearanceWorkersActiveKey}
      />
    </ClearanceWorkersContainer>
  );
};
