import { ReactNode } from "react";
import {
  IAllInspectionRespondersResult,
  IInspectionResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";
export enum IInspectionCompaniesTabs {
  tableOfInspectionCompanies = "1",
  addNewInspectionCompanies = "2",
}
export enum IAddNewInspectionCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export enum IEditInspectionCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface ResponderTableData extends IAllInspectionRespondersResult {
  "#"?: number;
  tools?: ReactNode;
}

export interface ResponderPhoneTableData
  extends IInspectionResponderDetailsByIdResult {
  "#"?: number;
  tools?: ReactNode;
}
