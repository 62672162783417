import {
  App,
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Popover,
  Row,
  Typography,
} from "antd";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { EditProfileContainer } from "./editProfile.styles";
import useGlobalStore from "src/store/global";
import { USER_DATA } from "src/constants";
import { FormItem } from "src/components/UiKit/FormItem";
import { IdentityService } from "src/services/Identity/Identity.service";
import { IEditProfile } from "src/services/Identity/models";
import { useUserInfo } from "src/hook/useUserInfo";
import { useMediaQuery } from "src/hook";
import { breakPointsSm } from "src/constants/screen";
import { DInput } from "src/components/UiKit/DInput";
import useLanguage from "src/store/language";
interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditProfile: FC<IProps> = ({ isModalOpen, setIsModalOpen }) => {
  const isMobileDevice = !useMediaQuery(breakPointsSm);
  const { message } = App.useApp();
  const { words } = useLanguage();
  const showMessage = () => {
    message.success(words.mainLayout.profileUpdateMessage);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { userInfo } = useGlobalStore();
  const [userRole, setUserRole] = useState<string[]>([]);
  useEffect(() => {
    const userRoleInLocal = localStorage.getItem(USER_DATA);
    if (userRoleInLocal) {
      setUserRole(JSON.parse(userRoleInLocal));
    }
  }, []);
  const { userInfoRefetch } = useUserInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onFinish = async (values: IEditProfile) => {
    try {
      setLoading(true);
      const { UpdateUserInfo } = new IdentityService();
      const result = await UpdateUserInfo(values);
      if (result && result.status === 200) {
        userInfoRefetch();
        form.resetFields();
        handleCancel();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      showMessage();
    }
  };
  return (
    <Modal
      width={isMobileDevice ? "95%" : "30%"}
      title={words.mainLayout.editProfile}
      open={isModalOpen}
      onCancel={handleCancel}
      styles={{ body: { minHeight: "500px" } }}
      footer={null}
      afterClose={() => {
        form.resetFields();
      }}
      className="relative"
    >
      <EditProfileContainer>
        <Form className="w-[100%] " form={form} onFinish={onFinish}>
          <Divider className="mt-0 mb-0" />
          <div className=" flex flex-col items-center gap-[16px] mt-[24px] ">
            <div className="flex flex-row items-center mb-[32px]">
              <div className="username">
                {userInfo?.firstName} {userInfo?.lastName}
              </div>
              <Divider type="vertical" dashed className="divider1" />
              {userRole && userRole.join(" , ").length > 30 ? (
                <Popover title={userRole.join(" , ")}>
                  <div className="userRole">
                    {userRole.join(" , ").substring(0, 30).concat("...")}
                  </div>
                </Popover>
              ) : (
                <div className="userRole">{userRole?.join(" , ")}</div>
              )}
            </div>
          </div>
          <Row justify={"center"}>
            <Divider className="mb-[32px] mt-0" />
            <Col span={24}>
              <Typography className="flex justify-center text-[18px]">
                {words.mainLayout.passwordInformation}
              </Typography>
            </Col>
            <Col span={24}>
              <FormItem
                label={words.mainLayout.firstName}
                name="firstName"
                rules={[{ required: true }]}
                required={false}
                className="!w-[100%]"
              >
                <DInput placeholder={words.global.insert} />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={words.mainLayout.lastName}
                name="lastName"
                rules={[{ required: true }]}
                required={false}
                className="!w-[100%]"
              >
                <DInput placeholder={words.global.insert} />
              </FormItem>
            </Col>
          </Row>
          <div className="flex justify-center gap-[24px] footer  mt-[24px] absolute ">
            <Button
              className="w-[30%]"
              htmlType="button"
              onClick={() => {
                handleCancel();
                form.resetFields();
              }}
            >
              {words.global.cancel}
            </Button>
            <Button
              className="w-[30%]"
              type="primary"
              htmlType="submit"
              // onClick={form.submit}
              loading={loading}
              disabled={!submittable}
            >
              {words.global.save}
            </Button>
          </div>
        </Form>
      </EditProfileContainer>
    </Modal>
  );
};
