import { IAveragePriceTableData } from "../models";

export const averagePriceMockData: IAveragePriceTableData[] = [
  {
    id: 1,
    product: "test 2",
    averagePrice: 8000,
    date: "2024.05.10",
  },
];
