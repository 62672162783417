import { Flex, Tabs } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { OrdersContainer } from "./style";
import { TabsProps } from "antd/lib";
import { IOrdersTabs } from "./models";
import { AddProforma } from "./components/addProforma";
import { OrdersTable } from "./components/ordersTable";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { useOrder } from "./context";

export const Orders: FC = () => {
  const { words } = useLanguage();
  const {
    value: { activeKey, editMode },
    dispatch: { setActiveKey },
  } = useOrder()!;
  const items: TabsProps["items"] = [
    {
      key: IOrdersTabs.table,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">table_chart</span>
          {words.orders.ordersTable}
        </span>
      ),
      children: <OrdersTable />,
    },
    {
      key: IOrdersTabs.add,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">add_box</span>
          {words.orders.newOrder}
        </span>
      ),
      children: <AddProforma />,
    },
  ];
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  return (
    <OrdersContainer>
      {editMode ? (
        <div>
          <HeaderProforma headerName={words.orders.editProformaInfo} />
          <Flex justify="center">
            <AddProforma />
          </Flex>
        </div>
      ) : (
        <Tabs items={items} onChange={onChange} activeKey={activeKey} />
      )}
    </OrdersContainer>
  );
};
