import { FC } from "react";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Col, Divider, Flex, Row } from "antd";
import { ViewVipContainer } from "../style";

export const ViewVip: FC = () => {
  const { words } = useLanguage();
  return (
    <div>
      <HeaderProforma headerName={words.orders.vip.viewVipInfo} />
      <Flex justify="center">
        <ViewVipContainer>
          <div className="px-[12px]">{words.orders.vip.viewVipInformation}</div>
          <Row className="proformaDataHolder" gutter={[16, 24]}>
            <Col span={8} className="!font-medium">
              {words.orders.vip.issueDate}
            </Col>
            <Col span={16} className="!font-normal text-[#4B4B4B]">
              1403.05.10
            </Col>
            <Col span={8} className="!font-medium">
              {words.orders.vip.description}
            </Col>
            <Col span={16} className="!font-normal text-[#4B4B4B]">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
              استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
              در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
              نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،
              کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
              جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای
              طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان
              فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری
              موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد
              نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل
              دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
            </Col>

            <Divider dashed />
            <Col span={24}>
              <div className="flex gap-[16px]">
                <div className="text-[14px] w-[150px]">
                  {words.orders.vip.vipDocument}
                </div>
                <div className="flex gap-[16px]">
                  <div>
                    <img
                      src="/assets/images/fileUploaded.svg"
                      alt="fileUploaded"
                    />
                  </div>
                  <div className="flex flex-col gap-[6px] justify-center">
                    <div className="text-[14px]">
                      {words.orders.origin.fileUploaded}
                    </div>
                    <div className="text-[10px]">
                      {words.orders.origin.dlFile}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </ViewVipContainer>
      </Flex>
    </div>
  );
};
