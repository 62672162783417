import { Col, Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useCallback, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useLocation } from "react-router-dom";
import { RAndCNumberTableContainer } from "./style";
import { IAllTransportResponderDetailsResult } from "src/services/BaseInfo/models/result.models";
import { IObject } from "src/models/interfaces";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const ResponderAndContactNumberTable: FC = () => {
  const location = useLocation();
  const values = location.state;
  const { words } = useLanguage();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [paginationTransportDetail, setPaginationTransportDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [TransportDetailData, setTransportDetailData] = useState<
    IAllTransportResponderDetailsResult[]
  >([]);

  const GetAllTransportResponderDetails = useCallback(
    async (TransportId: number) => {
      if (!(TransportId > 0)) return;
      setTableLoading(true);
      try {
        const { GetAllTransportResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationTransportDetail.current,
          Limit: paginationTransportDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllTransportResponderDetails(
          TransportId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setTransportDetailData(result.data.records);
          setPaginationTransportDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTableLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationTransportDetail.current, paginationTransportDetail.pageSize]
  );
  useEffect(() => {
    if (values?.record?.id) {
      GetAllTransportResponderDetails(values?.record?.id);
    }
  }, [GetAllTransportResponderDetails, values?.record?.id]);

  const columns: ColumnsType<IAllTransportResponderDetailsResult> = [
    {
      title: "#",
      key: "index",
      width: 47,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.responsive,
      dataIndex: "responderName",
      key: "responderName",
      width: 111,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.PhoneNumber,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 431,
      align: "center",
      className: "!font-bold",
      render: (text: string[]) => (
        <Flex gap={16} justify="center">
          {text.map((item, index) => (
            <div key={index} className="whitespace-nowrap !font-[vazir]">
              {item}
            </div>
          ))}
        </Flex>
      ),
    },
  ];
  return (
    <RAndCNumberTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={TransportDetailData}
          loading={tableLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            total: paginationTransportDetail.total,
            current: paginationTransportDetail.current,
            pageSize: paginationTransportDetail.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationTransportDetail((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </RAndCNumberTableContainer>
  );
};
