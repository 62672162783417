import { FC, useState } from "react";
import { Col, Table, Typography } from "antd";
import useLanguage from "src/store/language";
import { ColumnsType } from "antd/es/table";
import { IGetAveragePrice } from "../models";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useAveragePrice } from "../context/context";
import dayjs from "dayjs";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import { ITablePagination } from "src/models/interfaces/pagination";

export const AveragePriceTable: FC = () => {
  const { words } = useLanguage();
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const onChangeParams = useChangeParams();
  const {
    value: { dataTable, formController },
    dispatch: { setEditMode, setCurrentEditValue, setDataTable },
  } = useAveragePrice();
  const onDelete = (id: number) => {
    setDataTable((prev) => prev.filter((i) => i.id !== id));
  };
  const columns: ColumnsType<IGetAveragePrice> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "56px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.averagePrice.product,
      dataIndex: "product",
      key: "product",
      align: "center",
      width: "366px",
    },
    {
      title: words.basicInformation.averagePrice.averagePriceInput,
      dataIndex: "averagePrice",
      key: "averagePrice",
      align: "center",
      width: "249px",
    },
    {
      title: words.basicInformation.averagePrice.date,
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "130px",
    },
    {
      title: words.basicInformation.averagePrice.tools,
      key: "tools",
      align: "center",
      width: "187px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            onClick={() => {
              const data: any = { ...record };
              data.date = dayjs(record.date);
              formController?.setFieldsValue(data);
              setEditMode(true);
              setCurrentEditValue(record);
            }}
          >
            edit_note
          </span>
          <DPopconfirm
            onConfirm={() => {
              onDelete(record.id);
            }}
            description={words.basicInformation.averagePrice.deleteAveragePrice}
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      <Col span={24} className="py-[16px]">
        <Typography className="text-[16px]">
          {words.basicInformation.averagePrice.tableInformation}
        </Typography>
      </Col>
      <Col span={24} className="table">
        <Table
          columns={columns}
          dataSource={dataTable}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: pagination.total,
            current: pagination.current,
            onChange: (page: number, pageSize: number) => {
              setPagination((prev) => ({ ...prev, current: page, pageSize }));
              onChangeParams("Limit", pageSize.toString());
              onChangeParams("Offset", page.toString());
            },
            onShowSizeChange: (current: number, size: number) => {
              onChangeParams("Limit", size.toString());
              onChangeParams("Offset", current.toString());
              setPagination((prev) => ({ ...prev, current }));
            },
          }}
        />
      </Col>
    </>
  );
};
