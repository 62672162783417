import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import useLanguage from "src/store/language";
import { TransportCompaniesContainer } from "./style";
import { ITransportCompaniesTabs } from "./models";
import { useTransportCompanies } from "./context";
import { TransportCompaniesTable } from "./components/TransportCompaniesTable";
import { AddNewTransportCompanies } from "./components/AddNewTransportCompanies";

export const TransportCompanies: FC = () => {
  const {
    value: { transportCompaniesActiveKey },
    dispatch: { setTransportCompaniesActiveKey },
  } = useTransportCompanies()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: ITransportCompaniesTabs.tableOfTransportCompanies,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">table_chart</span>
          {words.basicInformation.transportCompanies.transportCompaniesTable}
        </span>
      ),
      children: <TransportCompaniesTable />,
    },
    {
      key: ITransportCompaniesTabs.addNewTransportCompanies,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">add_box</span>
          {words.basicInformation.transportCompanies.addNewTransportCompany}
        </span>
      ),
      children: <AddNewTransportCompanies />,
    },
  ];

  const onChange = (key: string) => {
    setTransportCompaniesActiveKey(key);
  };
  return (
    <TransportCompaniesContainer>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={transportCompaniesActiveKey}
      />
    </TransportCompaniesContainer>
  );
};
