import { FC } from "react";

interface IProps {
  width?: number;
}
const SideBarLogo: FC<IProps> = ({ width }) => {
  return (
    <div className={`sideBarLogoWrapper`}>
      <img src="/assets/images/sideBarLogo.svg" alt="logo" width={width} />
    </div>
  );
};

export default SideBarLogo;
