import { FC } from "react";
import { Ports } from "src/modules/ports";
import { PortProvider } from "src/modules/ports/context";

export const PortsPage: FC = () => {
  return (
    <PortProvider>
      <Ports />
    </PortProvider>
  );
};
