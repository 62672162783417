import { FC } from "react";
import { Col, Divider, Form, Row, Typography } from "antd";
import useLanguage from "src/store/language";
import { useLocation } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { HeaderViewClearanceWorkers } from "./header";
import { ViewClearanceWorkersContainer } from "./style";
import { ResponderAndContactNumberTable } from "./table";
import { IAllCustomBrokerResult } from "src/services/BaseInfo/models/result.models";
const { Title, Text } = Typography;

export const ViewClearanceWorkers: FC = () => {
  const [form] = useForm();
  const { words } = useLanguage();
  const location = useLocation();
  const values: IAllCustomBrokerResult = location.state;

  return (
    <Form form={form}>
      <HeaderViewClearanceWorkers />
      <Divider />
      <div className="flex !justify-center">
        <ViewClearanceWorkersContainer>
          <div className="w-full">
            <Col span={24} className="px-[24px]">
              <Row>
                <Col span={24}>
                  <Title level={4} className="pb-[8px]">
                    {
                      words.basicInformation.clearanceWorkers
                        .viewClearanceWorkersInformation
                    }
                  </Title>
                </Col>
                <Col span={24}>
                  <div className="borderViewItems ">
                    <div className="flex pb-[16px]">
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={5}>
                            <Title level={5}>
                              {
                                words.basicInformation.clearanceWorkers
                                  .workClearanceName
                              }
                              :
                            </Title>
                          </Col>
                          <Col span={7}>
                            <Text>{values?.workPlace}</Text>
                          </Col>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {
                                words.basicInformation.clearanceWorkers
                                  .workPlace
                              }
                              :
                            </Title>
                          </Col>
                          <Col span={20}>
                            <Text>{values?.workPlace}</Text>
                          </Col>
                        </div>
                      </Col>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="px-[24px]">
              <Title level={4} className="px-[12px] pt-[32px] pb-[24px]">
                {words.global.Contacts}
              </Title>
              <ResponderAndContactNumberTable />
            </Col>
          </div>
        </ViewClearanceWorkersContainer>
      </div>
    </Form>
  );
};
