interface IDeliveryTerm {
  id: number;
  name: string;
}
export const deliveryTerm: IDeliveryTerm[] = [
  {
    id: 0,
    name: "FOB",
  },
  {
    id: 1,
    name: "EXW",
  },
  {
    id: 2,
    name: "CFR",
  },
];
