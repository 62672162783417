import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { BasicInformation } from "./components/BasicInformation/BasicInformation";
import { Contact } from "./components/ContactInfo";
import { AddNewWarehousesContainer } from "./style";
import { useWarehouses } from "../../context";
import { IAddNewWarehousesTabs } from "./models";

export const AddNewWarehouses: FC = () => {
  const { words } = useLanguage();
  const {
    value: { addNewWarehousesActiveKey, editMode },
    dispatch: { setAddNewWarehousesActiveKey },
  } = useWarehouses()!;

  const onChange = (key: string) => {
    setAddNewWarehousesActiveKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: IAddNewWarehousesTabs.basicInformation,
      label: words.global.basicInformation,
      children: <BasicInformation />,
      disabled:
        !editMode &&
        addNewWarehousesActiveKey !== IAddNewWarehousesTabs.basicInformation,
    },
    {
      key: IAddNewWarehousesTabs.Contacts,
      label: words.global.Contacts,
      children: <Contact />,
      disabled:
        !editMode &&
        addNewWarehousesActiveKey !== IAddNewWarehousesTabs.Contacts,
    },
  ];

  return (
    <AddNewWarehousesContainer>
      <Tabs
        activeKey={addNewWarehousesActiveKey}
        items={items}
        onChange={onChange}
        centered
        animated
      />
    </AddNewWarehousesContainer>
  );
};
