import { App, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { getRowKey } from "src/helper/getRowKey";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { ROUTES } from "src/models/enums/routes";
import { WarehousesTableContainer } from "./style";
import { useWarehouses } from "../../context";
import { ContactNumberModal } from "./ContactNumberModal";
import { TableData } from "../../models";
import { IAllWarehouseResult } from "src/services/BaseInfo/models/result.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { Guard } from "src/components/Guard";
import {
  DeleteWarehousePath,
  UpdateWarehousePath,
} from "src/services/BaseInfo/guardPath";

export const WarehousesTable: FC = () => {
  const { words } = useLanguage();
  const {
    dispatch: { setIsModalOpen, setTableRecord, setPaginationWarehouse },
    value: { paginationWarehouse, dataSource, tableLoading },
    func: { getTableData },
  } = useWarehouses()!;
  const { message } = App.useApp();
  const navigate = useNavigate();
  const onClickResponderAndContactNumber = (record: IAllWarehouseResult) => {
    setIsModalOpen(true);
    setTableRecord(record);
  };
  const onClickDelete = async (id: number) => {
    try {
      const { DeleteWarehouse } = new BaseInfoService();
      const result = await DeleteWarehouse(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.warehouses.deleteWarehouseMessage
        );
        getTableData();
      }
    } catch (err) {
      console.log(err);
    }
  };
  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<IAllWarehouseResult> = [
    {
      title: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.warehouses.warehousesName,
      dataIndex: "name",
      key: "name",
      width: 170,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.basicInformation.warehouses.warehousesNumber,
      dataIndex: "number",
      key: "number",
      width: 130,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.basicInformation.warehouses.warehousesLocation,
      dataIndex: "location",
      key: "location",
      width: 130,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.global.responderAndContactNumber,
      // dataIndex: "responderAndContactNumber",
      key: "responderAndContactNumber",
      width: 166,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => (
        <span
          className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center"
          onClick={() => onClickResponderAndContactNumber(record)}
        >
          contact_phone
        </span>
      ),
    },
    {
      title: words.basicInformation.warehouses.address,
      dataIndex: "address",
      key: "address",
      width: 97,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record: TableData) => (
        <Tooltip title={record.address} placement="bottom">
          <span className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center">
            directions
          </span>
        </Tooltip>
      ),
    },
    {
      title: words.basicInformation.warehouses.mainAddress,
      dataIndex: "mainAddress",
      key: "mainAddress",
      width: 97,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record: TableData) => (
        <Tooltip title={record.mainAddress} placement="bottom">
          <span className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center">
            directions
          </span>
        </Tooltip>
      ),
    },
    {
      title: words.global.tools,
      key: "tools",
      width: 112,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateWarehousePath}>
            <span
              onClick={() => {
                navigate(ROUTES.editWarehouses, { state: record });
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <span
            onClick={() => {
              navigate(ROUTES.viewWarehouses, { state: record });
            }}
            className="material-icons !text-[#02514C] text-[16px] cursor-pointer"
          >
            visibility
          </span>
          <Guard action={DeleteWarehousePath}>
            <DPopconfirm
              onConfirm={async () => {
                await onClickDelete(record.id);
              }}
              description={words.basicInformation.warehouses.deleteWarehouses}
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <WarehousesTableContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={tableLoading}
        scroll={{ x: 730 }}
        rowKey={getRowKey}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: paginationWarehouse.total,
          current: paginationWarehouse.current,
          pageSize: paginationWarehouse.pageSize,
          onChange: (page: number, pageSize: number) => {
            setPaginationWarehouse((prev) => ({
              ...prev,
              current: page,
              pageSize,
            }));
          },
        }}
      />
      <ContactNumberModal />
    </WarehousesTableContainer>
  );
};
