import { FC } from "react";
import { CreateRole } from "src/modules/CreateRole";
import { CreateRoleProvider } from "src/modules/CreateRole/context";

export const CreateRolePage: FC = () => {
  return (
    <CreateRoleProvider>
      <CreateRole />
    </CreateRoleProvider>
  );
};
