import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import { AddNewSupplierContainer } from "./style";
import useLanguage from "src/store/language";
import { BasicInformation } from "./components/BasicInformation";
import { useSuppliers } from "../../context/context";
import { Contact } from "./components/ContactInfo";
import { IAddNewSupplierTabs } from "src/services/Identity/models/mockModels/supp.models";

export const AddNewSupplier: FC = () => {
  const { words } = useLanguage();
  const {
    value: { addNewSupplierActiveKey },
  } = useSuppliers()!;

  const items: TabsProps["items"] = [
    {
      key: IAddNewSupplierTabs.basicInformation,
      label: words.global.basicInformation,
      children: <BasicInformation />,
    },
    {
      key: IAddNewSupplierTabs.Contacts,
      label: words.global.Contacts,
      children: <Contact />,
    },
  ];

  return (
    <AddNewSupplierContainer>
      <Tabs
        activeKey={addNewSupplierActiveKey}
        items={items}
        centered
        animated
      />
    </AddNewSupplierContainer>
  );
};
