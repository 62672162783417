import { FC, PropsWithChildren, createContext, useContext } from "react";

interface IContextValue {}
const defaultValue: IContextValue = {};
export const DashboardDataContext = createContext<IContextValue>(defaultValue);

const DashboardDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const contextValues: IContextValue = {};

  return (
    <DashboardDataContext.Provider value={contextValues}>
      {children}
    </DashboardDataContext.Provider>
  );
};

export default DashboardDataProvider;

export const useDashboardData = () => useContext(DashboardDataContext)!;
