import { FC } from "react";
import { EditClearanceWorkers } from "src/modules/EditClearanceWorkers";
import { EditClearanceWorkersProvider } from "src/modules/EditClearanceWorkers/context/context";

export const EditClearanceWorkersPage: FC = () => {
  return (
    <EditClearanceWorkersProvider>
      <EditClearanceWorkers />
    </EditClearanceWorkersProvider>
  );
};
