import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Space,
  App,
} from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { RCInformationContainer } from "../../style";
import { FormItem } from "src/components/UiKit/FormItem";
import { RespondentContactTable } from "./RespondentContactTable";
import { useWarehouses } from "src/modules/Warehouses/context";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { Guard } from "src/components/Guard";
import { AddWarehousePhonePath } from "src/services/BaseInfo/guardPath";
interface IFormValue {
  responder: number;
  phoneNumber: string;
}
export const RespondentContactInformation: FC = () => {
  const { words } = useLanguage();
  const {
    form: { RCInformationForm },
    dispatch: { setResponderContactEditMode, setSelectedResponderPhone },
    value: {
      allRespondersData,
      responderContactEditMode,
      selectedResponderPhone,
    },
    func: { getResponderPhoneDetail },
  } = useWarehouses()!;
  const { Option } = Select;
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { message } = App.useApp();
  const values = Form.useWatch([], RCInformationForm);
  const addWarehousePhone = async (values: IFormValue) => {
    console.log({ values });
    setLoading(true);
    try {
      const { AddWarehousePhone } = new BaseInfoService();
      const result = await AddWarehousePhone(
        values.responder,
        values.phoneNumber
      );
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.warehouses.addWarehousePhoneMessage
        );
        RCInformationForm?.setFieldValue("phoneNumber", undefined);
        getResponderPhoneDetail(values.responder);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const editWarehousePhone = async (values: IFormValue) => {
    if (!selectedResponderPhone) return;
    setLoading(true);
    try {
      const { UpdateWarehousePhone } = new BaseInfoService();
      const result = await UpdateWarehousePhone(
        selectedResponderPhone.id,
        values.phoneNumber
      );
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.warehouses.editWarehousePhoneMessage
        );
        RCInformationForm?.setFieldValue("phoneNumber", undefined);
        getResponderPhoneDetail(values.responder);
        setResponderContactEditMode(false);
        setSelectedResponderPhone(undefined);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const onFinishHandler = (values: IFormValue) => {
    if (responderContactEditMode) {
      editWarehousePhone(values);
    } else {
      addWarehousePhone(values);
    }
  };
  useEffect(() => {
    RCInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [RCInformationForm, values]);
  return (
    <Form
      form={RCInformationForm}
      className="h-[100%]"
      onFinish={onFinishHandler}
    >
      <RCInformationContainer>
        <Row>
          <Guard action={AddWarehousePhonePath}>
            <Col span={24}>
              <Typography className="p-[16px]">
                {words.global.respondentContactInformation}
              </Typography>
            </Col>
            <Row className="w-[100%] pt-[24px]">
              <Col span={16}>
                <FormItem
                  name={"responder"}
                  label={words.global.responsive}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={words.global.registerRespondent}
                    optionLabelProp="label"
                    disabled={responderContactEditMode}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value: number | undefined) => {
                      if (value) getResponderPhoneDetail(value);
                    }}
                  >
                    {allRespondersData?.map((item, index) => (
                      <Option
                        key={index}
                        value={item.id}
                        label={item.responderName}
                      >
                        <Space>{item.responderName}</Space>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={16}>
                <FormItem
                  name={"phoneNumber"}
                  label={words.global.PhoneNumber}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={words.global.insert} allowClear />
                </FormItem>
              </Col>
              <Col span={8}>
                <div className="flex justify-end items-center h-[100%]">
                  <Button
                    type="primary"
                    ghost
                    disabled={!submittable}
                    loading={loading}
                    onClick={RCInformationForm?.submit}
                  >
                    {responderContactEditMode
                      ? words.global.edit
                      : words.global.add}
                  </Button>
                </div>
              </Col>
            </Row>
            <Divider className="pb-[24px]" />
          </Guard>
          <Col span={24}>
            <Typography className="pb-[24px]">
              {words.global.contactsTable}
            </Typography>
          </Col>
          <Col span={24}>
            <RespondentContactTable />
          </Col>
        </Row>
      </RCInformationContainer>
    </Form>
  );
};
