import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { EditBasicInformationContainer } from "../style";
import { useLocation } from "react-router-dom";
import { IEditOperatingBanksTabs } from "../../models";
import { useEditOperatingBanks } from "../../context/context";
import { IAllBankResult } from "src/services/BaseInfo/models/result.models";
import { IAddBank } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const EditBasicInformation: FC = () => {
  const {
    dispatch: { setEditOperatingBanksActiveKey, setPressKeyboard },
    form: { editBasicInformationForm },
    value: { pressKeyboard },
  } = useEditOperatingBanks()!;
  const { words } = useLanguage();
  const location = useLocation();
  const { message } = App.useApp();
  const data: IAllBankResult = location.state;
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: IAddBank) => {
    setLoading(true);
    try {
      const { UpdateBank } = new BaseInfoService();
      const result = await UpdateBank(values, data.id);
      if (result && result.status === 200) {
        message.success(words.basicInformation.operatingBanks.editBankMessage);
        setEditOperatingBanksActiveKey(IEditOperatingBanksTabs.Contacts);
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      editBasicInformationForm?.setFieldsValue(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
  return (
    <Form form={editBasicInformationForm} onFinish={onFinish}>
      <EditBasicInformationContainer>
        <Col span={24}>
          <Typography>
            {words.basicInformation.operatingBanks.editOperatingBankInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                name={"bankName"}
                label={words.basicInformation.operatingBanks.bankName}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"branchName"}
                label={words.basicInformation.operatingBanks.branchName}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"branchCode"}
                label={words.basicInformation.operatingBanks.branchCode}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name={"address"}
                label={words.basicInformation.operatingBanks.address}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div className="flex gap-[16px] justify-end borderTop">
            <Button
              className="!min-w[85px]"
              type="primary"
              disabled={!pressKeyboard}
              onClick={editBasicInformationForm?.submit}
              loading={loading}
            >
              {words.global.saveEdit}
            </Button>

            <Button
              type="default"
              className="!min-w[85px]"
              onClick={() => {
                editBasicInformationForm?.setFieldsValue(location.state);
                setPressKeyboard(false);
              }}
              disabled={!pressKeyboard}
            >
              {words.global.clearChanges}
            </Button>
          </div>
        </Col>
      </EditBasicInformationContainer>
    </Form>
  );
};
