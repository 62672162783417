import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { TableData } from "../models/models";
import { ITablePagination } from "src/models/interfaces/pagination";
import useLanguage from "src/store/language";

interface IContext {
  value: {
    editProduct: boolean;
    dataSource: TableData[];
    pagination: ITablePagination;
    productLoading: boolean;
    productId: number;
  };
  dispatch: {
    setEditProduct: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setProductId: Dispatch<SetStateAction<number>>;
  };
  func: {
    getProductsTableData: () => void;
    deleteProduct: (id: number) => void;
  };
  form: { form: FormInstance<any> | undefined };
}

export const ProductsContext = createContext<IContext | undefined>(undefined);

export const ProductsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const [editProduct, setEditProduct] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [productId, setProductId] = useState<number>(-1);

  ///////////Functions////////////////////////////////////////////////////////////////
  const getProductsTableData = async () => {
    setProductLoading(true);
    try {
      const { GetAllProduct } = new BaseInfoService();
      const result = await GetAllProduct(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };

  const deleteProduct = async (id: number) => {
    setProductLoading(true);
    try {
      const { DeleteProduct } = new BaseInfoService();
      const result = await DeleteProduct(id);
      if (result && result.status === 200) {
        message.success(words.basicInformation.products.deleteProductMessage);
        getProductsTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };
  const ContextValue: IContext = {
    value: { editProduct, dataSource, pagination, productLoading, productId },
    dispatch: { setEditProduct, setPagination, setProductId },
    func: { getProductsTableData, deleteProduct },
    form: { form },
  };
  return (
    <ProductsContext.Provider value={ContextValue}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = () => useContext(ProductsContext);
