import { App, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRowKey } from "src/helper/getRowKey";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { InspectionCompaniesTableContainer } from "./style";
import { ROUTES } from "src/models/enums/routes";
import { useInspectionCompanies } from "../../context";
import { ContactNumberModal } from "./ContactNumberModal";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { TableData } from "../../models/models";
import { Guard } from "src/components/Guard";
import {
  AllInspectionResponderDetailsPath,
  DeleteInspectionPath,
  UpdateInspectionPath,
} from "src/services/BaseInfo/guardPath";

export const InspectionCompaniesTable: FC = () => {
  const { words } = useLanguage();
  const { message } = App.useApp();
  const {
    dispatch: { setIsModalOpen, setPaginationInspection, setTableRecord },
    func: { getTableData, GetAllInspectionResponderDetails },
    value: { dataSource, loading, paginationInspection, inspectionDetailData },
  } = useInspectionCompanies()!;

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<number>();
  const navigate = useNavigate();
  const onClickResponderAndContactNumber = (id: number) => {
    setIsModalOpen(true);
    GetAllInspectionResponderDetails(id);
  };
  const onClickDelete = async (id: number) => {
    setDeleteLoading(true);
    try {
      const { DeleteInspection } = new BaseInfoService();
      const result = await DeleteInspection(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.inspectionCompanies.deleteInspectionMessage
        );
        getTableData();
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };
  useEffect(() => {
    getTableData();
  }, [getTableData]);
  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<TableData> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.inspectionCompanies.companyName,
      dataIndex: "name",
      key: "name",
      width: 170,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.responderAndContactNumber,
      dataIndex: "responderAndContactNumber",
      key: "responderAndContactNumber",
      width: 166,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => (
        <Guard action={AllInspectionResponderDetailsPath}>
          <span
            className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center"
            onClick={() => {
              onClickResponderAndContactNumber(record.id);
              setTableRecord(record.name);
            }}
          >
            contact_phone
          </span>
        </Guard>
      ),
    },
    {
      title: words.basicInformation.inspectionCompanies.address,
      dataIndex: "address",
      key: "address",
      width: 97,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => (
        <Tooltip title={record.address} placement="bottom">
          <span className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center">
            directions
          </span>
        </Tooltip>
      ),
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 112,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateInspectionPath}>
            <span
              onClick={() => {
                navigate(ROUTES.editInspectionCompanies, { state: record });
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <span
            onClick={() => {
              navigate(ROUTES.viewInspectionCompanies, {
                state: { inspectionDetailData, record },
              });
            }}
            className="material-icons !text-[#02514C] text-[16px] cursor-pointer"
          >
            visibility
          </span>
          <Guard action={DeleteInspectionPath}>
            <DPopconfirm
              onConfirm={() => {
                onClickDelete(record.id);
              }}
              description={
                words.basicInformation.inspectionCompanies
                  .deleteInspectionCompanies
              }
              okButtonProps={{
                loading: deleteLoading,
                danger: true,
              }}
              open={openDeleteConfirm === record.id}
              onCancel={() => {
                setOpenDeleteConfirm(undefined);
              }}
              okType="danger"
            >
              <span
                className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]"
                onClick={() => {
                  setOpenDeleteConfirm(record.id);
                }}
              >
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <InspectionCompaniesTableContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: "auto" }}
        rowKey={getRowKey}
        className="w-[100%]"
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: paginationInspection.total,
          current: paginationInspection.current,
          pageSize: paginationInspection.pageSize,
          onChange: (page: number, pageSize) => {
            setPaginationInspection((prev) => ({
              ...prev,
              current: page,
              pageSize,
            }));
          },
        }}
        // expandable={{
        //   expandedRowRender: (record: IGetInspectionCompanies) => (
        //     <Flex align="center" className="py-[16px]" gap={24} wrap="wrap">
        //       {record.phoneNumber.map((item) => (
        //         <div className="text-[10px]">
        //           {item.name + item.phoneNumber}
        //         </div>
        //       ))}
        //     </Flex>
        //   ),
        //   columnTitle: words.basicInformation.inspectionCompanies.phoneNumber,
        //   columnWidth: 166,
        //   expandIcon: ({ onExpand, record, expanded }) => (
        //     <p
        //       className={`cursor-pointer flex justify-center items-center gap-[6px] !m-0 w-[100%] h-[53px] ${
        //         expanded ? "bg-[#E1E1E1]" : ""
        //       }`}
        //       onClick={(e) => onExpand(record, e)}
        //     >
        //       <span
        //         className={
        //           expanded
        //             ? "!text-[#008C84] text-[12px] "
        //             : "!text-[#008C84] text-[12px] "
        //         }
        //       >
        //         {words.global.see}
        //       </span>
        //       <span
        //         className={
        //           expanded
        //             ? "material-icons !text-[#008C84] text-[16px]"
        //             : "material-icons !text-[#008C84] text-[16px]"
        //         }
        //       >
        //         {expanded ? "remove_circle_outline" : "add_circle_outline"}
        //       </span>
        //     </p>
        //   ),
        // }}
      />
      <ContactNumberModal />
    </InspectionCompaniesTableContainer>
  );
};
