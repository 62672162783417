import { Button, Col, Divider, Row, Typography } from "antd";
import useLanguage from "src/store/language";
import { IAddNewWarehousesTabs } from "../../models";
import { useWarehouses } from "src/modules/Warehouses/context";
import { RespondentName } from "./components/RespondentName/RespondentName";
import { RespondentContactInformation } from "./components/RespondentContactInformation/RespondentContactInformation";
import { ContactContainer } from "./style";
import { FC } from "react";
import { IWarehousesTabs } from "src/modules/Warehouses/models";

export const Contact: FC = () => {
  const {
    dispatch: {
      setAddNewWarehousesActiveKey,
      setAllRespondersData,
      setResponderPhoneData,
      setWarehouseDetailData,
      setWarehousesActiveKey,
      setEditMode,
    },
    form: {
      basicInformationForm,
      RCInformationForm,
      contactForm,
      respondentNameForm,
      form,
    },
    func: { getTableData },
    value: { editMode },
  } = useWarehouses()!;
  const { words } = useLanguage();

  const onClickBack = () => {
    setAddNewWarehousesActiveKey(IAddNewWarehousesTabs.basicInformation);
    setEditMode(true);
  };
  const onFinish = () => {
    basicInformationForm?.resetFields();
    RCInformationForm?.resetFields();
    contactForm?.resetFields();
    respondentNameForm?.resetFields();
    form?.resetFields();
    setAllRespondersData([]);
    setResponderPhoneData([]);
    setWarehouseDetailData([]);
    getTableData();
    setWarehousesActiveKey(IWarehousesTabs.tableOfWarehouses);
  };
  return (
    <ContactContainer>
      <Row className="flex gap-6">
        <Col span={24}>
          <Typography className="py-[16px]">
            {editMode
              ? words.basicInformation.warehouses.editContactInformation
              : words.basicInformation.warehouses.contactInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={12} className="h-[100%]">
              <RespondentName />
            </Col>
            <Col span={12} className="h-[100%]">
              <RespondentContactInformation />
            </Col>
          </Row>
        </Col>
        {!editMode && (
          <>
            <Divider />
            <Col span={24}>
              <div className="flex gap-[16px] py-[12px] justify-end">
                <Button type="primary" ghost onClick={onClickBack}>
                  {words.global.previousPage}
                </Button>
                <Button type="primary" onClick={onFinish}>
                  {words.global.completeRegistration}
                </Button>
              </div>
            </Col>
          </>
        )}
      </Row>
    </ContactContainer>
  );
};
