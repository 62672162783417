import { Button, Divider, Modal, Typography } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useLocation, useNavigate } from "react-router-dom";
import { IAllBankResult } from "src/services/BaseInfo/models/result.models";
import { ROUTES } from "src/models/enums/routes";
import { useEditOperatingBanks } from "./context/context";
const { Title } = Typography;

export const ConfirmModal: FC = () => {
  const {
    value: { isModalOpen },
    dispatch: { setIsModalOpen },
  } = useEditOperatingBanks()!;
  const navigate = useNavigate();
  const { words } = useLanguage();
  const location = useLocation();
  const data: IAllBankResult = location.state;
  const handleCancel = () => {
    setIsModalOpen(false);
    navigate(ROUTES.operatingBanks);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        className="!w-[45%] !h-[10%]"
        title={
          <div className="pt-[8px] pb-[4px]">
            <Title level={3}>{words.global.saveEdit}</Title>
            <div className="text-[16px] text-[#8E8E8E]">{data.bankName}</div>
            <Divider />
          </div>
        }
        open={isModalOpen}
        closable={false}
        footer={[
          <div className="flex justify-center items-center gap-[24px] mt-[24px]">
            <Button
              type="primary"
              ghost
              onClick={() => {
                handleOk();
              }}
            >
              {words.global.yes}
            </Button>
            <Button
              onClick={() => {
                handleCancel();
              }}
            >
              {words.global.no}
            </Button>
          </div>,
        ]}
      >
        <div className="rounded-[12px] border-[#BFE2E0] border-solid p-[48px]">
          <Typography className="flex justify-center">
            {words.global.saveEitPopConfirm}
          </Typography>
        </div>
      </Modal>
    </div>
  );
};
