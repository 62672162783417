import { IGetVessels } from "../models";

export const VesselsMockData: IGetVessels[] = [
  {
    id: 1,
    vesselName: "Mahoura",
    ageOfVessel: 40,
    flag: "ایران",
  },
];
