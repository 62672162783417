import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Space,
  App,
} from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { RespondentContactTable } from "./RespondentContactTable";
import { RCInformationContainer } from "../../style";
import { useEditInsuranceCompanies } from "src/modules/EditInsuranceCompanies/context/context";
import { IAddInsurancePhone } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const RespondentContactInformation: FC = () => {
  const { words } = useLanguage();
  const {
    form: { RCInformationForm },
    dispatch: { setResponderContactEditMode },
    value: { responderContactEditMode, allRespondersData, insurancePhoneId },
    func: { getResponderPhoneDetail },
  } = useEditInsuranceCompanies()!;
  const { Option } = Select;
  const { message } = App.useApp();
  const [loading, setLoading] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], RCInformationForm);
  useEffect(() => {
    RCInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  //////////////functions //////////////////////////////////
  const addInsurancePhone = async (values: IAddInsurancePhone) => {
    setLoading(true);
    try {
      const { AddInsurancePhone } = new BaseInfoService();
      const result = await AddInsurancePhone(
        values.responder,
        values.phoneNumber
      );
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.insuranceCompanies.addInsurancePhoneMessage
        );
        RCInformationForm?.setFieldValue("phoneNumber", undefined);
        getResponderPhoneDetail(values.responder);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const editInsurancePhone = async (values: IAddInsurancePhone) => {
    if (!insurancePhoneId) return console.log("insurancePhoneId not found");
    setLoading(true);
    try {
      const { UpdateInsurancePhone } = new BaseInfoService();
      const result = await UpdateInsurancePhone(
        insurancePhoneId,
        values.phoneNumber
      );
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.insuranceCompanies.editInsurancePhoneMessage
        );
        RCInformationForm?.setFieldValue("phoneNumber", undefined);
        getResponderPhoneDetail(values.responder);
        setResponderContactEditMode(false);
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <RCInformationContainer>
      <Row>
        <Col span={24}>
          <Typography className="p-[16px]">
            {words.global.editContactInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Form
            form={RCInformationForm}
            className="h-[100%]"
            onFinish={
              responderContactEditMode ? editInsurancePhone : addInsurancePhone
            }
          >
            <Row className="w-[100%] pt-[24px]">
              <Col span={16}>
                <FormItem
                  name={"responder"}
                  label={words.global.responsive}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={words.global.select}
                    optionLabelProp="label"
                    showSearch
                    onChange={(value: number | undefined) => {
                      if (value) {
                        getResponderPhoneDetail(value);
                        setResponderContactEditMode(false);
                      }
                    }}
                  >
                    {allRespondersData?.map((item, index) => (
                      <Option
                        key={index}
                        value={item.id}
                        label={item.responderName}
                      >
                        <Space>{item.responderName}</Space>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={16}>
                <FormItem
                  name={"phoneNumber"}
                  label={words.global.PhoneNumber}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={words.global.insert}
                    allowClear
                    onKeyDown={() => setPressKeyboard(true)}
                  />
                </FormItem>
              </Col>
              <Col span={24} className="h-[82px] pb-[24px]">
                <div className="flex justify-end items-center h-[100%] gap-[16px]">
                  {responderContactEditMode ? (
                    <Button
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={RCInformationForm?.submit}
                      loading={loading}
                    >
                      {words.global.saveEdit}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      disabled={!submittable}
                      onClick={RCInformationForm?.submit}
                      loading={loading}
                    >
                      {words.global.add}
                    </Button>
                  )}
                  {responderContactEditMode ? (
                    <Button
                      disabled={!submittable}
                      onClick={() => {
                        setResponderContactEditMode(false);
                        setPressKeyboard(false);
                        RCInformationForm?.resetFields();
                      }}
                    >
                      {words.global.cancel}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>

        <Divider className="pb-[24px]" />
        <Col span={24}>
          <Typography className="pb-[24px]">
            {words.global.contactsTable}
          </Typography>
        </Col>
        <Col span={24}>
          <RespondentContactTable />
        </Col>
      </Row>
    </RCInformationContainer>
  );
};
