import { Divider, Form, Tabs } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useEditSupplier } from "./context";
import { HeaderEditSuppliers } from "./header";
import { TabsProps } from "antd/lib";
import { EditBasicInformation } from "./components/BasicInformation/BasicInformation";
import { EditSupplierContainer } from "./style";
import { EditContact } from "./components/ContactInfo";
import { IEditSupplierTabs } from "src/services/Identity/models/mockModels/supp.models";

export const EditSupplier: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    value: { editSupplierActiveKey },
    dispatch: { setEditSupplierActiveKey },
  } = useEditSupplier()!;

  const onChange = (key: string) => {
    setEditSupplierActiveKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: IEditSupplierTabs.basicInformation,
      label: words.global.basicInformation,
      children: <EditBasicInformation />,
    },
    {
      key: IEditSupplierTabs.Contacts,
      label: words.global.Contacts,
      children: <EditContact />,
    },
  ];
  const onFinish = (values: any) => {};

  return (
    <Form form={form} onFinish={onFinish}>
      <HeaderEditSuppliers />
      <Divider />
      <div className="flex justify-center items-center">
        <EditSupplierContainer>
          <Tabs
            activeKey={editSupplierActiveKey}
            items={items}
            onChange={onChange}
            centered
            animated
          />
        </EditSupplierContainer>
      </div>
    </Form>
  );
};
