export enum BaseInfoUrls {
  BaseInfo = "BaseInfo/",
  AddSupplier = "BaseInfo/AddSupplier",
  AllProduct = "BaseInfo/AllProduct",
  AddProduct = "BaseInfo/AddProduct",
  UpdateProduct = "/UpdateProduct",
  DeleteProduct = "/DeleteProduct",
  AllUnit = "BaseInfo/AllUnit",
  AddUnit = "BaseInfo/AddUnit",
  UpdateUnit = "/UpdateUnit",
  DeleteUnit = "/DeleteUnit",
  AddCurrency = "BaseInfo/AddCurrency",
  AllCurrency = "BaseInfo/AllCurrency",
  UpdateCurrency = "/UpdateCurrency",
  DeleteCurrency = "/DeleteCurrency",
  AddVessel = "BaseInfo/AddVessel",
  AllVessel = "BaseInfo/AllVessel",
  UpdateVessel = "/UpdateVessel",
  DeleteVessel = "/DeleteVessel",
  AddCountry = "BaseInfo/AddCountry",
  AllCountry = "BaseInfo/AllCountry",
  UpdateCountry = "/UpdateCountry",
  DeleteCountry = "/DeleteCountry",
  AddPort = "BaseInfo/AddPort",
  AllPort = "/BaseInfo/AllPort",
  UpdatePort = "/UpdatePort",
  DeletePort = "/DeletePort",
  AllSupplier = "/BaseInfo/AllSupplier",
  UpdateSupplier = "/UpdateSupplier",
  DeleteSupplier = "/DeleteSupplier",
  AddSupplierResponder = "/AddSupplierResponder",
  AllSupplierResponders = "/AllSupplierResponders",
  UpdateSupplierResponder = "/UpdateSupplierResponder",
  DeleteSupplierResponder = "/DeleteSupplierResponder",
  AddSupplierPhone = "/AddSupplierPhone",
  SupplierResponderDetailsById = "/SupplierResponderDetailsById",
  UpdateSupplierPhone = "/UpdateSupplierPhone",
  DeleteSupplierPhone = "/DeleteSupplierPhone",
  AllSupplierResponderDetails = "/AllSupplierResponderDetails",
  AllInspection = "BaseInfo/AllInspection",
  AddInspection = "BaseInfo/AddInspection",
  UpdateInspection = "/UpdateInspection",
  DeleteInspection = "/DeleteInspection",
  AddInspectionResponder = "/AddInspectionResponder",
  AllInspectionResponders = "/AllInspectionResponders",
  UpdateInspectionResponder = "/UpdateInspectionResponder",
  DeleteInspectionResponder = "/DeleteInspectionResponder",
  AddInspectionPhone = "/AddInspectionPhone",
  InspectionResponderDetailsById = "/InspectionResponderDetailsById",
  AllInspectionResponderDetails = "/AllInspectionResponderDetails",
  UpdateInspectionPhone = "/UpdateInspectionPhone",
  DeleteInspectionPhone = "/DeleteInspectionPhone",
  AllInsurace = "BaseInfo/AllInsurace",
  AddInsurance = "BaseInfo/AddInsurance",
  UpdateInsurance = "/UpdateInsurance",
  DeleteInsurance = "/DeleteInsurance",
  AddInsuranceResponder = "/AddInsuranceResponder",
  AllInsuranceResponders = "/AllInsuranceResponders",
  UpdateInsuranceResponder = "/UpdateInsuranceResponder",
  DeleteInsuranceResponder = "/DeleteInsuranceResponder",
  AddInsurancePhone = "/AddInsurancePhone",
  UpdateInsurancePhone = "/UpdateInsurancePhone",
  DeleteInsurancePhone = "/DeleteInsurancePhone",
  InsuranceResponderDetailsById = "/InsuranceResponderDetailsById",
  AllInsuranceResponderDetails = "/AllInsuranceResponderDetails",
  //warehouse
  AllWarehouse = "BaseInfo/AllWarehouse",
  AddWarehouse = "BaseInfo/AddWarehouse",
  UpdateWarehouse = "/UpdateWarehouse",
  DeleteWarehouse = "/DeleteWarehouse",
  AddWarehouseResponder = "/AddWarehouseResponder",
  AllWarehouseResponders = "/AllWarehouseResponders",
  UpdateWarehouseResponder = "/UpdateWarehouseResponder",
  DeleteWarehouseResponder = "/DeleteWarehouseResponder",
  AddWarehousePhone = "/AddWarehousePhone",
  UpdateWarehousePhone = "/UpdateWarehousePhone",
  DeleteWarehousePhone = "/DeleteWarehousePhone",
  WarehouseResponderDetailsById = "/WarehouseResponderDetailsById",
  AllWarehouseResponderDetails = "/AllWarehouseResponderDetails",
  AllBank = "BaseInfo/AllBank",
  AddBank = "BaseInfo/AddBank",
  UpdateBank = "/UpdateBank",
  DeleteBank = "/DeleteBank",
  AddBankResponder = "/AddBankResponder",
  AllBankResponders = "/AllBankResponders",
  UpdateBankResponder = "/UpdateBankResponder",
  DeleteBankResponder = "/DeleteBankResponder",
  AddBankPhone = "/AddBankPhone",
  UpdateBankPhone = "/UpdateBankPhone",
  DeleteBankPhone = "/DeleteBankPhone",
  BankPhonesByResponderId = "/BankPhonesByResponderId",
  AllBankResponderDetails = "/AllBankResponderDetails",
  AllTransport = "BaseInfo/AllTransport",
  AddTransport = "BaseInfo/AddTransport",
  UpdateTransport = "/UpdateTransport",
  DeleteTransport = "/DeleteTransport",
  AddTransportResponder = "/AddTransportResponder",
  AllTransportResponders = "/AllTransportResponders",
  UpdateTransportResponder = "/UpdateTransportResponder",
  DeleteTransportResponder = "/DeleteTransportResponder",
  AddTransportPhone = "/AddTransportPhone",
  UpdateTransportPhone = "/UpdateTransportPhone",
  DeleteTransportPhone = "/DeleteTransportPhone",
  TransportResponderDetailsById = "/TransportResponderDetailsById",
  AllTransportResponderDetails = "/AllTransportResponderDetails",
  AllCustomBroker = "BaseInfo/AllCustomBroker",
  AddCustomBroker = "BaseInfo/AddCustomBroker",
  UpdateCustomBroker = "/UpdateCustomBroker",
  DeleteCustomBroker = "/DeleteCustomBroker",
  AddCustomBrokerPhone = "/AddCustomBrokerPhone",
  AllCustomBrokerPhone = "/AllCustomBrokerPhone",
  AllCustomBrokerResponderDetails = "/AllCustomBrokerResponderDetails",
  UpdateCustomBrokerPhone = "/UpdateCustomBrokerPhone",
  DeleteCustomBrokerPhone = "/DeleteCustomBrokerPhone",
}
