import { Button, Col, Divider, Form, Row, Typography, message } from "antd";
import { FC, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { usePort } from "../context";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddPort } from "src/services/BaseInfo/models/args.models";
import { Guard } from "src/components/Guard";
import { AddPortPath, UpdatePortPath } from "src/services/BaseInfo/guardPath";
import { AddNewPortContainer } from "../style";
import { DInput } from "src/components/UiKit/DInput";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";

export const AddNewPort: FC = () => {
  const { words } = useLanguage();
  const {
    dispatch: { setEditMode },
    form: { form },
    value: { editMode, productId },
    func: { getPortTableData },
  } = usePort();
  const [loading, setLoading] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);

  const onFinishAddPort = async (values: IAddPort) => {
    setLoading(true);
    try {
      const { AddPort } = new BaseInfoService();
      const result = await AddPort(values);
      if (result && result.status === 200) {
        message.success(words.basicInformation.ports.addPortMessage);
        getPortTableData();
        form?.resetFields();
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onFinishUpdatePort = async (values: IAddPort) => {
    setLoading(true);
    try {
      const { UpdatePort } = new BaseInfoService();
      const result = await UpdatePort(values, productId);
      if (result && result.status === 200) {
        message.success(words.basicInformation.ports.updatePortMessage);
        getPortTableData();
        form?.resetFields();
        setPressKeyboard(false);
        setEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Guard action={[UpdatePortPath, AddPortPath]} multiPath>
      <Form
        form={form}
        onFinish={(values) => {
          editMode ? onFinishUpdatePort(values) : onFinishAddPort(values);
        }}
      >
        <AddNewPortContainer>
          <Col span={24}>
            <Typography>
              {words.basicInformation.ports.portInformation}
            </Typography>
          </Col>
          <Col span={24}>
            <Row gutter={24} className="productHolder">
              <Col span={8}>
                <FormItem
                  name={"portName"}
                  label={words.basicInformation.ports.portName}
                  rules={[{ required: true }]}
                >
                  <DInput
                    placeholder={words.global.insert}
                    onKeyDown={() => setPressKeyboard(true)}
                  />
                </FormItem>
              </Col>
              <Col span={16}>
                <div className="flex gap-[16px] justify-end h-[100%] items-center">
                  {editMode ? (
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={form?.submit}
                      loading={loading}
                    >
                      {words.global.saveEdit}
                    </Button>
                  ) : (
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={form?.submit}
                      loading={loading}
                    >
                      {words.global.add}
                    </Button>
                  )}

                  {editMode ? (
                    <Button
                      type="default"
                      className="!min-w[85px]"
                      onClick={() => {
                        setEditMode(false);
                        form?.resetFields();
                        setPressKeyboard(false);
                      }}
                    >
                      {words.global.cancel}
                    </Button>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={() => {
                        setPressKeyboard(false);
                        form?.resetFields();
                      }}
                    >
                      <Button
                        type="default"
                        className="!min-w[85px]"
                        disabled={!pressKeyboard}
                      >
                        {words.global.reset}
                      </Button>
                    </DPopconfirm>
                  )}
                </div>
              </Col>
              <Divider />
            </Row>
          </Col>
        </AddNewPortContainer>
      </Form>
    </Guard>
  );
};
