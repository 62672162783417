import { ReactNode } from "react";
import { IAllWarehouseResult } from "src/services/BaseInfo/models/result.models";
export enum IWarehousesTabs {
  tableOfWarehouses = "1",
  addNewWarehouses = "2",
}
export interface IPhoneNumber {
  name: string;
  phoneNumber: string;
}

export interface TableData extends IAllWarehouseResult {
  tools?: ReactNode;
}
