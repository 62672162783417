import { Row } from "antd";
import styled from "styled-components";

export const OrderManagementDetailsContainer = styled(Row)`
  padding: 16px 24px;

  .card {
    height: 100px;
    padding: 16px 12px;
    border-radius: 14px;
    background: var(--color-Base-Color-White, #fff);
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
  .box-title {
    color: var(--color-Light-Mode-Text-Secondary-Text, #4b4b4b);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .date {
    color: var(--color-Light-Mode-Text-Third-Text, #8e8e8e);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 5px;
  }

  .notification {
    color: var(--color-Light-Mode-Text-Third-Text, #8e8e8e);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const ManagementOrderContentContainer = styled(Row)`
  .cardTitleHolder {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px 16px 0px 0px;
    background: var(--color-Light-Mode-BG-table-Header, #80ded9);
    margin-bottom: 8px;
  }
  .cardBody {
    display: flex;
    padding: 24px 24px 32px 24px;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 0px 0px 16px 16px;
    background: var(--color-Primary-Primary-200, #d9eeed);
  }
`;
