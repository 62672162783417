import { FC } from "react";
import { AddNewAveragePrice } from "./components/addNewAveragePrice";
import { AddNewAveragePriceContainer, AveragePriceContainer } from "./style";
import { AveragePriceHeader } from "./components/header";
import { AveragePriceTable } from "./components/table";

export const AveragePrice: FC = () => {
  return (
    <AveragePriceContainer>
      <AveragePriceHeader />
      <AddNewAveragePriceContainer>
        <AddNewAveragePrice />
        <AveragePriceTable />
      </AddNewAveragePriceContainer>
    </AveragePriceContainer>
  );
};
