import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { RespondentContactTable } from "./RespondentContactTable";
import { useWarehouses } from "src/modules/Warehouses/context";
import { RespondentNameContainer } from "../../style";
import { IAddWarehouseResponder } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { Guard } from "src/components/Guard";
import { AddWarehouseResponderPath } from "src/services/BaseInfo/guardPath";

export const RespondentName: FC = () => {
  const { words } = useLanguage();
  const {
    form: { respondentNameForm },
    value: { newWarehouseId, currentWarehouseResponderId, responderEditMode },
    func: { GetAllWarehouseResponders },
    dispatch: { setResponderEditMode, setCurrentWarehouseResponderId },
  } = useWarehouses()!;
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const { message } = App.useApp();
  const values = Form.useWatch([], respondentNameForm);
  useEffect(() => {
    respondentNameForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [respondentNameForm, values]);

  const addResponder = async (values: IAddWarehouseResponder) => {
    if (!newWarehouseId) return;
    setLoading(true);
    try {
      const { AddWarehouseResponder } = new BaseInfoService();
      const result = await AddWarehouseResponder(values, newWarehouseId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.warehouses.AddWarehouseResponderMessage
        );
        GetAllWarehouseResponders();
        respondentNameForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editResponder = async (values: IAddWarehouseResponder) => {
    if (!currentWarehouseResponderId)
      return console.log("currentWarehouseResponderId not found");
    setLoading(true);
    try {
      const { UpdateWarehouseResponder } = new BaseInfoService();
      const result = await UpdateWarehouseResponder(
        values,
        currentWarehouseResponderId
      );
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.warehouses.EditWarehouseResponderMessage
        );
        setResponderEditMode(false);
        respondentNameForm?.resetFields();
        setCurrentWarehouseResponderId(undefined);
        GetAllWarehouseResponders();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const onFinishHandler = (values: IAddWarehouseResponder) => {
    if (responderEditMode) {
      editResponder(values);
    } else {
      addResponder(values);
    }
  };
  useEffect(() => {
    GetAllWarehouseResponders();
  }, [GetAllWarehouseResponders]);
  return (
    <Form
      form={respondentNameForm}
      className="h-[100%]"
      onFinish={onFinishHandler}
    >
      <RespondentNameContainer>
        <Row>
          <Guard action={AddWarehouseResponderPath}>
            <Col span={24}>
              <Typography className="p-[16px]">
                {words.global.respondentName}
              </Typography>
            </Col>
            <Row className="w-[100%] py-[24px]">
              <Col span={16}>
                <FormItem
                  name={"fullName"}
                  label={words.global.responsive}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={words.global.insert} allowClear />
                </FormItem>
              </Col>
              <Col span={8}>
                <div className="flex justify-end items-center h-[100%]">
                  <Button
                    type="primary"
                    ghost
                    disabled={!submittable}
                    loading={loading}
                    onClick={respondentNameForm?.submit}
                  >
                    {responderEditMode ? words.global.edit : words.global.add}
                  </Button>
                </div>
              </Col>
            </Row>
            <Col span={24} className="h-[82px] pb-[24px]"></Col>
            <Divider className="pb-[24px]" />
          </Guard>
          <Col span={24}>
            <Typography className="pb-[24px]">
              {words.global.respondentContactTable}
            </Typography>
          </Col>
          <Col span={24}>
            <RespondentContactTable />
          </Col>
        </Row>
      </RespondentNameContainer>
    </Form>
  );
};
