import { FC } from "react";
import { Origin } from "src/modules/Origin";
import { OriginContextProvider } from "src/modules/Origin/context";

export const OriginPages: FC = () => {
  return (
    <OriginContextProvider>
      <Origin />
    </OriginContextProvider>
  );
};
