import { ReactNode } from "react";
import {
  IAllBankResponderDetailsResult,
  IAllBankRespondersResult,
  IAllBankResult,
  IBankResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";

export enum IAddNewOperatingBanksTabs {
  basicInformation = "1",
  Contacts = "2",
}
export enum IEditOperatingBanksTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface TableData extends IAllBankResult {
  "#"?: number;
  tools?: ReactNode;
  responderAndContactNumber?: ReactNode;
}
export interface ResponderTableData extends IAllBankRespondersResult {
  "#"?: number;
  tools?: ReactNode;
}
export interface BankDetailTableData extends IAllBankResponderDetailsResult {
  "#"?: number;
}
export interface ResponderPhoneTableData
  extends IBankResponderDetailsByIdResult {
  "#"?: number;
  tools?: ReactNode;
}
export interface IViewData {
  BankDetailData: IAllBankResponderDetailsResult[];
  record: TableData;
}

export enum IOperatingBanksTabs {
  tableOfOperatingBanks = "1",
  addNewOperatingBanks = "2",
}
