import { Divider } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import useLanguage from "src/store/language";

interface IHeaderProforma {
  headerName: string;
}
export const HeaderProforma: FC<IHeaderProforma> = ({ headerName }) => {
  const { words } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  return (
    <div>
      <div className="flex justify-between items-center pt-[16px] px-[24px]">
        <div className="flex gap-[12px]">
          <div className="text-[16px] text-[#8E8E8E]">
            {words.orders.orderManagement}
          </div>
          <div className="text-[18px] text-[#73C8C3]"> / </div>
          <div className="text-[16px] text-[#1F1F1F]">{headerName}</div>
        </div>
        <div
          className="flex flex-row gap-[12px] items-center cursor-pointer"
          onClick={() => {
            navigate(ROUTES.orderManagement.concat(`/${orderId}`));
          }}
        >
          <div className=" text-[#008C84] text-[16px] ">
            {words.global.back}
          </div>
          <span className="material-icons text-[#008C84] text-[24px]">
            keyboard_backspace
          </span>
        </div>
      </div>
      <Divider className="mb-[32px]" />
    </div>
  );
};
