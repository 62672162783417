import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { RespondentNameContainer } from "../../style";
import { FormItem } from "src/components/UiKit/FormItem";
import { RespondentContactTable } from "./RespondentContactTable";
import { useInsuranceCompanies } from "src/modules/InsuranceCompanies/context";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddInsuranceResponder } from "src/services/BaseInfo/models/args.models";

export const RespondentName: FC = () => {
  const { words } = useLanguage();
  const { message } = App.useApp();

  const {
    form: { respondentNameForm },
    value: { insuranceId, responderEditMode, insuranceResponderId },
    func: { GetAllInsuranceResponders },
    dispatch: { setResponderEditMode },
  } = useInsuranceCompanies()!;
  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], respondentNameForm);
  useEffect(() => {
    respondentNameForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const addResponder = async (values: IAddInsuranceResponder) => {
    setLoading(true);
    try {
      const { AddInsuranceResponder } = new BaseInfoService();
      const result = await AddInsuranceResponder(values, insuranceId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.insuranceCompanies.AddInsuranceResponderMessage
        );
        GetAllInsuranceResponders();
        respondentNameForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editResponder = async (values: IAddInsuranceResponder) => {
    if (!insuranceResponderId)
      return console.log("insuranceResponderId not found");
    setEditLoading(true);
    try {
      const { UpdateInsuranceResponder } = new BaseInfoService();
      const result = await UpdateInsuranceResponder(
        values,
        insuranceResponderId
      );
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.insuranceCompanies
            .EditInsuranceResponderMessage
        );
        setResponderEditMode(false);
        respondentNameForm?.resetFields();
        GetAllInsuranceResponders();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };
  return (
    <Form
      form={respondentNameForm}
      className="h-[100%]"
      onFinish={responderEditMode ? editResponder : addResponder}
    >
      <RespondentNameContainer>
        <Row>
          <Col span={24}>
            <Typography className="p-[16px]">
              {words.global.respondentName}
            </Typography>
          </Col>
          <Row className="w-[100%] py-[24px]">
            <Col span={16}>
              <FormItem
                name={"fullName"}
                label={words.global.responsive}
                rules={[{ required: true }]}
              >
                <Input placeholder={words.global.insert} allowClear />
              </FormItem>
            </Col>
            <Col span={8}>
              <div className="flex justify-end items-center h-[100%]">
                <Button
                  type="primary"
                  ghost
                  disabled={!submittable}
                  onClick={respondentNameForm?.submit}
                  loading={responderEditMode ? editLoading : loading}
                >
                  {responderEditMode ? words.global.saveEdit : words.global.add}
                </Button>
              </div>
            </Col>
          </Row>
          <Col span={24} className="h-[82px] pb-[24px]"></Col>
          <Divider className="pb-[24px]" />
          <Col span={24}>
            <Typography className="pb-[24px]">
              {words.global.respondentContactTable}
            </Typography>
          </Col>
          <Col span={24}>
            <RespondentContactTable />
          </Col>
        </Row>
      </RespondentNameContainer>
    </Form>
  );
};
