import { Form } from "antd";
import { FormItemProps } from "antd/lib/form";
import { FC, PropsWithChildren } from "react";
import { styled } from "styled-components";

interface IProps extends FormItemProps {}

export const FormItem: FC<PropsWithChildren<IProps>> = (props) => {
  return (
    <FormItemContainer
      labelCol={{
        span: 24,
        style: { paddingBottom: "unset", paddingLeft: 12 },
      }}
      {...props}
    >
      {props.children}
    </FormItemContainer>
  );
};

const FormItemContainer = styled(Form.Item)`
  label {
    color: ${({ theme }) => theme.colors.title} !important;
    padding: 0px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: unset;
  }
  label::before {
    position: absolute;
    left: 12px;
    top: 25%;
  }
`;
