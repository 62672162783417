import { FC } from "react";
import { Warehouses } from "src/modules/Warehouses";
import { WarehousesProvider } from "src/modules/Warehouses/context";

export const WarehousesPage: FC = () => {
  return (
    <WarehousesProvider>
      <Warehouses />
    </WarehousesProvider>
  );
};
