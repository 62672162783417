import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { RespondentContactTable } from "./RespondentContactTable";
import { RespondentNameContainer } from "../../style";
import { useEditSupplier } from "src/modules/EditSupplier/context";
import { useLocation } from "react-router-dom";
import { IAddSupplierResponder } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAllSupplierResult } from "src/services/BaseInfo/models/result.models";

export const RespondentName: FC = () => {
  const { words } = useLanguage();
  const {
    form: { respondentNameForm },
    value: { editResponse, supplierResponderId },
    dispatch: { setEditResponse },
    func: { GetAllSupplierResponders },
  } = useEditSupplier()!;
  const location = useLocation();
  const data: IAllSupplierResult = location.state;
  const { message } = App.useApp();
  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const value = Form.useWatch([], respondentNameForm);
  useEffect(() => {
    respondentNameForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const addResponder = async (values: IAddSupplierResponder) => {
    setLoading(true);
    try {
      const { AddSupplierResponder } = new BaseInfoService();
      const result = await AddSupplierResponder(values, data.id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.suppliers.AddSupplierResponderMessage
        );
        GetAllSupplierResponders(data.id);
        respondentNameForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editResponder = async (values: IAddSupplierResponder) => {
    if (!supplierResponderId)
      return console.log("supplierResponderId not found");
    setEditLoading(true);
    try {
      const { UpdateSupplierResponder } = new BaseInfoService();
      const result = await UpdateSupplierResponder(values, supplierResponderId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.suppliers.EditSupplierResponderMessage
        );
        setEditResponse(false);
        respondentNameForm?.resetFields();
        GetAllSupplierResponders(data.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };

  return (
    <Form
      form={respondentNameForm}
      className="h-[100%]"
      onFinish={editResponse ? editResponder : addResponder}
    >
      <RespondentNameContainer>
        <Row>
          <Col span={24}>
            <Typography className="p-[16px]">
              {words.global.editRespondent}
            </Typography>
          </Col>
          <Row className="w-[100%] py-[24px]">
            <Col span={16}>
              <FormItem
                name={"fullName"}
                label={words.global.responsive}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  allowClear
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
          </Row>
          <Col span={24} className="h-[82px] pb-[24px]">
            <div className="flex justify-end items-center h-[100%] gap-[16px]">
              {editResponse ? (
                <Button
                  type="primary"
                  disabled={!pressKeyboard}
                  onClick={respondentNameForm?.submit}
                  loading={editLoading}
                >
                  {words.global.saveEdit}
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={!submittable}
                  onClick={respondentNameForm?.submit}
                  loading={loading}
                >
                  {words.global.add}
                </Button>
              )}
              {editResponse ? (
                <Button
                  disabled={!submittable}
                  onClick={() => {
                    setEditResponse(false);
                    setPressKeyboard(false);
                    respondentNameForm?.resetFields();
                  }}
                >
                  {words.global.cancel}
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Divider className="pb-[24px]" />
          <Col span={24}>
            <Typography className="pb-[24px]">
              {words.global.respondentContactTable}
            </Typography>
          </Col>
          <Col span={24}>
            <RespondentContactTable />
          </Col>
        </Row>
      </RespondentNameContainer>
    </Form>
  );
};
