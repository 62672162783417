import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { useClearanceWorkers } from "src/modules/ClearanceWorkers/context/context";
import { BasicInformationContainer } from "./style";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddCustomBroker } from "src/services/BaseInfo/models/args.models";

export const BasicInformation: FC = () => {
  const {
    dispatch: {
      setBasicInfoEditMode,
      setIsRegister,
      setAddCustomBrokerLoading,
      setCustomBrokerId,
      setPressKeyboard,
    },
    form: { basicInformationForm },
    value: {
      CustomBrokerId,
      basicInfoEditMode,
      isRegister,
      addCustomBrokerLoading,
    },
  } = useClearanceWorkers()!;

  const { words } = useLanguage();
  const { message } = App.useApp();

  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], basicInformationForm);
  useEffect(() => {
    basicInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onFinishAddCustomBroker = async (values: IAddCustomBroker) => {
    setAddCustomBrokerLoading(true);
    try {
      const { AddCustomBroker } = new BaseInfoService();
      const result = await AddCustomBroker(values);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.clearanceWorkers.addCustomBrokerMessage
        );
        setCustomBrokerId(result.data);
        setPressKeyboard(false);
        setIsRegister(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddCustomBrokerLoading(false);
    }
  };

  const updateBasicInformation = async (values: IAddCustomBroker) => {
    if (!CustomBrokerId) return console.log("CustomBrokerId is undefined");
    setAddCustomBrokerLoading(true);

    try {
      const { UpdateCustomBroker } = new BaseInfoService();
      const result = await UpdateCustomBroker(values, CustomBrokerId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.clearanceWorkers.editCustomBrokerMessage
        );
        setBasicInfoEditMode(false);
        setIsRegister(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddCustomBrokerLoading(false);
    }
  };

  const onClickEdit = () => {
    setBasicInfoEditMode(true);
    setIsRegister(false);
  };
  const onClickSaveEdit = () => {
    setBasicInfoEditMode(true);
    basicInformationForm?.submit();
  };
  return (
    <Form
      form={basicInformationForm}
      onFinish={
        basicInfoEditMode ? updateBasicInformation : onFinishAddCustomBroker
      }
    >
      <BasicInformationContainer>
        <Col span={24}>
          <Typography>
            {
              words.basicInformation.clearanceWorkers
                .clearanceWorkersInformation
            }
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name={"name"}
                label={
                  words.basicInformation.clearanceWorkers.workClearanceName
                }
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                  disabled={isRegister}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name={"workPlace"}
                label={words.basicInformation.clearanceWorkers.workPlace}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                  disabled={isRegister}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div
            className={`flex gap-[16px] ${
              !!CustomBrokerId ? "justify-between" : "justify-end"
            } borderTop items-center`}
          >
            {!!CustomBrokerId ? (
              <div>
                {
                  words.basicInformation.clearanceWorkers
                    .editClearanceWorkersButton
                }
              </div>
            ) : (
              ""
            )}
            {CustomBrokerId ? (
              <Button
                className="!min-w[85px]"
                type="primary"
                ghost
                disabled={!submittable}
                onClick={basicInfoEditMode ? onClickSaveEdit : onClickEdit}
              >
                {basicInfoEditMode ? words.global.saveEdit : words.global.edit}
              </Button>
            ) : (
              <Button
                className="!min-w[85px]"
                type="primary"
                disabled={!submittable}
                onClick={basicInformationForm?.submit}
                loading={addCustomBrokerLoading}
              >
                {words.global.registration}
              </Button>
            )}
          </div>
        </Col>
      </BasicInformationContainer>
    </Form>
  );
};
