import { FC } from "react";
import { AveragePrice } from "src/modules/averagePrice";
import { AveragePriceProvider } from "src/modules/averagePrice/context/context";

export const AveragePricePage: FC = () => {
  return (
    <AveragePriceProvider>
      <AveragePrice />
    </AveragePriceProvider>
  );
};
