import { FC, useState } from "react";
import { Col, Flex, Spin } from "antd";
import useLanguage from "src/store/language";
import { OrderManagementDetailsContainer } from "../style";

const OrderManagementDetails: FC = () => {
  const { words } = useLanguage();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  // const {
  //   value: { tourManagement, mainLink, dataLoading },
  // } = useManagementTour();

  // const renderTourState = () => {
  //   switch (tourManagement?.tourState) {
  //     case TourStates.Waiting:
  //       return (
  //         <Flex>
  //           <img src="/assets/images/tour-waiting.svg" alt="tour-progress" />
  //           <span className="px-[5px] text-[14px] text-[#FE9B0E] font-bold">
  //             {words.orders.waitingToStart}
  //           </span>
  //         </Flex>
  //       );
  //     case TourStates.Progress:
  //       return (
  //         <Flex>
  //           <img src="/assets/images/tour-progress.svg" alt="tour-progress" />
  //           <span className="px-[5px] text-[14px] text-[#3B82F6] font-bold">
  //             {words.orders.progress}
  //           </span>
  //         </Flex>
  //       );
  //     case TourStates.Finished:
  //       return (
  //         <Flex>
  //           <img src="/assets/images/tour-finished.svg" alt="tour-progress" />
  //           <span className="px-[5px] text-[14px] text-[#47B881] font-bold">
  //             {words.orders.finished}
  //           </span>
  //         </Flex>
  //       );
  //     case TourStates.Inactive:
  //       return (
  //         <Flex>
  //           <img src="/assets/images/tour-cancel.svg" alt="tour-progress" />
  //           <span className="px-[5px] text-[14px] text-[#F64C4C] font-bold">
  //             {words.orders.inactive}
  //           </span>
  //         </Flex>
  //       );
  //   }
  // };

  return (
    <OrderManagementDetailsContainer gutter={24}>
      <Col span={6}>
        <Flex className="card">
          <div>
            <span className="material-icons text-[#008F87] text-[22px]">
              event_repeat
            </span>
          </div>
          <Flex vertical className="px-[16px] w-full">
            <div className="box-title">{words.orders.orderStatus}</div>
            {dataLoading ? (
              <div className="w-full flex items-center justify-center">
                <Spin size="small" />
              </div>
            ) : (
              <>
                <div className="py-[6px]">
                  <Flex>
                    <span className="material-icons text-[14px] text-[#FE9B0E] font-bold">
                      pause_circle_filled
                    </span>
                    <span className="px-[5px] text-[12px] text-[#FE9B0E] font-bold">
                      {words.orders.waitingToStart}
                    </span>
                  </Flex>
                </div>
                <Flex align="center" justify="space-between" className="w-full">
                  <div>
                    <span className="material-icons text-[#8e8e8e] text-[12px]">
                      edit_calendar
                    </span>
                    <span className="date">1403_02_20</span>
                  </div>

                  <div className="bg-[#F5F5F5] w-[32px] h-[24px] flex justify-center items-center px-[8px] cursor-pointer">
                    <span className="material-symbols-outlined text-[16px]">
                      keyboard_return
                    </span>
                  </div>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Col>
      <Col span={6}>
        <Flex className="card">
          <span className="material-icons text-[#008F87] text-[24px]">
            store
          </span>
          <Flex vertical className="px-[16px] gap-[16px] w-full">
            <div className="box-title">{words.orders.rialProfit}</div>
            <Flex justify="space-between">
              <div className="text-[#008F87] text-[14px] flex justify-center items-center">
                RIAL
              </div>
              <span className="text-[16px] flex justify-center items-center">
                ---
              </span>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col span={6}>
        <Flex className="card">
          <span className="material-icons text-[#008F87] text-[24px]">
            store
          </span>

          <Flex vertical className="px-[16px] gap-[16px] w-full">
            <div className="box-title">{words.orders.foreignProfit}</div>
            <Flex justify="space-between">
              <div className="text-[#008F87] text-[14px] flex justify-center items-center">
                USD
              </div>
              <span className="text-[16px] flex justify-center items-center">
                ---
              </span>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col span={6}>
        <Flex className="card">
          <span className="material-icons text-[#008F87] text-[24px]">
            monetization_on
          </span>
          <Flex vertical className="px-[16px] gap-[16px] w-full">
            <div className="box-title">{words.orders.currencyProfit}</div>
            <Flex justify="space-between">
              <div className="text-[#008F87] text-[14px] flex justify-center items-center">
                USD
              </div>
              <span className="text-[16px] flex justify-center items-center">
                ---
              </span>
            </Flex>
          </Flex>
        </Flex>
      </Col>
    </OrderManagementDetailsContainer>
  );
};

export default OrderManagementDetails;
