import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Row,
  UploadProps,
} from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { VipContainer } from "./style";
import { FormItem } from "src/components/UiKit/FormItem";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { ROUTES } from "src/models/enums/routes";
import { Upload, UploadFile } from "antd/lib";
import { UploadChangeParam } from "antd/es/upload";
import { dummyRequest } from "src/helper/dummyRequest";
import TextArea from "antd/es/input/TextArea";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";

export const Vip: FC = () => {
  const { words } = useLanguage();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [file, setFile] = useState<UploadFile<any>[]>([]);
  const [editMode, setEditMode] = useState<boolean>(true);

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setFile([info.file]);

    if (info.file.status === "uploading") {
      //setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setFile(info.fileList);
    }
  };
  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement);
  };
  const onClickReset = () => {
    form?.resetFields();
    setFile([]);
  };

  const update = (values: any) => {};
  const add = (values: any) => {};
  const onFinish = (values: any) => {
    if (editMode) {
      update(values);
    } else {
      add(values);
    }
  };

  return (
    <div>
      <HeaderProforma
        headerName={
          editMode ? words.orders.vip.editVipInfo : words.orders.vip.VipInfo
        }
      />
      <Flex justify="center">
        <VipContainer>
          <Form form={form} onFinish={onFinish}>
            <Col span={24}>
              <div className="py-[16px]">
                {editMode
                  ? words.orders.editInfo
                  : words.orders.vip.enterVipInfo}
              </div>
            </Col>
            <Col span={24} className="py-[16px]">
              <Row gutter={24}>
                <Col span={8}>
                  <FormItem
                    name={"issueDate"}
                    label={words.orders.vip.issueDate}
                    rules={[{ required: true }]}
                  >
                    <DatePicker placeholder={words.global.insert} />
                  </FormItem>
                </Col>
                <Col span={16}>
                  <div className="uploadHolder">
                    <div className="p-x[12px] flex flex-col justify-center">
                      <div className="text-[14px]">
                        {words.global.fileUpload}
                      </div>
                      <div className="text-[10px]">
                        {words.orders.vip.vipFileUpload}
                      </div>
                    </div>
                    <div
                      className={
                        file.length > 0 ? "w-[66px] h-[66px]" : `fileHolder`
                      }
                    >
                      <Upload
                        name="file"
                        onChange={handleChange}
                        customRequest={dummyRequest}
                        showUploadList={false}
                      >
                        <Button
                          type={file.length > 0 ? "text" : "default"}
                          icon={
                            file.length > 0 ? (
                              <img
                                src="/assets/images/fileUploaded.svg"
                                alt="fileUploaded"
                              />
                            ) : (
                              <span className="material-icons flex justify-center items-center text-[20px]">
                                attach_file
                              </span>
                            )
                          }
                          className="text-[10px]"
                        >
                          {file.length > 0 ? "" : words.global.add}
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <FormItem
                    name={"description"}
                    label={words.orders.vip.description}
                  >
                    <TextArea
                      className="!min-h-[90px]"
                      placeholder={words.global.description}
                      // onKeyDown={() => setPressKeyboard(true)}
                      onPressEnter={form?.submit}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider dashed />
              <div
                className={`flex py-[24px]  ${
                  editMode ? "justify-between" : "justify-end"
                }`}
              >
                {editMode ? (
                  <div className="!min-w-[85px]">
                    <DeletePopconfirm
                      onConfirm={() => {}}
                      endPointName={words.orders.vip.deleteVipInfo}
                      className="!min-w-[85px]"
                    >
                      <Button danger className="!min-w[85px]">
                        {words.global.delete}
                      </Button>
                    </DeletePopconfirm>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex gap-[16px] justify-end borderTop">
                  {editMode ? (
                    <div className="!min-w-[85px] h-[100%]">
                      <Button type="default" onClick={onClickCancel}>
                        {words.global.cancel}
                      </Button>
                    </div>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] h-[100%]">
                        <Button type="default" disabled={file.length === 0}>
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  )}
                  <Button
                    className="!min-w[85px]"
                    type="primary"
                    onClick={() => {
                      setEditMode(false);
                      navigate(ROUTES.orderManagement);
                    }}
                    // loading={addLoading}
                  >
                    {editMode ? words.global.saveEdit : words.global.add}
                  </Button>
                </div>
              </div>
            </Col>
          </Form>
        </VipContainer>
      </Flex>
    </div>
  );
};
