import { FC } from "react";
import { ClearanceWorkers } from "src/modules/ClearanceWorkers";
import { ClearanceWorkersProvider } from "src/modules/ClearanceWorkers/context/context";

export const ClearanceWorkersPage: FC = () => {
  return (
    <ClearanceWorkersProvider>
      <ClearanceWorkers />
    </ClearanceWorkersProvider>
  );
};
