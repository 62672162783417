import { FC } from "react";
import { Button, Divider, Modal, Typography } from "antd";
import useLanguage from "src/store/language";
import { ResponderAndContactNumberTable } from "./table";
import { useOperatingBanks } from "../../context";
const { Title } = Typography;

export const ContactNumberModal: FC = () => {
  const { words } = useLanguage();
  const {
    value: { isModalOpen, tableRecord },
    dispatch: { setIsModalOpen },
  } = useOperatingBanks()!;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        className="!w-[45%] !h-[10%]"
        title={
          <div className="pt-[8px] pb-[4px]">
            <Title level={3}>{words.global.responderAndContactNumber}</Title>
            <div className="text-[16px] text-[#8E8E8E]">
              {tableRecord?.bankName}
            </div>
          </div>
        }
        open={isModalOpen}
        closable={false}
        footer={[
          <div className="flex justify-center items-center">
            <Button onClick={handleCancel}>
              <div className="flex gap-[12px] justify-center items-center">
                <div className="text-[14px]">{words.global.close}</div>
                <span className="material-icons text-[24px] text-[#4B4B4B]">
                  highlight_off
                </span>
              </div>
            </Button>
          </div>,
        ]}
      >
        <Divider className="pb-[20px]" />
        <ResponderAndContactNumberTable />
      </Modal>
    </div>
  );
};
