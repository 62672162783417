import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import {
  IAllCountryResult,
  IAllCurrencyResult,
  IAllInspectionResult,
  IAllSupplierResult,
} from "src/services/BaseInfo/models/result.models";

interface IContext {
  value: {
    editMode: boolean;
    supplierLoading: boolean;
    supplier: IAllSupplierResult[];
    countryLoading: boolean;
    country: IAllCountryResult[];
    currency: IAllCurrencyResult[];
    inspection: IAllInspectionResult[];
    currencyLoading: boolean;
    inspectionLoading: boolean;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setSupplierLoading: Dispatch<SetStateAction<boolean>>;
    setCountryLoading: Dispatch<SetStateAction<boolean>>;
    setSupplier: Dispatch<SetStateAction<IAllSupplierResult[]>>;
    setCountry: Dispatch<SetStateAction<IAllCountryResult[]>>;
    setCurrencyLoading: Dispatch<SetStateAction<boolean>>;
    setInspectionLoading: Dispatch<SetStateAction<boolean>>;
    setCurrency: Dispatch<SetStateAction<IAllCurrencyResult[]>>;
    setInspection: Dispatch<SetStateAction<IAllInspectionResult[]>>;
  };
  func: {
    getSupplier: () => void;
    getCountry: () => {};
    getCurrency: () => void;
    getInspection: () => void;
  };
}

export const OriginContext = createContext<IContext | undefined>(undefined);

export const OriginContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [editMode, setEditMode] = useState<boolean>(true);
  const [supplier, setSupplier] = useState<IAllSupplierResult[]>([]);
  const [supplierLoading, setSupplierLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<IAllCountryResult[]>([]);
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [currency, setCurrency] = useState<IAllCurrencyResult[]>([]);
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(false);
  const [inspection, setInspection] = useState<IAllInspectionResult[]>([]);
  const [inspectionLoading, setInspectionLoading] = useState<boolean>(false);
  ////////////////////////////////Functions////////////////////////////////
  const getSupplier = useCallback(async () => {
    setSupplierLoading(true);
    try {
      const { GetAllSupplier } = new BaseInfoService();
      const result = await GetAllSupplier();
      if (result && result.status === 200) {
        setSupplier(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSupplierLoading(false);
    }
  }, []);

  const getCountry = useCallback(async () => {
    setCountryLoading(true);
    try {
      const { GetAllCountry } = new BaseInfoService();
      const result = await GetAllCountry();
      if (result && result.status === 200) {
        setCountry(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCountryLoading(false);
    }
  }, []);

  const getCurrency = useCallback(async () => {
    setCurrencyLoading(true);
    try {
      const { GetAllCurrency } = new BaseInfoService();
      const result = await GetAllCurrency();
      if (result && result.status === 200) {
        setCurrency(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrencyLoading(false);
    }
  }, []);

  const getInspection = useCallback(async () => {
    setInspectionLoading(true);
    try {
      const { GetAllInspection } = new BaseInfoService();
      const result = await GetAllInspection();
      if (result && result.status === 200) {
        setInspection(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setInspectionLoading(false);
    }
  }, []);

  const [search, setSearch] = useSearchParams();
  const editId = search.get("editId");
  useEffect(() => {
    if (editId) {
      setEditMode(true);
    }
  }, [editId]);

  useEffect(() => {
    getSupplier();
    getCountry();
    getCurrency();
    getInspection();
  }, [getCountry, getCurrency, getInspection, getSupplier]);
  const ContextValue: IContext = {
    value: {
      supplier,
      supplierLoading,
      country,
      countryLoading,
      currency,
      currencyLoading,
      inspectionLoading,
      inspection,
      editMode,
    },
    dispatch: {
      setSupplier,
      setSupplierLoading,
      setCountry,
      setCountryLoading,
      setCurrency,
      setCurrencyLoading,
      setInspection,
      setInspectionLoading,
      setEditMode,
    },
    func: { getSupplier, getCountry, getCurrency, getInspection },
  };
  return (
    <OriginContext.Provider value={ContextValue}>
      {children}
    </OriginContext.Provider>
  );
};

export const useOrigin = () => useContext(OriginContext);
