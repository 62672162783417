import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  IAddNewClearanceWorkersTabs,
  IClearanceWorkersTabs,
  IContactPhoneNumber,
} from "src/services/Identity/models/mockModels/clear.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";
import { ITablePagination } from "src/models/interfaces/pagination";
import {
  IAllCustomBrokerResponderDetailsResult,
  IAllCustomBrokerResult,
  IAllCustomBrokerPhoneResult,
} from "src/services/BaseInfo/models/result.models";

interface IContext {
  value: {
    clearanceWorkersActiveKey: string;
    addNewClearanceWorkersActiveKey: string;
    editContact: boolean;
    selectedResponsive: IContactPhoneNumber | undefined;
    isModalOpen: boolean;
    tableRecord: string;
    pressKeyboard: boolean;
    isRegister: boolean;
    loading: boolean;
    CustomBrokerDetailLoading: boolean;
    dataSource: IAllCustomBrokerResult[];
    paginationCustomBroker: ITablePagination;
    CustomBrokerDetailData: IAllCustomBrokerResponderDetailsResult[];
    paginationCustomBrokerDetail: ITablePagination;
    CustomBrokerId: number | undefined;
    basicInfoEditMode: boolean;
    addCustomBrokerLoading: boolean;
    CustomBrokerPhoneLoading: boolean;
    CustomBrokerPhoneData: IAllCustomBrokerPhoneResult[];
    paginationCustomBrokerPhone: ITablePagination;
    customBrokerPhoneId: number | undefined;
    endOfRegister: boolean;
  };
  dispatch: {
    setClearanceWorkersActiveKey: Dispatch<SetStateAction<string>>;
    setAddNewClearanceWorkersActiveKey: Dispatch<SetStateAction<string>>;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setSelectedResponsive: Dispatch<
      SetStateAction<IContactPhoneNumber | undefined>
    >;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;
    setTableRecord: Dispatch<SetStateAction<string>>;
    setIsRegister: Dispatch<SetStateAction<boolean>>;
    setPaginationCustomBroker: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationCustomBrokerDetail: Dispatch<SetStateAction<ITablePagination>>;
    setCustomBrokerDetailData: Dispatch<
      SetStateAction<IAllCustomBrokerResponderDetailsResult[]>
    >;
    setCustomBrokerId: Dispatch<SetStateAction<number | undefined>>;
    setBasicInfoEditMode: Dispatch<SetStateAction<boolean>>;
    setAddCustomBrokerLoading: Dispatch<SetStateAction<boolean>>;
    setPaginationCustomBrokerPhone: Dispatch<SetStateAction<ITablePagination>>;
    setCustomBrokerPhoneId: Dispatch<SetStateAction<number | undefined>>;
    setEndOfRegister: Dispatch<SetStateAction<boolean>>;
    setCustomBrokerPhoneData: Dispatch<
      SetStateAction<IAllCustomBrokerPhoneResult[]>
    >;
  };
  func: {
    getTableData: () => void;
    GetAllCustomBrokerResponderDetails: (CustomBrokerId: number) => void;
    GetAllCustomBrokerPhone: (CustomBrokerId: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
    basicInformationForm: FormInstance<any> | undefined;
    contactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const ClearanceWorkersContext = createContext<IContext | undefined>(
  undefined
);

export const ClearanceWorkersProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [clearanceWorkersActiveKey, setClearanceWorkersActiveKey] =
    useState<string>(IClearanceWorkersTabs.tableOfClearanceWorkers);
  const [addNewClearanceWorkersActiveKey, setAddNewClearanceWorkersActiveKey] =
    useState<string>(IAddNewClearanceWorkersTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [selectedResponsive, setSelectedResponsive] =
    useState<IContactPhoneNumber>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tableRecord, setTableRecord] = useState<string>("");
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [paginationCustomBroker, setPaginationCustomBroker] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [dataSource, setDataSource] = useState<IAllCustomBrokerResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [CustomBrokerDetailLoading, setCustomBrokerDetailLoading] =
    useState<boolean>(false);
  const [paginationCustomBrokerDetail, setPaginationCustomBrokerDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [CustomBrokerDetailData, setCustomBrokerDetailData] = useState<
    IAllCustomBrokerResponderDetailsResult[]
  >([]);
  const [CustomBrokerId, setCustomBrokerId] = useState<number>();
  const [basicInfoEditMode, setBasicInfoEditMode] = useState<boolean>(false);
  const [addCustomBrokerLoading, setAddCustomBrokerLoading] =
    useState<boolean>(false);
  const [CustomBrokerPhoneLoading, setCustomBrokerPhoneLoading] =
    useState<boolean>(false);
  const [CustomBrokerPhoneData, setCustomBrokerPhoneData] = useState<
    IAllCustomBrokerPhoneResult[]
  >([]);
  const [paginationCustomBrokerPhone, setPaginationCustomBrokerPhone] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [customBrokerPhoneId, setCustomBrokerPhoneId] = useState<number>();
  const [endOfRegister, setEndOfRegister] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [basicInformationForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();

  ////////////////////////////functions////////////////////////////////////////////////
  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      const { GetAllCustomBroker } = new BaseInfoService();

      const params: IObject = {
        Offset: paginationCustomBroker.current,
        Limit: paginationCustomBroker.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllCustomBroker(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPaginationCustomBroker((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationCustomBroker.current, paginationCustomBroker.pageSize]);

  const GetAllCustomBrokerResponderDetails = useCallback(
    async (CustomBrokerId: number) => {
      if (!(CustomBrokerId > 0)) return;
      setCustomBrokerDetailLoading(true);
      try {
        const { GetAllCustomBrokerResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationCustomBrokerDetail.current,
          Limit: paginationCustomBrokerDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllCustomBrokerResponderDetails(
          CustomBrokerId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setCustomBrokerDetailData(result.data.records);
          setPaginationCustomBrokerDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setCustomBrokerDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      paginationCustomBrokerDetail.current,
      paginationCustomBrokerDetail.pageSize,
    ]
  );

  const GetAllCustomBrokerPhone = useCallback(
    async (CustomBrokerId: number) => {
      if (!(CustomBrokerId > 0)) return;
      setCustomBrokerPhoneLoading(true);
      try {
        const { GetAllCustomBrokerPhone } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationCustomBrokerPhone.current,
          Limit: paginationCustomBrokerPhone.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllCustomBrokerPhone(
          searchParams.toString(),
          CustomBrokerId
        );
        if (result && result.status === 200 && result.data) {
          setCustomBrokerPhoneData(result.data.records);
          setPaginationCustomBrokerPhone((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
        if (result && result?.data.records.length > 0) {
          setEndOfRegister(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setCustomBrokerPhoneLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationCustomBrokerPhone.current, paginationCustomBrokerPhone.pageSize]
  );
  const ContextValue: IContext = {
    value: {
      clearanceWorkersActiveKey,
      addNewClearanceWorkersActiveKey,
      editContact,
      selectedResponsive,
      isModalOpen,
      tableRecord,
      pressKeyboard,
      isRegister,
      dataSource,
      loading,
      paginationCustomBroker,
      CustomBrokerDetailData,
      paginationCustomBrokerDetail,
      CustomBrokerDetailLoading,
      CustomBrokerId,
      basicInfoEditMode,
      addCustomBrokerLoading,
      CustomBrokerPhoneLoading,
      CustomBrokerPhoneData,
      paginationCustomBrokerPhone,
      customBrokerPhoneId,
      endOfRegister,
    },
    dispatch: {
      setClearanceWorkersActiveKey,
      setAddNewClearanceWorkersActiveKey,
      setEditContact,
      setSelectedResponsive,
      setIsModalOpen,
      setTableRecord,
      setPressKeyboard,
      setIsRegister,
      setPaginationCustomBroker,
      setCustomBrokerDetailData,
      setPaginationCustomBrokerDetail,
      setCustomBrokerId,
      setBasicInfoEditMode,
      setAddCustomBrokerLoading,
      setPaginationCustomBrokerPhone,
      setCustomBrokerPhoneId,
      setEndOfRegister,
      setCustomBrokerPhoneData,
    },
    func: {
      getTableData,
      GetAllCustomBrokerResponderDetails,
      GetAllCustomBrokerPhone,
    },
    form: {
      form,
      basicInformationForm,
      contactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  return (
    <ClearanceWorkersContext.Provider value={ContextValue}>
      {children}
    </ClearanceWorkersContext.Provider>
  );
};

export const useClearanceWorkers = () => useContext(ClearanceWorkersContext);
