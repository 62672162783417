import { MenuProps } from "antd";
import { ROUTES } from "../../../models/enums/routes";
import { getItem } from "../helper/createItem";
import { Link } from "react-router-dom";
import { LogoutStyle } from "../styles/logout";
import { useLogout } from "src/hook";
import useLanguage from "src/store/language";
import useRoutesAccessStore from "src/store/routesAccess";

type MenuItem = Required<MenuProps>["items"][number];

export const useSidebarItems = (activeKey: string) => {
  const { routes } = useRoutesAccessStore();
  const { logoutHandler } = useLogout();
  const { words } = useLanguage();
  const items: MenuItem[] = [
    getItem(
      <Link to={ROUTES.home}>{words.sidebarItems.dashboard}</Link>,
      ROUTES.home,
      <span className="material-icons !text-[#a3aed0] !text-[24px]	">
        dashboard
      </span>
    ),
    getItem(
      <Link to={ROUTES.orders}>{words.orders.orders}</Link>,
      ROUTES.orders,
      <span className="material-icons !text-[#a3aed0] !text-[24px]	">
        content_paste_go
      </span>
    ),
    getItem(
      words.basicInformation.basicInformation,
      ROUTES.basicInformation,
      <span className="material-icons !text-[#a3aed0] !text-[24px]">list</span>,
      [
        routes.suppliers
          ? getItem(
              <Link to={ROUTES.suppliers}>
                {words.basicInformation.suppliers.suppliers}
              </Link>,
              ROUTES.suppliers
            )
          : null,
        routes.inspectionCompanies
          ? getItem(
              <Link to={ROUTES.inspectionCompanies}>
                {words.basicInformation.inspectionCompanies.inspectionCompanies}
              </Link>,
              ROUTES.inspectionCompanies
            )
          : null,
        routes.insuranceCompanies
          ? getItem(
              <Link to={ROUTES.insuranceCompanies}>
                {words.basicInformation.insuranceCompanies.insuranceCompanies}
              </Link>,
              ROUTES.insuranceCompanies
            )
          : null,
        routes.operatingBanks
          ? getItem(
              <Link to={ROUTES.operatingBanks}>
                {words.basicInformation.operatingBanks.operatingBanks}
              </Link>,
              ROUTES.operatingBanks
            )
          : null,
        routes.transportCompanies
          ? getItem(
              <Link to={ROUTES.transportCompanies}>
                {words.basicInformation.transportCompanies.transportCompanies}
              </Link>,
              ROUTES.transportCompanies
            )
          : null,
        routes.clearanceWorkers
          ? getItem(
              <Link to={ROUTES.clearanceWorkers}>
                {words.basicInformation.clearanceWorkers.clearanceWorkers}
              </Link>,
              ROUTES.clearanceWorkers
            )
          : null,
        routes.products
          ? getItem(
              <Link to={ROUTES.products}>
                {words.basicInformation.products.products}
              </Link>,
              ROUTES.products
            )
          : null,
        routes.unitsOfMeasurement
          ? getItem(
              <Link to={ROUTES.unitsOfMeasurement}>
                {words.basicInformation.unitsOfMeasurement.unitsOfMeasurement}
              </Link>,
              ROUTES.unitsOfMeasurement
            )
          : null,
        routes.currencies
          ? getItem(
              <Link to={ROUTES.currencies}>
                {words.basicInformation.currencies.currencies}
              </Link>,
              ROUTES.currencies
            )
          : null,
        routes.vessels
          ? getItem(
              <Link to={ROUTES.vessels}>
                {words.basicInformation.vessels.vessels}
              </Link>,
              ROUTES.vessels
            )
          : null,
        routes.countries
          ? getItem(
              <Link to={ROUTES.countries}>
                {words.basicInformation.countries.countries}
              </Link>,
              ROUTES.countries
            )
          : null,
        routes.countries
          ? getItem(
              <Link to={ROUTES.ports}>
                {words.basicInformation.ports.ports}
              </Link>,
              ROUTES.ports
            )
          : null,

        routes.warehouses
          ? getItem(
              <Link to={ROUTES.warehouses}>
                {words.basicInformation.warehouses.warehouses}
              </Link>,
              ROUTES.warehouses
            )
          : null,
        routes.averagePrice
          ? getItem(
              <Link to={ROUTES.averagePrice}>
                {words.basicInformation.averagePrice.averagePrice}
              </Link>,
              ROUTES.averagePrice
            )
          : null,
      ]
    ),
    routes.CreateRole ||
    routes.AssignActionToRole ||
    routes.CreateUser ||
    routes.AssignRoleToUser
      ? getItem(
          words.systemSetting.systemSetting,
          ROUTES.settings,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            settings
          </span>,
          [
            routes.CreateRole
              ? getItem(
                  <Link to={ROUTES.CreateRole}>
                    {words.systemSetting.createRole.createRole}
                  </Link>,
                  ROUTES.CreateRole
                )
              : null,
            routes.AssignActionToRole
              ? getItem(
                  <Link to={ROUTES.AssignActionToRole}>
                    {words.systemSetting.actionToRole.actionToRole}
                  </Link>,
                  ROUTES.AssignActionToRole
                )
              : null,
            routes.CreateUser
              ? getItem(
                  <Link to={ROUTES.CreateUser}>
                    {words.systemSetting.createUser.createUser}
                  </Link>,
                  ROUTES.CreateUser
                )
              : null,
            routes.AssignRoleToUser
              ? getItem(
                  <Link to={ROUTES.AssignRoleToUser}>
                    {words.systemSetting.roleToUser.roleToUser}
                  </Link>,
                  ROUTES.AssignRoleToUser
                )
              : null,
          ]
        )
      : null,
    process.env.NODE_ENV !== "production"
      ? getItem(
          <Link to={ROUTES.design}>{words.sidebarItems.design}</Link>,
          ROUTES.design,
          <span className="material-icons !text-[#a3aed0] !text-[24px]	">
            draw
          </span>
        )
      : null,

    getItem(
      <LogoutStyle onClick={logoutHandler}>
        {words.mainLayout.logout}{" "}
      </LogoutStyle>,
      "Logout",
      <LogoutStyle onClick={logoutHandler}>
        <span className="material-symbols-outlined !text-[#CACACA]">
          logout
        </span>
      </LogoutStyle>
    ),
  ];
  return items;
};
