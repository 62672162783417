import { FC } from "react";
import { UnitsOfMeasurement } from "src/modules/UnitsOfMeasurement";
import { UnitsOfMeasurementProvider } from "src/modules/UnitsOfMeasurement/context/context";

export const UnitsOfMeasurementPage: FC = () => {
  return (
    <UnitsOfMeasurementProvider>
      <UnitsOfMeasurement />
    </UnitsOfMeasurementProvider>
  );
};
