import { FC } from "react";
import { ViewOriginContainer } from "../style";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Col, Divider, Flex, Row } from "antd";

export const ViewOrigin: FC = () => {
  const { words } = useLanguage();
  return (
    <div>
      <HeaderProforma headerName={words.orders.origin.viewOriginInfo} />
      <Flex justify="center">
        <ViewOriginContainer>
          <div className="px-[12px]">{words.orders.origin.sourceInfo}</div>
          <Row className="proformaDataHolder" gutter={[16, 24]}>
            <Col span={6} className="!font-medium">
              {words.basicInformation.suppliers.suppliers}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              سعید
            </Col>
            <Col span={6} className="!font-medium">
              {words.orders.origin.contractNo}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              1403021546
            </Col>
            <Col span={6} className="!font-medium">
              {words.orders.origin.contractDate}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              1403.05.10
            </Col>
            <Col span={6} className="!font-medium">
              {words.basicInformation.countries.countries}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              روسیه
            </Col>
            <Col span={6} className="!font-medium">
              {words.orders.origin.deliveryTerm}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              EXW
            </Col>
            <Col span={6} className="!font-medium">
              {words.basicInformation.currencies.currencies}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              دلار
            </Col>
            <Col span={6} className="!font-medium">
              {words.basicInformation.inspectionCompanies.inspectionCompanies}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              چاره
            </Col>
            <Col span={6} className="!font-medium">
              {words.orders.origin.warehousing}
            </Col>
            <Col span={6} className="!font-normal text-[#4B4B4B]">
              دارد
            </Col>
            <Divider dashed />
            <Col span={24}>
              <div className="flex gap-[16px]">
                <div className="text-[14px] w-[150px]">
                  {words.orders.origin.originalDocuments}
                </div>
                <div className="flex gap-[16px]">
                  <div>
                    <img
                      src="/assets/images/fileUploaded.svg"
                      alt="fileUploaded"
                    />
                  </div>
                  <div className="flex flex-col gap-[6px] justify-center">
                    <div className="text-[14px]">
                      {words.orders.origin.fileUploaded}
                    </div>
                    <div className="text-[10px]">
                      {words.orders.origin.dlFile}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </ViewOriginContainer>
      </Flex>
    </div>
  );
};
