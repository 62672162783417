import { Row } from "antd";
import styled from "styled-components";

export const AveragePriceContainer = styled.div``;

export const AveragePriceHeaderContainer = styled(Row)`
  .middleHeader {
    display: flex;
    width: 1178px;
    height: 80px;
    padding: 24px;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border6};
  }
  .ant-typography {
    margin-bottom: 0 !important;
  }
`;
export const AveragePriceTableContainer = styled(Row)``;

export const AddNewAveragePriceContainer = styled(Row)`
  margin: 32px 51px 0 51px;
  padding: 24px 32px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.background2};
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  gap: 24px;

  .borderBottom {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border6};
  }
  .ant-typography {
    margin-bottom: 0 !important;
  }
  .table {
    /* margin-top: 24px; */
    padding: 12px 12px 24px 12px;
  }
`;
