import { FC } from "react";
import { EditWarehouses } from "src/modules/EditWarehouses";
import { EditWarehousesProvider } from "src/modules/EditWarehouses/context/context";
import { WarehousesProvider } from "src/modules/Warehouses/context";

export const EditWarehousesPage: FC = () => {
  return (
    <EditWarehousesProvider>
      <WarehousesProvider>
        <EditWarehouses />
      </WarehousesProvider>
    </EditWarehousesProvider>
  );
};
