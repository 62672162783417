import { Button, Col, Divider, Form, Row, Typography, message } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { DInput } from "src/components/UiKit/DInput";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useUnitsOfMeasurement } from "../context/context";
import { AddNewUnitsOfMeasurementContainer } from "../style/style";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddUnit } from "src/services/BaseInfo/models/args.models";
import { Guard } from "src/components/Guard";
import { AddUnitPath, UpdateUnitPath } from "src/services/BaseInfo/guardPath";

export const AddNewUnitsOfMeasurement: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    value: { editMode, unitId },
    dispatch: { setEditMode },
    func: { getUnitTableData },
  } = useUnitsOfMeasurement()!;
  const [loading, setLoading] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);

  const onFinishAddUnit = async (values: any) => {
    setLoading(true);
    try {
      const { AddUnit } = new BaseInfoService();
      const result = await AddUnit(values);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.unitsOfMeasurement.addUnitMessage
        );
        getUnitTableData();
        form?.resetFields();
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onFinishUpdateUnit = async (values: IAddUnit) => {
    setLoading(true);
    try {
      const { UpdateUnit } = new BaseInfoService();
      const result = await UpdateUnit(values, unitId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.unitsOfMeasurement.updateUnitMessage
        );
        getUnitTableData();
        form?.resetFields();
        setPressKeyboard(false);
        setEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Guard action={[AddUnitPath, UpdateUnitPath]} multiPath>
      <Form
        form={form}
        onFinish={(values) => {
          editMode ? onFinishUpdateUnit(values) : onFinishAddUnit(values);
        }}
      >
        <AddNewUnitsOfMeasurementContainer>
          <Col span={24}>
            <Typography>
              {words.basicInformation.unitsOfMeasurement.measurementInformation}
            </Typography>
          </Col>
          <Col span={24}>
            <Row gutter={24} className="unitsOfMeasurementHolder">
              <Col span={8}>
                <FormItem
                  name={"name"}
                  label={words.basicInformation.unitsOfMeasurement.name}
                  rules={[{ required: true }]}
                >
                  <DInput
                    placeholder={words.global.insert}
                    onKeyDown={() => setPressKeyboard(true)}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"shortName"}
                  label={
                    words.basicInformation.unitsOfMeasurement.abbreviationName
                  }
                  rules={[{ required: true }]}
                >
                  <DInput
                    placeholder={words.global.insert}
                    onKeyDown={() => setPressKeyboard(true)}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <div className="flex gap-[16px] justify-end h-[100%] items-center">
                  {editMode ? (
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={form?.submit}
                      loading={loading}
                    >
                      {words.global.saveEdit}
                    </Button>
                  ) : (
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={form?.submit}
                      loading={loading}
                    >
                      {words.global.add}
                    </Button>
                  )}

                  {editMode ? (
                    <Button
                      type="default"
                      className="!min-w[85px]"
                      onClick={() => {
                        setEditMode(false);
                        form?.resetFields();
                        setPressKeyboard(false);
                      }}
                    >
                      {words.global.cancel}
                    </Button>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={() => {
                        setPressKeyboard(false);
                        form?.resetFields();
                      }}
                    >
                      <Button
                        type="default"
                        className="!min-w[85px]"
                        disabled={!pressKeyboard}
                      >
                        {words.global.reset}
                      </Button>
                    </DPopconfirm>
                  )}
                </div>
              </Col>
              <Divider />
            </Row>
          </Col>
        </AddNewUnitsOfMeasurementContainer>
      </Form>
    </Guard>
  );
};
