import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  IAddNewWarehousesTabs,
  IContactsTable,
  IWarehousesTabs,
} from "src/services/Identity/models/mockModels/ware.models";
import { ITablePagination } from "src/models/interfaces/pagination";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";
import {
  IAllWarehouseResponderDetailsResult,
  IAllWarehouseRespondersResult,
  IAllWarehouseResult,
  IWarehouseResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";

interface IContext {
  value: {
    warehousesActiveKey: string;
    addNewWarehousesActiveKey: string;
    editContact: boolean;
    selectedResponsive: IContactsTable | undefined;
    isModalOpen: boolean;
    tableRecord: IAllWarehouseResult | undefined;
    isRegister: boolean;
    paginationWarehouse: ITablePagination;
    paginationResponder: ITablePagination;
    paginationPhoneNumber: ITablePagination;
    paginationWarehouseDetail: ITablePagination;
    tableLoading: boolean;
    dataSource: IAllWarehouseResult[];
    newWarehouseId: number | undefined;
    allRespondersData: IAllWarehouseRespondersResult[];
    allWarehouseRespondersLoading: boolean;
    currentWarehouseResponderId: number | undefined;
    responderEditMode: boolean;
    responderPhoneDetailLoading: boolean;
    isAddPhoneNumber: boolean;
    responderPhoneData: IWarehouseResponderDetailsByIdResult[];
    responderContactEditMode: boolean;
    selectedResponderPhone: IWarehouseResponderDetailsByIdResult | undefined;
    warehouseDetailLoading: boolean;
    warehouseDetailData: IAllWarehouseResponderDetailsResult[];
    editMode: boolean;
    editRecord: IAllWarehouseResult | undefined;
  };
  dispatch: {
    setWarehousesActiveKey: Dispatch<SetStateAction<string>>;
    setAddNewWarehousesActiveKey: Dispatch<SetStateAction<string>>;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setSelectedResponsive: Dispatch<SetStateAction<IContactsTable | undefined>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setTableRecord: Dispatch<SetStateAction<IAllWarehouseResult | undefined>>;
    setIsRegister: Dispatch<SetStateAction<boolean>>;
    setPaginationWarehouse: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationResponder: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationPhoneNumber: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationWarehouseDetail: Dispatch<SetStateAction<ITablePagination>>;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setNewWarehouseId: Dispatch<SetStateAction<number | undefined>>;
    setAllRespondersData: Dispatch<
      SetStateAction<IAllWarehouseRespondersResult[]>
    >;

    setCurrentWarehouseResponderId: Dispatch<
      SetStateAction<number | undefined>
    >;
    setResponderEditMode: Dispatch<SetStateAction<boolean>>;
    setResponderPhoneData: Dispatch<
      SetStateAction<IWarehouseResponderDetailsByIdResult[]>
    >;
    setResponderContactEditMode: Dispatch<SetStateAction<boolean>>;
    setSelectedResponderPhone: Dispatch<
      SetStateAction<IWarehouseResponderDetailsByIdResult | undefined>
    >;
    setWarehouseDetailLoading: Dispatch<SetStateAction<boolean>>;
    setWarehouseDetailData: Dispatch<
      SetStateAction<IAllWarehouseResponderDetailsResult[]>
    >;
    setEditMode: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    GetAllWarehouseResponders: () => Promise<void>;
    getTableData: () => Promise<void>;
    GetAllWarehouseResponderDetails: (warehouseId: number) => Promise<void>;
    getResponderPhoneDetail: (id: number) => Promise<void>;
  };
  form: {
    form: FormInstance<any> | undefined;
    basicInformationForm: FormInstance<any> | undefined;
    contactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const WarehousesContext = createContext<IContext | undefined>(undefined);

export const WarehousesProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const [warehousesActiveKey, setWarehousesActiveKey] = useState<string>(
    IWarehousesTabs.tableOfWarehouses
  );
  const [addNewWarehousesActiveKey, setAddNewWarehousesActiveKey] =
    useState<string>(IAddNewWarehousesTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [selectedResponsive, setSelectedResponsive] =
    useState<IContactsTable>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tableRecord, setTableRecord] = useState<IAllWarehouseResult>();
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [allWarehouseRespondersLoading, setAllWarehouseRespondersLoading] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const [basicInformationForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IAllWarehouseResult[]>([]);
  const [newWarehouseId, setNewWarehouseId] = useState<number>();
  const [isAddPhoneNumber, setIsAddPhoneNumber] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editRecord, setEditRecord] = useState<IAllWarehouseResult>();

  const [currentWarehouseResponderId, setCurrentWarehouseResponderId] =
    useState<number>();
  const [responderEditMode, setResponderEditMode] = useState<boolean>(false);
  const [responderContactEditMode, setResponderContactEditMode] =
    useState<boolean>(false);
  const [responderPhoneDetailLoading, setResponderPhoneDetailLoading] =
    useState<boolean>(false);
  const [responderPhoneData, setResponderPhoneData] = useState<
    IWarehouseResponderDetailsByIdResult[]
  >([]);
  const [warehouseDetailLoading, setWarehouseDetailLoading] =
    useState<boolean>(false);
  const [warehouseDetailData, setWarehouseDetailData] = useState<
    IAllWarehouseResponderDetailsResult[]
  >([]);
  const [selectedResponderPhone, setSelectedResponderPhone] =
    useState<IWarehouseResponderDetailsByIdResult>();
  const [paginationWarehouse, setPaginationWarehouse] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [allRespondersData, setAllRespondersData] = useState<
    IAllWarehouseRespondersResult[]
  >([]);
  const [paginationResponder, setPaginationResponder] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationPhoneNumber, setPaginationPhoneNumber] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationWarehouseDetail, setPaginationWarehouseDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });

  const getTableData = useCallback(async () => {
    setTableLoading(true);
    try {
      const { AllWarehouse } = new BaseInfoService();

      const params: IObject = {
        Offset: paginationWarehouse.current,
        Limit: paginationWarehouse.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await AllWarehouse(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPaginationWarehouse((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationWarehouse.current, paginationWarehouse.pageSize]);
  const GetAllWarehouseResponders = useCallback(async () => {
    if (!newWarehouseId) return;
    setAllWarehouseRespondersLoading(true);
    try {
      const { GetAllWarehouseResponders: GetAllWarehouseResponder } =
        new BaseInfoService();
      const params: IObject = {
        Offset: paginationResponder.current,
        Limit: paginationResponder.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllWarehouseResponder(
        searchParams.toString(),
        newWarehouseId
      );
      if (result && result.status === 200 && result.data) {
        setAllRespondersData(result.data.records);
        setPaginationResponder((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllWarehouseRespondersLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationResponder.current,
    paginationResponder.pageSize,
    newWarehouseId,
  ]);
  const getResponderPhoneDetail = useCallback(
    async (id: number) => {
      setResponderPhoneDetailLoading(true);
      try {
        const { GetWarehouseResponderDetailsById } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationPhoneNumber.current,
          Limit: paginationPhoneNumber.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetWarehouseResponderDetailsById(
          searchParams.toString(),
          id
        );
        if (result && result.status === 200) {
          setResponderPhoneData(result.data.records);
          setPaginationPhoneNumber((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }

        if (result && result?.data.count > 0) {
          setIsAddPhoneNumber(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setResponderPhoneDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationPhoneNumber.current, paginationPhoneNumber.pageSize]
  );

  const GetAllWarehouseResponderDetails = useCallback(
    async (warehouseId: number) => {
      if (!(warehouseId > 0)) return;
      setWarehouseDetailLoading(true);
      try {
        const { GetAllWarehouseResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationWarehouseDetail.current,
          Limit: paginationWarehouseDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllWarehouseResponderDetails(
          warehouseId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setWarehouseDetailData(result.data.records);
          setPaginationWarehouseDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setWarehouseDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationWarehouseDetail.current, paginationWarehouseDetail.pageSize]
  );

  useEffect(() => {
    if (location.pathname === ROUTES.editWarehouses && location.state) {
      setEditRecord(location.state);
      setEditMode(true);
      basicInformationForm.setFieldsValue(location.state);
      setNewWarehouseId(location.state?.id);
    } else {
      setEditMode(false);
    }
  }, [basicInformationForm, location.pathname, location.state]);

  const ContextValue: IContext = {
    value: {
      warehousesActiveKey,
      addNewWarehousesActiveKey,
      editContact,
      selectedResponsive,
      isModalOpen,
      tableRecord,
      isRegister,
      paginationPhoneNumber,
      paginationResponder,
      paginationWarehouse,
      paginationWarehouseDetail,
      dataSource,
      tableLoading,
      newWarehouseId,
      allRespondersData,
      allWarehouseRespondersLoading,
      currentWarehouseResponderId,
      responderEditMode,
      responderPhoneDetailLoading,
      isAddPhoneNumber,
      responderPhoneData,
      responderContactEditMode,
      selectedResponderPhone,
      warehouseDetailData,
      warehouseDetailLoading,
      editMode,
      editRecord,
    },
    dispatch: {
      setWarehousesActiveKey,
      setAddNewWarehousesActiveKey,
      setEditContact,
      setSelectedResponsive,
      setIsModalOpen,
      setTableRecord,
      setIsRegister,
      setPaginationPhoneNumber,
      setPaginationResponder,
      setPaginationWarehouse,
      setPaginationWarehouseDetail,
      setTableLoading,
      setNewWarehouseId,
      setAllRespondersData,
      setCurrentWarehouseResponderId,
      setResponderEditMode,
      setResponderPhoneData,
      setResponderContactEditMode,
      setSelectedResponderPhone,
      setWarehouseDetailData,
      setWarehouseDetailLoading,
      setEditMode,
    },
    func: {
      GetAllWarehouseResponders,
      getResponderPhoneDetail,
      GetAllWarehouseResponderDetails,
      getTableData,
    },
    form: {
      form,
      basicInformationForm,
      contactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  useEffect(() => {
    getTableData();
  }, [getTableData]);
  return (
    <WarehousesContext.Provider value={ContextValue}>
      {children}
    </WarehousesContext.Provider>
  );
};

export const useWarehouses = () => useContext(WarehousesContext);
