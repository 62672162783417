import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { ContactContainer } from "../style";
import { useClearanceWorkers } from "src/modules/ClearanceWorkers/context/context";
import { ContactsTable } from "./ContactsTable";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const Contact: FC = () => {
  const {
    value: { editContact, CustomBrokerId, customBrokerPhoneId },
    dispatch: { setPressKeyboard, setEditContact },
    form: { contactForm },
    func: { GetAllCustomBrokerPhone },
  } = useClearanceWorkers()!;
  const { words } = useLanguage();
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], contactForm);

  useEffect(() => {
    contactForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onFinish = async (value: { phoneNumber: string }) => {
    if (!CustomBrokerId) return console.log("CustomBrokerId not found");
    setLoading(true);
    try {
      const { AddCustomBrokerPhone } = new BaseInfoService();
      const result = await AddCustomBrokerPhone(
        value.phoneNumber,
        CustomBrokerId
      );
      if (result && result.status === 200) {
        GetAllCustomBrokerPhone(CustomBrokerId);
        setPressKeyboard(false);
        contactForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const update = async (value: { phoneNumber: string }) => {
    if (!customBrokerPhoneId || !CustomBrokerId)
      return console.log("customBrokerPhoneId or CustomBrokerId not found");
    setLoading(true);
    try {
      const { UpdateCustomBrokerPhone } = new BaseInfoService();
      const result = await UpdateCustomBrokerPhone(
        customBrokerPhoneId,
        value.phoneNumber
      );
      if (result && result.status === 200) {
        GetAllCustomBrokerPhone(CustomBrokerId);
        setPressKeyboard(false);
        contactForm?.resetFields();
        setEditContact(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={contactForm} onFinish={editContact ? update : onFinish}>
      <ContactContainer>
        <Col span={24}>
          <Typography>
            {words.basicInformation.clearanceWorkers.contactInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name={"phoneNumber"}
                label={words.basicInformation.clearanceWorkers.phoneNumber}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.phoneNumberPLaceHolder}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <div className="flex justify-end items-center h-full">
                <Button
                  type="primary"
                  className="!min-w[85px]"
                  ghost
                  disabled={!submittable && !editContact}
                  onClick={contactForm?.submit}
                  loading={loading}
                >
                  {editContact ? (
                    <div className="flex gap-[10px] justify-center items-center">
                      {words.global.saveEdit}
                    </div>
                  ) : (
                    <div className="flex gap-[12px]">
                      <span className="material-icons text-[16px]">add</span>
                      <div className="flex gap-[10px] justify-center items-center">
                        {words.global.addNewPhoneNumber}
                      </div>
                    </div>
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <ContactsTable />
        </Col>
      </ContactContainer>
    </Form>
  );
};
