import { FC } from "react";
import { Countries } from "src/modules/countries";
import { CountriesProvider } from "src/modules/countries/context";

export const CountriesPage: FC = () => {
  return (
    <CountriesProvider>
      <Countries />
    </CountriesProvider>
  );
};
