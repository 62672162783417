import { Row } from "antd";
import { styled } from "styled-components";

export const EditBasicInformationContainer = styled(Row)`
  display: flex;
  padding: 24px 32px;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--color-Light-Mode-Border-4, #e1e1e1);
`;
