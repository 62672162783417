import { lightColors } from "./light";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { darkColors } from "./dark";
import { StorageKeys } from "src/constants/localStorage";
const lightTheme = {
  colors: lightColors,
};

const darkTheme = {
  colors: darkColors,
};

export type Theme = typeof lightTheme;
export type ThemeType = "dark" | "light";
export interface ThemeContextValue {
  theme: Theme;
  themeType: ThemeType;
  isDarkTheme: boolean;
  toggleThemeType: () => void;
  setThemeType: React.Dispatch<React.SetStateAction<ThemeType>>;
}
export const ThemeContext = React.createContext<ThemeContextValue>({
  theme: lightTheme,
  themeType: "light",
  isDarkTheme: false,
  setThemeType: () => {},
  toggleThemeType: () => {},
});
export const useTheme = () => useContext<ThemeContextValue>(ThemeContext);

export interface ThemeContextProviderProps {
  children: React.ReactNode;
}

export const ThemeContextProvider = ({
  children,
}: ThemeContextProviderProps) => {
  const [themeType, setThemeType] = useState<ThemeType>("light");

  const toggleThemeType = useCallback(() => {
    setThemeType((prev) => {
      const newTheme = prev === "dark" ? "light" : "dark";
      localStorage.setItem(StorageKeys.theme, newTheme);
      return newTheme;
    });
  }, []);

  const isDarkTheme = useMemo(() => themeType === "dark", [themeType]);
  const theme = useMemo(
    () => (isDarkTheme ? darkTheme : lightTheme),
    [isDarkTheme]
  );
  useEffect(() => {
    const localTheme = localStorage.getItem(StorageKeys.theme);
    if (!localTheme) {
      setThemeType("light");
    } else {
      setThemeType(localTheme as ThemeType);
    }
  }, []);
  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeType,
        isDarkTheme,
        setThemeType,
        toggleThemeType,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
