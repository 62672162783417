import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { IGetCountries, TableData } from "../models";
import { countriesMockData } from "../constants";
import { Form, FormInstance, message } from "antd";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import useLanguage from "src/store/language";

interface IContextCountry {
  value: {
    dataTable: IGetCountries[];
    editMode: boolean;
    dataSource: TableData[];
    pagination: ITablePagination;
    productLoading: boolean;
    productId: number;
  };
  dispatch: {
    setDataTable: Dispatch<SetStateAction<IGetCountries[]>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setProductId: Dispatch<SetStateAction<number>>;
  };
  form: { form: FormInstance | undefined };
  func: {
    getCountryTableData: () => void;
    deleteCountry: (id: number) => void;
  };
}

const defaultCtxValue: IContextCountry = {
  value: {
    dataTable: [],
    editMode: false,
    dataSource: [],
    pagination: { current: 0, pageSize: 0, total: 0 },
    productId: -1,
    productLoading: false,
  },
  dispatch: {
    setDataTable: () => {},
    setEditMode: () => {},
    setPagination: () => {},
    setProductId: () => {},
  },
  form: { form: undefined },
  func: { deleteCountry: (id: number) => {}, getCountryTableData: () => {} },
};

export const CountriesContext = createContext<IContextCountry>(defaultCtxValue);

export const CountriesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const [dataTable, setDataTable] =
    useState<IGetCountries[]>(countriesMockData);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [productId, setProductId] = useState<number>(-1);
  ///////////Functions////////////////////////////////////////////////////////////////
  const getCountryTableData = async () => {
    setProductLoading(true);
    try {
      const { GetAllCountry } = new BaseInfoService();
      const result = await GetAllCountry(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };

  const deleteCountry = async (id: number) => {
    setProductLoading(true);
    try {
      const { DeleteCountry } = new BaseInfoService();
      const result = await DeleteCountry(id);
      if (result && result.status === 200) {
        message.success(words.basicInformation.products.deleteProductMessage);
        getCountryTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };
  const contextValue: IContextCountry = {
    value: {
      dataTable,
      editMode,
      dataSource,
      pagination,
      productId,
      productLoading,
    },
    dispatch: { setDataTable, setEditMode, setPagination, setProductId },
    form: { form },
    func: { deleteCountry, getCountryTableData },
  };

  return (
    <CountriesContext.Provider value={contextValue}>
      {children}
    </CountriesContext.Provider>
  );
};

export const useCountries = () => useContext(CountriesContext);
