import { FC } from "react";
import { OperatingBanks } from "src/modules/OperatingBanks";
import { OperatingBanksProvider } from "src/modules/OperatingBanks/context";

export const OperatingBanksPage: FC = () => {
  return (
    <OperatingBanksProvider>
      <OperatingBanks />
    </OperatingBanksProvider>
  );
};
