import { FC } from "react";
import { CountriesContainer } from "./style";
import { CountriesHeader } from "./components/countriesHeader";
import { AddNewCountry } from "./components/addNewCountry";
import { CountriesTable } from "./components/countriesTable";

export const Countries: FC = () => {
  return (
    <div>
      <CountriesHeader />
      <div className="flex justify-center">
        <CountriesContainer>
          <AddNewCountry />
          <CountriesTable />
        </CountriesContainer>
      </div>
    </div>
  );
};
