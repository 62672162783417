import { Divider, Tabs } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { IEditOperatingBanksTabs } from "./models";
import { TabsProps } from "antd/lib";
import { useEditOperatingBanks } from "./context/context";
import { HeaderEditOperatingBank } from "./header";
import { EditBasicInformation } from "./components/BasicInformation/BasicInformation";
import { EditContact } from "./components/ContactInfo";
import { EditOperatingBankContainer } from "./style/style";

export const EditOperatingBank: FC = () => {
  const { words } = useLanguage();
  const {
    value: { editOperatingBanksActiveKey },
    dispatch: { setEditOperatingBanksActiveKey },
  } = useEditOperatingBanks()!;

  const onChange = (key: string) => {
    setEditOperatingBanksActiveKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: IEditOperatingBanksTabs.basicInformation,
      label: words.global.basicInformation,
      children: <EditBasicInformation />,
    },
    {
      key: IEditOperatingBanksTabs.Contacts,
      label: words.global.Contacts,
      children: <EditContact />,
    },
  ];

  return (
    <div>
      <HeaderEditOperatingBank />
      <Divider />
      <div className="flex justify-center items-center">
        <EditOperatingBankContainer>
          <Tabs
            activeKey={editOperatingBanksActiveKey}
            items={items}
            onChange={onChange}
            centered
            animated
          />
        </EditOperatingBankContainer>
      </div>
    </div>
  );
};
