import { FC } from "react";
import { useCreateUserContext } from "./context";
import { Button, Col, Form, Input, Row, TreeSelect, Typography } from "antd";
import { CreateUserContainer } from "./styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { ConfirmModal } from "src/components/UiKit/ConfirmModal";
import { DInput } from "src/components/UiKit/DInput";
import useLanguage from "src/store/language";

export const CreateUser: FC = () => {
  const {
    onFinish,
    onCancelConfirm,
    onConfirm,
    showConfirm,
    treeProps,
    confirmLoading,
    createUserForm,
  } = useCreateUserContext();

  const { words } = useLanguage();
  const { Title } = Typography;

  return (
    <>
      <Form onFinish={onFinish} form={createUserForm}>
        <CreateUserContainer justify={"center"} gutter={[0, 24]}>
          <Col span={24} className="header">
            <Title>{words.systemSetting.createUser.createUser}</Title>
          </Col>
          <Col span={15} className="content">
            <Row gutter={[24, 0]}>
              <Col span={24} className="mb-[40px]">
                <Typography className="title">
                  {words.systemSetting.createUser.userInformation}
                </Typography>
              </Col>
              <Col span={12}>
                <FormItem
                  name={"userName"}
                  label={words.systemSetting.createUser.userName}
                  required
                  rules={[{ required: true }]}
                >
                  <DInput placeholder={words.global.insert} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={words.systemSetting.createUser.firstName}
                  name={"firstName"}
                >
                  <DInput placeholder={words.global.insert} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={words.systemSetting.createUser.lastName}
                  name={"lastName"}
                >
                  <DInput placeholder={words.global.insert} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={words.systemSetting.createUser.password}
                  name={"password"}
                  required
                  rules={[{ required: true }]}
                >
                  <Input.Password
                    placeholder={words.global.insert}
                    className="input-password"
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={words.systemSetting.createUser.roles}
                  name={"roles"}
                  required
                  rules={[{ required: true }]}
                >
                  <TreeSelect
                    {...treeProps}
                    allowClear
                    className="selectOption"
                    treeCheckable
                    showSearch
                    treeNodeFilterProp="label"
                    placeholder={words.global.select}
                  />
                </FormItem>
              </Col>
              <Col span={24} className="flex justify-end gap-[24px] footer">
                <Button type="primary" htmlType="submit">
                  {words.global.create}
                </Button>
              </Col>
            </Row>
          </Col>
        </CreateUserContainer>
      </Form>
      <ConfirmModal
        open={showConfirm}
        onCancel={onCancelConfirm}
        onOk={onConfirm}
        text={words.systemSetting.createUser.modalTextMessage}
        title={words.systemSetting.createUser.createUser}
        confirmIcon="add_circle"
        okText={words.global.create}
        confirmLoading={confirmLoading}
      />
    </>
  );
};
