import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { BasicInformationContainer } from "../style";
import { useTransportCompanies } from "src/modules/TransportCompanies/context";
import { IAddNewTransportCompaniesTabs } from "src/modules/TransportCompanies/models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddTransport } from "src/services/BaseInfo/models/args.models";

export const BasicInformation: FC = () => {
  const {
    dispatch: {
      setPressKeyboard,
      setAddNewTransportCompaniesActiveKey,
      setBasicInfoEditMode,
    },
    form: { basicInformationForm },
    value: {
      pressKeyboard,
      addTransportLoading,
      TransportId,
      basicInfoEditMode,
    },
    func: { onFinishAddTransport },
  } = useTransportCompanies()!;
  const { words } = useLanguage();
  const { message } = App.useApp();

  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], basicInformationForm);
  useEffect(() => {
    basicInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const updateBasicInformation = async (values: IAddTransport) => {
    try {
      const { UpdateTransport } = new BaseInfoService();
      const result = await UpdateTransport(values, TransportId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.transportCompanies.editTransportMessage
        );
        setAddNewTransportCompaniesActiveKey(
          IAddNewTransportCompaniesTabs.Contacts
        );
        setBasicInfoEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  return (
    <Form
      form={basicInformationForm}
      onFinish={
        basicInfoEditMode ? updateBasicInformation : onFinishAddTransport
      }
    >
      <BasicInformationContainer>
        <Col span={24}>
          <Typography>
            {
              words.basicInformation.transportCompanies
                .transportCompaniesInformation
            }
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                name={"name"}
                label={words.basicInformation.transportCompanies.companyName}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem
                name={"address"}
                label={words.basicInformation.transportCompanies.address}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={24}></Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div className="flex gap-[16px] justify-end borderTop">
            <DPopconfirm
              description={words.global.descriptionPopConfirm}
              onConfirm={() => {
                basicInformationForm?.resetFields();
                setPressKeyboard(false);
              }}
            >
              <Button
                type="default"
                className="!min-w[85px]"
                disabled={!pressKeyboard}
              >
                {words.global.reset}
              </Button>
            </DPopconfirm>
            <Button
              className="!min-w[85px]"
              type="primary"
              disabled={!submittable}
              onClick={basicInformationForm?.submit}
              loading={addTransportLoading}
            >
              {words.global.confirmationAndNext}
            </Button>
          </div>
        </Col>
      </BasicInformationContainer>
    </Form>
  );
};
