import { App, Col, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ContactsTableContainer } from "../../style";
import { useTransportCompanies } from "src/modules/TransportCompanies/context";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAllTransportRespondersResult } from "src/services/BaseInfo/models/result.models";

export const RespondentContactTable: FC = () => {
  const {
    dispatch: {
      setResponderEditMode,
      setTransportResponderId,
      setResponderPhoneData,
      setPaginationResponder,
    },
    form: { respondentNameForm, RCInformationForm },
    value: { allRespondersData, paginationResponder, allTransportResponders },
    func: { GetAllTransportResponders },
  } = useTransportCompanies()!;

  const { words } = useLanguage();
  const { message } = App.useApp();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<number>();

  useEffect(() => {
    GetAllTransportResponders();
  }, [GetAllTransportResponders]);

  const deleteResponder = async (id: number) => {
    setDeleteLoading(true);
    try {
      const { DeleteTransportResponder } = new BaseInfoService();
      const result = await DeleteTransportResponder(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.transportCompanies
            .deleteTransportResponderMessage
        );
        RCInformationForm?.resetFields();
        setResponderPhoneData([]);
        GetAllTransportResponders();
        setResponderEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };
  const columns: ColumnsType<IAllTransportRespondersResult> = [
    {
      title: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.responsive,
      dataIndex: "responderName",
      key: "responderName",
      width: 233,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.tools,
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setResponderEditMode(true);
              respondentNameForm?.setFieldValue(
                "fullName",
                record.responderName
              );
              setTransportResponderId(record.id);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <DPopconfirm
            onConfirm={() => {
              deleteResponder(record.id);
            }}
            description={words.global.deletePhoneNumberPopConfirm}
            okButtonProps={{
              loading: deleteLoading,
            }}
            open={openDeleteConfirm === record.id}
            onCancel={() => {
              setOpenDeleteConfirm(undefined);
            }}
            okType="danger"
          >
            <span
              className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]"
              onClick={() => {
                setOpenDeleteConfirm(record.id);
              }}
            >
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];
  return (
    <ContactsTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={allRespondersData}
          loading={allTransportResponders}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            total: paginationResponder.total,
            current: paginationResponder.current,
            pageSize: paginationResponder.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationResponder((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </ContactsTableContainer>
  );
};
