import { App, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRowKey } from "src/helper/getRowKey";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { ROUTES } from "src/models/enums/routes";
import { OperatingBanksTableContainer } from "./style";
import { useOperatingBanks } from "../../context";
import { ContactNumberModal } from "./ContactNumberModal";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { TableData } from "../../models";
import { Guard } from "src/components/Guard";
import {
  AllBankResponderDetailsPath,
  DeleteBankPath,
  UpdateBankPath,
} from "src/services/BaseInfo/guardPath";

export const OperatingBanksTable: FC = () => {
  const { words } = useLanguage();
  const { message } = App.useApp();

  const {
    dispatch: { setIsModalOpen, setPaginationBank, setTableRecord },
    func: { getTableData, GetAllBankResponderDetails },
    value: { dataSource, loading, paginationBank, BankDetailData },
  } = useOperatingBanks()!;

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<number>();
  const navigate = useNavigate();
  const onClickResponderAndContactNumber = (id: number) => {
    setIsModalOpen(true);
    GetAllBankResponderDetails(id);
  };
  const onClickDelete = async (id: number) => {
    setDeleteLoading(true);
    try {
      const { DeleteBank } = new BaseInfoService();
      const result = await DeleteBank(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.operatingBanks.deleteBankMessage
        );
        getTableData();
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };
  useEffect(() => {
    getTableData();
  }, [getTableData]);
  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<TableData> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.operatingBanks.bankName,
      dataIndex: "bankName",
      key: "bankName",
      width: 170,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.basicInformation.operatingBanks.branchName,
      dataIndex: "branchName",
      key: "branchName",
      width: 130,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.basicInformation.operatingBanks.branchCode,
      dataIndex: "branchCode",
      key: "branchCode",
      width: 130,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.global.responderAndContactNumber,
      dataIndex: "responderAndContactNumber",
      key: "responderAndContactNumber",
      width: 166,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => (
        <Guard action={AllBankResponderDetailsPath}>
          <span
            className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center"
            onClick={() => {
              onClickResponderAndContactNumber(record.id);
              setTableRecord(record);
            }}
          >
            contact_phone
          </span>
        </Guard>
      ),
    },
    {
      title: words.basicInformation.operatingBanks.address,
      dataIndex: "address",
      key: "address",
      width: 97,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => (
        <Tooltip title={record.address} placement="bottom">
          <span className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center">
            directions
          </span>
        </Tooltip>
      ),
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 112,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateBankPath}>
            <span
              onClick={() => {
                navigate(ROUTES.editOperatingBank, { state: record });
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <span
            onClick={() => {
              navigate(ROUTES.viewOperatingBank, {
                state: { BankDetailData, record },
              });
            }}
            className="material-icons !text-[#02514C] text-[16px] cursor-pointer"
          >
            visibility
          </span>
          <Guard action={DeleteBankPath}>
            <DPopconfirm
              deleteClassName
              onConfirm={() => {
                onClickDelete(record.id);
              }}
              description={
                words.basicInformation.operatingBanks.deleteOperatingBank
              }
              okButtonProps={{
                loading: deleteLoading,
              }}
              open={openDeleteConfirm === record.id}
              onCancel={() => {
                setOpenDeleteConfirm(undefined);
              }}
              okType="danger"
            >
              <span
                className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]"
                onClick={() => {
                  setOpenDeleteConfirm(record.id);
                }}
              >
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <OperatingBanksTableContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: 730 }}
        rowKey={getRowKey}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: paginationBank.total,
          current: paginationBank.current,
          pageSize: paginationBank.pageSize,
          onChange: (page: number, pageSize) => {
            setPaginationBank((prev) => ({
              ...prev,
              current: page,
              pageSize,
            }));
          },
        }}
      />
      <ContactNumberModal />
    </OperatingBanksTableContainer>
  );
};
