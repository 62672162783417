import {
  App,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
} from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { IAddNewWarehousesTabs } from "../../models";
import { useWarehouses } from "src/modules/Warehouses/context";
import { BasicInformationContainer } from "./style";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { IAddWarehouse } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";

export const BasicInformation: FC = () => {
  const {
    dispatch: { setAddNewWarehousesActiveKey, setNewWarehouseId, setEditMode },
    form: { basicInformationForm },
    value: { editMode, newWarehouseId },
  } = useWarehouses()!;
  const { words } = useLanguage();
  const [submittable, setSubmittable] = useState<boolean>(false);
  const { message } = App.useApp();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const values = Form.useWatch([], basicInformationForm);
  useEffect(() => {
    basicInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [basicInformationForm, values]);
  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (!!values[val]) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);
  const onFinish = (values: IAddWarehouse) => {
    if (editMode) {
      updateBasicInformation(values);
    } else {
      addBasicInformation(values);
    }
  };
  const addBasicInformation = async (values: IAddWarehouse) => {
    try {
      setLoading(true);
      const { AddWarehouse } = new BaseInfoService();
      const res = await AddWarehouse({
        ...values,
        number: Number(values.number),
      });
      if (res && res.data) {
        setNewWarehouseId(res.data);
        setAddNewWarehousesActiveKey(IAddNewWarehousesTabs.Contacts);
        message.success(words.basicInformation.warehouses.addWarehouseMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const updateBasicInformation = async (values: IAddWarehouse) => {
    console.log("edit");
    if (!newWarehouseId) return;
    try {
      const { UpdateWarehouse } = new BaseInfoService();
      const result = await UpdateWarehouse(values, newWarehouseId);
      if (result && result.status === 200) {
        message.success(words.basicInformation.warehouses.editWarehouseMessage);
        if (location.pathname !== ROUTES.editWarehouses) {
          setAddNewWarehousesActiveKey(IAddNewWarehousesTabs.Contacts);
          setEditMode(false);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  return (
    <Form form={basicInformationForm} onFinish={onFinish}>
      <BasicInformationContainer>
        <Col span={24}>
          <Typography>
            {editMode
              ? words.basicInformation.warehouses.editWarehousesInformation
              : words.basicInformation.warehouses.warehousesInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                name={"name"}
                label={words.basicInformation.warehouses.warehousesName}
                rules={[{ required: true }]}
              >
                <Input placeholder={words.global.insert} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"number"}
                label={words.basicInformation.warehouses.warehousesNumber}
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder={words.global.insert}
                  changeOnWheel={false}
                  controls={false}
                  className="w-full"
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"location"}
                label={words.basicInformation.warehouses.warehousesLocation}
              >
                <Input placeholder={words.global.insert} />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name={"address"}
                label={words.basicInformation.warehouses.address}
              >
                <Input placeholder={words.global.insert} />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name={"mainAddress"}
                label={words.basicInformation.warehouses.mainAddress}
              >
                <Input placeholder={words.global.insert} />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div className="flex gap-[16px] justify-end borderTop">
            <DPopconfirm
              description={words.global.descriptionPopConfirm}
              onConfirm={() => {
                if (location.state) {
                  basicInformationForm?.setFieldsValue(location.state);
                } else {
                  basicInformationForm?.resetFields();
                }
              }}
            >
              <Button
                type="default"
                className="!min-w[85px]"
                disabled={isDisabledResetForm}
              >
                {words.global.reset}
              </Button>
            </DPopconfirm>
            <Button
              className="!min-w[85px]"
              type="primary"
              disabled={!submittable}
              onClick={basicInformationForm?.submit}
              loading={loading}
            >
              {editMode
                ? words.global.saveEdit
                : words.global.confirmationAndNext}
            </Button>
          </div>
        </Col>
      </BasicInformationContainer>
    </Form>
  );
};
