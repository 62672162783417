import { FC } from "react";
import { EditInsuranceCompanies } from "src/modules/EditInsuranceCompanies";
import { EditInsuranceCompaniesProvider } from "src/modules/EditInsuranceCompanies/context/context";

export const EditInsuranceCompaniesPage: FC = () => {
  return (
    <EditInsuranceCompaniesProvider>
      <EditInsuranceCompanies />
    </EditInsuranceCompaniesProvider>
  );
};
