import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import useLanguage from "src/store/language";
import useOrderStore from "src/store/order";

type PageTitleKeys = keyof typeof ROUTES;
export type PageTitleKeysType = (typeof ROUTES)[PageTitleKeys];

export const usePageTitle = (path: PageTitleKeysType) => {
  const { words } = useLanguage();
  const { orderCode } = useOrderStore();
  const pageTitles = {
    [ROUTES.home]: words.pageTitles.home,
    [ROUTES.login]: words.pageTitles.login,
    [ROUTES.design]: words.sidebarItems.design,
    [ROUTES.suppliers]: words.basicInformation.suppliers.suppliers,
    [ROUTES.basicInformation]: words.basicInformation.basicInformation,
    [ROUTES.editSupplier]: words.basicInformation.suppliers.suppliers,
    [ROUTES.viewSuppliers]: words.basicInformation.suppliers.suppliers,
    [ROUTES.inspectionCompanies]:
      words.basicInformation.inspectionCompanies.inspectionCompanies,
    [ROUTES.viewInspectionCompanies]:
      words.basicInformation.inspectionCompanies.inspectionCompanies,
    [ROUTES.editInspectionCompanies]:
      words.basicInformation.inspectionCompanies.inspectionCompanies,
    [ROUTES.insuranceCompanies]:
      words.basicInformation.insuranceCompanies.insuranceCompanies,
    [ROUTES.viewInsuranceCompanies]:
      words.basicInformation.insuranceCompanies.insuranceCompanies,
    [ROUTES.editInsuranceCompanies]:
      words.basicInformation.insuranceCompanies.insuranceCompanies,
    [ROUTES.transportCompanies]:
      words.basicInformation.transportCompanies.transportCompanies,
    [ROUTES.viewTransportCompanies]:
      words.basicInformation.transportCompanies.transportCompanies,
    [ROUTES.editTransportCompany]:
      words.basicInformation.transportCompanies.transportCompanies,
    [ROUTES.operatingBanks]:
      words.basicInformation.operatingBanks.operatingBanks,
    [ROUTES.viewOperatingBank]:
      words.basicInformation.operatingBanks.operatingBanks,
    [ROUTES.editOperatingBank]:
      words.basicInformation.operatingBanks.operatingBanks,
    [ROUTES.clearanceWorkers]:
      words.basicInformation.clearanceWorkers.clearanceWorkers,
    [ROUTES.viewClearanceWorkers]:
      words.basicInformation.clearanceWorkers.clearanceWorkers,
    [ROUTES.editClearanceWorkers]:
      words.basicInformation.clearanceWorkers.clearanceWorkers,
    [ROUTES.ports]: words.basicInformation.ports.ports,
    [ROUTES.countries]: words.basicInformation.countries.countries,

    [ROUTES.averagePrice]: words.basicInformation.averagePrice.averagePrice,
    [ROUTES.warehouses]: words.basicInformation.warehouses.warehouses,
    [ROUTES.viewWarehouses]: words.basicInformation.warehouses.warehouses,
    [ROUTES.editWarehouses]: words.basicInformation.warehouses.warehouses,
    [ROUTES.products]: words.basicInformation.products.products,
    [ROUTES.unitsOfMeasurement]:
      words.basicInformation.unitsOfMeasurement.unitsOfMeasurement,
    [ROUTES.currencies]: words.basicInformation.currencies.currencies,
    [ROUTES.vessels]: words.basicInformation.vessels.vessels,
    [ROUTES.CreateRole]: words.systemSetting.systemSetting,
    [ROUTES.CreateUser]: words.systemSetting.systemSetting,
    [ROUTES.AssignActionToRole]: words.systemSetting.systemSetting,
    [ROUTES.AssignRoleToUser]: words.systemSetting.systemSetting,
    [ROUTES.settings]: words.systemSetting.systemSetting,
    [ROUTES.orders]: words.orders.orders,
    [ROUTES.orderManagement]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.ViewOrder]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.origin]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewOrigin]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.vip]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewVip]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.addOrder]: words.orders.order.concat(` ${orderCode}`),
    [ROUTES.viewAddOrder]: words.orders.order.concat(` ${orderCode}`),
  };
  return pageTitles[
    Object.keys(pageTitles).find((key) =>
      key.replace(/[^a-zA-Z]+/g, "").includes(path.replace(/[^a-zA-Z]+/g, ""))
    ) as PageTitleKeysType
  ];
};
