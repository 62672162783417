import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { EditBasicInformationContainer } from "../style";
import { useLocation } from "react-router-dom";
import { useEditTransportCompany } from "../../context/context";
import { IEditTransportCompaniesTabs } from "../../models";
import { IAllTransportResult } from "src/services/BaseInfo/models/result.models";
import { IAddTransport } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const EditBasicInformation: FC = () => {
  const {
    dispatch: { setEditTransportCompaniesActiveKey, setPressKeyboard },
    form: { editBasicInformationForm },
    value: { pressKeyboard },
  } = useEditTransportCompany()!;
  const { words } = useLanguage();
  const location = useLocation();
  const { message } = App.useApp();
  const data: IAllTransportResult = location.state;
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: IAddTransport) => {
    setLoading(true);
    try {
      const { UpdateTransport } = new BaseInfoService();
      const result = await UpdateTransport(values, data.id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.transportCompanies.editTransportMessage
        );
        setEditTransportCompaniesActiveKey(
          IEditTransportCompaniesTabs.Contacts
        );
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      editBasicInformationForm?.setFieldValue("name", data.companyName);
      editBasicInformationForm?.setFieldValue("address", data.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <Form form={editBasicInformationForm} onFinish={onFinish}>
      <EditBasicInformationContainer>
        <Col span={24}>
          <Typography>
            {words.basicInformation.transportCompanies.editInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                name={"name"}
                label={words.basicInformation.transportCompanies.companyName}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem
                name={"address"}
                label={words.basicInformation.transportCompanies.address}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div className="flex gap-[16px] justify-end borderTop">
            <Button
              className="!min-w[85px]"
              type="primary"
              disabled={!pressKeyboard}
              onClick={editBasicInformationForm?.submit}
              loading={loading}
            >
              {words.global.saveEdit}
            </Button>

            <Button
              type="default"
              className="!min-w[85px]"
              onClick={() => {
                editBasicInformationForm?.setFieldsValue(location.state);
                setPressKeyboard(false);
              }}
              disabled={!pressKeyboard}
            >
              {words.global.clearChanges}
            </Button>
          </div>
        </Col>
      </EditBasicInformationContainer>
    </Form>
  );
};
