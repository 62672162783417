import { Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  IEditInspectionCompaniesTabs,
  ResponderTableData,
} from "../models/models";
import { IInspectionResponderDetailsByIdResult } from "src/services/BaseInfo/models/result.models";
import { ITablePagination } from "src/models/interfaces/pagination";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";

interface IContext {
  value: {
    editInspectionCompaniesActiveKey: string;
    editContact: boolean;
    pressKeyboard: boolean;
    editResponse: boolean;
    allRespondersData: ResponderTableData[] | undefined;
    responderContactEditMode: boolean;
    InspectionResponderId: number | undefined;
    responderPhoneDetailLoading: boolean;
    responderPhoneData: IInspectionResponderDetailsByIdResult[];
    paginationPhoneNumber: ITablePagination;
    isModalOpen: boolean;
    allInspectionResponders: boolean;
    phoneId: number | undefined;
  };
  dispatch: {
    setEditInspectionCompaniesActiveKey: Dispatch<SetStateAction<string>>;

    setEditContact: Dispatch<SetStateAction<boolean>>;
    setEditResponse: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;

    setResponderContactEditMode: Dispatch<SetStateAction<boolean>>;
    setInspectionResponderId: Dispatch<SetStateAction<number | undefined>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setPhoneId: Dispatch<SetStateAction<number | undefined>>;
  };
  func: {
    GetAllInspectionResponders: (id: number) => void;
    getResponderPhoneDetail: (id: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
    editBasicInformationForm: FormInstance<any> | undefined;
    editContactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const EditInspectionCompaniesContext = createContext<
  IContext | undefined
>(undefined);

export const EditInspectionCompaniesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [
    editInspectionCompaniesActiveKey,
    setEditInspectionCompaniesActiveKey,
  ] = useState<string>(IEditInspectionCompaniesTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);

  const [editResponse, setEditResponse] = useState<boolean>(false);
  const [paginationResponder, setPaginationResponder] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [allInspectionResponders, setAllInspectionResponders] =
    useState<boolean>(false);
  const [allRespondersData, setAllRespondersData] = useState<
    ResponderTableData[]
  >([]);
  const [responderContactEditMode, setResponderContactEditMode] =
    useState<boolean>(false);
  const [responderPhoneDetailLoading, setResponderPhoneDetailLoading] =
    useState<boolean>(false);
  const [paginationPhoneNumber, setPaginationPhoneNumber] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [responderPhoneData, setResponderPhoneData] = useState<
    IInspectionResponderDetailsByIdResult[]
  >([]);
  const [InspectionResponderId, setInspectionResponderId] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [phoneId, setPhoneId] = useState<number>();

  const [form] = Form.useForm();
  const [editBasicInformationForm] = Form.useForm();
  const [editContactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();

  ////////////////////////////////////////////////////////////////func////////////////////////////////
  const GetAllInspectionResponders = useCallback(
    async (id: number) => {
      setAllInspectionResponders(true);
      try {
        const { GetAllInspectionResponders: GetAllInspectionR } =
          new BaseInfoService();
        const params: IObject = {
          Offset: paginationResponder.current,
          Limit: paginationResponder.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllInspectionR(searchParams.toString(), id);
        if (result && result.status === 200 && result.data) {
          setAllRespondersData(result.data.records);
          setPaginationResponder((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setAllInspectionResponders(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationResponder.current, paginationResponder.pageSize]
  );

  const getResponderPhoneDetail = useCallback(
    async (id: number) => {
      setResponderPhoneDetailLoading(true);
      try {
        const { GetInspectionResponderDetailsById } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationPhoneNumber.current,
          Limit: paginationPhoneNumber.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetInspectionResponderDetailsById(
          searchParams.toString(),
          id
        );
        if (result && result.status === 200) {
          setResponderPhoneData(result.data.records);
          setPaginationPhoneNumber((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setResponderPhoneDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationPhoneNumber.current, paginationPhoneNumber.pageSize]
  );
  const ContextValue: IContext = {
    value: {
      editInspectionCompaniesActiveKey,
      editContact,
      pressKeyboard,
      editResponse,
      allRespondersData,
      responderContactEditMode,
      InspectionResponderId,
      responderPhoneDetailLoading,
      responderPhoneData,
      paginationPhoneNumber,
      isModalOpen,
      allInspectionResponders,
      phoneId,
    },
    dispatch: {
      setEditInspectionCompaniesActiveKey,
      setEditContact,
      setPressKeyboard,
      setEditResponse,
      setResponderContactEditMode,
      setInspectionResponderId,
      setIsModalOpen,
      setPhoneId,
    },
    func: { GetAllInspectionResponders, getResponderPhoneDetail },
    form: {
      form,
      editBasicInformationForm,
      editContactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  return (
    <EditInspectionCompaniesContext.Provider value={ContextValue}>
      {children}
    </EditInspectionCompaniesContext.Provider>
  );
};

export const useEditInspectionCompanies = () =>
  useContext(EditInspectionCompaniesContext);
