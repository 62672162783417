import { FC } from "react";
import { Header } from "./components/header";
import OrderManagementDetails from "./components/orderManagementDetail";
import { ManagementOrderContent } from "./components/managementOrder";

export const OrderManagement: FC = () => {
  return (
    <div>
      <Header />
      <OrderManagementDetails />
      <ManagementOrderContent />
    </div>
  );
};
