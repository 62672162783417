import { Col, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ContactsTableContainer } from "../../style";
import { useSuppliers } from "src/modules/suppliers/context/context";
import { ResponderTableData } from "src/modules/suppliers/models";

import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const RespondentContactTable: FC = () => {
  const {
    dispatch: {
      setResponderEditMode,
      setSupplierResponderId,
      setResponderPhoneData,
      setPaginationResponder,
    },
    value: { allRespondersData, paginationResponder, allSupplierResponders },
    form: { respondentNameForm, RCInformationForm },
    func: { GetAllSupplierResponders },
  } = useSuppliers()!;
  const { words } = useLanguage();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<number>();

  useEffect(() => {
    GetAllSupplierResponders();
  }, [GetAllSupplierResponders]);

  const deleteResponder = async (id: number) => {
    try {
      const { DeleteSupplierResponder } = new BaseInfoService();
      const result = await DeleteSupplierResponder(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.suppliers.deleteSupplierResponderMessage
        );
        RCInformationForm?.resetFields();
        setResponderPhoneData([]);
        GetAllSupplierResponders();
        setResponderEditMode(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columns: ColumnsType<ResponderTableData> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.responsive,
      dataIndex: "responderName",
      key: "responderName",
      width: 233,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setResponderEditMode(true);
              respondentNameForm?.setFieldValue(
                "fullName",
                record.responderName
              );
              setSupplierResponderId(record.id);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <DPopconfirm
            deleteClassName
            onConfirm={async () => {
              await deleteResponder(record.id);
            }}
            description={words.global.deletePhoneNumberPopConfirm}
            open={openDeleteConfirm === record.id}
            onCancel={() => {
              setOpenDeleteConfirm(undefined);
            }}
          >
            <span
              className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]"
              onClick={() => {
                setOpenDeleteConfirm(record.id);
              }}
            >
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];
  return (
    <ContactsTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={allRespondersData}
          loading={allSupplierResponders}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            total: paginationResponder.total,
            current: paginationResponder.current,
            pageSize: paginationResponder.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationResponder((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </ContactsTableContainer>
  );
};
