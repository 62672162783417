import { FC } from "react";

import DashboardDataProvider from "./context";
import Typography from "antd/es/typography/Typography";

interface IProps {}

const Home: FC<IProps> = () => {
  return (
    <DashboardDataProvider>
      <Typography className="flex justify-center text-[40px]">
        به SDA خوش آمدید
      </Typography>
    </DashboardDataProvider>
  );
};
export default Home;
