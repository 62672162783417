import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { App, Form } from "antd";
import { FormInstance } from "antd/lib";
import { IAddNewInspectionCompaniesTabs } from "../components/AddNewInspectionCompanies/models";
import {
  IInspectionCompaniesTabs,
  ResponderTableData,
  TableData,
} from "../models/models";
import { ITablePagination } from "src/models/interfaces/pagination";
import {
  IAllInspectionResponderDetailsResult,
  IInspectionResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";
import { IAddInspection } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";
import useLanguage from "src/store/language";

interface IContext {
  value: {
    inspectionCompaniesActiveKey: string;
    addNewInspectionCompaniesActiveKey: string;
    editContact: boolean;
    isModalOpen: boolean;
    tableRecord: string;
    pressKeyboard: boolean;
    dataSource: TableData[];
    loading: boolean;
    paginationInspection: ITablePagination;
    paginationResponder: ITablePagination;
    paginationPhoneNumber: ITablePagination;
    paginationInspectionDetail: ITablePagination;
    inspectionId: number;
    allRespondersData: ResponderTableData[];
    responderEditMode: boolean;
    responderContactEditMode: boolean;
    inspectionResponderId: number | undefined;
    isAddPhoneNumber: boolean;
    addInspectionLoading: boolean;
    allInspectionResponders: boolean;
    inspectionDetailLoading: boolean;
    responderPhoneDetailLoading: boolean;
    responderPhoneData: IInspectionResponderDetailsByIdResult[];
    inspectionDetailData: IAllInspectionResponderDetailsResult[];
    basicInfoEditMode: boolean;
    responderPhoneId: number | undefined;
  };
  dispatch: {
    setInspectionCompaniesActiveKey: Dispatch<SetStateAction<string>>;
    setAddNewInspectionCompaniesActiveKey: Dispatch<SetStateAction<string>>;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;
    setTableRecord: Dispatch<SetStateAction<string>>;
    setPaginationInspection: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationResponder: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationPhoneNumber: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationInspectionDetail: Dispatch<SetStateAction<ITablePagination>>;
    setInspectionResponderId: Dispatch<SetStateAction<number | undefined>>;
    setResponderPhoneData: Dispatch<
      SetStateAction<IInspectionResponderDetailsByIdResult[]>
    >;
    setBasicInfoEditMode: Dispatch<SetStateAction<boolean>>;
    setResponderContactEditMode: Dispatch<SetStateAction<boolean>>;
    setResponderEditMode: Dispatch<SetStateAction<boolean>>;
    setIsAddPhoneNumber: Dispatch<SetStateAction<boolean>>;
    setResponderPhoneId: Dispatch<SetStateAction<number | undefined>>;
  };
  func: {
    getTableData: () => void;
    onFinishAddInspection: (values: IAddInspection) => void;
    GetAllInspectionResponders: () => void;
    getResponderPhoneDetail: (id: number) => void;
    GetAllInspectionResponderDetails: (InspectionId: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
    basicInformationForm: FormInstance<any> | undefined;
    contactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const InspectionCompaniesContext = createContext<IContext | undefined>(
  undefined
);

export const InspectionCompaniesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const [inspectionCompaniesActiveKey, setInspectionCompaniesActiveKey] =
    useState<string>(IInspectionCompaniesTabs.tableOfInspectionCompanies);
  const [
    addNewInspectionCompaniesActiveKey,
    setAddNewInspectionCompaniesActiveKey,
  ] = useState<string>(IAddNewInspectionCompaniesTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tableRecord, setTableRecord] = useState<string>("");
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [responderEditMode, setResponderEditMode] = useState<boolean>(false);
  const [responderContactEditMode, setResponderContactEditMode] =
    useState<boolean>(false);
  const [allInspectionResponders, setAllInspectionResponders] =
    useState<boolean>(false);
  const [addInspectionLoading, setAddInspectionLoading] =
    useState<boolean>(false);
  const [responderPhoneDetailLoading, setResponderPhoneDetailLoading] =
    useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [paginationInspection, setPaginationInspection] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [paginationResponder, setPaginationResponder] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationPhoneNumber, setPaginationPhoneNumber] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationInspectionDetail, setPaginationInspectionDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [inspectionId, setInspectionId] = useState<number>(-1);
  const [allRespondersData, setAllRespondersData] = useState<
    ResponderTableData[]
  >([]);
  const [inspectionResponderId, setInspectionResponderId] = useState<number>();
  const [responderPhoneData, setResponderPhoneData] = useState<
    IInspectionResponderDetailsByIdResult[]
  >([]);
  const [isAddPhoneNumber, setIsAddPhoneNumber] = useState<boolean>(false);
  const [inspectionDetailLoading, setInspectionDetailLoading] =
    useState<boolean>(false);
  const [inspectionDetailData, setInspectionDetailData] = useState<
    IAllInspectionResponderDetailsResult[]
  >([]);
  const [basicInfoEditMode, setBasicInfoEditMode] = useState<boolean>(false);
  const [responderPhoneId, setResponderPhoneId] = useState<number>();

  const [form] = Form.useForm();
  const [basicInformationForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();

  ////////////////////////////functions////////////////////////////////////////////////
  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      const { GetAllInspection } = new BaseInfoService();

      const params: IObject = {
        Offset: paginationInspection.current,
        Limit: paginationInspection.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllInspection(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPaginationInspection((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInspection.current, paginationInspection.pageSize]);

  const onFinishAddInspection = async (values: IAddInspection) => {
    setAddInspectionLoading(true);
    try {
      const { AddInspection } = new BaseInfoService();
      const result = await AddInspection(values);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.inspectionCompanies.addInspectionMessage
        );
        setAddNewInspectionCompaniesActiveKey(
          IAddNewInspectionCompaniesTabs.Contacts
        );
        setInspectionId(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddInspectionLoading(false);
    }
  };

  const GetAllInspectionResponders = useCallback(async () => {
    setAllInspectionResponders(true);
    try {
      const { GetAllInspectionResponders: GetAllInspectionR } =
        new BaseInfoService();
      const params: IObject = {
        Offset: paginationResponder.current,
        Limit: paginationResponder.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllInspectionR(
        searchParams.toString(),
        inspectionId
      );
      if (result && result.status === 200 && result.data) {
        setAllRespondersData(result.data.records);
        setPaginationResponder((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllInspectionResponders(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationResponder.current, paginationResponder.pageSize, inspectionId]);

  const getResponderPhoneDetail = useCallback(
    async (id: number) => {
      setResponderPhoneDetailLoading(true);
      try {
        const { GetInspectionResponderDetailsById } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationPhoneNumber.current,
          Limit: paginationPhoneNumber.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetInspectionResponderDetailsById(
          searchParams.toString(),
          id
        );
        if (result && result.status === 200) {
          setResponderPhoneData(result.data.records);
          setPaginationPhoneNumber((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }

        if (result && result?.data.count > 0) {
          setIsAddPhoneNumber(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setResponderPhoneDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationPhoneNumber.current, paginationPhoneNumber.pageSize]
  );

  const GetAllInspectionResponderDetails = useCallback(
    async (InspectionId: number) => {
      if (!(InspectionId > 0)) return;
      setInspectionDetailLoading(true);
      try {
        const { GetAllInspectionResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationInspectionDetail.current,
          Limit: paginationInspectionDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllInspectionResponderDetails(
          InspectionId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setInspectionDetailData(result.data.records);
          setPaginationInspectionDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setInspectionDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationInspectionDetail.current, paginationInspectionDetail.pageSize]
  );
  const ContextValue: IContext = {
    value: {
      inspectionCompaniesActiveKey,
      addNewInspectionCompaniesActiveKey,
      editContact,
      isModalOpen,
      tableRecord,
      pressKeyboard,
      dataSource,
      loading,
      paginationPhoneNumber,
      paginationResponder,
      paginationInspection,
      inspectionId,
      allRespondersData,
      inspectionResponderId,
      isAddPhoneNumber,
      addInspectionLoading,
      allInspectionResponders,
      responderPhoneDetailLoading,
      responderPhoneData,
      responderContactEditMode,
      inspectionDetailData,
      paginationInspectionDetail,
      inspectionDetailLoading,
      basicInfoEditMode,
      responderEditMode,
      responderPhoneId,
    },
    dispatch: {
      setInspectionCompaniesActiveKey,
      setAddNewInspectionCompaniesActiveKey,
      setEditContact,
      setIsModalOpen,
      setTableRecord,
      setPressKeyboard,
      setPaginationPhoneNumber,
      setPaginationResponder,
      setResponderPhoneData,
      setBasicInfoEditMode,
      setPaginationInspection,
      setPaginationInspectionDetail,
      setInspectionResponderId,
      setResponderContactEditMode,
      setResponderEditMode,
      setResponderPhoneId,
      setIsAddPhoneNumber,
    },
    func: {
      getTableData,
      onFinishAddInspection,
      GetAllInspectionResponders,
      getResponderPhoneDetail,
      GetAllInspectionResponderDetails,
    },
    form: {
      form,
      basicInformationForm,
      contactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  return (
    <InspectionCompaniesContext.Provider value={ContextValue}>
      {children}
    </InspectionCompaniesContext.Provider>
  );
};

export const useInspectionCompanies = () =>
  useContext(InspectionCompaniesContext);
