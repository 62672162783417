import { Col, Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ITablePagination } from "src/models/interfaces/pagination";
import { RAndCNumberTableContainer } from "./style";
import { useLocation } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";
import {
  IAllWarehouseResponderDetailsResult,
  IAllWarehouseResult,
} from "src/services/BaseInfo/models/result.models";

export const ResponderAndContactNumberTable: FC = () => {
  const location = useLocation();
  const values: IAllWarehouseResult | undefined = useMemo(
    () => location.state,
    [location.state]
  );
  const { words } = useLanguage();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [paginationWarehouseDetail, setPaginationWarehouseDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [warehouseDetailData, setWarehouseDetailData] = useState<
    IAllWarehouseResponderDetailsResult[]
  >([]);

  const GetAllWarehouseResponderDetails = useCallback(
    async (warehouseId: number) => {
      if (!(warehouseId > 0)) return;
      setTableLoading(true);
      try {
        const { GetAllWarehouseResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationWarehouseDetail.current,
          Limit: paginationWarehouseDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllWarehouseResponderDetails(
          warehouseId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setWarehouseDetailData(result.data.records);
          setPaginationWarehouseDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTableLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationWarehouseDetail.current, paginationWarehouseDetail.pageSize]
  );
  useEffect(() => {
    console.log({ values });
    if (values?.id) {
      GetAllWarehouseResponderDetails(values.id);
    }
  }, [GetAllWarehouseResponderDetails, values]);

  const columns: ColumnsType<IAllWarehouseResponderDetailsResult> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 47,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.responsive,
      dataIndex: "responderName",
      key: "responderName",
      width: 111,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.PhoneNumber,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 431,
      align: "center",
      className: "!font-bold",
      render: (text: string[]) => (
        <Flex gap={16}>
          {text.map((item, index) => (
            <div key={index} className="whitespace-nowrap !font-[vazir]">
              {item}
            </div>
          ))}
        </Flex>
      ),
    },
  ];
  return (
    <RAndCNumberTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={warehouseDetailData}
          loading={tableLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: paginationWarehouseDetail.total,
            current: paginationWarehouseDetail.current,
            pageSize: paginationWarehouseDetail.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationWarehouseDetail((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </RAndCNumberTableContainer>
  );
};
