import { ReactNode } from "react";
export enum IClearanceWorkersTabs {
  tableOfClearanceWorkers = "1",
  addNewClearanceWorkers = "2",
}
export enum IAddNewClearanceWorkersTabs {
  basicInformation = "1",
  Contacts = "2",
}
export enum IEditClearanceWorkersTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface IEditContactsTable {
  id: number;
  // name: string;
  phoneNumber: number;
}
export interface IContactsTable {
  id: number;
  // name: string;
  phoneNumber: number;
}
export interface TableDataNamePhoneNumber extends IContactsTable {
  tools?: ReactNode;
}
export interface IPhoneNumber {
  name: string;
  phoneNumber: string;
  id: number;
}
export interface IContactPhoneNumber {
  phoneNumber: number;
}
export interface IGetClearanceWorkers {
  id: number;
  workClearanceName: string;
  workPlace: string;
  phoneNumber: IPhoneNumber[];
}
export interface TableData extends IGetClearanceWorkers {
  tools?: ReactNode;
}

export const dataSource: TableData[] = [
  {
    id: 1,
    workClearanceName: "Jafari",
    workPlace: "Amirabad port",
    phoneNumber: [
      {
        id: 1,
        name: "سید مهدی موسوی اسلام آبادی",
        phoneNumber: "09115697466",
      },
      { id: 2, name: "حامد نظری ", phoneNumber: "09115697466" },
      { id: 3, name: "حمید بهروزی ", phoneNumber: "09115697466" },
      { id: 4, name: "حمید بهروزی ", phoneNumber: "09115697466" },
    ],
  },
  {
    id: 2,
    workClearanceName: "Parsian insurance",
    workPlace: "Tehran, Waliasr St., above Mirdamad ",
    phoneNumber: [{ id: 1, name: "Hamid behruzi", phoneNumber: "09115697466" }],
  },
  {
    id: 3,
    workClearanceName: "Asan tarabar",
    workPlace: "Mazandaran - Amirabad Behshahr",
    phoneNumber: [
      { id: 1, name: "Hamid behruzi", phoneNumber: "09115697466" },
      {
        id: 2,
        name: "Seyed mehdi moosavy eslam abadi",
        phoneNumber: "09115697466",
      },
      {
        id: 3,
        name: "Seyed mehdi moosavy eslam abadi",
        phoneNumber: "09115697466",
      },
      {
        id: 4,
        name: "Seyed mehdi moosavy eslam abadi",
        phoneNumber: "09115697466",
      },
    ],
  },
];
export const dataSourceNamePhoneNumber: IEditContactsTable[] = [
  {
    id: 0,
    // name: "حمید بهروزی",
    phoneNumber: 9170916009,
  },
  {
    id: 1,
    // name: "سید مهدی موسوی اسلام آبادی",
    phoneNumber: 9123456789,
  },
  {
    id: 2,
    // name: "حامد نظری",
    phoneNumber: 9123456789,
  },
];
