import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { useLocation } from "react-router-dom";
import { useEditClearanceWorkers } from "../../context/context";
import { EditBasicInformationContainer } from "./style";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddCustomBroker } from "src/services/BaseInfo/models/args.models";
import { IAllCustomBrokerResult } from "src/services/BaseInfo/models/result.models";

export const EditBasicInformation: FC = () => {
  const {
    dispatch: { setIsRegister, setBasicInfoEditMode, setPressKeyboard },
    form: { editBasicInformationForm },
    value: { isRegister, pressKeyboard },
  } = useEditClearanceWorkers()!;

  const { words } = useLanguage();
  const { message } = App.useApp();
  const location = useLocation();
  const data: IAllCustomBrokerResult = location.state;

  const [addCustomBrokerLoading, setAddCustomBrokerLoading] =
    useState<boolean>(false);

  const onFinishAddCustomBroker = async (values: IAddCustomBroker) => {
    setAddCustomBrokerLoading(true);
    try {
      const { AddCustomBroker } = new BaseInfoService();
      const result = await AddCustomBroker(values);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.clearanceWorkers.addCustomBrokerMessage
        );
        setIsRegister(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddCustomBrokerLoading(false);
    }
  };

  const updateBasicInformation = async (values: IAddCustomBroker) => {
    if (!data.id) return console.log("CustomBrokerId is undefined");
    setAddCustomBrokerLoading(true);
    try {
      const { UpdateCustomBroker } = new BaseInfoService();
      const result = await UpdateCustomBroker(values, data.id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.clearanceWorkers.editCustomBrokerMessage
        );
        setBasicInfoEditMode(false);
        setIsRegister(true);
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddCustomBrokerLoading(false);
    }
  };

  useEffect(() => {
    editBasicInformationForm?.setFieldsValue(location.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
  return (
    <Form
      form={editBasicInformationForm}
      onFinish={
        pressKeyboard ? updateBasicInformation : onFinishAddCustomBroker
      }
    >
      <EditBasicInformationContainer>
        <Col span={24}>
          <Typography>
            {
              words.basicInformation.clearanceWorkers
                .editClearanceWorkersInformation
            }
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                name={"name"}
                label={
                  words.basicInformation.clearanceWorkers.workClearanceName
                }
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  disabled={isRegister}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem
                name={"workPlace"}
                label={words.basicInformation.clearanceWorkers.workPlace}
              >
                <Input
                  placeholder={words.global.insert}
                  disabled={isRegister}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div
            className={`flex gap-[16px] ${
              pressKeyboard ? "justify-between" : "justify-end"
            } borderTop items-center`}
          >
            {pressKeyboard ? (
              <div>
                {
                  words.basicInformation.clearanceWorkers
                    .saveEditClearanceWorkersButton
                }
              </div>
            ) : (
              ""
            )}
            <Button
              className="!min-w[85px]"
              type="primary"
              disabled={!pressKeyboard}
              onClick={editBasicInformationForm?.submit}
              loading={addCustomBrokerLoading}
            >
              {words.global.saveEdit}
            </Button>
          </div>
        </Col>
      </EditBasicInformationContainer>
    </Form>
  );
};
