import { FC, useState } from "react";
import { ViewViewAddOrderContainer } from "../style";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import useLanguage from "src/store/language";
import { Col, Flex, Row, Skeleton } from "antd";

export const ViewAddOrder: FC = () => {
  const { words } = useLanguage();
  const [loading, setLoading] = useState<boolean>(false);

  const loadingMode = (
    <div className="flex flex-col gap-[32px]">
      <div className="mx-[12px]">{words.orders.proformaInfo}</div>
      <Row
        className="w-full bg-[#f2f9f9] py-[24px] px-[16px]"
        gutter={[16, 24]}
      >
        <Col span={6} className="!font-medium">
          {words.orders.proformaNo}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.proformaDate}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.currency}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
        <Col span={6} className="!font-medium">
          {words.orders.bank}
        </Col>
        <Col span={6} className="!font-normal text-[#4B4B4B]">
          <Skeleton.Input className="!h-[22px]" />
        </Col>
      </Row>
    </div>
  );
  return (
    <div>
      <HeaderProforma headerName={words.orders.addOrder.viewAddOrderHeader} />
      <Flex justify="center">
        <ViewViewAddOrderContainer>
          {loading ? (
            loadingMode
          ) : (
            <>
              <div className="px-[12px]">
                {words.orders.addOrder.viewAddOrder}
              </div>
              <Row className="proformaDataHolder" gutter={[16, 24]}>
                <Col span={6} className="!font-medium">
                  {words.orders.addOrder.registrationNumber}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  1234
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.addOrder.issueDate}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  1403.05.10
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.addOrder.validityDate}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  1403.05.10
                </Col>
                <Col span={6} className="!font-medium">
                  {words.orders.addOrder.currencySupply}
                </Col>
                <Col span={6} className="!font-normal text-[#4B4B4B]">
                  تهاتر
                </Col>
              </Row>
            </>
          )}
        </ViewViewAddOrderContainer>
      </Flex>
    </div>
  );
};
