import { Layout } from "antd";
import styled from "styled-components";
const { Content } = Layout;

export const LoginLayoutStyled = styled(Content)`
  background: url(/assets/images/dealra-Login.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100vw;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .language-box {
    margin: 20px 0 0 30px;
  }
`;

export const LoginLayoutOutletStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 40px;
  min-height: 100vh;
`;
