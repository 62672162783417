import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { RespondentContactTable } from "./RespondentContactTable";
import { useEditTransportCompany } from "src/modules/EditTransportCompanies/context/context";
import { RespondentNameContainer } from "../../style";
import { useLocation } from "react-router-dom";
import { IAllTransportResult } from "src/services/BaseInfo/models/result.models";
import { IAddTransportResponder } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const RespondentName: FC = () => {
  const { words } = useLanguage();
  const {
    form: { respondentNameForm },
    value: { editResponse, TransportResponderId },
    dispatch: { setEditResponse },
    func: { GetAllTransportResponders },
  } = useEditTransportCompany()!;

  const location = useLocation();
  const data: IAllTransportResult = location.state;
  const { message } = App.useApp();
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const values = Form.useWatch([], respondentNameForm);
  useEffect(() => {
    respondentNameForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const addResponder = async (values: IAddTransportResponder) => {
    setLoading(true);
    try {
      const { AddTransportResponder } = new BaseInfoService();
      const result = await AddTransportResponder(values, data.id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.transportCompanies.addTransportResponderMessage
        );
        GetAllTransportResponders(data.id);
        respondentNameForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editResponder = async (values: IAddTransportResponder) => {
    if (!TransportResponderId)
      return console.log("TransportResponderId not found");
    setEditLoading(true);
    try {
      const { UpdateTransportResponder } = new BaseInfoService();
      const result = await UpdateTransportResponder(
        values,
        TransportResponderId
      );
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.transportCompanies
            .editTransportResponderMessage
        );
        setEditResponse(false);
        respondentNameForm?.resetFields();
        GetAllTransportResponders(data.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };
  return (
    <Form
      form={respondentNameForm}
      className="h-[100%]"
      onFinish={editResponse ? editResponder : addResponder}
    >
      <RespondentNameContainer>
        <Row>
          <Col span={24}>
            <Typography className="p-[16px]">
              {words.global.editRespondent}
            </Typography>
          </Col>
          <Row className="w-[100%] py-[24px]">
            <Col span={16}>
              <FormItem
                name={"fullName"}
                label={words.global.responsive}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  allowClear
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={8}></Col>
          </Row>
          <Col span={24} className="h-[82px] pb-[24px]">
            <div className="flex justify-end items-center h-[100%] gap-[16px]">
              {editResponse ? (
                <Button
                  type="primary"
                  disabled={!pressKeyboard}
                  onClick={respondentNameForm?.submit}
                  loading={editLoading}
                >
                  {words.global.saveEdit}
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={!submittable}
                  onClick={respondentNameForm?.submit}
                  loading={loading}
                >
                  {words.global.add}
                </Button>
              )}
              {editResponse ? (
                <Button
                  disabled={!submittable}
                  onClick={() => {
                    setEditResponse(false);
                    setPressKeyboard(false);
                    respondentNameForm?.resetFields();
                  }}
                >
                  {words.global.cancel}
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Divider className="pb-[24px]" />
          <Col span={24}>
            <Typography className="pb-[24px]">
              {words.global.respondentContactTable}
            </Typography>
          </Col>
          <Col span={24}>
            <RespondentContactTable />
          </Col>
        </Row>
      </RespondentNameContainer>
    </Form>
  );
};
