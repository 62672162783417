import { InputProps } from "antd";
import { FC } from "react";
import { InputStyle } from "./styles";
import { omit } from "lodash";

interface IProps extends InputProps {
  numbermode?: boolean;
}
export const DInput: FC<IProps> = (props) => {
  return (
    <InputStyle
      {...omit(props, "numbermode")}
      placeholder={props.placeholder ? props.placeholder : "insert"}
      onBeforeInput={
        props.numbermode
          ? (event: any) => {
              if (
                /[0-9]/.test(event.data) ||
                event.data === "." ||
                event.data === "-"
              ) {
                if (
                  (event.data === "." && event.target.value.includes(".")) ||
                  (event.data === "-" && event.target.value.includes("-"))
                )
                  event.preventDefault();
              } else event.preventDefault();
            }
          : undefined
      }
    />
  );
};
