import { FC } from "react";
import { Header } from "./components/Header";
import { AddNewUnitsOfMeasurement } from "./components/AddNewProduct";
import { UnitsOfMeasurementTable } from "./components/ProductsTable";
import { UnitsOfMeasurementContainer } from "./style/style";

export const UnitsOfMeasurement: FC = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-center">
        <UnitsOfMeasurementContainer>
          <AddNewUnitsOfMeasurement />
          <UnitsOfMeasurementTable />
        </UnitsOfMeasurementContainer>
      </div>
    </div>
  );
};
