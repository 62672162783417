import styled from "styled-components";

export const LayoutContainer = styled.div<{ path: string }>`
  .ant-menu-horizontal {
    border: unset;
  }

  .ant-layout-content {
    height: calc(100vh - 83px);
    /* width: 100vw; */
    overflow-y: auto;
    background-color: ${(props) => props.theme.colors.background1} !important;

    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-layout-header {
    background-color: ${(props) => props.theme.colors.secondary[50]};
    line-height: unset;
    padding-inline: ${(props) => (props.path === "/" ? "0" : "24px")};
    margin-top: 37px;
  }
  .ant-layout-sider {
    background: #fff;
    height: 100vh;
    overflow-y: auto;
  }
  overflow-y: auto;
`;
