import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import useLanguage from "src/store/language";
import { IWarehousesTabs } from "./models";
import { useWarehouses } from "./context";
import { IAddNewWarehousesTabs } from "./components/AddNewWarehouses/models";
import { WarehousesTable } from "./components/WarehouseTable/WarehousesTable";
import { AddNewWarehouses } from "./components/AddNewWarehouses";
import { WarehousesContainer } from "./style/style";

export const Warehouses: FC = () => {
  const {
    value: { warehousesActiveKey },
    dispatch: {
      setWarehousesActiveKey,
      setAddNewWarehousesActiveKey,
      setEditContact,
    },
    form: { basicInformationForm, contactForm },
  } = useWarehouses()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: IWarehousesTabs.tableOfWarehouses,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">table_chart</span>
          {words.basicInformation.warehouses.warehousesTable}
        </span>
      ),
      children: <WarehousesTable />,
    },
    {
      key: IWarehousesTabs.addNewWarehouses,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">add_box</span>
          {words.basicInformation.warehouses.addNewWarehouses}
        </span>
      ),
      children: <AddNewWarehouses />,
    },
  ];

  const onChange = (key: string) => {
    setWarehousesActiveKey(key);
    setAddNewWarehousesActiveKey(IAddNewWarehousesTabs.basicInformation);
    contactForm?.resetFields();
    basicInformationForm?.resetFields();
    setEditContact(false);
  };
  return (
    <WarehousesContainer>
      <Tabs items={items} onChange={onChange} activeKey={warehousesActiveKey} />
    </WarehousesContainer>
  );
};
