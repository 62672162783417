import styled from "styled-components";
import { Layout } from "antd";
const { Sider } = Layout;

export const SidebarContainer = styled(Sider)<{ $isRtl: boolean }>`
  transition: all 0.3s;
  z-index: 100;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: -4px 0px 5px 0px rgba(0, 140, 132, 0.1);
  /* .ant-layout-sider-children {
    position: fixed;
    width: ${({ width }) => width};
    padding: 24px;
  } */
  /* .ant-layout-sider-children {
    overflow-y: auto;
  } */
  .ant-menu-submenu-title {
    padding-inline: 8px !important;
  }
  .ant-menu-submenu-arrow {
    color: ${({ theme }) => theme.colors.gray[500]};
  }
  .ant-layout-sider-trigger {
    /* transform: translateX(${({ collapsed }) =>
      collapsed ? "0px" : "24px"}); */
    top: 50%;
    width: 32px !important;
    height: 32px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    transition: 0.2s all;
    align-items: center;
    box-shadow: -4px 0px 5px 0px rgba(0, 140, 132, 0.1);

    right: ${({ $isRtl, collapsed, width }) =>
      $isRtl
        ? collapsed
          ? "64px"
          : (((width as number) || 0) - 16).toString().concat("px")
        : "unset"};
    left: ${({ $isRtl, collapsed, width }) =>
      $isRtl
        ? "unset"
        : collapsed
        ? "64px"
        : (((width as number) || 0) - 16).toString().concat("px")};
    svg {
      fill: ${({ theme }) => theme.colors.primary[600]};
    }
    background-color: ${({ theme }) => theme.colors.primary[100]};
  }
  .sidebar {
    width: 100%;
  }
  .sideBarLogoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 7px;
    min-height: 129px;
  }
  .ant-menu-sub {
    background: ${({ theme }) => theme.colors.white} !important;
    li {
      padding-inline: 8px !important;
    }
  }
  /* .menu {
    li {
      margin-top: 20px;
    }
    height: calc(100vh - 76px - 40px - 45px - 50px);
  } */
  .ant-menu-title-content {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.32px;
  }
  .ant-menu-item {
    padding-inline: 8px !important;
  }
  .ant-menu-item:not(.ant-menu-item-selected) {
    .ant-menu-title-content * {
      color: ${({ theme }) => theme.colors.gray[400]};
    }
    .ant-menu-item-icon {
      color: ${({ theme }) => theme.colors.gray[400]} !important;
    }
  }
  .ant-menu-submenu-title {
    color: ${({ theme }) => theme.colors.gray[500]};
  }
  .ant-menu-item-selected {
    /* background-color: #2c73fc; */
    .ant-menu-title-content {
      /* color: ${({ theme }) => theme.colors.white}; */
    }
    .ant-menu-item-icon {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
  .ant-menu-root {
    border: unset !important;
  }
  .ant-menu .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.primary[600]} !important;
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title[aria-expanded="true"] {
    color: ${({ theme }) => theme.colors.selected} !important;

    .ant-menu-item-icon {
      color: ${({ theme }) => theme.colors.selected} !important;
    }
    .ant-menu-submenu-arrow {
      color: ${({ theme }) => theme.colors.selected};
    }
  }
  /* .ant-menu-light > .ant-menu.ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.selected};
  } */
  .ant-menu-submenu-selected > .ant-menu-submenu-title[aria-expanded="true"] {
    background-color: ${({ theme }) => theme.colors.primary[600]} !important;
  }
  /* .ant-menu-item .ant-menu-item-only-child {
    background-color: ${({ theme }) => theme.colors.selected} !important;
  } */
  .ant-menu-submenu-selected {
    .ant-menu-item-selected {
      background-color: ${({ theme }) => theme.colors.selected} !important;
      color: ${({ theme }) => theme.colors.primary[600]} !important;
    }
  }
`;
