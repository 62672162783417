import { App, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRowKey } from "src/helper/getRowKey";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { ROUTES } from "src/models/enums/routes";
import { ClearanceWorkersTableContainer } from "./style";
import { useClearanceWorkers } from "../../context/context";
import { ContactNumberModal } from "./ContactNumberModal";
import { IAllCustomBrokerResult } from "src/services/BaseInfo/models/result.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const ClearanceWorkersTable: FC = () => {
  const { words } = useLanguage();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const {
    dispatch: { setIsModalOpen, setTableRecord, setPaginationCustomBroker },
    func: { getTableData, GetAllCustomBrokerResponderDetails },
    value: { loading, dataSource, paginationCustomBroker },
  } = useClearanceWorkers()!;

  const onClickResponderAndContactNumber = (id: number) => {
    setIsModalOpen(true);
    GetAllCustomBrokerResponderDetails(id);
  };

  const onClickDelete = async (id: number) => {
    try {
      const { DeleteCustomBroker } = new BaseInfoService();
      const result = await DeleteCustomBroker(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.clearanceWorkers.deleteCustomBrokerMessage
        );
        getTableData();
      }
    } catch (err) {
    } finally {
    }
  };
  useEffect(() => {
    getTableData();
  }, [getTableData]);
  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<IAllCustomBrokerResult> = [
    {
      title: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.clearanceWorkers.workClearanceName,
      dataIndex: "name",
      key: "name",
      width: 170,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.basicInformation.clearanceWorkers.workPlace,
      dataIndex: "workPlace",
      key: "workPlace",
      width: 97,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.global.responderAndContactNumber,
      key: "responderAndContactNumber",
      width: 166,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => (
        <span
          className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center"
          onClick={() => {
            onClickResponderAndContactNumber(record.id);
            setTableRecord(record.name);
          }}
        >
          contact_phone
        </span>
      ),
    },
    {
      title: words.global.tools,
      key: "tools",
      width: 112,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              navigate(ROUTES.editClearanceWorkers, { state: record });
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <span
            onClick={() => {
              console.log({ record });
              navigate(ROUTES.viewClearanceWorkers, {
                state: record,
              });
            }}
            className="material-icons !text-[#02514C] text-[16px] cursor-pointer"
          >
            visibility
          </span>
          <DPopconfirm
            deleteClassName
            onConfirm={async () => {
              await onClickDelete(record.id);
            }}
            description={
              words.basicInformation.clearanceWorkers.deleteClearanceWorkers
            }
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];
  return (
    <ClearanceWorkersTableContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: "auto" }}
        rowKey={getRowKey}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: paginationCustomBroker.total,
          current: paginationCustomBroker.current,
          pageSize: paginationCustomBroker.pageSize,
          onChange: (page: number, pageSize) => {
            setPaginationCustomBroker((prev) => ({
              ...prev,
              current: page,
              pageSize,
            }));
          },
        }}
      />
      <ContactNumberModal />
    </ClearanceWorkersTableContainer>
  );
};
