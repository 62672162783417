import { Card, Col, Row, Spin } from "antd";
import { FC, useState } from "react";
import useLanguage from "src/store/language";
import { useManagement } from "../hook/managementHook";
import { ManagementOrderContentContainer } from "../style";
import { useNavigate } from "react-router-dom";

export const ManagementOrderContent: FC = () => {
  const { words } = useLanguage();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { orderInformationManagement } = useManagement();

  return (
    <ManagementOrderContentContainer className="mt-[10px] px-[24px]">
      <Col span={24} className="mt-[20px]">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <div className="cardTitleHolder">
              <div className="text-[#FFFFFF]">
                {words.orders.orderCardTitle}
              </div>
            </div>
            <div className="cardBody">
              {dataLoading ? (
                <div className="w-full flex items-center justify-center">
                  <Spin size="large" />
                </div>
              ) : (
                <Row gutter={[16, 24]}>
                  {orderInformationManagement.map((i) => (
                    <Col span={4} key={i.id}>
                      <Card
                        bordered={false}
                        // className={`card ${
                        //   tourManagement?.flags.includes(i.flag) &&
                        //   "active-border"
                        // }`}
                      >
                        <div className="flex justify-center gap-[8px] items-center border-0 border-b-[1px] border-solid border-[#E1E1E1] pb-[12px]">
                          <span className="text-[11px] text-[#1F1F1F] font-bold">
                            {i.name}
                          </span>
                        </div>
                        <div className="flex justify-evenly items-center pt-[12px] ">
                          <div
                            className="text-[10px] text-[#3B82F6] cursor-pointer"
                            onClick={() => {
                              navigate(i.endPointView);
                            }}
                          >
                            {words.orders.view}
                          </div>
                          <div className="border border-solid border-[#E1E1E1] !w-[1px] !h-[18px]"></div>
                          <div
                            className="text-[10px] font-normal text-[#0C9D61] cursor-pointer"
                            onClick={() => {
                              navigate(i.endPointAdd);
                            }}
                          >
                            {words.orders.edit}
                          </div>
                        </div>
                        <div className="flex justify-center items-center pt-[12px] px[8px]">
                          {/* <div className="text-[10px] font-normal text-[#007E77] cursor-pointer">
                            {words.orders.add}
                          </div> */}
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </ManagementOrderContentContainer>
  );
};
