import { App, Col, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ITablePagination } from "src/models/interfaces/pagination";
import { ContactsTableContainer } from "../../style";
import { useEditInspectionCompanies } from "src/modules/EditInspectionCompanies/context/context";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { ResponderPhoneTableData } from "src/modules/EditInspectionCompanies/models/models";

export const RespondentContactTable: FC = () => {
  const {
    dispatch: { setResponderContactEditMode, setPhoneId },
    form: { RCInformationForm },
    value: {
      InspectionResponderId,
      responderPhoneDetailLoading,
      responderPhoneData,
    },
    func: { getResponderPhoneDetail },
  } = useEditInspectionCompanies()!;
  const { words } = useLanguage();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const { message } = App.useApp();
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  useEffect(() => {
    if (InspectionResponderId) getResponderPhoneDetail(InspectionResponderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResponderPhoneDetail]);

  const onDeleteResponderPhone = async (
    InspectionPhoneId: number,
    responderId: number
  ) => {
    try {
      setDeleteLoading(true);
      const { DeleteInspectionPhone } = new BaseInfoService();
      const res = await DeleteInspectionPhone(InspectionPhoneId);
      if (res && res.status === 200) {
        message.success(
          words.basicInformation.inspectionCompanies
            .deleteInspectionPhoneMessage
        );
        getResponderPhoneDetail(responderId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setOpenDeleteConfirm(undefined);
    }
  };
  const columns: ColumnsType<ResponderPhoneTableData> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.PhoneNumber,
      dataIndex: "phone",
      key: "phone",
      width: 233,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setResponderContactEditMode(true);
              RCInformationForm?.setFieldsValue({
                responder: record.responderId,
                phoneNumber: record.phone,
              });
              setPhoneId(record.id);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <DPopconfirm
            deleteClassName
            onConfirm={() => {
              onDeleteResponderPhone(record.id, record.responderId);
            }}
            description={words.global.deletePhoneNumberPopConfirm}
            okButtonProps={{
              loading: deleteLoading,
            }}
            open={openDeleteConfirm === record.id}
            onCancel={() => {
              setOpenDeleteConfirm(undefined);
            }}
          >
            <span
              className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]"
              onClick={() => {
                setOpenDeleteConfirm(record.id);
              }}
            >
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];
  return (
    <ContactsTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={responderPhoneData}
          loading={responderPhoneDetailLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            total: pagination.total,
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPagination((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </ContactsTableContainer>
  );
};
