import axios from "axios";
import { OrderUrl } from "./urls";
import { IDataModel } from "../models";
import {
  IGetAllProforma,
  IGetOrigin,
  IGetOriginFile,
  IGetProform,
} from "./models/result.models";
import { IAddOrigin, IAddProform, INewOriginFile } from "./models/args.models";

export class OrderService {
  ////////////proforma-service////////////////////////////////////////////////////////////////
  async GetAllProforma(queries?: string) {
    let endPoint: string = OrderUrl.GetAllProform;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IGetAllProforma[]>>(endPoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteProform(id: number) {
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${id}${OrderUrl.DeleteProform}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddProform(data: IAddProform) {
    try {
      const response = await axios.post<void>(OrderUrl.AddProform, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetProform(id: number) {
    try {
      const response = await axios.get<IGetProform>(
        OrderUrl.Order.concat(`${id}${OrderUrl.GetProform}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateProform(id: number, data: IAddProform) {
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${id}${OrderUrl.UpdateProform}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////origin-service////////////////////////////////////////////////////////////////
  async AddOrigin(data: IAddOrigin, ProformId: number) {
    try {
      const response = await axios.post<number>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.AddOrigin}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewOriginFile(data: INewOriginFile, OriginId: number) {
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${OriginId}${OrderUrl.NewOriginFile}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetOriginFile(OriginId: number) {
    try {
      const response = await axios.get<IGetOriginFile[]>(
        OrderUrl.Order.concat(`${OriginId}${OrderUrl.GetOriginFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetOrigin(ProformId: number) {
    try {
      const response = await axios.get<IGetOrigin>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.GetOrigin}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateOrigin(data: IAddOrigin, ProformId: number) {
    try {
      const response = await axios.put<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.UpdateOrigin}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateOriginFile(data: INewOriginFile, OriginId: number) {
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${OriginId}${OrderUrl.UpdateOriginFile}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteOrigin(ProformId: number) {
    try {
      const response = await axios.delete<void>(
        OrderUrl.Order.concat(`${ProformId}${OrderUrl.DeleteOrigin}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteOriginFile(OriginId: number) {
    try {
      const response = await axios.patch<void>(
        OrderUrl.Order.concat(`${OriginId}${OrderUrl.DeleteOriginFile}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
