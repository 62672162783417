import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import useLanguage from "src/store/language";
import { useInspectionCompanies } from "./context";
import { InspectionCompaniesContainer } from "./styles";
import { InspectionCompaniesTable } from "./components/InspectionCompaniesTable";
import { AddNewInspectionCompanies } from "./components/AddNewInspectionCompanies";
import { IAddNewInspectionCompaniesTabs } from "./components/AddNewInspectionCompanies/models";
import { IInspectionCompaniesTabs } from "./models/models";

export const InspectionCompanies: FC = () => {
  const {
    value: { inspectionCompaniesActiveKey },
    dispatch: {
      setInspectionCompaniesActiveKey,
      setAddNewInspectionCompaniesActiveKey,
      setEditContact,
    },
    form: { basicInformationForm, contactForm },
  } = useInspectionCompanies()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: IInspectionCompaniesTabs.tableOfInspectionCompanies,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">table_chart</span>
          {words.basicInformation.inspectionCompanies.inspectionCompaniesTable}
        </span>
      ),
      children: <InspectionCompaniesTable />,
    },
    {
      key: IInspectionCompaniesTabs.addNewInspectionCompanies,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">add_box</span>
          {words.basicInformation.inspectionCompanies.addNewInspectionCompany}
        </span>
      ),
      children: <AddNewInspectionCompanies />,
    },
  ];

  const onChange = (key: string) => {
    setInspectionCompaniesActiveKey(key);
    setAddNewInspectionCompaniesActiveKey(
      IAddNewInspectionCompaniesTabs.basicInformation
    );
    contactForm?.resetFields();
    basicInformationForm?.resetFields();
    setEditContact(false);
  };
  return (
    <InspectionCompaniesContainer>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={inspectionCompaniesActiveKey}
      />
    </InspectionCompaniesContainer>
  );
};
