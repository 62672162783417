import { Row } from "antd";
import { styled } from "styled-components";

export const EditContactContainer = styled(Row)`
  display: flex;
  padding: 24px;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--color-Light-Mode-Border-4, #e1e1e1);
`;

export const ContactsTableContainer = styled(Row)`
  display: flex;
  gap: 24px;
  width: 100%;
`;
export const RespondentNameContainer = styled(Row)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--color-Light-Mode-Border-4, #e1e1e1);
`;
export const RCInformationContainer = styled(Row)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--color-Light-Mode-Border-4, #e1e1e1);
`;
