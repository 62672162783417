import { Button, Col, Divider } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useClearanceWorkers } from "../../context/context";
import { AddNewClearanceWorkerContainer } from "./style";
import { BasicInformation } from "./components/BasicInformaton/BasicInformation";
import { Contact } from "./components/ContactInfo";
import { IClearanceWorkersTabs } from "./models";

export const AddNewClearanceWorker: FC = () => {
  const { words } = useLanguage();
  const {
    dispatch: {
      setEndOfRegister,
      setClearanceWorkersActiveKey,
      setIsRegister,
      setCustomBrokerId,
      setEditContact,
      setCustomBrokerPhoneData,
    },
    form: { basicInformationForm, contactForm },
    value: { endOfRegister },
    func: { getTableData },
  } = useClearanceWorkers()!;

  return (
    <AddNewClearanceWorkerContainer>
      <Col span={24}>
        <BasicInformation />
      </Col>
      <Col span={24}>
        <Contact />
      </Col>
      <Divider />
      <Col span={24}>
        <div className="flex justify-end">
          <Button
            className="!min-w[85px]"
            type="primary"
            onClick={() => {
              setEndOfRegister(false);
              basicInformationForm?.resetFields();
              contactForm?.resetFields();
              setIsRegister(false);
              setCustomBrokerId(undefined);
              setEditContact(false);
              setCustomBrokerPhoneData([]);
              setClearanceWorkersActiveKey(
                IClearanceWorkersTabs.tableOfClearanceWorkers
              );
              getTableData();
            }}
          >
            {words.global.endOfRegistration}
          </Button>
        </div>
      </Col>
    </AddNewClearanceWorkerContainer>
  );
};
