import { Col, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import useLanguage from "src/store/language";
import { TableData } from "../models";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import { PortTableContainer } from "../style";
import { getRowKey } from "src/helper/getRowKey";
import { usePort } from "../context";
import { useSearchParams } from "react-router-dom";
import { IAllPortResult } from "src/services/BaseInfo/models/result.models";
import { Guard } from "src/components/Guard";
import {
  DeletePortPath,
  UpdatePortPath,
} from "src/services/BaseInfo/guardPath";

export const PortsTable: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    dispatch: { setEditMode, setPagination, setProductId },
    value: { dataSource, pagination, productLoading },
    func: { deletePort, getPortTableData },
  } = usePort()!;
  const onChangeParams = useChangeParams();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.current.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);
  useEffect(() => {
    getPortTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns: ColumnsType<TableData> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "56px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.ports.portName,
      dataIndex: "portName",
      key: "portName",
      align: "center",
    },
    {
      title: words.global.tools,
      key: "id",
      align: "center",
      width: "152px",
      render: (text, record: IAllPortResult) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdatePortPath}>
            <span
              onClick={() => {
                setEditMode(true);
                form?.setFieldsValue(record);
                setProductId(record.id);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <Guard action={DeletePortPath}>
            <DPopconfirm
              deleteClassName
              onConfirm={() => deletePort(record.id)}
              description={words.basicInformation.ports.deletePort}
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <PortTableContainer>
      <Col span={24}>
        <Typography className="py-[16px]">
          {words.basicInformation.ports.portTable}
        </Typography>
      </Col>
      <Col span={24}>
        <div className="tableHolder">
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={productLoading}
            scroll={{ x: "auto" }}
            rowKey={getRowKey}
            className="w-[100%]"
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: pagination.total,
              current: pagination.current,
              onChange: (page: number, pageSize: number) => {
                setPagination((prev) => ({ ...prev, current: page, pageSize }));
                onChangeParams("Limit", pageSize.toString());
                onChangeParams("Offset", page.toString());
              },
              onShowSizeChange: (current: number, size: number) => {
                onChangeParams("Limit", size.toString());
                onChangeParams("Offset", current.toString());
                setPagination((prev) => ({ ...prev, current }));
              },
            }}
          />
        </div>
      </Col>
    </PortTableContainer>
  );
};
