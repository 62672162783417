import { FC } from "react";
import { Col, Divider, Row, Typography } from "antd";
import useLanguage from "src/store/language";
import { useLocation } from "react-router-dom";
import { HeaderViewOperatingBank } from "./header";
import { ViewOperatingBankContainer } from "./style";
import { ResponderAndContactNumberTable } from "./table";
import { IViewData } from "../../models";
const { Title, Text } = Typography;

export const ViewOperatingBank: FC = () => {
  const { words } = useLanguage();
  const location = useLocation();
  const values: IViewData = location.state;

  return (
    <div>
      <HeaderViewOperatingBank />
      <Divider />
      <div className="flex !justify-center">
        <ViewOperatingBankContainer>
          <div className="w-full">
            <Col span={24} className="px-[24px]">
              <Row>
                <Col span={24}>
                  <Title level={4} className="pb-[8px]">
                    {words.basicInformation.operatingBanks.bankInformation}
                  </Title>
                </Col>
                <Col span={24}>
                  <div className="borderViewItems ">
                    <div className="flex pb-[16px]">
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {words.basicInformation.operatingBanks.bankName}:
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Text>{values?.record?.bankName}</Text>
                          </Col>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {words.basicInformation.operatingBanks.branchCode}
                              :
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Text>{values?.record?.branchCode}</Text>
                          </Col>
                        </div>
                      </Col>
                    </div>
                    <div className="flex pb-[16px]">
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {words.basicInformation.operatingBanks.branchName}
                              :
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Text>{values?.record?.branchName}</Text>
                          </Col>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {words.basicInformation.operatingBanks.address}:
                            </Title>
                          </Col>
                          <Col span={20}>
                            <Text>{values?.record?.address}</Text>
                          </Col>
                        </div>
                      </Col>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="px-[24px]">
              <Title level={4} className="px-[12px] pt-[32px] pb-[24px]">
                {words.global.Contacts}
              </Title>
              <ResponderAndContactNumberTable />
            </Col>
          </div>
        </ViewOperatingBankContainer>
      </div>
    </div>
  );
};
