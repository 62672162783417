import { FC } from "react";
import { Suppliers } from "src/modules/suppliers";
import { SuppliersProvider } from "src/modules/suppliers/context/context";

export const SuppliersPages: FC = () => {
  return (
    <SuppliersProvider>
      <Suppliers />
    </SuppliersProvider>
  );
};
