import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { App, Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  IAddNewOperatingBanksTabs,
  IOperatingBanksTabs,
  ResponderTableData,
  TableData,
} from "../models";
import { ITablePagination } from "src/models/interfaces/pagination";
import {
  IAllBankResponderDetailsResult,
  IAllBankResult,
  IBankResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";
import { IAddBank } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";
import useLanguage from "src/store/language";

interface IContext {
  value: {
    OperatingBanksActiveKey: string;
    addNewOperatingBanksActiveKey: string;
    editContact: boolean;
    isModalOpen: boolean;
    tableRecord: IAllBankResult | undefined;
    pressKeyboard: boolean;
    dataSource: TableData[];
    loading: boolean;
    paginationBank: ITablePagination;
    paginationResponder: ITablePagination;
    paginationPhoneNumber: ITablePagination;
    paginationBankDetail: ITablePagination;
    BankId: number;
    allRespondersData: ResponderTableData[];
    responderEditMode: boolean;
    responderContactEditMode: boolean;
    BankResponderId: number | undefined;
    isAddPhoneNumber: boolean;
    addBankLoading: boolean;
    allBankResponders: boolean;
    BankDetailLoading: boolean;
    responderPhoneDetailLoading: boolean;
    responderPhoneData: IBankResponderDetailsByIdResult[];
    BankDetailData: IAllBankResponderDetailsResult[];
    basicInfoEditMode: boolean;
    responderPhoneId: number | undefined;
  };
  dispatch: {
    setOperatingBanksActiveKey: Dispatch<SetStateAction<string>>;
    setAddNewOperatingBanksActiveKey: Dispatch<SetStateAction<string>>;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;
    setTableRecord: Dispatch<SetStateAction<IAllBankResult | undefined>>;
    setPaginationBank: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationResponder: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationPhoneNumber: Dispatch<SetStateAction<ITablePagination>>;
    setPaginationBankDetail: Dispatch<SetStateAction<ITablePagination>>;
    setBankResponderId: Dispatch<SetStateAction<number | undefined>>;
    setResponderPhoneData: Dispatch<
      SetStateAction<IBankResponderDetailsByIdResult[]>
    >;
    setBasicInfoEditMode: Dispatch<SetStateAction<boolean>>;
    setResponderContactEditMode: Dispatch<SetStateAction<boolean>>;
    setResponderEditMode: Dispatch<SetStateAction<boolean>>;
    setIsAddPhoneNumber: Dispatch<SetStateAction<boolean>>;
    setResponderPhoneId: Dispatch<SetStateAction<number | undefined>>;
  };
  func: {
    getTableData: () => void;
    onFinishAddBank: (values: IAddBank) => void;
    GetAllBankResponders: () => void;
    getResponderPhoneDetail: (id: number) => void;
    GetAllBankResponderDetails: (BankId: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
    basicInformationForm: FormInstance<any> | undefined;
    contactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const OperatingBanksContext = createContext<IContext | undefined>(
  undefined
);

export const OperatingBanksProvider: FC<PropsWithChildren> = ({ children }) => {
  const { message } = App.useApp();
  const { words } = useLanguage();
  const [OperatingBanksActiveKey, setOperatingBanksActiveKey] =
    useState<string>(IOperatingBanksTabs.tableOfOperatingBanks);
  const [addNewOperatingBanksActiveKey, setAddNewOperatingBanksActiveKey] =
    useState<string>(IAddNewOperatingBanksTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tableRecord, setTableRecord] = useState<IAllBankResult | undefined>();
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [responderEditMode, setResponderEditMode] = useState<boolean>(false);
  const [responderContactEditMode, setResponderContactEditMode] =
    useState<boolean>(false);
  const [allBankResponders, setAllBankResponders] = useState<boolean>(false);
  const [addBankLoading, setAddBankLoading] = useState<boolean>(false);
  const [responderPhoneDetailLoading, setResponderPhoneDetailLoading] =
    useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [paginationBank, setPaginationBank] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
  });
  const [paginationResponder, setPaginationResponder] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationPhoneNumber, setPaginationPhoneNumber] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [paginationBankDetail, setPaginationBankDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [BankId, setBankId] = useState<number>(-1);
  const [allRespondersData, setAllRespondersData] = useState<
    ResponderTableData[]
  >([]);
  const [BankResponderId, setBankResponderId] = useState<number>();
  const [responderPhoneData, setResponderPhoneData] = useState<
    IBankResponderDetailsByIdResult[]
  >([]);
  const [isAddPhoneNumber, setIsAddPhoneNumber] = useState<boolean>(false);
  const [BankDetailLoading, setBankDetailLoading] = useState<boolean>(false);
  const [BankDetailData, setBankDetailData] = useState<
    IAllBankResponderDetailsResult[]
  >([]);
  const [basicInfoEditMode, setBasicInfoEditMode] = useState<boolean>(false);
  const [responderPhoneId, setResponderPhoneId] = useState<number>();

  const [form] = Form.useForm();
  const [basicInformationForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();

  ////////////////////////////functions////////////////////////////////////////////////
  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      const { GetAllBank } = new BaseInfoService();

      const params: IObject = {
        Offset: paginationBank.current,
        Limit: paginationBank.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllBank(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPaginationBank((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationBank.current, paginationBank.pageSize]);

  const onFinishAddBank = async (values: IAddBank) => {
    setAddBankLoading(true);
    try {
      const { AddBank } = new BaseInfoService();
      const result = await AddBank(values);
      if (result && result.status === 200) {
        message.success(words.basicInformation.operatingBanks.addBankMessage);
        setAddNewOperatingBanksActiveKey(IAddNewOperatingBanksTabs.Contacts);

        setBankId(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddBankLoading(false);
    }
  };

  const GetAllBankResponders = useCallback(async () => {
    setAllBankResponders(true);
    try {
      const { GetAllBankResponders: GetAllBankR } = new BaseInfoService();
      const params: IObject = {
        Offset: paginationResponder.current,
        Limit: paginationResponder.pageSize,
      };
      const searchParams = new URLSearchParams(params);
      const result = await GetAllBankR(searchParams.toString(), BankId);
      if (result && result.status === 200 && result.data) {
        setAllRespondersData(result.data.records);
        setPaginationResponder((prev) => ({
          ...prev,
          total: result.data.count,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAllBankResponders(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationResponder.current, paginationResponder.pageSize, BankId]);

  const getResponderPhoneDetail = useCallback(
    async (id: number) => {
      setResponderPhoneDetailLoading(true);
      try {
        const { GetBankPhonesByResponderId } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationPhoneNumber.current,
          Limit: paginationPhoneNumber.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetBankPhonesByResponderId(
          searchParams.toString(),
          id
        );
        if (result && result.status === 200) {
          setResponderPhoneData(result.data.records);
          setPaginationPhoneNumber((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }

        if (result && result?.data.count > 0) {
          setIsAddPhoneNumber(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setResponderPhoneDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationPhoneNumber.current, paginationPhoneNumber.pageSize]
  );

  const GetAllBankResponderDetails = useCallback(
    async (BankId: number) => {
      if (!(BankId > 0)) return;
      setBankDetailLoading(true);
      try {
        const { GetAllBankResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationBankDetail.current,
          Limit: paginationBankDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllBankResponderDetails(
          BankId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setBankDetailData(result.data.records);
          setPaginationBankDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setBankDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationBankDetail.current, paginationBankDetail.pageSize]
  );
  const ContextValue: IContext = {
    value: {
      OperatingBanksActiveKey,
      addNewOperatingBanksActiveKey,
      editContact,
      isModalOpen,
      tableRecord,
      pressKeyboard,
      dataSource,
      loading,
      paginationPhoneNumber,
      paginationResponder,
      paginationBank,
      BankId,
      allRespondersData,
      BankResponderId,
      isAddPhoneNumber,
      addBankLoading,
      allBankResponders,
      responderPhoneDetailLoading,
      responderPhoneData,
      responderContactEditMode,
      BankDetailData,
      paginationBankDetail,
      BankDetailLoading,
      basicInfoEditMode,
      responderEditMode,
      responderPhoneId,
    },
    dispatch: {
      setOperatingBanksActiveKey,
      setAddNewOperatingBanksActiveKey,
      setEditContact,
      setIsModalOpen,
      setTableRecord,
      setPressKeyboard,
      setPaginationPhoneNumber,
      setPaginationResponder,
      setResponderPhoneData,
      setBasicInfoEditMode,
      setPaginationBank,
      setPaginationBankDetail,
      setBankResponderId,
      setResponderContactEditMode,
      setResponderEditMode,
      setResponderPhoneId,
      setIsAddPhoneNumber,
    },
    func: {
      getTableData,
      onFinishAddBank,
      GetAllBankResponders,
      getResponderPhoneDetail,
      GetAllBankResponderDetails,
    },
    form: {
      form,
      basicInformationForm,
      contactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  return (
    <OperatingBanksContext.Provider value={ContextValue}>
      {children}
    </OperatingBanksContext.Provider>
  );
};

export const useOperatingBanks = () => useContext(OperatingBanksContext);
