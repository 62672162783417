import { FC } from "react";
import { EditTransportCompanies } from "src/modules/EditTransportCompanies";
import { EditTransportCompanyProvider } from "src/modules/EditTransportCompanies/context/context";

export const EditTransportCompanyPage: FC = () => {
  return (
    <EditTransportCompanyProvider>
      <EditTransportCompanies />
    </EditTransportCompanyProvider>
  );
};
