import { Col, Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useCallback, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useLocation } from "react-router-dom";
import { RAndCNumberTableContainer } from "./style";
import { IViewData, InspectionDetailTableData } from "../../models/models";
import { IAllInspectionResponderDetailsResult } from "src/services/BaseInfo/models/result.models";
import { IObject } from "src/models/interfaces";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const ResponderAndContactNumberTable: FC = () => {
  const location = useLocation();
  const values: IViewData = location.state;
  const { words } = useLanguage();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [paginationSupplierDetail, setPaginationSupplierDetail] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [supplierDetailData, setSupplierDetailData] = useState<
    IAllInspectionResponderDetailsResult[]
  >([]);

  const GetAllInspectionResponderDetails = useCallback(
    async (supplierId: number) => {
      if (!(supplierId > 0)) return;
      setTableLoading(true);
      try {
        const { GetAllInspectionResponderDetails } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationSupplierDetail.current,
          Limit: paginationSupplierDetail.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllInspectionResponderDetails(
          supplierId,
          searchParams.toString()
        );
        if (result && result.status === 200 && result.data) {
          setSupplierDetailData(result.data.records);
          setPaginationSupplierDetail((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTableLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationSupplierDetail.current, paginationSupplierDetail.pageSize]
  );
  useEffect(() => {
    if (values?.record?.id) {
      GetAllInspectionResponderDetails(values?.record?.id);
    }
  }, [GetAllInspectionResponderDetails, values?.record?.id]);

  const columns: ColumnsType<InspectionDetailTableData> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 47,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.responsive,
      dataIndex: "responderName",
      key: "responderName",
      width: 111,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.PhoneNumber,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 431,
      align: "center",
      className: "!font-bold",
      render: (text: string[]) => (
        <Flex gap={16} justify="center">
          {text.map((item, index) => (
            <div key={index} className="whitespace-nowrap !font-[vazir]">
              {item}
            </div>
          ))}
        </Flex>
      ),
    },
  ];
  return (
    <RAndCNumberTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={supplierDetailData}
          loading={tableLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            total: paginationSupplierDetail.total,
            current: paginationSupplierDetail.current,
            pageSize: paginationSupplierDetail.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationSupplierDetail((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </RAndCNumberTableContainer>
  );
};
