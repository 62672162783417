import { FC } from "react";
import { CreateRoleContainer } from "./styles";
import { Button, Col, Form, Input, Row, TreeSelect, Typography } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { useCreateRoleContext } from "./context";
import { DInput } from "src/components/UiKit/DInput";
import { ConfirmModal } from "src/components/UiKit/ConfirmModal";
import useLanguage from "src/store/language";

export const CreateRole: FC = () => {
  const {
    onFinish,
    onCancelConfirm,
    onConfirm,
    showConfirm,
    treeProps,
    confirmLoading,
    createRoleForm,
  } = useCreateRoleContext();
  const { words } = useLanguage();
  const { Title } = Typography;
  return (
    <>
      <Form onFinish={onFinish} form={createRoleForm}>
        <CreateRoleContainer justify={"center"} gutter={[0, 24]}>
          <Col span={24} className="header">
            <Title>{words.systemSetting.createRole.createRole}</Title>
          </Col>
          <Col span={15} className="content">
            <Row gutter={[24, 0]}>
              <Col span={24} className="mb-[40px]">
                <Typography className="title">
                  {words.systemSetting.createRole.roleInformation}
                </Typography>
              </Col>
              <Col span={12}>
                <FormItem
                  name={"name"}
                  label={words.systemSetting.createRole.rollName}
                  required
                  rules={[{ required: true }]}
                >
                  <DInput placeholder={words.global.insert} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={words.systemSetting.createRole.seniorRole}
                  name={"parentId"}
                >
                  <TreeSelect
                    {...treeProps}
                    multiple={false}
                    allowClear
                    className="selectOption"
                    placeholder={words.global.select}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label={words.global.description}
                  required
                  name={"comment"}
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 15, maxRows: 15 }}
                    className="s-textarea py-[16px] px-[12px]"
                    placeholder={words.systemSetting.createRole.roleDescription}
                  />
                </FormItem>
              </Col>
              <Col span={24} className="flex justify-end gap-[24px] footer">
                <Button type="primary" htmlType="submit">
                  {words.global.create}
                </Button>
              </Col>
            </Row>
          </Col>
        </CreateRoleContainer>
      </Form>
      <ConfirmModal
        open={showConfirm}
        onCancel={onCancelConfirm}
        onOk={onConfirm}
        text={words.systemSetting.createRole.modalTextMessage}
        title={words.systemSetting.createRole.createRole}
        confirmIcon="add_circle"
        okText={words.global.create}
        confirmLoading={confirmLoading}
      />
    </>
  );
};
