import { FC } from "react";
import { Currencies } from "src/modules/Currencies";
import { CurrenciesProvider } from "src/modules/Currencies/context/context";

export const CurrenciesPage: FC = () => {
  return (
    <CurrenciesProvider>
      <Currencies />
    </CurrenciesProvider>
  );
};
