import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { IGetPorts, TableData } from "../models";
import { portsMockData } from "../constants/portMackData";
import { Form, FormInstance, message } from "antd";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import useLanguage from "src/store/language";

interface IContextPort {
  value: {
    tableData: IGetPorts[];
    editMode: boolean;
    dataSource: TableData[];
    pagination: ITablePagination;
    productLoading: boolean;
    productId: number;
  };
  dispatch: {
    setTableData: Dispatch<SetStateAction<IGetPorts[]>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setProductId: Dispatch<SetStateAction<number>>;
  };
  func: {
    getPortTableData: () => void;
    deletePort: (id: number) => void;
  };
  form: { form: FormInstance | undefined };
}

const defaultCtxValue: IContextPort = {
  value: {
    tableData: [],
    editMode: false,
    dataSource: [],
    pagination: { current: 0, pageSize: 0, total: 0 },
    productId: -1,
    productLoading: false,
  },
  dispatch: {
    setTableData: () => {},
    setEditMode: () => {},
    setPagination: () => {},
    setProductId: () => {},
  },
  func: { deletePort: (id: number) => {}, getPortTableData: () => {} },
  form: { form: undefined },
};

export const PortContext = createContext<IContextPort>(defaultCtxValue);

export const PortProvider: FC<PropsWithChildren> = ({ children }) => {
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const [tableData, setTableData] = useState<IGetPorts[]>(portsMockData);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [productId, setProductId] = useState<number>(-1);
  ///////////Functions////////////////////////////////////////////////////////////////
  const getPortTableData = async () => {
    setProductLoading(true);
    try {
      const { GetAllPort } = new BaseInfoService();
      const result = await GetAllPort(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };

  const deletePort = async (id: number) => {
    setProductLoading(true);
    try {
      const { DeletePort } = new BaseInfoService();
      const result = await DeletePort(id);
      if (result && result.status === 200) {
        message.success(words.basicInformation.ports.deletePortMessage);
        getPortTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };
  const contextValue: IContextPort = {
    value: {
      tableData,
      editMode,
      dataSource,
      pagination,
      productId,
      productLoading,
    },
    dispatch: { setTableData, setEditMode, setPagination, setProductId },
    func: { deletePort, getPortTableData },
    form: { form },
  };
  return (
    <PortContext.Provider value={contextValue}>{children}</PortContext.Provider>
  );
};

export const usePort = () => useContext(PortContext);
