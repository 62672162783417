import { FC } from "react";
import { TransportCompanies } from "src/modules/TransportCompanies";
import { TransportCompaniesProvider } from "src/modules/TransportCompanies/context";

export const TransportCompaniesPage: FC = () => {
  return (
    <TransportCompaniesProvider>
      <TransportCompanies />
    </TransportCompaniesProvider>
  );
};
