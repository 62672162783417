import { Navigate, NonIndexRouteObject, RouteObject } from "react-router-dom";
import {
  AddOrderPages,
  AssignActionToRolePage,
  AssignRoleToUserPage,
  AveragePricePage,
  ClearanceWorkersPage,
  CountriesPage,
  CreateRolePage,
  CreateUserPage,
  CurrenciesPage,
  DesignSystemPage,
  EditClearanceWorkersPage,
  EditInspectionCompaniesPage,
  EditInsuranceCompaniesPage,
  EditOperatingBankPage,
  EditSupplierPage,
  EditTransportCompanyPage,
  EditWarehousesPage,
  HomePage,
  InspectionCompaniesPages,
  InsuranceCompaniesPages,
  OperatingBanksPage,
  OrderManagementPages,
  OrdersPages,
  OriginPages,
  PortsPage,
  ProductsPage,
  SuppliersPages,
  TransportCompaniesPage,
  UnitsOfMeasurementPage,
  VesselsPage,
  ViewAddOrderPages,
  ViewClearanceWorkersPage,
  ViewInspectionCompaniesPages,
  ViewInsuranceCompaniesPage,
  ViewOperatingBankPage,
  ViewOrderPages,
  ViewSuppliersPage,
  ViewTransportCompaniesPage,
  ViewWarehousesPage,
  VipPages,
  WarehousesPage,
} from "src/pages/private";
import { ROUTES } from "../../models/enums/routes";
import { AdminPanelLayout } from "src/templates/mainLayout";
import useRoutesAccessStore from "src/store/routesAccess";
import { ViewOriginPages } from "src/pages/private/ViewOrigin";
import { ViewVipPages } from "src/pages/private/ViewVip";
import { routesKeys } from "src/constants/routesKeys";

interface IRouteObject extends NonIndexRouteObject {
  access: boolean | undefined;
}
export const usePrivateRoutes = () => {
  const { routes } = useRoutesAccessStore();
  const orderId = `/:${routesKeys.orderId}`;

  const privateRoutes: RouteObject[] = [
    {
      path: ROUTES.home,
      element: <AdminPanelLayout />,
    },
  ];

  const childrenRoutes: RouteObject[] = [
    {
      path: ROUTES.home,
      element: <HomePage />,
    },
    {
      path: ROUTES.login,
      element: <Navigate to={ROUTES.home} />,
    },
    {
      path: ROUTES.home,
      element: <HomePage />,
    },
    {
      path: ROUTES.design,
      element: <DesignSystemPage />,
    },
    {
      path: ROUTES.insuranceCompanies,
      element: <InsuranceCompaniesPages />,
    },
    {
      path: ROUTES.viewInsuranceCompanies,
      element: <ViewInsuranceCompaniesPage />,
    },
    {
      path: ROUTES.editInsuranceCompanies,
      element: <EditInsuranceCompaniesPage />,
    },
    {
      path: ROUTES.transportCompanies,
      element: <TransportCompaniesPage />,
    },
    {
      path: ROUTES.viewTransportCompanies,
      element: <ViewTransportCompaniesPage />,
    },
    {
      path: ROUTES.editTransportCompany,
      element: <EditTransportCompanyPage />,
    },
    {
      path: ROUTES.operatingBanks,
      element: <OperatingBanksPage />,
    },
    {
      path: ROUTES.viewOperatingBank,
      element: <ViewOperatingBankPage />,
    },
    {
      path: ROUTES.editOperatingBank,
      element: <EditOperatingBankPage />,
    },
    {
      path: ROUTES.clearanceWorkers,
      element: <ClearanceWorkersPage />,
    },
    {
      path: ROUTES.viewClearanceWorkers,
      element: <ViewClearanceWorkersPage />,
    },
    {
      path: ROUTES.editClearanceWorkers,
      element: <EditClearanceWorkersPage />,
    },
    {
      path: ROUTES.warehouses,
      element: <WarehousesPage />,
    },
    {
      path: ROUTES.viewWarehouses,
      element: <ViewWarehousesPage />,
    },
    {
      path: ROUTES.editWarehouses,
      element: <EditWarehousesPage />,
    },
  ];
  const childRouteWithAccess: IRouteObject[] = [
    {
      path: ROUTES.products,
      element: <ProductsPage />,
      access: routes.products,
    },
    {
      path: ROUTES.ports,
      element: <PortsPage />,
      access: routes.ports,
    },
    {
      path: ROUTES.suppliers,
      element: <SuppliersPages />,
      access: routes.suppliers,
    },
    {
      path: ROUTES.editSupplier,
      element: <EditSupplierPage />,
      access: routes.editSupplier,
    },
    {
      path: ROUTES.viewSuppliers,
      element: <ViewSuppliersPage />,
      access: routes.viewSuppliers,
    },
    {
      path: ROUTES.countries,
      element: <CountriesPage />,
      access: routes.countries,
    },
    {
      path: ROUTES.averagePrice,
      element: <AveragePricePage />,
      access: routes.averagePrice,
    },
    {
      path: ROUTES.unitsOfMeasurement,
      element: <UnitsOfMeasurementPage />,
      access: routes.unitsOfMeasurement,
    },
    {
      path: ROUTES.currencies,
      element: <CurrenciesPage />,
      access: routes.currencies,
    },
    {
      path: ROUTES.vessels,
      element: <VesselsPage />,
      access: routes.vessels,
    },
    {
      path: ROUTES.inspectionCompanies,
      element: <InspectionCompaniesPages />,
      access: routes.inspectionCompanies,
    },
    {
      path: ROUTES.viewInspectionCompanies,
      element: <ViewInspectionCompaniesPages />,
      access: routes.viewInspectionCompanies,
    },
    {
      path: ROUTES.editInspectionCompanies,
      element: <EditInspectionCompaniesPage />,
      access: routes.editInspectionCompanies,
    },
    {
      path: ROUTES.CreateRole,
      element: <CreateRolePage />,
      access: routes.CreateRole,
    },
    {
      path: ROUTES.AssignActionToRole,
      element: <AssignActionToRolePage />,
      access: routes.AssignActionToRole,
    },
    {
      path: ROUTES.CreateUser,
      element: <CreateUserPage />,
      access: routes.CreateUser,
    },
    {
      path: ROUTES.AssignRoleToUser,
      element: <AssignRoleToUserPage />,
      access: routes.AssignRoleToUser,
    },
    {
      path: ROUTES.orders,
      element: <OrdersPages />,
      access: routes.orders,
    },
    {
      path: ROUTES.orders.concat(orderId),
      element: <OrdersPages />,
      access: routes.orders,
    },
    {
      path: ROUTES.orderManagement.concat(orderId),
      element: <OrderManagementPages />,
      access: routes.orderManagement,
    },
    {
      path: ROUTES.ViewOrder.concat(orderId),
      element: <ViewOrderPages />,
      access: routes.ViewOrder,
    },
    {
      path: ROUTES.origin.concat(orderId),
      element: <OriginPages />,
      access: routes.origin,
    },
    {
      path: ROUTES.viewOrigin.concat(orderId),
      element: <ViewOriginPages />,
      access: routes.viewOrigin,
    },
    {
      path: ROUTES.vip.concat(orderId),
      element: <VipPages />,
      access: routes.vip,
    },
    {
      path: ROUTES.viewVip.concat(orderId),
      element: <ViewVipPages />,
      access: routes.viewVip,
    },
    {
      path: ROUTES.addOrder.concat(orderId),
      element: <AddOrderPages />,
      access: routes.addOrder,
    },
    {
      path: ROUTES.viewAddOrder.concat(orderId),
      element: <ViewAddOrderPages />,
      access: routes.viewAddOrder,
    },
  ];
  childRouteWithAccess.forEach((route) => {
    if (route.access) {
      childrenRoutes.push(route);
    }
  });
  privateRoutes[0].children = childrenRoutes;
  return privateRoutes;
};
