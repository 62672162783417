import { FC } from "react";
import { EditOperatingBank } from "src/modules/EditOperatingBanks";
import { EditOperatingBanksProvider } from "src/modules/EditOperatingBanks/context/context";

export const EditOperatingBankPage: FC = () => {
  return (
    <EditOperatingBanksProvider>
      <EditOperatingBank />
    </EditOperatingBanksProvider>
  );
};
