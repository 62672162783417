import { Col, Divider } from "antd";
import { FC } from "react";
import { HeaderEditClearanceWorkers } from "./header";
import { EditClearanceWorkersContainer } from "./style/style";
import { EditBasicInformation } from "./components/BasicInformation/BasicInformation";
import { EditContact } from "./components/ContactInfo/Contact";

export const EditClearanceWorkers: FC = () => {
  return (
    <div>
      <HeaderEditClearanceWorkers />
      <Divider />
      <div className="flex justify-center items-center">
        <EditClearanceWorkersContainer>
          <Col span={24}>
            <EditBasicInformation />
          </Col>
          <Col span={24}>
            <EditContact />
          </Col>
          <Divider />
        </EditClearanceWorkersContainer>
      </div>
    </div>
  );
};
