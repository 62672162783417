import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { IAllBankResult } from "src/services/BaseInfo/models/result.models";
import useLanguage from "src/store/language";
import { useEditOperatingBanks } from "./context/context";
import { ConfirmModal } from "./confirmModal";
export const HeaderEditOperatingBank: FC = () => {
  const { words } = useLanguage();
  const {
    value: {
      editContactInfo,
      editResponse,
      responderContactEditMode,
      pressKeyboard,
    },
    dispatch: { setIsModalOpen },
  } = useEditOperatingBanks()!;
  const navigate = useNavigate();
  const location = useLocation();
  const values: IAllBankResult = location.state;
  return (
    <div>
      <div className="flex justify-between items-center pt-[19px] px-[24px] ">
        <div className="flex flex-col items-end">
          <div className="text-[#044440] text-[18px] ">
            {words.basicInformation.operatingBanks.editOperatingBank}
          </div>
          <div className="text-[#4B4B4B] text-[14px] flex justify-start w-full">
            {values?.bankName}
          </div>
        </div>
        <div
          className="flex flex-row gap-[12px] items-center cursor-pointer"
          onClick={() => {
            if (
              editContactInfo ||
              editResponse ||
              responderContactEditMode ||
              pressKeyboard
            ) {
              setIsModalOpen(true);
            } else {
              navigate(ROUTES.operatingBanks);
            }
          }}
        >
          <div className=" text-[#008C84] text-[24px] ">
            {words.global.backToTable}
          </div>
          <span className="material-icons text-[#008C84] text-[32px]">
            keyboard_backspace
          </span>
        </div>
      </div>
      <div>
        <ConfirmModal />
      </div>
    </div>
  );
};
