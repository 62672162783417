import { Button, Col, Divider, Form, Row, Typography, message } from "antd";
import { FC, useState } from "react";
import useLanguage from "src/store/language";
import { AddNewCurrenciesContainer } from "../style/style";
import { FormItem } from "src/components/UiKit/FormItem";
import { DInput } from "src/components/UiKit/DInput";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useCurrencies } from "../context/context";
import { IAddCurrency } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const AddNewCurrencies: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    value: { editMode, currencyId },
    dispatch: { setEditMode },
    func: { getCurrencyTableData },
  } = useCurrencies()!;
  const [loading, setLoading] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);

  const onFinishAddCurrency = async (values: any) => {
    setLoading(true);
    try {
      const { AddCurrency } = new BaseInfoService();
      const result = await AddCurrency(values);
      if (result && result.status === 200) {
        message.success(words.basicInformation.currencies.addCurrenciesMessage);
        getCurrencyTableData();
        form?.resetFields();
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onFinishUpdateCurrency = async (values: IAddCurrency) => {
    setLoading(true);
    try {
      const { UpdateCurrency } = new BaseInfoService();
      const result = await UpdateCurrency(values, currencyId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.currencies.updateCurrenciesMessage
        );
        getCurrencyTableData();
        form?.resetFields();
        setPressKeyboard(false);
        setEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form
      form={form}
      onFinish={(values) => {
        editMode ? onFinishUpdateCurrency(values) : onFinishAddCurrency(values);
      }}
    >
      <AddNewCurrenciesContainer>
        <Col span={24}>
          <Typography>
            {words.basicInformation.currencies.currenciesInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24} className="CurrenciesHolder">
            <Col span={8}>
              <FormItem
                name={"name"}
                label={words.basicInformation.currencies.currenciesName}
                rules={[{ required: true }]}
              >
                <DInput
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"shortName"}
                label={words.basicInformation.currencies.abbreviationName}
                rules={[{ required: true }]}
              >
                <DInput
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <div className="flex gap-[16px] justify-end h-[100%] items-center">
                {editMode ? (
                  <Button
                    className="!min-w[85px]"
                    type="primary"
                    disabled={!pressKeyboard}
                    onClick={form?.submit}
                    loading={loading}
                  >
                    {words.global.saveEdit}
                  </Button>
                ) : (
                  <Button
                    className="!min-w[85px]"
                    type="primary"
                    disabled={!pressKeyboard}
                    onClick={form?.submit}
                    loading={loading}
                  >
                    {words.global.add}
                  </Button>
                )}

                {editMode ? (
                  <Button
                    type="default"
                    className="!min-w[85px]"
                    onClick={() => {
                      setEditMode(false);
                      form?.resetFields();
                      setPressKeyboard(false);
                    }}
                  >
                    {words.global.cancel}
                  </Button>
                ) : (
                  <DPopconfirm
                    description={words.global.descriptionPopConfirm}
                    onConfirm={() => {
                      setPressKeyboard(false);
                      form?.resetFields();
                    }}
                  >
                    <Button
                      type="default"
                      className="!min-w[85px]"
                      disabled={!pressKeyboard}
                    >
                      {words.global.reset}
                    </Button>
                  </DPopconfirm>
                )}
              </div>
            </Col>
            <Divider />
          </Row>
        </Col>
      </AddNewCurrenciesContainer>
    </Form>
  );
};
