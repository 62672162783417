import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { RespondentNameContainer } from "../../style";
import { FormItem } from "src/components/UiKit/FormItem";
import { RespondentContactTable } from "./RespondentContactTable";
import { useOperatingBanks } from "src/modules/OperatingBanks/context";
import { IAddBankResponder } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const RespondentName: FC = () => {
  const { words } = useLanguage();
  const { message } = App.useApp();

  const {
    form: { respondentNameForm },
    value: { BankId, responderEditMode, BankResponderId },
    func: { GetAllBankResponders },
    dispatch: { setResponderEditMode },
  } = useOperatingBanks()!;

  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], respondentNameForm);
  useEffect(() => {
    respondentNameForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const addResponder = async (values: IAddBankResponder) => {
    setLoading(true);
    try {
      const { AddBankResponder } = new BaseInfoService();
      const result = await AddBankResponder(values, BankId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.operatingBanks.addBankResponderMessage
        );
        GetAllBankResponders();
        respondentNameForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editResponder = async (values: IAddBankResponder) => {
    if (!BankResponderId) return console.log("BankResponderId not found");
    setEditLoading(true);
    try {
      const { UpdateBankResponder } = new BaseInfoService();
      const result = await UpdateBankResponder(values, BankResponderId);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.operatingBanks.editBankResponderMessage
        );
        setResponderEditMode(false);
        respondentNameForm?.resetFields();
        GetAllBankResponders();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };
  return (
    <Form
      form={respondentNameForm}
      className="h-[100%]"
      onFinish={responderEditMode ? editResponder : addResponder}
    >
      <RespondentNameContainer>
        <Row>
          <Col span={24}>
            <Typography className="p-[16px]">
              {words.global.respondentName}
            </Typography>
          </Col>
          <Row className="w-[100%] py-[24px]">
            <Col span={16}>
              <FormItem
                name={"fullName"}
                label={words.global.responsive}
                rules={[{ required: true }]}
              >
                <Input placeholder={words.global.insert} allowClear />
              </FormItem>
            </Col>
            <Col span={8}>
              <div className="flex justify-end items-center h-[100%]">
                <Button
                  type="primary"
                  ghost
                  disabled={!submittable}
                  onClick={respondentNameForm?.submit}
                  loading={responderEditMode ? editLoading : loading}
                >
                  {responderEditMode ? words.global.saveEdit : words.global.add}
                </Button>
              </div>
            </Col>
          </Row>
          <Col span={24} className="h-[82px] pb-[24px]"></Col>
          <Divider className="pb-[24px]" />
          <Col span={24}>
            <Typography className="pb-[24px]">
              {words.global.respondentContactTable}
            </Typography>
          </Col>
          <Col span={24}>
            <RespondentContactTable />
          </Col>
        </Row>
      </RespondentNameContainer>
    </Form>
  );
};
