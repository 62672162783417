import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { useOperatingBanks } from "src/modules/OperatingBanks/context";
import { BasicInformationContainer } from "./style";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddBank } from "src/services/BaseInfo/models/args.models";
import { IAddNewOperatingBanksTabs } from "src/modules/OperatingBanks/models";

export const BasicInformation: FC = () => {
  const {
    dispatch: {
      setPressKeyboard,
      setAddNewOperatingBanksActiveKey,
      setBasicInfoEditMode,
    },
    form: { basicInformationForm },
    value: { pressKeyboard, addBankLoading, BankId, basicInfoEditMode },
    func: { onFinishAddBank },
  } = useOperatingBanks()!;
  const { words } = useLanguage();
  const { message } = App.useApp();

  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], basicInformationForm);
  useEffect(() => {
    basicInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  const updateBasicInformation = async (values: IAddBank) => {
    try {
      const { UpdateBank } = new BaseInfoService();
      const result = await UpdateBank(values, BankId);
      if (result && result.status === 200) {
        message.success(words.basicInformation.operatingBanks.editBankMessage);
        setAddNewOperatingBanksActiveKey(IAddNewOperatingBanksTabs.Contacts);
        setBasicInfoEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  return (
    <Form
      form={basicInformationForm}
      onFinish={basicInfoEditMode ? updateBasicInformation : onFinishAddBank}
    >
      <BasicInformationContainer>
        <Col span={24}>
          <Typography>
            {words.basicInformation.operatingBanks.operatingBanksInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                name={"bankName"}
                label={words.basicInformation.operatingBanks.bankName}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"branchName"}
                label={words.basicInformation.operatingBanks.branchName}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name={"branchCode"}
                label={words.basicInformation.operatingBanks.branchCode}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name={"address"}
                label={words.basicInformation.operatingBanks.address}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div className="flex gap-[16px] justify-end borderTop">
            <DPopconfirm
              description={words.global.descriptionPopConfirm}
              onConfirm={() => {
                basicInformationForm?.resetFields();
                setPressKeyboard(false);
              }}
            >
              <Button
                type="default"
                className="!min-w[85px]"
                disabled={!pressKeyboard}
              >
                {words.global.reset}
              </Button>
            </DPopconfirm>
            <Button
              className="!min-w[85px]"
              type="primary"
              disabled={!submittable}
              onClick={basicInformationForm?.submit}
              loading={addBankLoading}
            >
              {words.global.confirmationAndNext}
            </Button>
          </div>
        </Col>
      </BasicInformationContainer>
    </Form>
  );
};
