import { App, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRowKey } from "src/helper/getRowKey";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { ROUTES } from "src/models/enums/routes";
import { InsuranceCompanyTableContainer } from "./style";
import { useInsuranceCompanies } from "../../context";
import { ContactNumberModal } from "./ContactNumberModal";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { Guard } from "src/components/Guard";
import {
  AllInsuranceResponderDetailsPath,
  DeleteInsurancePath,
  UpdateInsurancePath,
} from "src/services/BaseInfo/guardPath";
import { TableData } from "../../models/models";

export const InsuranceCompanyTable: FC = () => {
  const { words } = useLanguage();
  const { message } = App.useApp();
  const {
    dispatch: { setIsModalOpen, setPaginationInsurance, setTableRecord },
    func: { getTableData, GetAllInsuranceResponderDetails },
    value: { dataSource, loading, paginationInsurance, insuranceDetailData },
  } = useInsuranceCompanies()!;

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<number>();
  const navigate = useNavigate();
  const onClickResponderAndContactNumber = (id: number) => {
    setIsModalOpen(true);
    GetAllInsuranceResponderDetails(id);
  };
  const onClickDelete = async (id: number) => {
    setDeleteLoading(true);
    try {
      const { DeleteInsurance } = new BaseInfoService();
      const result = await DeleteInsurance(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.insuranceCompanies.deleteInsuranceMessage
        );
        getTableData();
      }
    } catch (err) {
    } finally {
      setDeleteLoading(false);
    }
  };
  useEffect(() => {
    getTableData();
  }, [getTableData]);
  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<TableData> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.suppliers.companyName,
      dataIndex: "name",
      key: "name",
      width: 170,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.responderAndContactNumber,
      dataIndex: "responderAndContactNumber",
      key: "responderAndContactNumber",
      width: 166,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => (
        <Guard action={AllInsuranceResponderDetailsPath}>
          <span
            className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center"
            onClick={() => {
              onClickResponderAndContactNumber(record.id);
              setTableRecord(record);
            }}
          >
            contact_phone
          </span>
        </Guard>
      ),
    },
    {
      title: words.basicInformation.suppliers.address,
      dataIndex: "address",
      key: "address",
      width: 97,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => (
        <Tooltip title={record.address} placement="bottom">
          <span className="material-icons !text-[#02514C] text-[16px] cursor-pointer flex justify-center items-center">
            directions
          </span>
        </Tooltip>
      ),
    },
    {
      title: words.basicInformation.suppliers.tools,
      dataIndex: "tools",
      key: "tools",
      width: 112,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateInsurancePath}>
            <span
              onClick={() => {
                navigate(ROUTES.editInsuranceCompanies, { state: record });
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <span
            onClick={() => {
              navigate(ROUTES.viewInsuranceCompanies, {
                state: { insuranceDetailData, record },
              });
            }}
            className="material-icons !text-[#02514C] text-[16px] cursor-pointer"
          >
            visibility
          </span>
          <Guard action={DeleteInsurancePath}>
            <DPopconfirm
              deleteClassName
              onConfirm={() => {
                onClickDelete(record.id);
              }}
              description={
                words.basicInformation.insuranceCompanies
                  .deleteInsuranceCompanies
              }
              okButtonProps={{
                loading: deleteLoading,
              }}
              open={openDeleteConfirm === record.id}
              onCancel={() => {
                setOpenDeleteConfirm(undefined);
              }}
              okType="danger"
            >
              <span
                className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]"
                onClick={() => {
                  setOpenDeleteConfirm(record.id);
                }}
              >
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <InsuranceCompanyTableContainer>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: "auto" }}
        rowKey={getRowKey}
        className="w-[100%]"
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: paginationInsurance.total,
          current: paginationInsurance.current,
          pageSize: paginationInsurance.pageSize,
          onChange: (page: number, pageSize) => {
            setPaginationInsurance((prev) => ({
              ...prev,
              current: page,
              pageSize,
            }));
          },
        }}
      />
      <ContactNumberModal />
    </InsuranceCompanyTableContainer>
  );
};
