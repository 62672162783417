import { FC } from "react";
import { Orders } from "src/modules/orders";
import { OrderContextProvider } from "src/modules/orders/context";

export const OrdersPages: FC = () => {
  return (
    <OrderContextProvider>
      <Orders />
    </OrderContextProvider>
  );
};
