import { Divider, Modal, Typography } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useLocation } from "react-router-dom";
import { IAllTransportResult } from "src/services/BaseInfo/models/result.models";
import { useEditTransportCompany } from "./context/context";
const { Title } = Typography;

export const ConfirmModal: FC = () => {
  const {
    value: { isModalOpen },
    dispatch: { setIsModalOpen },
  } = useEditTransportCompany()!;
  const { words } = useLanguage();
  const location = useLocation();
  const data: IAllTransportResult = location.state;
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        className="!w-[45%] !h-[10%]"
        title={
          <div className="pt-[8px] pb-[4px]">
            <Title level={3}>{words.global.saveEdit}</Title>
            <div className="text-[16px] text-[#8E8E8E]">{data.companyName}</div>
            <Divider />
          </div>
        }
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="rounded-[12px] border-[#BFE2E0] border-solid p-[48px]">
          <Typography className="flex justify-center">
            {words.global.saveEitPopConfirm}
          </Typography>
        </div>
      </Modal>
    </div>
  );
};
