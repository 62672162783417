import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import useLanguage from "src/store/language";
import { InsuranceCompaniesContainer } from "./style/style";
import { useInsuranceCompanies } from "./context";
import { InsuranceCompanyTable } from "./components/InsuranceCompaniesTable";
import { AddNewInsuranceCompanies } from "./components/AddNewInsuranceCompany";
import { IAddNewInsuranceCompanyTabs } from "./components/AddNewInsuranceCompany/models";
import { IInsuranceCompaniesTabs } from "src/services/Identity/models/mockModels/insur.models";

export const InsuranceCompanies: FC = () => {
  const {
    value: { insuranceCompaniesActiveKey },
    dispatch: {
      setInsuranceCompaniesActiveKey,
      setAddNewInsuranceCompaniesActiveKey,
      setEditContact,
    },
    form: { contactForm, basicInformationForm },
  } = useInsuranceCompanies()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: IInsuranceCompaniesTabs.tableOfInsuranceCompanies,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">table_chart</span>
          {words.basicInformation.insuranceCompanies.insuranceCompaniesTable}
        </span>
      ),
      children: <InsuranceCompanyTable />,
    },
    {
      key: IInsuranceCompaniesTabs.addNewInsuranceCompanies,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">add_box</span>
          {words.basicInformation.insuranceCompanies.addNewInsuranceCompany}
        </span>
      ),
      children: <AddNewInsuranceCompanies />,
    },
  ];

  const onChange = (key: string) => {
    setInsuranceCompaniesActiveKey(key);
    setAddNewInsuranceCompaniesActiveKey(
      IAddNewInsuranceCompanyTabs.basicInformation
    );
    contactForm?.resetFields();
    basicInformationForm?.resetFields();
    setEditContact(false);
  };
  return (
    <InsuranceCompaniesContainer>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={insuranceCompaniesActiveKey}
        animated
      />
    </InsuranceCompaniesContainer>
  );
};
