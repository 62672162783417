export enum OrderUrl {
  Order = "Order/",
  GetAllProform = "Order/GetAllProform",
  DeleteProform = "/DeleteProform",
  AddProform = "Order/AddProform",
  GetProform = "/GetProform",
  UpdateProform = "/UpdateProform",
  AddOrigin = "/AddOrigin",
  NewOriginFile = "/NewOriginFile",
  GetOriginFile = "/GetOriginFile",
  GetOrigin = "/GetOrigin",
  UpdateOrigin = "/UpdateOrigin",
  UpdateOriginFile = "/UpdateOriginFile",
  DeleteOrigin = "/DeleteOrigin",
  DeleteOriginFile = "/DeleteOriginFile",
}
