import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import useLanguage from "src/store/language";
import { useWarehouses } from "../Warehouses/context";
import { Form } from "antd";

export const HeaderEditWarehouses: FC = () => {
  const { words } = useLanguage();
  const navigate = useNavigate();
  const {
    form: { basicInformationForm },
  } = useWarehouses()!;
  const subTitleValue = Form.useWatch("name", basicInformationForm);

  return (
    <div className="flex justify-between items-center pt-[19px] px-[24px] ">
      <div className="flex flex-col justify-end">
        <div className="text-[#044440] text-[18px]">
          {words.basicInformation.warehouses.editWarehouses}
        </div>
        <div className="text-[#4B4B4B] text-[14px]">{subTitleValue}</div>
      </div>
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={() => {
          navigate(ROUTES.warehouses);
        }}
      >
        <div className=" text-[#008C84] text-[24px] ">
          {words.global.backToTable}
        </div>
        <span className="material-icons text-[#008C84] text-[32px]">
          keyboard_backspace
        </span>
      </div>
    </div>
  );
};
