import { Button, Col, Divider, Form, Row, Typography, message } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { useCountries } from "../context";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddCountry } from "src/services/BaseInfo/models/args.models";
import { Guard } from "src/components/Guard";
import {
  AddCountryPath,
  UpdateCountryPath,
} from "src/services/BaseInfo/guardPath";
import { DInput } from "src/components/UiKit/DInput";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { AddNewCountryContainer } from "../style";

export const AddNewCountry: FC = () => {
  const { words } = useLanguage();
  const {
    value: { editMode, productId },
    form: { form },
    dispatch: { setEditMode },
    func: { getCountryTableData },
  } = useCountries();
  const [loading, setLoading] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);

  const onFinishAddCountry = async (values: IAddCountry) => {
    setLoading(true);
    try {
      const { AddCountry } = new BaseInfoService();
      const result = await AddCountry(values);
      if (result && result.status === 200) {
        message.success(words.basicInformation.countries.addCountryMessage);
        getCountryTableData();
        form?.resetFields();
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onFinishUpdateCountry = async (values: IAddCountry) => {
    setLoading(true);
    try {
      const { UpdateCountry } = new BaseInfoService();
      const result = await UpdateCountry(values, productId);
      if (result && result.status === 200) {
        message.success(words.basicInformation.countries.updateCountryMessage);
        getCountryTableData();
        form?.resetFields();
        setPressKeyboard(false);
        setEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Guard action={[UpdateCountryPath, AddCountryPath]} multiPath>
      <Form
        form={form}
        onFinish={(values) => {
          editMode ? onFinishUpdateCountry(values) : onFinishAddCountry(values);
        }}
      >
        <AddNewCountryContainer>
          <Col span={24}>
            <Typography>
              {words.basicInformation.countries.countriesInformation}
            </Typography>
          </Col>
          <Col span={24}>
            <Row gutter={24} className="productHolder">
              <Col span={8}>
                <FormItem
                  name={"faName"}
                  label={words.basicInformation.countries.countryName}
                  rules={[{ required: true }]}
                >
                  <DInput
                    placeholder={words.global.insert}
                    onKeyDown={() => setPressKeyboard(true)}
                  />
                </FormItem>
              </Col>
              <Col span={16}>
                <div className="flex gap-[16px] justify-end h-[100%] items-center">
                  {editMode ? (
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={form?.submit}
                      loading={loading}
                    >
                      {words.global.saveEdit}
                    </Button>
                  ) : (
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={form?.submit}
                      loading={loading}
                    >
                      {words.global.add}
                    </Button>
                  )}

                  {editMode ? (
                    <Button
                      type="default"
                      className="!min-w[85px]"
                      onClick={() => {
                        setEditMode(false);
                        form?.resetFields();
                        setPressKeyboard(false);
                      }}
                    >
                      {words.global.cancel}
                    </Button>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={() => {
                        setPressKeyboard(false);
                        form?.resetFields();
                      }}
                    >
                      <Button
                        type="default"
                        className="!min-w[85px]"
                        disabled={!pressKeyboard}
                      >
                        {words.global.reset}
                      </Button>
                    </DPopconfirm>
                  )}
                </div>
              </Col>
              <Divider />
            </Row>
          </Col>
        </AddNewCountryContainer>
      </Form>
    </Guard>
  );
};
