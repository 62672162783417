import { Row } from "antd";
import { styled } from "styled-components";

export const OperatingBanksTableContainer = styled.div`
  display: flex;
  width: 65%;
  padding: 12px 12px 24px 12px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
  .ant-table-row-expand-icon-cell {
    text-align: center !important;
  }
  .ant-table-wrapper {
    width: 100%;
  }
  tbody {
    tr {
      td {
        padding-block: 0 !important;
      }
    }
  }
`;
export const RAndCNumberTableContainer = styled(Row)`
  display: flex;
  gap: 24px;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border6};
`;
