import { FC } from "react";
import { Vessels } from "src/modules/vessels";
import { VesselsProvider } from "src/modules/vessels/context";

export const VesselsPage: FC = () => {
  return (
    <VesselsProvider>
      <Vessels />
    </VesselsProvider>
  );
};
