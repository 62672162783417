import { ReactNode } from "react";
export enum IWarehousesTabs {
  tableOfWarehouses = "1",
  addNewWarehouses = "2",
}
export enum IAddNewWarehousesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export enum IEditWarehousesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface IEditContactsTable {
  id: number;
  name: string;
  phoneNumber: number[];
}
export interface IContactsTable {
  id: number;
  name: string;
  phoneNumber: number[];
}
export interface TableDataNamePhoneNumber extends IContactsTable {
  tools?: ReactNode;
  name: string;
}
export interface IPhoneNumber {
  name: string;
  phoneNumber: string[];
}
export interface IContactPhoneNumber {
  phoneNumber: number;
}
export interface IGetWarehouses {
  id: number;
  warehousesName: string;
  warehousesNumber: string;
  warehousesLocation: string;
  address: string;
  phoneNumber: IPhoneNumber[];
}
export interface TableData extends IGetWarehouses {
  tools?: ReactNode;
}

export const dataSource: TableData[] = [
  {
    id: 1,
    warehousesName: "دانه طلایی آمل",
    warehousesLocation: "بندر امام",
    warehousesNumber: "1",
    address: "414040, Astrakhan, Admiralteyskaya str. 51,Liter B, office 54",
    phoneNumber: [
      {
        name: "سید مهدی موسوی اسلام آبادی",
        phoneNumber: [
          "09115697466",
          "09115697466",
          "09115697466",
          "09115697466",
        ],
      },
      { name: "حامد نظری ", phoneNumber: ["09115697466"] },
      { name: "حمید بهروزی ", phoneNumber: ["09115697466"] },
      { name: "حمید بهروزی ", phoneNumber: ["09115697466"] },
    ],
  },
  {
    id: 2,
    address: "address",
    warehousesName: "دانه طلایی",
    warehousesLocation: "بندر",
    warehousesNumber: "23",
    phoneNumber: [{ name: "Hamid behruzi", phoneNumber: ["09115697466"] }],
  },
  {
    id: 3,
    warehousesName: "دانه آمل",
    warehousesLocation: " امام",
    warehousesNumber: "12",
    address: "address",
    phoneNumber: [
      { name: "Hamid behruzi", phoneNumber: ["09115697466"] },
      {
        name: "Seyed mehdi moosavy",
        phoneNumber: ["09115697466"],
      },
      {
        name: "Seyed mehdi moosavy",
        phoneNumber: ["09115697466"],
      },
      {
        name: "Seyed mehdi moosavy",
        phoneNumber: ["09115697466"],
      },
    ],
  },
];
export const dataSourceNamePhoneNumber: TableDataNamePhoneNumber[] = [
  {
    id: 0,
    name: "حمید بهروزی",
    phoneNumber: [9170916009],
  },
  {
    id: 1,
    name: "سید مهدی موسوی اسلام آبادی",
    phoneNumber: [9123456789],
  },
  {
    id: 2,
    name: "حامد نظری",
    phoneNumber: [9123456789],
  },
];
