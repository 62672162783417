import { Col, Typography, Table, App } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { ContactsTableContainer } from "./style";
import { useClearanceWorkers } from "src/modules/ClearanceWorkers/context/context";
import { IAllCustomBrokerPhoneResult } from "src/services/BaseInfo/models/result.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

export const ContactsTable: FC = () => {
  const {
    dispatch: {
      setEditContact,
      setPaginationCustomBrokerPhone,
      setCustomBrokerPhoneId,
      setEndOfRegister,
    },
    form: { contactForm },
    func: { GetAllCustomBrokerPhone },
    value: {
      CustomBrokerId,
      CustomBrokerPhoneData,
      CustomBrokerPhoneLoading,
      paginationCustomBrokerPhone,
    },
  } = useClearanceWorkers()!;
  const { words } = useLanguage();
  const { message } = App.useApp();

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const onDeletePhone = async (CustomBrokerPhoneId: number) => {
    if (!CustomBrokerId) return console.log("CustomBrokerId not found");

    try {
      setDeleteLoading(true);
      const { DeleteCustomBrokerPhone } = new BaseInfoService();
      const res = await DeleteCustomBrokerPhone(CustomBrokerPhoneId);
      if (res && res.status === 200) {
        message.success(
          words.basicInformation.clearanceWorkers.deleteCustomBrokerPhoneMessage
        );
        setEndOfRegister(false);
        GetAllCustomBrokerPhone(CustomBrokerId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setOpenDeleteConfirm(undefined);
    }
  };

  useEffect(() => {
    if (!CustomBrokerId) return console.log("CustomBrokerId not found");
    GetAllCustomBrokerPhone(CustomBrokerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetAllCustomBrokerPhone]);

  const columns: ColumnsType<IAllCustomBrokerPhoneResult> = [
    {
      title: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.clearanceWorkers.phoneNumber,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 233,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.global.tools,
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => {
              setEditContact(true);
              contactForm?.setFieldsValue(record);
              setCustomBrokerPhoneId(record.id);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <DPopconfirm
            deleteClassName
            onConfirm={() => {
              onDeletePhone(record.id);
            }}
            description={words.global.deletePhoneNumberPopConfirm}
            okButtonProps={{
              loading: deleteLoading,
            }}
            open={openDeleteConfirm === record.id}
            onCancel={() => {
              setOpenDeleteConfirm(undefined);
            }}
          >
            <span
              className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]"
              onClick={() => {
                setOpenDeleteConfirm(record.id);
              }}
            >
              delete_forever
            </span>
          </DPopconfirm>
        </div>
      ),
    },
  ];
  return (
    <ContactsTableContainer>
      <Col span={24}>
        <Typography>{words.global.contactsTable}</Typography>
      </Col>
      <Col span={24}>
        <div className="tableHolder">
          <Table
            columns={columns}
            dataSource={CustomBrokerPhoneData}
            loading={CustomBrokerPhoneLoading}
            scroll={{ x: "auto" }}
            rowKey={getRowKey}
            className="w-[100%]"
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: false,
              total: paginationCustomBrokerPhone.total,
              current: paginationCustomBrokerPhone.current,
              pageSize: paginationCustomBrokerPhone.pageSize,
              onChange: (page: number, pageSize: number) => {
                setPaginationCustomBrokerPhone((prev) => ({
                  ...prev,
                  current: page,
                  pageSize,
                }));
              },
            }}
          />
        </div>
      </Col>
    </ContactsTableContainer>
  );
};
