import { Col, Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";
import { RAndCNumberTableContainer } from "./style";
import { useTransportCompanies } from "../../context";
import { IAllTransportResponderDetailsResult } from "src/services/BaseInfo/models/result.models";

export const ResponderAndContactNumberTable: FC = () => {
  const {
    value: {
      TransportDetailData,
      paginationTransportDetail,
      TransportDetailLoading,
      TransportId,
    },
    func: { GetAllTransportResponderDetails },
    dispatch: { setPaginationTransportDetail },
  } = useTransportCompanies()!;
  const { words } = useLanguage();

  useEffect(() => {
    if (TransportId && TransportId > 0) {
      GetAllTransportResponderDetails(TransportId);
    }
  }, [GetAllTransportResponderDetails, TransportId]);
  const columns: ColumnsType<IAllTransportResponderDetailsResult> = [
    {
      title: "#",
      key: "index",
      width: 47,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.responsive,
      dataIndex: "responderName",
      key: "responderName",
      width: 111,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.PhoneNumber,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 431,
      align: "center",
      className: "!font-bold",
      render: (text: string[]) => (
        <Flex gap={16} justify="center">
          {text.map((item, index) => (
            <div key={index} className="whitespace-nowrap !font-[vazir]">
              {item}
            </div>
          ))}
        </Flex>
      ),
    },
  ];
  return (
    <RAndCNumberTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={TransportDetailData}
          loading={TransportDetailLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            total: paginationTransportDetail.total,
            current: paginationTransportDetail.current,
            pageSize: paginationTransportDetail.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationTransportDetail((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </RAndCNumberTableContainer>
  );
};
