import { FC, ReactNode } from "react";
import { LoginLayoutOutletStyled, LoginLayoutStyled } from "./styles";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

const LoginLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Layout>
      <LoginLayoutStyled>
        {/* <div className="language-box">
          <ChooseLanguage />
        </div> */}
        <LoginLayoutOutletStyled>
          <Outlet />
        </LoginLayoutOutletStyled>
      </LoginLayoutStyled>
    </Layout>
  );
};

export default LoginLayout;
