import { Row } from "antd";
import { styled } from "styled-components";

export const BasicInformationContainer = styled(Row)`
  display: flex;
  padding: 24px 32px;
  gap: 24px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};
`;
