import { Row } from "antd";
import { styled } from "styled-components";

export const ProductContainer = styled.div`
  width: 92%;
  padding: 24px 32px 24px 32px;
  border-radius: 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 6px 0px #7182b740;
`;
export const AddNewProductContainer = styled(Row)`
  display: flex;
  gap: 24px;
  .productHolder {
    display: flex;
    padding: 16px 0px 12px 0px;
  }
`;
export const ProductTableContainer = styled(Row)`
  display: flex;
  gap: 24px;
  .tableHolder {
    display: flex;
    padding: 12px;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;
