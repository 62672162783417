import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { IAveragePriceTableData } from "../models";
import { averagePriceMockData } from "../constants/averagePriceMockData";
import { Form } from "antd";
import { FormInstance } from "antd/lib";

interface IContext {
  value: {
    dataTable: IAveragePriceTableData[];
    formController: FormInstance | undefined;
    editMode: boolean;
    currentEditValue: IAveragePriceTableData | undefined;
  };
  dispatch: {
    setDataTable: Dispatch<SetStateAction<IAveragePriceTableData[]>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setCurrentEditValue: Dispatch<
      SetStateAction<IAveragePriceTableData | undefined>
    >;
  };
  func: {};
}
const defaultCtxValue: IContext = {
  value: {
    dataTable: [],
    formController: undefined,
    editMode: false,
    currentEditValue: undefined,
  },
  dispatch: {
    setDataTable: () => {},
    setEditMode: () => {},
    setCurrentEditValue: () => {},
  },
  func: {},
};
export const AveragePriceContext = createContext<IContext>(defaultCtxValue);

export const AveragePriceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dataTable, setDataTable] =
    useState<IAveragePriceTableData[]>(averagePriceMockData);
  const [formController] = Form.useForm();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentEditValue, setCurrentEditValue] =
    useState<IAveragePriceTableData>();
  const contextValue: IContext = {
    value: {
      dataTable,
      formController,
      editMode,
      currentEditValue,
    },
    dispatch: { setDataTable, setEditMode, setCurrentEditValue },
    func: {},
  };
  return (
    <AveragePriceContext.Provider value={contextValue}>
      {children}
    </AveragePriceContext.Provider>
  );
};

export const useAveragePrice = () => useContext(AveragePriceContext);
