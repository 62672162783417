import { App, Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { useEditSupplier } from "../../context";
import { EditBasicInformationContainer } from "../style";
import { useLocation } from "react-router-dom";
import { IEditSupplierTabs } from "src/services/Identity/models/mockModels/supp.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IAddSupplier } from "src/services/BaseInfo/models/args.models";
import { IAllSupplierResult } from "src/services/BaseInfo/models/result.models";

export const EditBasicInformation: FC = () => {
  const {
    dispatch: { setEditSupplierActiveKey, setPressKeyboard },
    form: { editBasicInformationForm },
    value: { pressKeyboard },
  } = useEditSupplier()!;
  const { words } = useLanguage();
  const location = useLocation();
  const { message } = App.useApp();
  const data: IAllSupplierResult = location.state;
  const [loading, setLoading] = useState<boolean>(false);
  const onFinish = async (values: IAddSupplier) => {
    setLoading(true);
    try {
      const { UpdateSupplier } = new BaseInfoService();
      const result = await UpdateSupplier(values, data.id);
      if (result && result.status === 200) {
        message.success(words.basicInformation.suppliers.editSupplierMessage);
        setEditSupplierActiveKey(IEditSupplierTabs.Contacts);
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      editBasicInformationForm?.setFieldsValue(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
  return (
    <Form form={editBasicInformationForm} onFinish={onFinish}>
      <EditBasicInformationContainer>
        <Col span={24}>
          <Typography>
            {words.basicInformation.suppliers.editInformation}
          </Typography>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name={"name"}
                label={words.basicInformation.suppliers.companyName}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name={"sellerId"}
                label={words.basicInformation.suppliers.sellerID}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name={"address"}
                label={words.basicInformation.suppliers.address}
              >
                <Input
                  placeholder={words.global.insert}
                  onKeyDown={() => setPressKeyboard(true)}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <div className="flex gap-[16px] justify-end borderTop">
            <Button
              className="!min-w[85px]"
              type="primary"
              disabled={!pressKeyboard}
              onClick={editBasicInformationForm?.submit}
              loading={loading}
            >
              {words.global.saveEdit}
            </Button>

            <Button
              type="default"
              className="!min-w[85px]"
              onClick={() => {
                editBasicInformationForm?.setFieldsValue(location.state);
                setPressKeyboard(false);
              }}
              disabled={!pressKeyboard}
            >
              {words.global.clearChanges}
            </Button>
          </div>
        </Col>
      </EditBasicInformationContainer>
    </Form>
  );
};
