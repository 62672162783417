import { IPath } from "../Identity/models";

export const UserManagementGuardPath: IPath[] = [
  {
    path: "api/UserManagement/Roles",
    httpMethod: "GET",
  },
  {
    path: "api/UserManagement/newUser",
    httpMethod: "POST",
  },
  {
    path: "api/UserManagement/userReport",
    httpMethod: "GET",
  },
  {
    path: "api/UserManagement/{userId}/LoginHistory",
    httpMethod: "GET",
  },
  {
    path: "api/UserManagement/{userId}/AddUserRole",
    httpMethod: "POST",
  },
  {
    path: "api/UserManagement/{userId}/RemoveUserRole",
    httpMethod: "POST",
  },
  {
    path: "api/UserManagement/{userId}/ResetUserPassword",
    httpMethod: "POST",
  },
  {
    path: "api/UserManagement/{userId}/Disable",
    httpMethod: "POST",
  },
  {
    path: "api/UserManagement/{userId}/Enable",
    httpMethod: "POST",
  },
  {
    path: "api/UserManagement/{userId}/RolesOfUser",
    httpMethod: "GET",
  },
  {
    path: "api/UserManagement/{RoleId}/UsersOfRole",
    httpMethod: "GET",
  },
  {
    path: "api/UserManagement/AllActions",
    httpMethod: "GET",
  },
  {
    path: "api/UserManagement/NewRole",
    httpMethod: "POST",
  },
  {
    path: "api/UserManagement/AssignRoleToAction",
    httpMethod: "POST",
  },
  {
    path: "api/UserManagement/{ActionId}/RolesOfAction",
    httpMethod: "GET",
  },
];

export const RolesPath: IPath = {
  path: "api/UserManagement/Roles",
  httpMethod: "GET",
};
export const newUserPath: IPath = {
  path: "api/UserManagement/newUser",
  httpMethod: "POST",
};
export const userReportPath: IPath = {
  path: "api/UserManagement/userReport",
  httpMethod: "GET",
};
export const LoginHistoryPath: IPath = {
  path: "api/UserManagement/{userId}/LoginHistory",
  httpMethod: "GET",
};
export const AddUserRolePath: IPath = {
  path: "api/UserManagement/{userId}/AddUserRole",
  httpMethod: "POST",
};
export const RemoveUserRolePath: IPath = {
  path: "api/UserManagement/{userId}/RemoveUserRole",
  httpMethod: "POST",
};
export const ResetUserPasswordPath: IPath = {
  path: "api/UserManagement/{userId}/ResetUserPassword",
  httpMethod: "POST",
};
export const DisableUserPath: IPath = {
  path: "api/UserManagement/{userId}/Disable",
  httpMethod: "POST",
};
export const EnableUserPath: IPath = {
  path: "api/UserManagement/{userId}/Enable",
  httpMethod: "POST",
};
export const RolesOfUserPath: IPath = {
  path: "api/UserManagement/{userId}/RolesOfUser",
  httpMethod: "GET",
};
export const UsersOfRolePath: IPath = {
  path: "api/UserManagement/{RoleId}/UsersOfRole",
  httpMethod: "GET",
};
export const AssignRoleToActionPath: IPath = {
  path: "api/UserManagement/AssignRoleToAction",
  httpMethod: "POST",
};
export const NewRolePath: IPath = {
  path: "api/UserManagement/NewRole",
  httpMethod: "POST",
};
export const AllActionsPath: IPath = {
  path: "api/UserManagement/AllActions",
  httpMethod: "GET",
};
export const RolesOfActionPath: IPath = {
  path: "api/UserManagement/{ActionId}/RolesOfAction",
  httpMethod: "GET",
};
