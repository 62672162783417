import { Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { ITablePagination } from "src/models/interfaces/pagination";
import {
  IEditContactsTable,
  IEditOperatingBanksTabs,
} from "src/services/Identity/models/mockModels/opera.models";
import { ResponderTableData } from "../models";
import { IBankResponderDetailsByIdResult } from "src/services/BaseInfo/models/result.models";
import { IObject } from "src/models/interfaces";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

interface IContext {
  value: {
    editOperatingBanksActiveKey: string;
    editContact: boolean;
    editContactInfo: IEditContactsTable | undefined;
    pressKeyboard: boolean;
    editResponse: boolean;
    selectedResponsive: IEditContactsTable | undefined;
    allRespondersData: ResponderTableData[] | undefined;
    responderContactEditMode: boolean;
    BankResponderId: number | undefined;
    responderPhoneDetailLoading: boolean;
    responderPhoneData: IBankResponderDetailsByIdResult[];
    paginationPhoneNumber: ITablePagination;
    isModalOpen: boolean;
    allBankResponders: boolean;
    bankPhoneId: number | undefined;
  };
  dispatch: {
    setEditOperatingBanksActiveKey: Dispatch<SetStateAction<string>>;
    setEditContactInfo: Dispatch<
      SetStateAction<IEditContactsTable | undefined>
    >;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setEditResponse: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;
    setSelectedResponsive: Dispatch<
      SetStateAction<IEditContactsTable | undefined>
    >;
    setResponderContactEditMode: Dispatch<SetStateAction<boolean>>;
    setBankResponderId: Dispatch<SetStateAction<number | undefined>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setBankPhoneId: Dispatch<SetStateAction<number | undefined>>;
  };
  func: {
    GetAllBankResponders: (id: number) => void;
    getResponderPhoneDetail: (id: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
    editBasicInformationForm: FormInstance<any> | undefined;
    editContactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const EditOperatingBanksContext = createContext<IContext | undefined>(
  undefined
);

export const EditOperatingBanksProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [editOperatingBanksActiveKey, setEditOperatingBanksActiveKey] =
    useState<string>(IEditOperatingBanksTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [selectedResponsive, setSelectedResponsive] = useState<
    IEditContactsTable | undefined
  >();
  const [editResponse, setEditResponse] = useState<boolean>(false);
  const [editContactInfo, setEditContactInfo] = useState<IEditContactsTable>();
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [paginationResponder, setPaginationResponder] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [allBankResponders, setAllBankResponders] = useState<boolean>(false);
  const [allRespondersData, setAllRespondersData] = useState<
    ResponderTableData[]
  >([]);
  const [responderContactEditMode, setResponderContactEditMode] =
    useState<boolean>(false);
  const [responderPhoneDetailLoading, setResponderPhoneDetailLoading] =
    useState<boolean>(false);
  const [paginationPhoneNumber, setPaginationPhoneNumber] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [responderPhoneData, setResponderPhoneData] = useState<
    IBankResponderDetailsByIdResult[]
  >([]);
  const [BankResponderId, setBankResponderId] = useState<number>();
  const [bankPhoneId, setBankPhoneId] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [editBasicInformationForm] = Form.useForm();
  const [editContactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();

  ////////////////////////////////////////////////////////////////func////////////////////////////////
  const GetAllBankResponders = useCallback(
    async (id: number) => {
      setAllBankResponders(true);
      try {
        const { GetAllBankResponders: GetAllBankR } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationResponder.current,
          Limit: paginationResponder.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllBankR(searchParams.toString(), id);
        if (result && result.status === 200 && result.data) {
          setAllRespondersData(result.data.records);
          setPaginationResponder((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setAllBankResponders(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationResponder.current, paginationResponder.pageSize]
  );

  const getResponderPhoneDetail = useCallback(
    async (id: number) => {
      setResponderPhoneDetailLoading(true);
      try {
        const { GetBankPhonesByResponderId } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationPhoneNumber.current,
          Limit: paginationPhoneNumber.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetBankPhonesByResponderId(
          searchParams.toString(),
          id
        );
        if (result && result.status === 200) {
          setResponderPhoneData(result.data.records);
          setPaginationPhoneNumber((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setResponderPhoneDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationPhoneNumber.current, paginationPhoneNumber.pageSize]
  );

  const ContextValue: IContext = {
    value: {
      editOperatingBanksActiveKey,
      editContact,
      editContactInfo,
      pressKeyboard,
      selectedResponsive,
      editResponse,
      allRespondersData,
      responderContactEditMode,
      BankResponderId,
      responderPhoneDetailLoading,
      responderPhoneData,
      paginationPhoneNumber,
      isModalOpen,
      allBankResponders,
      bankPhoneId,
    },
    dispatch: {
      setEditOperatingBanksActiveKey,
      setEditContactInfo,
      setEditContact,
      setPressKeyboard,
      setSelectedResponsive,
      setEditResponse,
      setResponderContactEditMode,
      setBankResponderId,
      setIsModalOpen,
      setBankPhoneId,
    },
    func: { GetAllBankResponders, getResponderPhoneDetail },
    form: {
      form,
      editBasicInformationForm,
      editContactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  return (
    <EditOperatingBanksContext.Provider value={ContextValue}>
      {children}
    </EditOperatingBanksContext.Provider>
  );
};

export const useEditOperatingBanks = () =>
  useContext(EditOperatingBanksContext);
