import { FC } from "react";
import { OrdersTableContainer } from "./style";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import useLanguage from "src/store/language";
import { Guard } from "src/components/Guard";
import { getRowKey } from "src/helper/getRowKey";
import { useOrder } from "../context";
import { IGetAllProforma } from "src/services/Order/models/result.models";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";
import dayjs from "dayjs";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import useOrderStore from "src/store/order";
export const OrdersTable: FC = () => {
  const { words } = useLanguage();
  const navigate = useNavigate();
  const {
    value: { pagination, allProforma, allProformaLoading },
    dispatch: { setPagination },
    func: { deleteProforma },
  } = useOrder()!;
  const onChangeParams = useChangeParams();
  const { setOrderCode } = useOrderStore();
  const columns: ColumnsType<IGetAllProforma> = [
    {
      title: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: words.orders.proformaNo,
      dataIndex: "piNo",
      key: "piNo",
      width: 109,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.orders.proformaDate,
      dataIndex: "piDate",
      key: "piDate",
      width: 105,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => dayjs(record.piDate).format("YYYY-MM-DD"),
    },
    {
      title: words.orders.product,
      dataIndex: "productName",
      key: "productName",
      width: 86,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.orders.weight,
      dataIndex: "weight",
      key: "weight",
      width: 73,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.orders.freightPerTon,
      dataIndex: "freightUnitPmt",
      key: "freightUnitPmt",
      width: 141,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.orders.FOB,
      dataIndex: "fobUnitPmt",
      key: "fobUnitPmt",
      width: 175,
      align: "center",
      className: "!font-bold !px-2",
    },
    {
      title: words.orders.CFR,
      dataIndex: "address",
      key: "address",
      width: 174,
      align: "center",
      className: "!font-bold !px-2",
      render: (text, record) => record.freightUnitPmt + record.fobUnitPmt,
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 192,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <div
            className="orderManagementHolder"
            onClick={() => {
              navigate(ROUTES.orderManagement.concat(`/${record.id}`));
              setOrderCode(record.piNo);
            }}
          >
            <span className="material-symbols-outlined text-[#008F87] text-[12px]">
              display_settings
            </span>
            <div className="text-[#008F87] text-[10px] whitespace-nowrap	">
              {words.orders.orderManagement}
            </div>
          </div>
          <DeletePopconfirm
            endPointName=""
            onConfirm={() => deleteProforma(record.id)}
          >
            <div className="deleteHolder">
              <span className="material-symbols-outlined text-[#F64C4C] text-[12px]">
                delete_forever
              </span>
              <div className="text-[#F64C4C] text-[10px]">
                {words.global.delete}
              </div>
            </div>
          </DeletePopconfirm>
        </div>
      ),
    },
  ];
  return (
    <OrdersTableContainer>
      <Table
        columns={columns}
        dataSource={allProforma}
        loading={allProformaLoading}
        scroll={{ x: "auto" }}
        rowKey={getRowKey}
        className="w-[100%]"
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: pagination.total,
          current: pagination.current,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize) => {
            onChangeParams("Limit", pageSize.toString());
            onChangeParams(
              "Offset",
              pageSize !== pagination.pageSize ? "1" : pageSize.toString()
            );
            setPagination((prev) => ({
              ...prev,
              current: pageSize !== prev.pageSize ? 1 : page,
              pageSize,
            }));
          },
        }}
      />
    </OrdersTableContainer>
  );
};
