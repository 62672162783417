import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { AddNewInspectionCompaniesContainer } from "./style";
import { IAddNewInspectionCompaniesTabs } from "./models";
import { useInspectionCompanies } from "../../context";
import { BasicInformation } from "./components/BasicInformation";
import { Contact } from "./components/ContactInfo";

export const AddNewInspectionCompanies: FC = () => {
  const { words } = useLanguage();
  const {
    value: { addNewInspectionCompaniesActiveKey },
  } = useInspectionCompanies()!;

  const items: TabsProps["items"] = [
    {
      key: IAddNewInspectionCompaniesTabs.basicInformation,
      label: words.global.basicInformation,
      children: <BasicInformation />,
    },
    {
      key: IAddNewInspectionCompaniesTabs.Contacts,
      label: words.global.Contacts,
      children: <Contact />,
    },
  ];

  return (
    <AddNewInspectionCompaniesContainer>
      <Tabs
        activeKey={addNewInspectionCompaniesActiveKey}
        items={items}
        centered
        animated
      />
    </AddNewInspectionCompaniesContainer>
  );
};
