import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { ITablePagination } from "src/models/interfaces/pagination";
import { TableData } from "../models/models";
import useLanguage from "src/store/language";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";

interface IContext {
  value: {
    editMode: boolean;
    dataSource: TableData[];
    pagination: ITablePagination;
    currencyLoading: boolean;
    currencyId: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setCurrencyId: Dispatch<SetStateAction<number>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    getCurrencyTableData: () => void;
    deleteCurrency: (id: number) => void;
  };
  form: { form: FormInstance<any> | undefined };
}

export const CurrenciesContext = createContext<IContext | undefined>(undefined);

export const CurrenciesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { words } = useLanguage();
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [currencyId, setCurrencyId] = useState<number>(-1);

  ///////////Functions////////////////////////////////////////////////////////////////
  const getCurrencyTableData = async () => {
    setCurrencyLoading(true);
    try {
      const { GetAllCurrency } = new BaseInfoService();
      const result = await GetAllCurrency(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrencyLoading(false);
    }
  };

  const deleteCurrency = async (id: number) => {
    setCurrencyLoading(true);
    try {
      const { DeleteCurrency } = new BaseInfoService();
      const result = await DeleteCurrency(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.currencies.deleteCurrenciesMessage
        );
        getCurrencyTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrencyLoading(false);
    }
  };
  const ContextValue: IContext = {
    value: { editMode, dataSource, pagination, currencyId, currencyLoading },
    dispatch: { setEditMode, setPagination, setCurrencyId },
    func: { getCurrencyTableData, deleteCurrency },
    form: { form },
  };
  return (
    <CurrenciesContext.Provider value={ContextValue}>
      {children}
    </CurrenciesContext.Provider>
  );
};

export const useCurrencies = () => useContext(CurrenciesContext);
