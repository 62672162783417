import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { IGetVessels, TableData } from "../models";
import { VesselsMockData } from "../constants";
import { Form, FormInstance, message } from "antd";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useSearchParams } from "react-router-dom";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import useLanguage from "src/store/language";

interface IVesselsContext {
  value: {
    tableData: IGetVessels[];
    editMode: boolean;
    submit: boolean;
    dataSource: TableData[];
    pagination: ITablePagination;
    productLoading: boolean;
    productId: number;
  };
  dispatch: {
    setTableData: Dispatch<SetStateAction<IGetVessels[]>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setSubmit: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setProductId: Dispatch<SetStateAction<number>>;
  };
  form: { form: FormInstance | undefined };
  func: { getVesselsTableData: () => void; deleteVessel: (id: number) => void };
}

const DefaultCtxValue: IVesselsContext = {
  value: {
    tableData: [],
    editMode: false,
    submit: false,
    dataSource: [],
    pagination: { current: 0, pageSize: 0, total: 0 },
    productId: -1,
    productLoading: false,
  },
  dispatch: {
    setTableData: () => {},
    setEditMode: () => {},
    setSubmit: () => {},
    setPagination: () => {},
    setProductId: () => {},
  },
  form: { form: undefined },
  func: { getVesselsTableData: () => {}, deleteVessel: (id: number) => {} },
};

export const VesselsContext = createContext<IVesselsContext>(DefaultCtxValue);

export const VesselsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const [tableData, setTableData] = useState<IGetVessels[]>(VesselsMockData);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [productId, setProductId] = useState<number>(-1);

  ///////////Functions////////////////////////////////////////////////////////////////
  const getVesselsTableData = async () => {
    setProductLoading(true);
    try {
      const { GetAllVessel } = new BaseInfoService();
      const result = await GetAllVessel(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };

  const deleteVessel = async (id: number) => {
    setProductLoading(true);
    try {
      const { DeleteVessel } = new BaseInfoService();
      const result = await DeleteVessel(id);
      if (result && result.status === 200) {
        message.success(words.basicInformation.vessels.deleteVesselsMessage);
        getVesselsTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProductLoading(false);
    }
  };
  const contextValue: IVesselsContext = {
    value: {
      tableData,
      editMode,
      submit,
      dataSource,
      pagination,
      productId,
      productLoading,
    },
    dispatch: {
      setTableData,
      setEditMode,
      setSubmit,
      setPagination,
      setProductId,
    },
    form: { form },
    func: { getVesselsTableData, deleteVessel },
  };

  return (
    <VesselsContext.Provider value={contextValue}>
      {children}
    </VesselsContext.Provider>
  );
};

export const UseVessels = () => useContext(VesselsContext);
