import { FC } from "react";
import { EditInspectionCompanies } from "src/modules/EditInspectionCompanies";
import { EditInspectionCompaniesProvider } from "src/modules/EditInspectionCompanies/context/context";

export const EditInspectionCompaniesPage: FC = () => {
  return (
    <EditInspectionCompaniesProvider>
      <EditInspectionCompanies />
    </EditInspectionCompaniesProvider>
  );
};
