import { Divider } from "antd";
import { FC } from "react";

import { HeaderEditWarehouses } from "./header";

import { AddNewWarehouses } from "../Warehouses/components/AddNewWarehouses";

export const EditWarehouses: FC = () => {
  return (
    <>
      <HeaderEditWarehouses />
      <Divider />
      <div className="flex justify-center items-center">
        <AddNewWarehouses />
      </div>
    </>
  );
};
