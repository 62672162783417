import { ReactNode } from "react";
import {
  IAllInspectionResponderDetailsResult,
  IAllInspectionRespondersResult,
  IAllInspectionResult,
  IInspectionResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";

export enum IInspectionCompaniesTabs {
  tableOfInspectionCompanies = "1",
  addNewInspectionCompanies = "2",
}
export enum IAddNewInspectionCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export enum IEditInspectionCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface TableData extends IAllInspectionResult {
  "#"?: number;
  tools?: ReactNode;
  responderAndContactNumber?: ReactNode;
}
export interface ResponderTableData extends IAllInspectionRespondersResult {
  "#"?: number;
  tools?: ReactNode;
}
export interface InspectionDetailTableData
  extends IAllInspectionResponderDetailsResult {
  "#"?: number;
}
export interface ResponderPhoneTableData
  extends IInspectionResponderDetailsByIdResult {
  "#"?: number;
  tools?: ReactNode;
}
export interface IViewData {
  inspectionDetailData: IAllInspectionResponderDetailsResult[];
  record: TableData;
}
