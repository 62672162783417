import { Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { IEditClearanceWorkersTabs, IEditContactsTable } from "../models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";
import { ITablePagination } from "src/models/interfaces/pagination";
import { IAllCustomBrokerPhoneResult } from "src/services/BaseInfo/models/result.models";

interface IContext {
  value: {
    editClearanceWorkersActiveKey: string;
    editContact: boolean;
    editContactInfo: IEditContactsTable | undefined;
    isRegister: boolean;
    basicInfoEditMode: boolean;
    CustomBrokerPhoneLoading: boolean;
    customBrokerPhoneId: number | undefined;
    CustomBrokerPhoneData: IAllCustomBrokerPhoneResult[];
    paginationCustomBrokerPhone: ITablePagination;
    isModalOpen: boolean;
    pressKeyboard: boolean;
  };
  dispatch: {
    setEditClearanceWorkersActiveKey: Dispatch<SetStateAction<string>>;
    setEditContactInfo: Dispatch<
      SetStateAction<IEditContactsTable | undefined>
    >;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setIsRegister: Dispatch<SetStateAction<boolean>>;
    setBasicInfoEditMode: Dispatch<SetStateAction<boolean>>;
    setCustomBrokerPhoneId: Dispatch<SetStateAction<number | undefined>>;
    setPaginationCustomBrokerPhone: Dispatch<SetStateAction<ITablePagination>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;
  };
  func: { GetAllCustomBrokerPhone: (CustomBrokerId: number) => void };
  form: {
    form: FormInstance<any> | undefined;
    editBasicInformationForm: FormInstance<any> | undefined;
    editContactForm: FormInstance<any> | undefined;
  };
}

export const EditClearanceWorkersContext = createContext<IContext | undefined>(
  undefined
);

export const EditClearanceWorkersProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [editClearanceWorkersActiveKey, setEditClearanceWorkersActiveKey] =
    useState<string>(IEditClearanceWorkersTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [editContactInfo, setEditContactInfo] = useState<IEditContactsTable>();
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [basicInfoEditMode, setBasicInfoEditMode] = useState<boolean>(false);
  const [customBrokerPhoneId, setCustomBrokerPhoneId] = useState<number>();
  const [CustomBrokerPhoneLoading, setCustomBrokerPhoneLoading] =
    useState<boolean>(false);
  const [paginationCustomBrokerPhone, setPaginationCustomBrokerPhone] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 10,
    });
  const [CustomBrokerPhoneData, setCustomBrokerPhoneData] = useState<
    IAllCustomBrokerPhoneResult[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [editBasicInformationForm] = Form.useForm();
  const [editContactForm] = Form.useForm();

  ///////////////////////////function////////////////////////////////
  const GetAllCustomBrokerPhone = useCallback(
    async (CustomBrokerId: number) => {
      if (!(CustomBrokerId > 0)) return;
      setCustomBrokerPhoneLoading(true);
      try {
        const { GetAllCustomBrokerPhone } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationCustomBrokerPhone.current,
          Limit: paginationCustomBrokerPhone.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllCustomBrokerPhone(
          searchParams.toString(),
          CustomBrokerId
        );
        if (result && result.status === 200 && result.data) {
          setCustomBrokerPhoneData(result.data.records);
          setPaginationCustomBrokerPhone((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setCustomBrokerPhoneLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationCustomBrokerPhone.current, paginationCustomBrokerPhone.pageSize]
  );
  const ContextValue: IContext = {
    value: {
      editClearanceWorkersActiveKey,
      editContact,
      editContactInfo,
      isRegister,
      basicInfoEditMode,
      customBrokerPhoneId,
      CustomBrokerPhoneData,
      CustomBrokerPhoneLoading,
      paginationCustomBrokerPhone,
      isModalOpen,
      pressKeyboard,
    },
    dispatch: {
      setEditClearanceWorkersActiveKey,
      setEditContactInfo,
      setEditContact,
      setIsRegister,
      setBasicInfoEditMode,
      setCustomBrokerPhoneId,
      setPaginationCustomBrokerPhone,
      setIsModalOpen,
      setPressKeyboard,
    },
    func: { GetAllCustomBrokerPhone },
    form: { form, editBasicInformationForm, editContactForm },
  };
  return (
    <EditClearanceWorkersContext.Provider value={ContextValue}>
      {children}
    </EditClearanceWorkersContext.Provider>
  );
};

export const useEditClearanceWorkers = () =>
  useContext(EditClearanceWorkersContext);
