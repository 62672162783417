import { FC } from "react";
import { PortsContainer } from "./style";
import { PortsHeader } from "./components/portsHeader";
import { AddNewPort } from "./components/addNewPort";
import { PortsTable } from "./components/portsTable";

export const Ports: FC = () => {
  return (
    <div>
      <PortsHeader />
      <div className="flex justify-center">
        <PortsContainer>
          <AddNewPort />
          <PortsTable />
        </PortsContainer>
      </div>
    </div>
  );
};
