import axios from "axios";
import { BaseInfoUrls } from "./urls";
import {
  IAddBank,
  IAddBankResponder,
  IAddCountry,
  IAddCurrency,
  IAddCustomBroker,
  IAddInspection,
  IAddInspectionResponder,
  IAddInsurance,
  IAddInsuranceResponder,
  IAddPort,
  IAddProduct,
  IAddSupplier,
  IAddSupplierResponder,
  IAddTransport,
  IAddTransportResponder,
  IAddUnit,
  IAddVessel,
  IAddWarehouse,
  IAddWarehouseResponder,
} from "./models/args.models";
import { IDataModel } from "../models";
import { checkActionPermission } from "src/helper/checkPermissionFunc";
import * as GuardPermissions from "./guardPath";
import {
  IAllBankResponderDetailsResult,
  IAllBankRespondersResult,
  IAllBankResult,
  IAllCountryResult,
  IAllCurrencyResult,
  IAllCustomBrokerPhoneResult,
  IAllCustomBrokerResponderDetailsResult,
  IAllCustomBrokerResult,
  IAllInspectionResponderDetailsResult,
  IAllInspectionRespondersResult,
  IAllInspectionResult,
  IAllInsuranceResponderDetailsResult,
  IAllInsuranceRespondersResult,
  IAllInsuranceResult,
  IAllPortResult,
  IAllProductResult,
  IAllSupplierResponderDetailsResult,
  IAllSupplierRespondersResult,
  IAllSupplierResult,
  IAllTransportResponderDetailsResult,
  IAllTransportRespondersResult,
  IAllTransportResult,
  IAllUnitResult,
  IAllVesselResult,
  IAllWarehouseResponderDetailsResult,
  IAllWarehouseRespondersResult,
  IAllWarehouseResult,
  IInspectionResponderDetailsByIdResult,
  IInsuranceResponderDetailsByIdResult,
  ISupplierResponderDetailsByIdResult,
  IWarehouseResponderDetailsByIdResult,
  IBankResponderDetailsByIdResult,
  ITransportResponderDetailsByIdResult,
} from "./models/result.models";
export class BaseInfoService {
  ////////////product-service////////////////////////////////////////////////////////////////
  async GetAllProduct(queries?: string) {
    if (!checkActionPermission(GuardPermissions.AllProductPath)) {
      return undefined;
    }
    let endPoint: string = BaseInfoUrls.AllProduct;
    if (endPoint) endPoint = endPoint.concat(`? ${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllProductResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddProduct(data: IAddProduct) {
    if (!checkActionPermission(GuardPermissions.AddProductPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<void>(BaseInfoUrls.AddProduct, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateProduct(data: IAddProduct, id: number) {
    if (!checkActionPermission(GuardPermissions.UpdateProductPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.UpdateProduct}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteProduct(id: number) {
    if (!checkActionPermission(GuardPermissions.DeleteProductPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.DeleteProduct}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////unit-service////////////////////////////////////////////////////////////////
  async GetAllUnit(queries?: string) {
    if (!checkActionPermission(GuardPermissions.AllUnitPath)) {
      return undefined;
    }
    let endPoint: string = BaseInfoUrls.AllUnit;
    if (endPoint) endPoint = endPoint.concat(`? ${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllUnitResult[]>>(endPoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddUnit(data: IAddUnit) {
    if (!checkActionPermission(GuardPermissions.AddUnitPath)) {
      return undefined;
    }
    try {
      const response = await axios.post<void>(BaseInfoUrls.AddUnit, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateUnit(data: IAddUnit, id: number) {
    if (!checkActionPermission(GuardPermissions.UpdateUnitPath)) {
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.UpdateUnit}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteUnit(id: number) {
    if (!checkActionPermission(GuardPermissions.DeleteUnitPath)) {
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.DeleteUnit}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////currency-service////////////////////////////////////////////////////////////////
  async AddCurrency(data: IAddCurrency) {
    if (!checkActionPermission(GuardPermissions.AddCurrencyPath))
      return undefined;
    try {
      const response = await axios.post<void>(BaseInfoUrls.AddCurrency, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllCurrency(queries?: string) {
    if (!checkActionPermission(GuardPermissions.AllCurrencyPath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllCurrency;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllCurrencyResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateCurrency(data: IAddCurrency, id: number) {
    if (!checkActionPermission(GuardPermissions.UpdateCurrencyPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.UpdateCurrency}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteCurrency(id: number) {
    if (!checkActionPermission(GuardPermissions.DeleteCurrencyPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.DeleteCurrency}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////vessel-service////////////////////////////////////////////////////////////////
  async AddVessel(data: IAddVessel) {
    if (!checkActionPermission(GuardPermissions.AddVesselPath))
      return undefined;
    try {
      const response = await axios.post<void>(BaseInfoUrls.AddVessel, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllVessel(queries: string) {
    if (!checkActionPermission(GuardPermissions.AllVesselPath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllVessel;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllVesselResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateVessel(data: IAddVessel, id: number) {
    if (!checkActionPermission(GuardPermissions.UpdateVesselPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.UpdateVessel}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteVessel(id: number) {
    if (!checkActionPermission(GuardPermissions.DeleteVesselPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.DeleteVessel}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////Country-service////////////////////////////////////////////////////////////////
  async AddCountry(data: IAddCountry) {
    if (!checkActionPermission(GuardPermissions.AddCountryPath))
      return undefined;
    try {
      const response = await axios.post<void>(BaseInfoUrls.AddCountry, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllCountry(queries?: string) {
    if (!checkActionPermission(GuardPermissions.AllCountryPath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllCountry;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllCountryResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateCountry(data: IAddCountry, id: number) {
    if (!checkActionPermission(GuardPermissions.UpdateCountryPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.UpdateCountry}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteCountry(id: number) {
    if (!checkActionPermission(GuardPermissions.DeleteCountryPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.DeleteCountry}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////Port-service////////////////////////////////////////////////////////////////
  async AddPort(data: IAddPort) {
    if (!checkActionPermission(GuardPermissions.AddPortPath)) return undefined;
    try {
      const response = await axios.post<void>(BaseInfoUrls.AddPort, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllPort(queries: string) {
    if (!checkActionPermission(GuardPermissions.AllPortPath)) return undefined;
    let endPoint: string = BaseInfoUrls.AllPort;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllPortResult[]>>(endPoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdatePort(data: IAddPort, id: number) {
    if (!checkActionPermission(GuardPermissions.UpdatePortPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.UpdatePort}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeletePort(id: number) {
    if (!checkActionPermission(GuardPermissions.DeletePortPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.DeletePort}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////supplier-service////////////////////////////////////////////////////////////////
  async GetAllSupplier(queries?: string) {
    if (!checkActionPermission(GuardPermissions.AllSupplierPath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllSupplier;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllSupplierResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddSupplier(data: IAddSupplier) {
    if (!checkActionPermission(GuardPermissions.AddSupplierPath))
      return undefined;
    try {
      const response = await axios.post<number>(BaseInfoUrls.AddSupplier, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateSupplier(data: IAddSupplier, id: number) {
    if (!checkActionPermission(GuardPermissions.UpdateSupplierPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.UpdateSupplier}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteSupplier(id: number) {
    if (!checkActionPermission(GuardPermissions.DeleteSupplierPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.DeleteSupplier}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddSupplierResponder(data: IAddSupplierResponder, id: number) {
    if (!checkActionPermission(GuardPermissions.AddSupplierResponderPath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${id}${BaseInfoUrls.AddSupplierResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllSupplierResponders(queries: string, id: number) {
    if (!checkActionPermission(GuardPermissions.AllSupplierRespondersPath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${id}${BaseInfoUrls.AllSupplierResponders}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllSupplierRespondersResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateSupplierResponder(data: IAddSupplierResponder, id: number) {
    if (!checkActionPermission(GuardPermissions.UpdateSupplierResponderPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${id}${BaseInfoUrls.UpdateSupplierResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteSupplierResponder(id: number) {
    if (!checkActionPermission(GuardPermissions.DeleteSupplierResponderPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${id}${BaseInfoUrls.DeleteSupplierResponder}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddSupplierPhone(id: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.AddSupplierPhonePath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.AddSupplierPhone}`),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateSupplierPhone(supplierPhoneId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.UpdateSupplierPhonePath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${supplierPhoneId}${BaseInfoUrls.UpdateSupplierPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteSupplierPhone(supplierPhoneId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteSupplierPhonePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${supplierPhoneId}${BaseInfoUrls.DeleteSupplierPhone}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetSupplierResponderDetailsById(queries: string, id: number) {
    if (
      !checkActionPermission(GuardPermissions.SupplierResponderDetailsByIdPath)
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${id}${BaseInfoUrls.SupplierResponderDetailsById}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<ISupplierResponderDetailsByIdResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllSupplierResponderDetails(SupplierId: number, queries: string) {
    if (
      !checkActionPermission(GuardPermissions.AllSupplierResponderDetailsPath)
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${SupplierId}${BaseInfoUrls.AllSupplierResponderDetails}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllSupplierResponderDetailsResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////inspection-service////////////////////////////////////////////////////////////////
  async GetAllInspection(queries?: string) {
    if (!checkActionPermission(GuardPermissions.AllInspectionPath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllInspection;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllInspectionResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddInspection(data: IAddInspection) {
    if (!checkActionPermission(GuardPermissions.AddInspectionPath))
      return undefined;
    try {
      const response = await axios.post<number>(
        BaseInfoUrls.AddInspection,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInspection(data: IAddInspection, InspectionId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateInspectionPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InspectionId}${BaseInfoUrls.UpdateInspection}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInspection(InspectionId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInspectionPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InspectionId}${BaseInfoUrls.DeleteInspection}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddInspectionResponder(
    data: IAddInspectionResponder,
    InspectionId: number
  ) {
    if (!checkActionPermission(GuardPermissions.AddInspectionResponderPath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InspectionId}${BaseInfoUrls.AddInspectionResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllInspectionResponders(queries: string, InspectionId: number) {
    if (!checkActionPermission(GuardPermissions.AllInspectionRespondersPath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${InspectionId}${BaseInfoUrls.AllInspectionResponders}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllInspectionRespondersResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInspectionResponder(
    data: IAddInspectionResponder,
    InspectionResponderId: number
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateInspectionResponderPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InspectionResponderId}${BaseInfoUrls.UpdateInspectionResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInspectionResponder(InspectionResponderId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInspectionResponderPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InspectionResponderId}${BaseInfoUrls.DeleteInspectionResponder}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddInspectionPhone(InspectionResponderId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.AddInspectionPhonePath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InspectionResponderId}${BaseInfoUrls.AddInspectionPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInspectionPhone(InspectionPhoneId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.UpdateInspectionPhonePath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InspectionPhoneId}${BaseInfoUrls.UpdateInspectionPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInspectionPhone(InspectionPhoneId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInspectionPhonePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InspectionPhoneId}${BaseInfoUrls.DeleteInspectionPhone}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetInspectionResponderDetailsById(
    queries: string,
    InspectionResponderId: number
  ) {
    if (
      !checkActionPermission(
        GuardPermissions.InspectionResponderDetailsByIdPath
      )
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${InspectionResponderId}${BaseInfoUrls.InspectionResponderDetailsById}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IInspectionResponderDetailsByIdResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllInspectionResponderDetails(
    InspectionId: number,
    queries: string
  ) {
    if (
      !checkActionPermission(GuardPermissions.AllInspectionResponderDetailsPath)
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${InspectionId}${BaseInfoUrls.AllInspectionResponderDetails}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllInspectionResponderDetailsResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////insurance-service////////////////////////////////////////////////////////////////
  async GetAllInsurance(queries: string) {
    if (!checkActionPermission(GuardPermissions.AllInsuracePath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllInsurace;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllInsuranceResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddInsurance(data: IAddInsurance) {
    if (!checkActionPermission(GuardPermissions.AddInsurancePath))
      return undefined;
    try {
      const response = await axios.post<number>(
        BaseInfoUrls.AddInsurance,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInsurance(data: IAddInsurance, InsuranceId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateInsurancePath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InsuranceId}${BaseInfoUrls.UpdateInsurance}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInsurance(InsuranceId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInsurancePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InsuranceId}${BaseInfoUrls.DeleteInsurance}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddInsuranceResponder(
    data: IAddInsuranceResponder,
    InsuranceId: number
  ) {
    if (!checkActionPermission(GuardPermissions.AddInsuranceResponderPath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InsuranceId}${BaseInfoUrls.AddInsuranceResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllInsuranceResponders(queries: string, InsuranceId: number) {
    if (!checkActionPermission(GuardPermissions.AllInsuranceRespondersPath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${InsuranceId}${BaseInfoUrls.AllInsuranceResponders}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllInsuranceRespondersResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInsuranceResponder(
    data: IAddInsuranceResponder,
    InsuranceResponderId: number
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateInsuranceResponderPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InsuranceResponderId}${BaseInfoUrls.UpdateInsuranceResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInsuranceResponder(InsuranceResponderId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInsuranceResponderPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InsuranceResponderId}${BaseInfoUrls.DeleteInsuranceResponder}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddInsurancePhone(InsuranceResponderId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.AddInsurancePhonePath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InsuranceResponderId}${BaseInfoUrls.AddInsurancePhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInsurancePhone(InsurancePhoneId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.UpdateInsurancePhonePath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InsurancePhoneId}${BaseInfoUrls.UpdateInsurancePhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInsurancePhone(InsurancePhoneId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteInsurancePhonePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${InsurancePhoneId}${BaseInfoUrls.DeleteInsurancePhone}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetInsuranceResponderDetailsById(
    queries: string,
    InsuranceResponderId: number
  ) {
    if (
      !checkActionPermission(GuardPermissions.InsuranceResponderDetailsByIdPath)
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${InsuranceResponderId}${BaseInfoUrls.InsuranceResponderDetailsById}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IInsuranceResponderDetailsByIdResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllInsuranceResponderDetails(InsuranceId: number, queries: string) {
    if (
      !checkActionPermission(GuardPermissions.AllInsuranceResponderDetailsPath)
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${InsuranceId}${BaseInfoUrls.AllInsuranceResponderDetails}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllInsuranceResponderDetailsResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  /////////////////////////Warehouse////////////////////////////////

  async AllWarehouse(queries: string) {
    if (!checkActionPermission(GuardPermissions.AllWarehousePath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllWarehouse;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllWarehouseResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddWarehouse(data: IAddWarehouse) {
    if (!checkActionPermission(GuardPermissions.AddWarehousePath))
      return undefined;
    try {
      const response = await axios.post<number>(
        BaseInfoUrls.AddWarehouse,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateWarehouse(data: IAddWarehouse, WarehouseId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateWarehousePath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${WarehouseId}${BaseInfoUrls.UpdateWarehouse}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteWarehouse(WarehouseId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteWarehousePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${WarehouseId}${BaseInfoUrls.DeleteWarehouse}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddWarehouseResponder(
    data: IAddWarehouseResponder,
    WarehouseId: number
  ) {
    if (!checkActionPermission(GuardPermissions.AddWarehouseResponderPath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${WarehouseId}${BaseInfoUrls.AddWarehouseResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllWarehouseResponders(queries: string, WarehouseId: number) {
    if (!checkActionPermission(GuardPermissions.AllWarehouseRespondersPath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${WarehouseId}${BaseInfoUrls.AllWarehouseResponders}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllWarehouseRespondersResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateWarehouseResponder(
    data: IAddWarehouseResponder,
    WarehouseResponderId: number
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateWarehouseResponderPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${WarehouseResponderId}${BaseInfoUrls.UpdateWarehouseResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteWarehouseResponder(WarehouseResponderId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteWarehouseResponderPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${WarehouseResponderId}${BaseInfoUrls.DeleteWarehouseResponder}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddWarehousePhone(WarehouseResponderId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.AddWarehousePhonePath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${WarehouseResponderId}${BaseInfoUrls.AddWarehousePhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateWarehousePhone(WarehousePhoneId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.UpdateWarehousePhonePath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${WarehousePhoneId}${BaseInfoUrls.UpdateWarehousePhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteWarehousePhone(WarehousePhoneId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteWarehousePhonePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${WarehousePhoneId}${BaseInfoUrls.DeleteWarehousePhone}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetWarehouseResponderDetailsById(
    queries: string,
    WarehouseResponderId: number
  ) {
    if (
      !checkActionPermission(GuardPermissions.WarehouseResponderDetailsByIdPath)
    )
      return undefined;
    let endPoint: string = BaseInfoUrls.BaseInfo.concat(
      `${WarehouseResponderId}${BaseInfoUrls.WarehouseResponderDetailsById}`
    );
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IWarehouseResponderDetailsByIdResult[]>
      >(endPoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllWarehouseResponderDetails(WarehouseId: number, queries: string) {
    if (
      !checkActionPermission(GuardPermissions.AllWarehouseResponderDetailsPath)
    )
      return undefined;
    let endPoint: string = BaseInfoUrls.BaseInfo.concat(
      `${WarehouseId}${BaseInfoUrls.AllWarehouseResponderDetails}`
    );
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllWarehouseResponderDetailsResult[]>
      >(endPoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////bank-service////////////////////////////////////////////////////////////////
  async GetAllBank(queries?: string) {
    if (!checkActionPermission(GuardPermissions.AllBankPath)) return undefined;
    let endPoint: string = BaseInfoUrls.AllBank;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllBankResult[]>>(endPoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddBank(data: IAddBank) {
    if (!checkActionPermission(GuardPermissions.AddBankPath)) return undefined;
    try {
      const response = await axios.post<number>(BaseInfoUrls.AddBank, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateBank(data: IAddBank, BankId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateBankPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${BankId}${BaseInfoUrls.UpdateBank}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteBank(BankId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteBankPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${BankId}${BaseInfoUrls.DeleteBank}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddBankResponder(data: IAddBankResponder, BankId: number) {
    if (!checkActionPermission(GuardPermissions.AddBankResponderPath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${BankId}${BaseInfoUrls.AddBankResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllBankResponders(queries: string, BankId: number) {
    if (!checkActionPermission(GuardPermissions.AllBankRespondersPath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${BankId}${BaseInfoUrls.AllBankResponders}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllBankRespondersResult[]>>(
        endPOint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateBankResponder(data: IAddBankResponder, BankResponderId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateBankResponderPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${BankResponderId}${BaseInfoUrls.UpdateBankResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteBankResponder(BankResponderId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteBankResponderPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${BankResponderId}${BaseInfoUrls.DeleteBankResponder}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddBankPhone(BankResponderId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.AddBankPhonePath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${BankResponderId}${BaseInfoUrls.AddBankPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateBankPhone(BankPhoneId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.UpdateBankPhonePath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${BankPhoneId}${BaseInfoUrls.UpdateBankPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteBankPhone(BankPhoneId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteBankPhonePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${BankPhoneId}${BaseInfoUrls.DeleteBankPhone}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetBankPhonesByResponderId(queries: string, ResponderId: number) {
    if (!checkActionPermission(GuardPermissions.BankPhonesByResponderIdPath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${ResponderId}${BaseInfoUrls.BankPhonesByResponderId}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IBankResponderDetailsByIdResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllBankResponderDetails(BankId: number, queries: string) {
    if (!checkActionPermission(GuardPermissions.AllBankResponderDetailsPath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${BankId}${BaseInfoUrls.AllBankResponderDetails}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllBankResponderDetailsResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  ////////////transport-service////////////////////////////////////////////////////////////////
  async GetAllTransport(queries: string) {
    if (!checkActionPermission(GuardPermissions.AllTransportPath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllTransport;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllTransportResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddTransport(data: IAddTransport) {
    if (!checkActionPermission(GuardPermissions.AddTransportPath))
      return undefined;
    try {
      const response = await axios.post<number>(
        BaseInfoUrls.AddTransport,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateTransport(data: IAddTransport, TransportId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateTransportPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${TransportId}${BaseInfoUrls.UpdateTransport}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteTransport(TransportId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteTransportPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${TransportId}${BaseInfoUrls.DeleteTransport}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddTransportResponder(
    data: IAddTransportResponder,
    TransportId: number
  ) {
    if (!checkActionPermission(GuardPermissions.AddTransportResponderPath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${TransportId}${BaseInfoUrls.AddTransportResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllTransportResponders(queries: string, TransportId: number) {
    if (!checkActionPermission(GuardPermissions.AllTransportRespondersPath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${TransportId}${BaseInfoUrls.AllTransportResponders}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllTransportRespondersResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateTransportResponder(
    data: IAddTransportResponder,
    TransportResponderId: number
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateTransportResponderPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${TransportResponderId}${BaseInfoUrls.UpdateTransportResponder}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteTransportResponder(TransportResponderId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteTransportResponderPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${TransportResponderId}${BaseInfoUrls.DeleteTransportResponder}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddTransportPhone(TransportResponderId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.AddTransportPhonePath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${TransportResponderId}${BaseInfoUrls.AddTransportPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateTransportPhone(TransportPhoneId: number, phoneNumber: string) {
    if (!checkActionPermission(GuardPermissions.UpdateTransportPhonePath))
      return undefined;

    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${TransportPhoneId}${BaseInfoUrls.UpdateTransportPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteTransportPhone(TransportPhoneId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteTransportPhonePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${TransportPhoneId}${BaseInfoUrls.DeleteTransportPhone}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetTransportResponderDetailsById(queries: string, ResponderId: number) {
    if (
      !checkActionPermission(GuardPermissions.TransportResponderDetailsByIdPath)
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${ResponderId}${BaseInfoUrls.TransportResponderDetailsById}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<ITransportResponderDetailsByIdResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllTransportResponderDetails(TransportId: number, queries: string) {
    if (
      !checkActionPermission(GuardPermissions.AllTransportResponderDetailsPath)
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${TransportId}${BaseInfoUrls.AllTransportResponderDetails}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllTransportResponderDetailsResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  ////////////customBroker-service////////////////////////////////////////////////////////////////
  async GetAllCustomBroker(queries: string) {
    if (!checkActionPermission(GuardPermissions.AllCustomBrokerPath))
      return undefined;
    let endPoint: string = BaseInfoUrls.AllCustomBroker;
    if (endPoint) endPoint = endPoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IAllCustomBrokerResult[]>>(
        endPoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddCustomBroker(data: IAddCustomBroker) {
    if (!checkActionPermission(GuardPermissions.AddCustomBrokerPath))
      return undefined;
    try {
      const response = await axios.post<number>(
        BaseInfoUrls.AddCustomBroker,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateCustomBroker(data: IAddCustomBroker, CustomBrokerId: number) {
    if (!checkActionPermission(GuardPermissions.UpdateCustomBrokerPath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${CustomBrokerId}${BaseInfoUrls.UpdateCustomBroker}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteCustomBroker(CustomBrokerId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteCustomBrokerPath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${CustomBrokerId}${BaseInfoUrls.DeleteCustomBroker}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddCustomBrokerPhone(phoneNumber: string, CustomBrokerId: number) {
    if (!checkActionPermission(GuardPermissions.AddCustomBrokerPhonePath))
      return undefined;
    try {
      const response = await axios.post<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${CustomBrokerId}${BaseInfoUrls.AddCustomBrokerPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateCustomBrokerPhone(
    CustomBrokerPhoneId: number,
    phoneNumber: string
  ) {
    if (!checkActionPermission(GuardPermissions.UpdateCustomBrokerPhonePath))
      return undefined;
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${CustomBrokerPhoneId}${BaseInfoUrls.UpdateCustomBrokerPhone}`
        ),
        { phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteCustomBrokerPhone(CustomBrokerPhoneId: number) {
    if (!checkActionPermission(GuardPermissions.DeleteCustomBrokerPhonePath))
      return undefined;
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(
          `${CustomBrokerPhoneId}${BaseInfoUrls.DeleteCustomBrokerPhone}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetAllCustomBrokerResponderDetails(
    CustomBrokerId: number,
    queries: string
  ) {
    if (
      !checkActionPermission(
        GuardPermissions.AllCustomBrokerResponderDetailsPath
      )
    )
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${CustomBrokerId}${BaseInfoUrls.AllCustomBrokerResponderDetails}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllCustomBrokerResponderDetailsResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetAllCustomBrokerPhone(queries: string, CustomBrokerId: number) {
    if (!checkActionPermission(GuardPermissions.AllCustomBrokerPhonePath))
      return undefined;
    let endPOint: string = BaseInfoUrls.BaseInfo.concat(
      `${CustomBrokerId}${BaseInfoUrls.AllCustomBrokerPhone}`
    );
    if (endPOint) endPOint = endPOint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<IAllCustomBrokerPhoneResult[]>
      >(endPOint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
