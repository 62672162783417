import { Popconfirm, PopconfirmProps } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
interface IDPopconfirm extends Omit<PopconfirmProps, "title"> {
  description: string;
  deleteClassName?: boolean;
}
export const DPopconfirm: FC<IDPopconfirm> = (props) => {
  const { words } = useLanguage();
  const { children, description, deleteClassName } = props;
  return (
    <Popconfirm
      {...props}
      icon=""
      placement="topRight"
      title={deleteClassName ? words.global.delete : words.global.reset}
      description={description}
      rootClassName={deleteClassName ? "deletePopconfirm" : "resetPopConfirm"}
      okText={words.global.yes}
      cancelText={words.global.no}
      okType={deleteClassName ? "danger" : "default"}
    >
      <>{children}</>
    </Popconfirm>
  );
};
