import { FC, useState } from "react";
import { OriginContainer } from "./style";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
  UploadProps,
} from "antd";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import { useOrigin } from "./context";
import { HeaderProforma } from "src/components/UiKit/HeaderProforma";
import { deliveryTerm } from "./model";
import { CheckboxProps, UploadFile } from "antd/lib";
import { dummyRequest } from "src/helper/dummyRequest";
import { UploadChangeParam } from "antd/es/upload";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { DeletePopconfirm } from "src/components/UiKit/DeletePopConfirm";

export const Origin: FC = () => {
  const {
    value: {
      supplier,
      supplierLoading,
      country,
      countryLoading,
      currency,
      currencyLoading,
      inspection,
      inspectionLoading,
      editMode,
    },
    dispatch: { setEditMode },
  } = useOrigin()!;
  const [form] = Form.useForm();
  const { words } = useLanguage();
  const { Option } = Select;
  const navigate = useNavigate();
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [file, setFile] = useState<UploadFile<any>[]>([]);

  const onChangeCheckbox: CheckboxProps["onChange"] = () => {};

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setFile([info.file]);

    if (info.file.status === "uploading") {
      //setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setFile(info.fileList);
    }
  };
  const onClickCancel = () => {
    setEditMode(false);
    navigate(ROUTES.orderManagement);
  };
  const onClickReset = () => {
    form?.resetFields();
    setPressKeyboard(false);
    setFile([]);
  };

  const update = (values: any) => {};
  const add = (values: any) => {};
  const onFinish = (values: any) => {
    if (editMode) {
      update(values);
    } else {
      add(values);
    }
  };

  return (
    <div>
      <HeaderProforma
        headerName={
          editMode
            ? words.orders.origin.editSourceInfo
            : words.orders.origin.recordSourceInfo
        }
      />
      <Flex justify="center">
        <OriginContainer>
          <Form form={form} onFinish={onFinish}>
            <Col span={24}>
              <div className="py-[16px]">
                {editMode
                  ? words.orders.editInfo
                  : words.orders.origin.originInfo}
              </div>
            </Col>
            <Col span={24} className="py-[16px]">
              <Row gutter={24}>
                <Col span={8}>
                  <FormItem
                    name={"supplier"}
                    label={words.basicInformation.suppliers.suppliers}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={supplierLoading}
                    >
                      {supplier?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.name}>
                          <Space>{item.name}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"contractNo"}
                    label={words.orders.origin.contractNo}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={words.global.insert}
                      onKeyDown={() => setPressKeyboard(true)}
                      onPressEnter={form?.submit}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"contractDate"}
                    label={words.orders.origin.contractDate}
                    rules={[{ required: true }]}
                  >
                    <DatePicker placeholder={words.global.insert} />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"bank"}
                    label={words.basicInformation.countries.countries}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={countryLoading}
                    >
                      {country?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.faName}>
                          <Space>{item.faName}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"deliveryTerm"}
                    label={words.orders.origin.deliveryTerm}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                    >
                      {deliveryTerm?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.name}>
                          <Space>{item.name}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"currencies"}
                    label={words.basicInformation.currencies.currencies}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={currencyLoading}
                    >
                      {currency?.map((item, index) => (
                        <Option
                          key={index}
                          value={item.id}
                          label={item.shortName}
                        >
                          <Space>{item.shortName}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"inspectionCompanies"}
                    label={
                      words.basicInformation.inspectionCompanies
                        .inspectionCompanies
                    }
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={words.global.select}
                      optionLabelProp="label"
                      showSearch
                      optionFilterProp="label"
                      loading={inspectionLoading}
                    >
                      {inspection?.map((item, index) => (
                        <Option key={index} value={item.id} label={item.name}>
                          <Space>{item.name}</Space>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"Warehousing"}
                    label={""}
                    className="checkboxHolder"
                  >
                    <Checkbox onChange={onChangeCheckbox}>
                      {words.orders.origin.warehousing}
                    </Checkbox>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <div className="uploadHolder">
                    <div className="p-x[12px] flex flex-col justify-center">
                      <div className="text-[14px]">
                        {words.global.fileUpload}
                      </div>
                      <div className="text-[10px]">
                        {words.global.fileUploadInfo}
                      </div>
                    </div>
                    <div
                      className={
                        file.length > 0 ? "w-[66px] h-[66px]" : `fileHolder`
                      }
                    >
                      <Upload
                        name="file"
                        onChange={handleChange}
                        customRequest={dummyRequest}
                        showUploadList={false}
                      >
                        <Button
                          type={file.length > 0 ? "text" : "default"}
                          icon={
                            file.length > 0 ? (
                              <img
                                src="/assets/images/fileUploaded.svg"
                                alt="fileUploaded"
                              />
                            ) : (
                              <span className="material-icons flex justify-center items-center text-[20px]">
                                attach_file
                              </span>
                            )
                          }
                          className="text-[10px]"
                        >
                          {file.length > 0 ? "" : words.global.add}
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider dashed />
              <div
                className={`flex py-[24px]  ${
                  editMode ? "justify-between" : "justify-end"
                }`}
              >
                {editMode ? (
                  <div className="!min-w-[85px]">
                    <DeletePopconfirm
                      endPointName={words.orders.origin.deleteSourceInformation}
                      onConfirm={() => {}}
                      className="!min-w-[85px]"
                    >
                      <Button danger className="!min-w-[85px]">
                        {words.global.delete}
                      </Button>
                    </DeletePopconfirm>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex gap-[16px] justify-end borderTop">
                  {editMode ? (
                    <div className="!min-w-[85px] h-[100%]">
                      <Button type="default" onClick={onClickCancel}>
                        {words.global.cancel}
                      </Button>
                    </div>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={onClickReset}
                    >
                      <div className="!min-w-[85px] h-[100%]">
                        <Button
                          type="default"
                          disabled={!pressKeyboard || file.length === 0}
                        >
                          {words.global.reset}
                        </Button>
                      </div>
                    </DPopconfirm>
                  )}
                  <Button
                    className="!min-w-[85px]"
                    type="primary"
                    onClick={() => {
                      setEditMode(false);
                      navigate(ROUTES.orderManagement);
                    }}
                    // loading={addLoading}
                  >
                    {editMode ? words.global.saveEdit : words.global.add}
                  </Button>
                </div>
              </div>
            </Col>
          </Form>
        </OriginContainer>
      </Flex>
    </div>
  );
};
