import { Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  IEditContactsTable,
  IEditTransportCompaniesTabs,
} from "src/services/Identity/models/mockModels/transp.models";
import { ResponderTableData } from "../models";
import { ITransportResponderDetailsByIdResult } from "src/services/BaseInfo/models/result.models";
import { ITablePagination } from "src/models/interfaces/pagination";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { IObject } from "src/models/interfaces";

interface IContext {
  value: {
    editTransportCompaniesActiveKey: string;
    editContact: boolean;
    editContactInfo: IEditContactsTable | undefined;
    pressKeyboard: boolean;
    editResponse: boolean;
    selectedResponsive: IEditContactsTable | undefined;
    allRespondersData: ResponderTableData[] | undefined;
    responderContactEditMode: boolean;
    TransportResponderId: number | undefined;
    responderPhoneDetailLoading: boolean;
    responderPhoneData: ITransportResponderDetailsByIdResult[];
    paginationPhoneNumber: ITablePagination;
    isModalOpen: boolean;
    allTransportResponders: boolean;
    TransportPhoneId: number | undefined;
  };
  dispatch: {
    setEditTransportCompaniesActiveKey: Dispatch<SetStateAction<string>>;
    setEditContactInfo: Dispatch<
      SetStateAction<IEditContactsTable | undefined>
    >;
    setEditContact: Dispatch<SetStateAction<boolean>>;
    setEditResponse: Dispatch<SetStateAction<boolean>>;
    setPressKeyboard: Dispatch<SetStateAction<boolean>>;
    setSelectedResponsive: Dispatch<
      SetStateAction<IEditContactsTable | undefined>
    >;
    setResponderContactEditMode: Dispatch<SetStateAction<boolean>>;
    setTransportResponderId: Dispatch<SetStateAction<number | undefined>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setTransportPhoneId: Dispatch<SetStateAction<number | undefined>>;
  };
  func: {
    GetAllTransportResponders: (id: number) => void;
    getResponderPhoneDetail: (id: number) => void;
  };
  form: {
    form: FormInstance<any> | undefined;
    editBasicInformationForm: FormInstance<any> | undefined;
    editContactForm: FormInstance<any> | undefined;
    respondentNameForm: FormInstance<any> | undefined;
    RCInformationForm: FormInstance<any> | undefined;
  };
}

export const EditTransportCompanyContext = createContext<IContext | undefined>(
  undefined
);

export const EditTransportCompanyProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [editTransportCompaniesActiveKey, setEditTransportCompaniesActiveKey] =
    useState<string>(IEditTransportCompaniesTabs.basicInformation);
  const [editContact, setEditContact] = useState<boolean>(false);
  const [selectedResponsive, setSelectedResponsive] = useState<
    IEditContactsTable | undefined
  >();
  const [editResponse, setEditResponse] = useState<boolean>(false);

  const [editContactInfo, setEditContactInfo] = useState<IEditContactsTable>();
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [paginationResponder, setPaginationResponder] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [allTransportResponders, setAllTransportResponders] =
    useState<boolean>(false);
  const [allRespondersData, setAllRespondersData] = useState<
    ResponderTableData[]
  >([]);
  const [responderContactEditMode, setResponderContactEditMode] =
    useState<boolean>(false);
  const [responderPhoneDetailLoading, setResponderPhoneDetailLoading] =
    useState<boolean>(false);
  const [paginationPhoneNumber, setPaginationPhoneNumber] =
    useState<ITablePagination>({
      current: 1,
      pageSize: 5,
    });
  const [responderPhoneData, setResponderPhoneData] = useState<
    ITransportResponderDetailsByIdResult[]
  >([]);
  const [TransportResponderId, setTransportResponderId] = useState<number>();
  const [TransportPhoneId, setTransportPhoneId] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [editBasicInformationForm] = Form.useForm();
  const [editContactForm] = Form.useForm();
  const [respondentNameForm] = Form.useForm();
  const [RCInformationForm] = Form.useForm();

  ////////////////////////////////////////////////////////////////func////////////////////////////////
  const GetAllTransportResponders = useCallback(
    async (id: number) => {
      setAllTransportResponders(true);
      try {
        const { GetAllTransportResponders: GetAllTransportR } =
          new BaseInfoService();
        const params: IObject = {
          Offset: paginationResponder.current,
          Limit: paginationResponder.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetAllTransportR(searchParams.toString(), id);
        if (result && result.status === 200 && result.data) {
          setAllRespondersData(result.data.records);
          setPaginationResponder((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setAllTransportResponders(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationResponder.current, paginationResponder.pageSize]
  );

  const getResponderPhoneDetail = useCallback(
    async (id: number) => {
      setResponderPhoneDetailLoading(true);
      try {
        const { GetTransportResponderDetailsById } = new BaseInfoService();
        const params: IObject = {
          Offset: paginationPhoneNumber.current,
          Limit: paginationPhoneNumber.pageSize,
        };
        const searchParams = new URLSearchParams(params);
        const result = await GetTransportResponderDetailsById(
          searchParams.toString(),
          id
        );
        if (result && result.status === 200) {
          setResponderPhoneData(result.data.records);
          setPaginationPhoneNumber((prev) => ({
            ...prev,
            total: result.data.count,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setResponderPhoneDetailLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationPhoneNumber.current, paginationPhoneNumber.pageSize]
  );

  const ContextValue: IContext = {
    value: {
      editTransportCompaniesActiveKey,
      editContact,
      editContactInfo,
      pressKeyboard,
      selectedResponsive,
      editResponse,
      allRespondersData,
      responderContactEditMode,
      TransportResponderId,
      responderPhoneDetailLoading,
      responderPhoneData,
      paginationPhoneNumber,
      isModalOpen,
      allTransportResponders,
      TransportPhoneId,
    },
    dispatch: {
      setEditTransportCompaniesActiveKey,
      setEditContactInfo,
      setEditContact,
      setPressKeyboard,
      setSelectedResponsive,
      setEditResponse,
      setResponderContactEditMode,
      setTransportResponderId,
      setIsModalOpen,
      setTransportPhoneId,
    },
    func: { GetAllTransportResponders, getResponderPhoneDetail },
    form: {
      form,
      editBasicInformationForm,
      editContactForm,
      respondentNameForm,
      RCInformationForm,
    },
  };
  return (
    <EditTransportCompanyContext.Provider value={ContextValue}>
      {children}
    </EditTransportCompanyContext.Provider>
  );
};

export const useEditTransportCompany = () =>
  useContext(EditTransportCompanyContext);
