import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import useLanguage from "src/store/language";
import { OperatingBanksContainer } from "./style";
import { IAddNewOperatingBanksTabs, IOperatingBanksTabs } from "./models";
import { useOperatingBanks } from "./context";
import { OperatingBanksTable } from "./components/OperatingBanksTable";
import { AddNewOperatingBanks } from "./components/AddNewOperatingBank";

export const OperatingBanks: FC = () => {
  const {
    value: { OperatingBanksActiveKey },
    dispatch: {
      setOperatingBanksActiveKey,
      setAddNewOperatingBanksActiveKey,
      setEditContact,
    },
    form: { basicInformationForm, contactForm },
  } = useOperatingBanks()!;
  const { words } = useLanguage();

  const items: TabsProps["items"] = [
    {
      key: IOperatingBanksTabs.tableOfOperatingBanks,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">table_chart</span>
          {words.basicInformation.operatingBanks.operatingBanksTable}
        </span>
      ),
      children: <OperatingBanksTable />,
    },
    {
      key: IOperatingBanksTabs.addNewOperatingBanks,
      label: (
        <span className="flex gap-[8px] justify-center items-center">
          <span className="material-icons text-[20px]">add_box</span>
          {words.basicInformation.operatingBanks.addNewOperatingBank}
        </span>
      ),
      children: <AddNewOperatingBanks />,
    },
  ];

  const onChange = (key: string) => {
    setOperatingBanksActiveKey(key);
    setAddNewOperatingBanksActiveKey(
      IAddNewOperatingBanksTabs.basicInformation
    );
    contactForm?.resetFields();
    basicInformationForm?.resetFields();
    setEditContact(false);
  };
  return (
    <OperatingBanksContainer>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={OperatingBanksActiveKey}
      />
    </OperatingBanksContainer>
  );
};
