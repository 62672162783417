import { Button, Col, Divider, Form, Row, Typography, message } from "antd";
import { FC, useEffect, useState } from "react";
import useLanguage from "src/store/language";
import { AddNewVesselContainer } from "../style";
import { FormItem } from "src/components/UiKit/FormItem";
import { UseVessels } from "../context";
import { IAddVessel } from "src/services/BaseInfo/models/args.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { Guard } from "src/components/Guard";
import {
  AddVesselPath,
  UpdateVesselPath,
} from "src/services/BaseInfo/guardPath";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { DInput } from "src/components/UiKit/DInput";

export const AddNewVessel: FC = () => {
  const { words } = useLanguage();

  const {
    value: { editMode, productId },
    dispatch: { setSubmit, setEditMode },
    form: { form },
    func: { getVesselsTableData },
  } = UseVessels();
  const [pressKeyboard, setPressKeyboard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fromValue = Form.useWatch([], form);

  useEffect(() => {
    form
      ?.validateFields({ validateOnly: true })
      .then(() => {
        setSubmit(true);
      })
      .catch(() => {
        setSubmit(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, fromValue]);

  const onFinishAddVessel = async (values: IAddVessel) => {
    setLoading(true);
    try {
      const { AddVessel } = new BaseInfoService();
      const result = await AddVessel(values);
      if (result && result.status === 200) {
        message.success(words.basicInformation.vessels.addVesselsMessage);
        getVesselsTableData();
        form?.resetFields();
        setPressKeyboard(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onFinishUpdateVessel = async (values: IAddVessel) => {
    setLoading(true);
    try {
      const { UpdateVessel } = new BaseInfoService();
      const result = await UpdateVessel(values, productId);
      if (result && result.status === 200) {
        message.success(words.basicInformation.vessels.updateVesselsMessage);
        getVesselsTableData();
        form?.resetFields();
        setPressKeyboard(false);
        setEditMode(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Guard action={[UpdateVesselPath, AddVesselPath]} multiPath>
      <Form
        form={form}
        onFinish={(values) => {
          editMode ? onFinishUpdateVessel(values) : onFinishAddVessel(values);
        }}
      >
        <AddNewVesselContainer gutter={24}>
          <Col span={24}>
            <Typography>
              {words.basicInformation.vessels.vesselsInformation}
            </Typography>
          </Col>
          <Col span={24}>
            <Row className="productHolder" gutter={[24, 24]}>
              <Col span={8}>
                <FormItem
                  name={"vesselName"}
                  label={words.basicInformation.vessels.vesselName}
                  rules={[{ required: true }]}
                >
                  <DInput
                    placeholder={words.global.insert}
                    onKeyDown={() => setPressKeyboard(true)}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"flag"}
                  label={words.basicInformation.vessels.flag}
                >
                  <DInput
                    placeholder={words.global.insert}
                    onKeyDown={() => setPressKeyboard(true)}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name={"age"}
                  label={words.basicInformation.vessels.ageOfVessel}
                >
                  <DInput
                    placeholder={words.global.insert}
                    onKeyDown={() => setPressKeyboard(true)}
                    numbermode
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <div className="flex gap-[16px] justify-end h-[100%] items-center">
                  {editMode ? (
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={form?.submit}
                      loading={loading}
                    >
                      {words.global.saveEdit}
                    </Button>
                  ) : (
                    <Button
                      className="!min-w[85px]"
                      type="primary"
                      disabled={!pressKeyboard}
                      onClick={form?.submit}
                      loading={loading}
                    >
                      {words.global.add}
                    </Button>
                  )}

                  {editMode ? (
                    <Button
                      type="default"
                      className="!min-w[85px]"
                      onClick={() => {
                        setEditMode(false);
                        form?.resetFields();
                        setPressKeyboard(false);
                      }}
                    >
                      {words.global.cancel}
                    </Button>
                  ) : (
                    <DPopconfirm
                      description={words.global.descriptionPopConfirm}
                      onConfirm={() => {
                        setPressKeyboard(false);
                        form?.resetFields();
                      }}
                    >
                      <Button
                        type="default"
                        className="!min-w[85px]"
                        disabled={!pressKeyboard}
                      >
                        {words.global.reset}
                      </Button>
                    </DPopconfirm>
                  )}
                </div>
              </Col>
              <Divider />
            </Row>
          </Col>
        </AddNewVesselContainer>
      </Form>
    </Guard>
  );
};
