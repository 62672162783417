import { FC } from "react";
import { Col, Divider, Row, Typography } from "antd";
import useLanguage from "src/store/language";
import { useLocation } from "react-router-dom";
import { ViewWarehousesContainer } from "./style";
import { HeaderViewWarehouses } from "./header";
import { ResponderAndContactNumberTable } from "./table";
import { IAllWarehouseResult } from "src/services/BaseInfo/models/result.models";
const { Title, Text } = Typography;

export const ViewWarehouses: FC = () => {
  const { words } = useLanguage();
  const location = useLocation();
  const values: IAllWarehouseResult | undefined = location.state;

  return (
    <div>
      <HeaderViewWarehouses />
      <Divider />
      <div className="flex !justify-center">
        <ViewWarehousesContainer>
          <div className="w-full">
            <Col span={24} className="px-[24px]">
              <Row>
                <Col span={24}>
                  <Title level={4} className="pb-[8px]">
                    {words.global.companyInformation}
                  </Title>
                </Col>
                <Col span={24}>
                  <div className="borderViewItems ">
                    <div className="flex pb-[16px]">
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {words.basicInformation.warehouses.warehousesName}
                              :
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Text>{values?.name}</Text>
                          </Col>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {
                                words.basicInformation.warehouses
                                  .warehousesNumber
                              }
                              :
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Text>{values?.number}</Text>
                          </Col>
                        </div>
                      </Col>
                    </div>
                    <div className="flex pb-[16px]">
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {
                                words.basicInformation.warehouses
                                  .warehousesLocation
                              }
                              :
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Text>{values?.location}</Text>
                          </Col>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="flex gap-[10px]">
                          <Col span={4}>
                            <Title level={5}>
                              {words.basicInformation.warehouses.address}:
                            </Title>
                          </Col>
                          <Col span={20}>
                            <Text>{values?.address}</Text>
                          </Col>
                        </div>
                      </Col>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="px-[24px]">
              <Title level={4} className="px-[12px] pt-[32px] pb-[24px]">
                {words.global.Contacts}
              </Title>
              <ResponderAndContactNumberTable />
            </Col>
          </div>
        </ViewWarehousesContainer>
      </div>
    </div>
  );
};
