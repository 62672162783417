import { FC, useMemo, useState } from "react";
import { Layout, Skeleton } from "antd";
import { ContentContainer } from "./styles/content";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { LayoutContainer } from "./styles";
import { Outlet, useLocation } from "react-router-dom";
import { AppContextProviders } from "src/components/AppContextProviders";
import useLanguage from "src/store/language";
import { useUserInfo } from "src/hook/useUserInfo";

const { Content } = Layout;

export const AdminPanelLayout: FC = () => {
  const { pathname } = useLocation();
  const { isRtl } = useLanguage();
  const [collapsed, setCollapsed] = useState(false);
  const sidebarWidth = useMemo(() => (collapsed ? 32 + 48 : 262), [collapsed]);
  useUserInfo();
  return (
    <AppContextProviders>
      <Skeleton loading={false} active avatar>
        <LayoutContainer path={pathname}>
          <Layout
            className="layout"
            style={{
              marginLeft: isRtl ? 0 : sidebarWidth,
              marginRight: isRtl ? sidebarWidth : 0,
            }}
          >
            <Sidebar
              width={sidebarWidth}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
            <Layout className="contentWrapper">
              <Header />
              <Content>
                <ContentContainer path={pathname}>
                  <Outlet />
                </ContentContainer>
              </Content>
            </Layout>
          </Layout>
        </LayoutContainer>
      </Skeleton>
    </AppContextProviders>
  );
};
