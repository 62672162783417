import { App, Col, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import useLanguage from "src/store/language";
import { getRowKey } from "src/helper/getRowKey";

import { ContactsTableContainer } from "../../style";

import { useWarehouses } from "src/modules/Warehouses/context";
import { IAllWarehouseRespondersResult } from "src/services/BaseInfo/models/result.models";
import { BaseInfoService } from "src/services/BaseInfo/baseInfo.service";
import { Guard } from "src/components/Guard";
import {
  DeleteWarehouseResponderPath,
  UpdateWarehouseResponderPath,
} from "src/services/BaseInfo/guardPath";

export const RespondentContactTable: FC = () => {
  const {
    dispatch: {
      setResponderEditMode,
      setPaginationResponder,
      setCurrentWarehouseResponderId,
    },
    form: { respondentNameForm },
    value: {
      allRespondersData,
      allWarehouseRespondersLoading,
      paginationResponder,
    },
    func: { GetAllWarehouseResponders },
  } = useWarehouses()!;
  const { words } = useLanguage();
  const { message } = App.useApp();
  const deleteResponder = async (id: number) => {
    try {
      const { DeleteWarehouseResponder } = new BaseInfoService();
      const result = await DeleteWarehouseResponder(id);
      if (result && result.status === 200) {
        message.success(
          words.basicInformation.suppliers.deleteSupplierResponderMessage
        );
        respondentNameForm?.resetFields();
        GetAllWarehouseResponders();
        setResponderEditMode(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columns: ColumnsType<IAllWarehouseRespondersResult> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 56,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.global.responsive,
      dataIndex: "responderName",
      key: "name",
      width: 233,
      align: "center",
      className: "!font-bold",
    },
    {
      title: words.global.tools,
      dataIndex: "tools",
      key: "tools",
      width: 118,
      align: "center",
      className: "!font-bold",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateWarehouseResponderPath}>
            <span
              onClick={() => {
                setResponderEditMode(true);
                respondentNameForm?.setFieldsValue({
                  fullName: record.responderName,
                });
                setCurrentWarehouseResponderId(record.id);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <Guard action={DeleteWarehouseResponderPath}>
            <DPopconfirm
              deleteClassName
              onConfirm={async () => {
                await deleteResponder(record.id);
              }}
              description={words.global.deletePhoneNumberPopConfirm}
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  return (
    <ContactsTableContainer>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={allRespondersData}
          loading={allWarehouseRespondersLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%] min-h-[300px]"
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: false,
            total: paginationResponder.total,
            current: paginationResponder.current,
            pageSize: paginationResponder.pageSize,
            onChange: (page: number, pageSize: number) => {
              setPaginationResponder((prev) => ({
                ...prev,
                current: page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
    </ContactsTableContainer>
  );
};
