import { ReactNode } from "react";
import {
  IAllTransportResponderDetailsResult,
  IAllTransportRespondersResult,
  IAllTransportResult,
  ITransportResponderDetailsByIdResult,
} from "src/services/BaseInfo/models/result.models";

export enum ITransportCompaniesTabs {
  tableOfTransportCompanies = "1",
  addNewTransportCompanies = "2",
}
export enum IAddNewTransportCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export enum IEditTransportCompaniesTabs {
  basicInformation = "1",
  Contacts = "2",
}
export interface TableData extends IAllTransportResult {
  "#"?: number;
  tools?: ReactNode;
  responderAndContactNumber?: ReactNode;
}
export interface ResponderTableData extends IAllTransportRespondersResult {
  "#"?: number;
  tools?: ReactNode;
}
export interface TransportDetailTableData
  extends IAllTransportResponderDetailsResult {
  "#"?: number;
}
export interface ResponderPhoneTableData
  extends ITransportResponderDetailsByIdResult {
  "#"?: number;
  tools?: ReactNode;
}
export interface IViewData {
  TransportDetailData: IAllTransportResponderDetailsResult[];
  record: TableData;
}
