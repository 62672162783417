import axios from "axios";
import { USER_JWT_TOKEN } from "src/constants";
import { responseHandler } from "src/services/responseHandler";
import { useLogout } from "./auth";

export const useAxiosConfiguration = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const { logoutHandler } = useLogout();
  axios.interceptors.response.use(
    (response) => {
      if (response.status !== 200) responseHandler(response);
      if (response.status === 401) {
        logoutHandler();
      }
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutHandler();
      }
      responseHandler(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem(USER_JWT_TOKEN);
      if (accessToken && !config.url?.includes("Login")) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      responseHandler(error);
      throw Promise.reject(error);
    }
  );
};
