import { Col, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import useLanguage from "src/store/language";
import { TableData } from "../models";
import { DPopconfirm } from "src/components/UiKit/PopConfirm";
import { useChangeParams } from "src/hook/auth/useParamsChange";
import { VesselsTableContainer } from "../style";
import { getRowKey } from "src/helper/getRowKey";
import { IAllVesselResult } from "src/services/BaseInfo/models/result.models";
import { Guard } from "src/components/Guard";
import {
  DeleteVesselPath,
  UpdateVesselPath,
} from "src/services/BaseInfo/guardPath";
import { UseVessels } from "../context";
import { useSearchParams } from "react-router-dom";

export const VesselsTable: FC = () => {
  const { words } = useLanguage();
  const {
    form: { form },
    dispatch: { setEditMode, setPagination, setProductId },
    value: { dataSource, pagination, productLoading },
    func: { getVesselsTableData, deleteVessel },
  } = UseVessels()!;
  const onChangeParams = useChangeParams();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.current.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);
  useEffect(() => {
    getVesselsTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Columns: ColumnsType<TableData> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "56px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.basicInformation.vessels.vesselName,
      dataIndex: "vesselName",
      key: "vesselName",
      align: "center",
    },
    {
      title: words.basicInformation.vessels.ageOfVessel,
      dataIndex: "age",
      key: "age",
      align: "center",
    },
    {
      title: words.basicInformation.vessels.flag,
      dataIndex: "flag",
      key: "flag",
      align: "center",
    },
    {
      title: words.global.tools,
      key: "id",
      width: "144px",
      align: "center",
      render: (text, record: IAllVesselResult) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateVesselPath}>
            <span
              onClick={() => {
                setEditMode(true);
                form?.setFieldsValue(record);
                setProductId(record.id);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <Guard action={DeleteVesselPath}>
            <DPopconfirm
              deleteClassName
              onConfirm={() => deleteVessel(record.id)}
              description={words.basicInformation.vessels.deleteVessel}
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer mt-[10px]">
                delete_forever
              </span>
            </DPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];

  return (
    <VesselsTableContainer>
      <Col span={24}>
        <Typography className="py-[16px]">
          {words.basicInformation.vessels.vesselsTable}
        </Typography>
      </Col>
      <Col span={24} className="tableHolder">
        <Table
          columns={Columns}
          loading={productLoading}
          scroll={{ x: "auto" }}
          rowKey={getRowKey}
          className="w-[100%]"
          dataSource={dataSource}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: pagination.total,
            current: pagination.current,
            onChange: (page: number, pageSize: number) => {
              setPagination((prev) => ({ ...prev, current: page, pageSize }));
              onChangeParams("Limit", pageSize.toString());
              onChangeParams("Offset", page.toString());
            },
            onShowSizeChange: (current: number, size: number) => {
              onChangeParams("Limit", size.toString());
              onChangeParams("Offset", current.toString());
              setPagination((prev) => ({ ...prev, current }));
            },
          }}
        />
      </Col>
    </VesselsTableContainer>
  );
};
